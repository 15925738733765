import { ReactNode, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  wrapperId: string;
  children: ReactNode;
}

function createWrapperAndAppendToBody(wrapperId: string): HTMLDivElement {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);

  return document.body.appendChild(wrapperElement);
}

export const Portal = ({
  children,
  wrapperId,
}: PortalProps): JSX.Element | null => {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement>();

  useLayoutEffect(() => {
    const elementFromHtml = document.getElementById(wrapperId);

    if (elementFromHtml) {
      setWrapperElement(elementFromHtml);
      return () => {};
    }

    const createdElement = createWrapperAndAppendToBody(wrapperId);
    setWrapperElement(createdElement);

    return () => {
      if (createdElement?.parentNode) {
        createdElement.parentNode.removeChild(createdElement);
      }
    };
  }, [wrapperId]);

  if (!wrapperElement) {
    return null;
  }

  return createPortal(children, wrapperElement);
};
