import { ErrorCodeEnum } from '@app/config/error-config';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useUser } from '../context-providers/user-context/use-user';
import { ApiError } from '../error/api-error';
import {
  extractOcpiTariffsOnChargePointResponse,
  mapOcpiTariffModelToTariffModel,
} from '../helper/ocpi-utils';
import {
  ChargePointModel,
  ChargePointResponseModel,
} from '../model/charge-point.model';
import { chargePointService } from './charge-point.service';

type ChargePointServiceHookReturn = UseQueryResult<ChargePointModel | null> & {
  isBeingInstalled: boolean;
};

export const useChargePointService = (
  chargePointId: string | null
): ChargePointServiceHookReturn => {
  const { user } = useUser();

  const result = useQuery({
    queryKey: ['chargePoint', chargePointId],

    queryFn: async ({ queryKey }) =>
      chargePointService
        .getChargePoint(queryKey[1])
        .then(
          (
            payload: ChargePointResponseModel | null
          ): ChargePointModel | null => {
            if (!payload) {
              return null;
            }

            const ocpiTariff = extractOcpiTariffsOnChargePointResponse(
              payload,
              user?.emspSubscriptionIds
            );

            return {
              ...payload,
              ...(ocpiTariff && {
                tariff: mapOcpiTariffModelToTariffModel(ocpiTariff),
              }),
            };
          }
        ),

    enabled: !!chargePointId,
    staleTime: 5 * 60 * 1000,
  });

  const isBeingInstalled = useMemo(
    () =>
      (result.error as ApiError)?.code === ErrorCodeEnum.OCPI_EVSE_NOT_FOUND ||
      (result.error as ApiError)?.code ===
        ErrorCodeEnum.OCPI_LOCATION_NOT_FOUND,
    [result.error]
  );

  return {
    ...result,
    isBeingInstalled,
  };
};
