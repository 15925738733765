/* eslint-disable no-console */

import { LogLevelEnum, LoggerHandler } from './logger.types';

const ConsoleLogger = {
  [LogLevelEnum.DEBUG]: console.debug,
  [LogLevelEnum.INFO]: console.log,
  [LogLevelEnum.WARN]: console.warn,
  [LogLevelEnum.ERROR]: console.error,
};

export const ConsoleHandler: LoggerHandler = (
  logLevel: LogLevelEnum,
  ...parameters: any[]
): void => {
  ConsoleLogger[logLevel](...parameters);
};
