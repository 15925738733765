import { useTranslation } from 'react-i18next';
import { InfosWithLegend } from '@app/shared/infos-with-legend/infos-with-legend';
import classNames from 'classnames';

import css from './live-total-kwh.module.scss';

type Props = {
  energyDelivered?: number;
  className?: string;
  disabled?: boolean;
};

export const LiveTotalKwh = (props: Props): JSX.Element => {
  const { energyDelivered = 0, disabled, className } = props;
  const { t } = useTranslation();

  return (
    <InfosWithLegend
      disabled={disabled}
      className={classNames(className, css.container)}
      icon="lightning-02"
      value={t('charging.live-total-kwh.value', {
        value: energyDelivered,
      })}
      label={t('charging.live-total-kwh.label')}
    />
  );
};
