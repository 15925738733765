import { nanoid } from 'nanoid';

import { WS_TOKEN_SSK } from '@app/config/sessionstorage-keys.const';
import {
  getFromSessionStorage,
  setInSessionStorage,
} from '@app/core/storage/session-storage';

// See nanoid collision calculator before editing this number : https://zelark.github.io/nano-id-cc/
const WS_TOKEN_SIZE = 36;

export function recoverWsTokenOrCreate(): string {
  const existingSessionStorage = getFromSessionStorage(WS_TOKEN_SSK);

  if (existingSessionStorage) {
    return existingSessionStorage;
  }

  return setInSessionStorage(WS_TOKEN_SSK, nanoid(WS_TOKEN_SIZE));
}

export interface AuthSocketOptions {
  webAppToken: string;
}
