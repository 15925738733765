import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Link } from '../typography/link/link';
import css from './back-link.module.scss';
import { Icon } from '../icon/icon';

interface BackLinkProps {
  onClick: () => void;
  className?: string;
}

export const BackLink = ({
  onClick,
  className = '',
}: BackLinkProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Link
      onClick={onClick}
      startIcon={<Icon name="arrow-narrow-left" />}
      className={classNames(css.backLink, className)}>
      {t('shared.back-link')}
    </Link>
  );
};
