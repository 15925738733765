import { AbortablePromise } from '@app/core/helper/abort-promise';
import { httpClientService } from '@app/core/client/http/http-client.service';
import {
  START_EMAIL_REGISTRATION_API,
  START_WEBAUTHN_REGISTRATION_API,
  VERIFY_EMAIL_REGISTRATION_API,
  VERIFY_WEBAUTHN_REGISTRATION_API,
} from '@app/config/api-routes.const';
import {
  EmailRegistrationVerificationRequestModel,
  RegistrationStartRequestModel,
  RegistrationStartResponseModel,
  WebauthnRegistrationStartResponseModel,
  WebauthnRegistrationVerificationRequestModel,
} from '@app/core/model/registration.model';

import { JwtResponseModel } from '@app/core/model/jwt-response.model';

class RegistrationService {
  startWebauthn(
    data: RegistrationStartRequestModel
  ): AbortablePromise<WebauthnRegistrationStartResponseModel> {
    return httpClientService.post<WebauthnRegistrationStartResponseModel>(
      START_WEBAUTHN_REGISTRATION_API,
      data
    );
  }

  verifyWebauthn(
    data: WebauthnRegistrationVerificationRequestModel
  ): AbortablePromise<JwtResponseModel> {
    return httpClientService.post<JwtResponseModel>(
      VERIFY_WEBAUTHN_REGISTRATION_API,
      data
    );
  }

  startEmail(
    data: RegistrationStartRequestModel
  ): AbortablePromise<RegistrationStartResponseModel> {
    return httpClientService.post<RegistrationStartResponseModel>(
      START_EMAIL_REGISTRATION_API,
      data
    );
  }

  verifyEmail(
    data: EmailRegistrationVerificationRequestModel
  ): AbortablePromise<JwtResponseModel> {
    return httpClientService.post<JwtResponseModel>(
      VERIFY_EMAIL_REGISTRATION_API,
      data
    );
  }
}

export const registrationService = new RegistrationService();
