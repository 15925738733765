import { HTMLProps } from 'react';
import classNames from 'classnames';

import css from './step-instruction-icon.module.scss';

export const StepInstructionIcon = (
  props: HTMLProps<HTMLDivElement>
): JSX.Element => {
  const { className, ...divProps } = props;

  return <div className={classNames(className, css.container)} {...divProps} />;
};
