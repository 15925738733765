import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ConnectorTypeEnum } from '@app/core/model/enum/connector-type.enum';

export function useChargePointConnectorType(
  connectorType?: ConnectorTypeEnum
): string {
  const { t, i18n } = useTranslation();

  const connectorTypeText = useMemo(() => {
    if (!connectorType) {
      return '-';
    }

    const key = `shared.charge-point.connector-type.${connectorType}`;
    const defaultKey = `shared.charge-point.connector-type.default`;

    return i18n.exists(key) ? t(key) : t(defaultKey);
  }, [connectorType, t, i18n]);

  return connectorTypeText;
}
