import { ForwardedRef, HTMLProps, forwardRef } from 'react';
import classNames from 'classnames';

import css from './base-layout.module.scss';

export interface BaseLayoutProps extends HTMLProps<HTMLDivElement> {}

const BaseLayout = (props: BaseLayoutProps): JSX.Element => {
  const { children, className, ...divProps } = props;

  return (
    <div className={classNames(css.layout, className)} {...divProps}>
      {children}
    </div>
  );
};

export interface BaseHeaderProps extends HTMLProps<HTMLDivElement> {}

const BaseHeader = (baseHeaderProps: BaseHeaderProps): JSX.Element => (
  <div {...baseHeaderProps} />
);

export interface BaseBodyProps extends HTMLProps<HTMLDivElement> {}

const BaseBody = ({
  className,
  ...baseBodyProps
}: BaseBodyProps): JSX.Element => (
  <div className={classNames(className, css.body)} {...baseBodyProps} />
);

export interface BaseFooterProps extends HTMLProps<HTMLDivElement> {}

const BaseFooter = forwardRef<HTMLDivElement, BaseFooterProps>(
  (
    { className, ...baseFooterProps }: BaseFooterProps,
    ref?: ForwardedRef<HTMLDivElement>
  ): JSX.Element => (
    <div
      ref={ref}
      className={classNames(className, css.footer)}
      {...baseFooterProps}
    />
  )
);

BaseLayout.Header = BaseHeader;
BaseLayout.Body = BaseBody;
BaseLayout.Footer = BaseFooter;

export { BaseLayout };
