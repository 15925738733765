import { ChargePointPriceUnitEnum } from '@app/core/model/enum/charge-point-price-unit.enum';
import { useTranslation } from 'react-i18next';

const ROOT_WITH_CURRENCY = 'tariff.unit';
const ROOT_WITHOUT_CURRENCY = 'tariff.unit-without-currency';

const UNIT_LABEL_MAPPER: Record<ChargePointPriceUnitEnum, string> = {
  [ChargePointPriceUnitEnum.PER_KWH]: 'per-kwh',
  [ChargePointPriceUnitEnum.PER_MIN]: 'per-min',
};
const DEFAULT_LABEL = 'flat';

export function usePriceUnit(
  unit?: ChargePointPriceUnitEnum | null,
  withCurrency = true
): string {
  const { t } = useTranslation();

  return t(
    `${withCurrency ? ROOT_WITH_CURRENCY : ROOT_WITHOUT_CURRENCY}.${
      unit ? UNIT_LABEL_MAPPER[unit] : DEFAULT_LABEL
    }`
  );
}
