import { HttpStatusEnum } from '@app/config/error-config';
import { TIMEOUT } from '@app/config/timeout.const';
import { WebsocketClientService } from '@app/core/client/websocket/websocket-client.service';
import { AxiosError } from 'axios';

export function receiveResponseFromHttpOrWebsocketPromise<T>(
  httpPromise: Promise<T>,
  wsToken: string
): Promise<T> {
  return new Promise((resolve, reject) => {
    httpPromise.then(resolve).catch((error: AxiosError) => {
      if (error?.response?.status.toString() !== HttpStatusEnum.TIMEOUT) {
        reject(error);
      }
    });

    WebsocketClientService.getInstance()
      .getOne<T>(wsToken, { timeout: TIMEOUT.default })
      .then(resolve)
      .catch(reject);
  });
}
