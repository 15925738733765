import { useTranslation } from 'react-i18next';

import { CallSupportButton } from '@app/shared/call-support/call-support-button';
import { ItemMenu } from '@app/shared/item-menu/item-menu';
import { ItemMenuInterface } from '@app/shared/item-menu/item-menu.interface';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { ListItemMenu } from '@app/shared/list-item-menu/list-item-menu';

import css from './help.module.scss';

interface HelpViewProps {
  items: ItemMenuInterface[];
}

export const HelpView = ({ items }: HelpViewProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PageLayout>
      <PageLayout.Title title={t('help.title')} icon="help-circle" />

      <PageLayout.Body>
        <ListItemMenu className={css.list}>
          {items.map((item) => (
            <ItemMenu
              key={item.label}
              bold
              label={t(item.label)}
              onClick={item.onClick}
              disabled={item.disabled}
            />
          ))}
        </ListItemMenu>

        <CallSupportButton />
      </PageLayout.Body>

      <PageLayout.AppbarFooter />
    </PageLayout>
  );
};
