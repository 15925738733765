import { useTranslation } from 'react-i18next';

import CarSvg from '@app/asset/car-charging/car.svg';
import CableSvg from '@app/asset/car-charging/cable.svg';
import ChargePointSvg from '@app/asset/car-charging/charge-point.svg';

import { useNavigateWithState } from '@app/router/redirect/use-navigate-with-state';
import {
  GUEST_FAQ_PATH,
  GUEST_LEGAL_TEXT_PATH,
} from '@app/config/app-paths.const';
import { Button } from '@app/shared/button/button';
import { Link } from '@app/shared/typography/link/link';
import { H1 } from '@app/shared/typography/heading/heading';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { OuterLink } from '@app/shared/outer-link/outer-link';
import { DISCOVER_URL } from '@app/config/external-url';
import { Icon } from '@app/shared/icon/icon';

import css from './home.module.scss';

interface HomeViewProps {
  onStart: () => void;
}

export const HomeView = (props: HomeViewProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigateWithState();

  const { onStart } = props;

  return (
    <PageLayout colored>
      <PageLayout.Header>
        <H1 className={css.title} color="light">
          {t('home.title')}
        </H1>
      </PageLayout.Header>

      <PageLayout.Body withTexture className={css.body}>
        <div className={css.car}>
          <CarSvg className={css.svgCar} />
          <div className={css.svgCableFill} />
          <CableSvg className={css.svgCable} />
          <ChargePointSvg className={css.svgChargepoint} />
        </div>
      </PageLayout.Body>

      <PageLayout.RawFooter className={css.footer}>
        <Button onClick={onStart}>
          <Icon name="log-in-02" />
          {t('home.start-button')}
        </Button>
        <div className={css.bottomLinks}>
          <OuterLink
            withIcon={false}
            color="light"
            className={css.discoverTheServiceLink}
            url={DISCOVER_URL}
            title={t('home.redirect-dialog')}>
            {t('home.discover-the-service')}
          </OuterLink>

          <Link onClick={() => navigate(GUEST_FAQ_PATH)}>{t('home.faq')}</Link>

          <Link onClick={() => navigate(GUEST_LEGAL_TEXT_PATH)}>
            {t('home.legal-text')}
          </Link>
        </div>
      </PageLayout.RawFooter>
    </PageLayout>
  );
};
