import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@app/shared/card/card';
import { Text } from '@app/shared/typography/text/text';
import { PaymentSchemeLogo } from '@app/shared/payment-scheme-logo/payment-scheme-logo';
import { PaymentSchemeEnum } from '@app/core/model/enum/payment-scheme.enum';
import { PaymentMethodModel } from '@app/core/model/payment-method.model';

import css from './credit-card-information.module.scss';

export interface CreditCardInformationProps
  extends Pick<
    PaymentMethodModel,
    'productType' | 'scheme' | 'last4' | 'expiryMonth' | 'expiryYear'
  > {}

export function CreditCardInformation(
  props: CreditCardInformationProps
): JSX.Element {
  const { t } = useTranslation();
  const { productType, scheme, last4, expiryMonth, expiryYear } = props;

  const securedBy = useMemo(() => {
    if (productType) {
      return productType;
    }

    switch (scheme) {
      case PaymentSchemeEnum.APPLE_PAY:
        return t('shared.credit-card-information.secured-by.apple');
      case PaymentSchemeEnum.GOOGLE_PAY:
        return t('shared.credit-card-information.secured-by.google');
      default:
        return t('shared.credit-card-information.secured-by.checkout');
    }
  }, [t, scheme, productType]);

  return (
    <Card background="colored" className={css.creditCard}>
      <div className={css.line}>
        <PaymentSchemeLogo
          className={css.scheme}
          paymentScheme={scheme}
          variant="light"
        />

        <Text className={css.securedBy}>{securedBy}</Text>
      </div>

      {last4 && (
        <div className={css.line}>
          <div className={css.pointGroup}>
            <div className={css.point} />
            <div className={css.point} />
            <div className={css.point} />
            <div className={css.point} />
          </div>

          <div className={css.pointGroup}>
            <div className={css.point} />
            <div className={css.point} />
            <div className={css.point} />
            <div className={css.point} />
          </div>

          <div className={css.pointGroup}>
            <div className={css.point} />
            <div className={css.point} />
            <div className={css.point} />
            <div className={css.point} />
          </div>

          <Text bold>{last4}</Text>
        </div>
      )}

      <div className={css.line}>
        <div />

        {expiryMonth && expiryYear && (
          <div className={css.expiration}>
            <Text>{t('shared.credit-card-information.expiration')}</Text>
            <Text bold>
              {t('shared.credit-card-information.expiration-date', {
                month: expiryMonth,
                year: expiryYear,
              })}
            </Text>
          </div>
        )}
      </div>
    </Card>
  );
}
