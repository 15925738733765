import {
  START_CHARGE_API,
  STOP_CHARGE_API,
} from '@app/config/api-routes.const';
import {
  httpClientService,
  HeadersEnum,
} from '@app/core/client/http/http-client.service';
import { matchPath } from 'react-router-dom';
import { getFromLocalStorage } from '@app/core/storage/local-storage';
import { JWT_LSK } from '@app/config/localstorage-keys.const';

export const SUITCAR_CHARGEPOINT_ID = '75e520da-d220-4f66-86de-06105689f26c';

function sendHardRequest(path) {
  return fetch(new URL(path, process.env.DELMONICOS_API), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      [HeadersEnum.CPO_ID]: process.env.DELMONICOS_DEFAULT_CPO_ID,
      [HeadersEnum.DELMO_CP_ID]: SUITCAR_CHARGEPOINT_ID,
      [HeadersEnum.JWT]: `Bearer ${getFromLocalStorage(JWT_LSK)}`,
    },
  });
}

export function registerSuitcar() {
  httpClientService.axios.interceptors.request.use(
    (config) => {
      const chargePointIdTarget = config.headers[HeadersEnum.DELMO_CP_ID];

      if (
        config.url &&
        matchPath(START_CHARGE_API, config.url) &&
        chargePointIdTarget === SUITCAR_CHARGEPOINT_ID
      ) {
        sendHardRequest(START_CHARGE_API).catch(() => {});
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  httpClientService.axios.interceptors.request.use(
    (config) => {
      const chargePointIdTarget = config.headers[HeadersEnum.DELMO_CP_ID];

      if (
        config.url &&
        matchPath(STOP_CHARGE_API, config.url) &&
        chargePointIdTarget === SUITCAR_CHARGEPOINT_ID
      ) {
        sendHardRequest(STOP_CHARGE_API).catch(() => {});
      }

      return config;
    },
    (error) => Promise.reject(error)
  );
}
