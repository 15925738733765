import { Navigate } from 'react-router-dom';

import { DASHBOARD_PATH } from '@app/config/app-paths.const';
import { OrderModel } from '@app/core/model/order.model';
import { useOrder } from '@app/core/context-providers/order-context/use-order';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { ErrorCodeEnum } from '@app/config/error-config';

export interface WithOrderProps {
  order: OrderModel;
  onOrder: (payload: OrderModel) => void;
}

type ComponentProps<T> = Omit<T, keyof WithOrderProps>;

export function withOrderGuard<T extends WithOrderProps = WithOrderProps>(
  WrappedComponent: React.ComponentType<T>
): (props: ComponentProps<T>) => JSX.Element {
  const ComponentWithOrder = (props: ComponentProps<T>): JSX.Element => {
    const orderProps = useOrder();

    if (!orderProps?.order) {
      showErrorMessage(
        'get-order',
        ErrorCodeEnum.WITH_ORDER_GUARD_HAS_NO_ORDER
      );

      return <Navigate to={DASHBOARD_PATH} />;
    }

    return <WrappedComponent {...(props as T)} {...orderProps} />;
  };

  return ComponentWithOrder;
}
