import { useTranslation } from 'react-i18next';
import { LineChartOptions } from 'chartist';

import { ChartProps } from './model/chart-props';
import { LineChart } from './line-chart/line-chart';
import { ChargeChartLayout } from './charge-chart-layout/charge-chart-layout';

function getChartOptions(numberOfPoints: number): Partial<LineChartOptions> {
  return {
    showPoint: numberOfPoints <= 16,
    showArea: false,
  };
}

export const SpeedChart = (props: ChartProps): JSX.Element => {
  const { points } = props;
  const { t } = useTranslation();

  return (
    <ChargeChartLayout
      icon="line-chart-up-01"
      title={t('charging.chart.speed.title')}
      subTitle={t('charging.chart.speed.sub-title')}>
      <LineChart points={points} options={getChartOptions(points.length)}>
        {({ chartRef }) => <div className="speed-chart" ref={chartRef} />}
      </LineChart>
    </ChargeChartLayout>
  );
};
