import { LEGAL_TEXT_API } from '@app/config/api-routes.const';
import { AbortablePromise } from '@app/core/helper/abort-promise';
import { httpClientService } from '@app/core/client/http/http-client.service';
import { LegalText } from '@app/core/model/legal-text';

class LegalTextService {
  list(): AbortablePromise<LegalText[]> {
    return httpClientService.list<LegalText[]>(LEGAL_TEXT_API);
  }
}

export const legalTextService = new LegalTextService();
