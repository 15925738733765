import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { ChargePointNotFoundView } from '@app/feature/charge-point/charge-point-not-found.view';
import { CreateQuoteRouterStateModel } from '@app/core/model/create-quote-router-state.model';
import { CREATE_QUOTE_PATH } from '@app/config/app-paths.const';
import { ChargePointModel } from '@app/core/model/charge-point.model';
import { useDrawer } from '@app/shared/drawer/use-drawer';

import { useCharge } from '@app/core/context-providers/charge-context/use-charge';
import { ChargePointView } from './charge-point.view';
import { ConnectorDrawer } from './component/connector-drawer/connector-drawer';
import { TariffDetailDrawer } from '../tariff/component/tariff-detail-drawer/tariff-detail-drawer';

type Props = {
  connectorList: ChargePointModel[];
  isError: boolean;
  isBeingInstalled: boolean;
};

export const ChargePointController = (props: Props): JSX.Element => {
  const { connectorList, isBeingInstalled, isError } = props;
  const { isCharging } = useCharge();

  const navigate = useNavigate();
  const scannedAt = useMemo(() => new Date(), []);
  const { drawerState, openDrawer, closeDrawer } = useDrawer();

  const [selectedConnector, setSelectedConnector] =
    useState<ChargePointModel | null>(null);

  const handleGoToQuote = (cpoId?: string, cpId?: string) => {
    if (!cpoId || !cpId) {
      return;
    }

    const state: CreateQuoteRouterStateModel = {
      delmoChargePointOperatorId: cpoId,
      delmoChargePointId: cpId,
      scannedAt,
    };

    navigate(CREATE_QUOTE_PATH, { state });
  };

  if (isError) {
    return <ChargePointNotFoundView isBeingInstalled={isBeingInstalled} />;
  }

  return (
    <>
      <ChargePointView
        name={connectorList.at(0)?.name}
        address={connectorList.at(0)?.address}
        physicalReference={
          connectorList.at(0)?.specifications.physicalReference
        }
        imageUrl={connectorList.at(0)?.imageUrl}
        connectors={connectorList}
        onSelect={(connector: ChargePointModel) =>
          setSelectedConnector(connector)
        }
      />

      <ConnectorDrawer
        connector={selectedConnector}
        tariff={selectedConnector?.tariff}
        onClose={() => setSelectedConnector(null)}
        onSelect={() =>
          handleGoToQuote(
            selectedConnector?.cpoId,
            selectedConnector?.chargePointId
          )
        }
        onTariffDetail={openDrawer}
        canChooseConnector={!isCharging}
      />

      {selectedConnector?.tariff && (
        <TariffDetailDrawer
          state={drawerState}
          onClose={closeDrawer}
          tariff={selectedConnector.tariff}
        />
      )}
    </>
  );
};
