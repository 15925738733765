import { ChargePointPriceUnitEnum } from '@app/core/model/enum/charge-point-price-unit.enum';
import { Text } from '@app/shared/typography/text/text';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import css from './single-price-square.module.scss';
import { usePriceUnit } from './use-price-unit';

type Props = {
  price?: number;
  unit?: ChargePointPriceUnitEnum | null;
  className?: string;
};

export function SinglePriceSquare(props: Props): JSX.Element {
  const { price, unit, className } = props;
  const { t } = useTranslation();
  const unitLabel = usePriceUnit(unit);

  return (
    <div className={classNames(className, css.container)}>
      {unit ? (
        <Text className={css.price}>{t('tariff.price', { value: price })}</Text>
      ) : (
        <Text className={css.price}>
          {t('tariff.currency', { value: price })}
        </Text>
      )}

      {unit && (
        <Text bold color="secondary">
          {t(unitLabel)}
        </Text>
      )}
    </div>
  );
}
