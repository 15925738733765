import { useCallback, useState } from 'react';
import { DrawerStateEnum } from './drawer';

interface UseDrawerHook {
  drawerState: DrawerStateEnum;
  openDrawer: () => void;
  minimizeDrawer: () => void;
  closeDrawer: () => void;
}

export const useDrawer = (): UseDrawerHook => {
  const [drawerState, setDrawerState] = useState(DrawerStateEnum.CLOSE);

  const closeDrawer = useCallback((): void => {
    setDrawerState(DrawerStateEnum.CLOSE);
  }, []);

  const openDrawer = useCallback((): void => {
    setDrawerState(DrawerStateEnum.OPEN);
  }, []);

  const minimizeDrawer = useCallback((): void => {
    setDrawerState(DrawerStateEnum.SMALL);
  }, []);

  return {
    drawerState,
    openDrawer,
    closeDrawer,
    minimizeDrawer,
  };
};
