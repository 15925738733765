import { SECURITY_PATH } from '@app/config/app-paths.const';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTypedTranslation } from '@app/core/helper/use-typed-translation';
import { WithUserProps } from '@app/router/guard/with-user.guard';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { ApiError } from '@app/core/error/api-error';
import { catchApiError } from '@app/core/error/catch-api-error';
import { userService } from '@app/core/service/user.service';

import { ManagePersonalInformationForm } from './use-manage-personal-information-form-validation';
import { ManagePersonalInformationView } from './manage-personal-information.view';

export const ManagePersonalInformationController = (
  props: WithUserProps
): JSX.Element => {
  const { user, refresh: refreshUser } = props;

  const navigate = useNavigate();
  const { t } = useTypedTranslation();

  const [pending, setPending] = useState(false);

  const handleSubmit = useCallback(
    async (updatedUser: ManagePersonalInformationForm) => {
      setPending(true);
      const [patchPromise, patchAbort] = userService.patch(updatedUser);

      patchPromise
        .then(() => {
          toast.success(t('personal-information.success-update'));
          refreshUser();
          navigate(SECURITY_PATH);
        })
        .catch((error) => {
          catchApiError(error, (apiError: ApiError) => {
            showErrorMessage('personal-information', apiError.code);
          });
        })
        .finally(() => setPending(false));

      return () => {
        patchAbort.abort();
      };
    },
    [navigate, t, refreshUser]
  );
  const handleBack = () => navigate(SECURITY_PATH);

  return (
    <ManagePersonalInformationView
      user={user}
      pending={pending}
      onSubmit={handleSubmit}
      onBack={handleBack}
    />
  );
};
