import {
  DEFAULT_CHECKOUT_MESSAGE,
  checkoutErrorsToMessage,
} from '@app/config/error-config';

type ErrorCode = keyof typeof checkoutErrorsToMessage;

export function mapCheckoutErrorToTranslationKey(code: string): string {
  if (!code || !(code in checkoutErrorsToMessage)) {
    return DEFAULT_CHECKOUT_MESSAGE;
  }

  const typedCode = code as ErrorCode;
  return checkoutErrorsToMessage[typedCode];
}
