import { HTMLProps } from 'react';
import classNames from 'classnames';

import {
  BaseBodyProps,
  BaseHeaderProps,
  BaseLayout,
  BaseLayoutProps,
} from '../base-layout/base-layout';

import css from './drawer-layout.module.scss';

const DrawerLayout = ({
  className,
  ...baseLayoutProps
}: BaseLayoutProps): JSX.Element => (
  <BaseLayout
    className={classNames(css.layout, className)}
    {...baseLayoutProps}
  />
);

const DrawerHeader = ({
  className,
  ...baseHeaderProps
}: BaseHeaderProps): JSX.Element => (
  <BaseLayout.Header
    className={classNames(css.header, className)}
    {...baseHeaderProps}
  />
);

const DrawerBody = ({
  className,
  ...baseBodyProps
}: BaseBodyProps): JSX.Element => (
  <BaseLayout.Body
    className={classNames(css.body, className)}
    {...baseBodyProps}
  />
);

const DrawerFooter = ({
  className,
  ...footerProps
}: HTMLProps<HTMLDivElement>): JSX.Element => (
  <div {...footerProps} className={classNames(css.footer, className)} />
);

DrawerLayout.Header = DrawerHeader;
DrawerLayout.Body = DrawerBody;
DrawerLayout.Footer = DrawerFooter;

export { DrawerLayout };
