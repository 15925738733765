import { OrderModel } from '../model/order.model';
import { InvoiceModel } from '../model/invoice.model';

export function mapOrderModelToInvoiceModel(order: OrderModel): InvoiceModel {
  return {
    invoiceId: order.orderId,

    orderId: order.orderId,

    pricing: order.pricing,

    customer: order.customer,

    chargePointName: order.chargePointName,

    cost: order.cost,

    createdAt: order.createdAt,

    startedAt: order.startedAt ?? order.createdAt,

    duration: order.duration ?? 0,

    energyDelivered: order.energyDelivered ?? 0,

    proforma: true,
  };
}
