import {
  EMAIL_CODE_LENGTH,
  EMAIL_PATTERN,
} from '@app/config/form-validation-rules.const';

export const StartEmailOtpFormValidationRules = {
  emailAddress: {
    required: true,
    pattern: EMAIL_PATTERN,
  },
};

export const VerifyEmailOtpFormValidationRules = {
  challenge: {
    required: true,
    minLength: EMAIL_CODE_LENGTH,
    maxLength: EMAIL_CODE_LENGTH,
  },
};
