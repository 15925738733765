import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { browserSupportsWebAuthn } from '@simplewebauthn/browser';

import { ACCOUNT_PATH } from '@app/config/app-paths.const';
import { useUser } from '@app/core/context-providers/user-context/use-user';

import { AppbarLink } from './appbar-link/appbar.link';

interface AccountLinkProps {
  on?: boolean;
}

export const AccountLink = ({ on = false }: AccountLinkProps): JSX.Element => {
  const { t } = useTranslation();
  const { user, isWebauthnEnabled } = useUser();

  const showBadge = useMemo(
    () =>
      !user?.isEmailAddressVerified ||
      (!isWebauthnEnabled && browserSupportsWebAuthn()),
    [user?.isEmailAddressVerified, isWebauthnEnabled]
  );

  return (
    <AppbarLink
      label={t('shared.app-bar.account')}
      icon="user-circle"
      to={ACCOUNT_PATH}
      on={on}
      badge={showBadge}
    />
  );
};
