import { HTMLProps } from 'react';
import classNames from 'classnames';

import css from './card.module.scss';

export interface CardProps extends HTMLProps<HTMLDivElement> {
  background?: 'paper' | 'colored';
  radius?: 'full' | 'half';
  border?: 'none' | 'secondary' | 'tertiary';
}

export const Card = ({
  background = 'paper',
  radius = 'full',
  border = 'none',
  className,
  ...props
}: CardProps): JSX.Element => (
  <div
    className={classNames(
      className,
      css.card,
      background === 'paper' && css.backgroundPaper,
      background === 'colored' && css.backgroundColored,
      radius === 'full' && css.radiusFull,
      radius === 'half' && css.radiusHalf,
      border === 'none' && css.borderNone,
      border === 'secondary' && css.borderSecondary,
      border === 'tertiary' && css.borderTertiary
    )}
    {...props}
  />
);
