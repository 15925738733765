import { PricingModel } from '@app/core/model/charge-point.model';
import { CostModel } from '@app/core/model/cost.model';
import { CustomerInformationsModel } from '@app/core/model/customer-information.model';
import { OrderResponseModel } from '@app/core/model/order-response.model';
import { OrderStatusEnum } from '@app/core/model/enum/order-status-enum';
import { PaymentMethodModel } from '@app/core/model/payment-method.model';
import { TariffModel } from './tariff.model';
import { mapOcpiTariffModelToTariffModel } from '../helper/ocpi-utils';

export class OrderModel {
  orderId: string;

  invoiceId?: string;

  status: OrderStatusEnum;

  pricing: PricingModel & {
    tariff?: TariffModel;
  };

  customer: CustomerInformationsModel;

  paymentInfos?: PaymentMethodModel;

  chargePointName: string;

  cost: CostModel;

  startedAt?: Date;

  duration?: number;

  energyDelivered?: number;

  createdAt: Date;

  proforma: boolean;

  constructor(data: OrderResponseModel) {
    const { tariff, ...pricing } = data.pricing;

    this.proforma = true;
    this.status = OrderStatusEnum[data.status as keyof typeof OrderStatusEnum];
    this.pricing = {
      ...pricing,
      ...(tariff && {
        tariff: mapOcpiTariffModelToTariffModel(tariff),
      }),
    };
    this.customer = data.customer;
    this.chargePointName = data.chargePointName;
    this.cost = {
      totalHT: data.cost?.totalHT,
      totalTTC: data.cost?.totalTTC,
      totalVAT: (data.cost?.totalTTC ?? 0) - (data.cost?.totalHT ?? 0),
      vatRate: data.cost.vatRate,
    };

    if (data.paymentInfos) {
      this.paymentInfos = new PaymentMethodModel(data.paymentInfos);
    }

    if (data.startChargeSession) {
      this.startedAt = new Date(data.startChargeSession);
    }

    this.duration = data.duration;
    this.energyDelivered = data.energyDelivered;

    this.createdAt = new Date(data.createdAt);
    this.orderId = data?.orderId;
  }
}
