import { Dialog } from '@app/shared/dialog/dialog';
import { useDialog } from '@app/shared/dialog/use-dialog';
import { Icon } from '@app/shared/icon/icon';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';
import { Text } from '@app/shared/typography/text/text';
import { useTranslation } from 'react-i18next';

import css from './pre-capture-warning.module.scss';

export function PrecaptureWarning(): JSX.Element {
  const { t } = useTranslation();
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  return (
    <>
      <Text color="secondary" className={css.warning} onClick={openDialog}>
        {t('quote.preauthorization-warning')}
        <Icon name="info-circle" />
      </Text>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogLayout>
          <DialogLayout.Body>
            <Icon
              color="primary"
              name="info-circle"
              className={css.dialogIcon}
            />

            <Text className={css.paragraph}>
              {t('quote.price-section.dialog.p1')}
            </Text>
            <Text className={css.paragraph}>
              {t('quote.price-section.dialog.p2')}
            </Text>
            <Text className={css.paragraph}>
              {t('quote.price-section.dialog.p3')}
            </Text>
          </DialogLayout.Body>
        </DialogLayout>
      </Dialog>
    </>
  );
}
