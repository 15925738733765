import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ApiError } from '@app/core/error/api-error';
import { catchApiError } from '@app/core/error/catch-api-error';
import {
  EmailOtpProcessStartRequestModel,
  EmailOtpProcessVerificationRequestModel,
} from '@app/core/model/email-otp-process.model';

import { showErrorMessage } from '@app/core/error/show-error-message';
import { ErrorCodeEnum } from '@app/config/error-config';
import { authenticationMethodService } from '@app/core/service/authentication-method.service';

interface ValidateEmailHookReturn {
  onStart: (payload: EmailOtpProcessStartRequestModel) => Promise<void>;
  onVerify: (payload: EmailOtpProcessVerificationRequestModel) => Promise<void>;
}

interface ValidateEmailHookProps {
  onSuccess: () => void;
}

export const useValidateEmail = (
  props: ValidateEmailHookProps
): ValidateEmailHookReturn => {
  const { onSuccess } = props;

  const { t } = useTranslation();

  const handleStart = useCallback(async () => {
    try {
      const [startPromise] = authenticationMethodService.startEmailValidation();
      await startPromise;
    } catch (err) {
      catchApiError(err, (error: ApiError) => {
        showErrorMessage('email-verification-failed', error.code);
      });

      // This is intercepted in EmailOTPController and prevents from going to the next page
      throw err;
    }
  }, []);

  const handleVerify = useCallback(
    async (payload: EmailOtpProcessVerificationRequestModel) => {
      const [verifyPromise] = authenticationMethodService.verifyEmailValidation(
        {
          challenge: payload.challenge,
        }
      );

      try {
        await verifyPromise;
        toast.success(
          t('manage-authentication-method.email.verify-code.success')
        );

        onSuccess();
      } catch (err) {
        catchApiError(err, (error: ApiError) => {
          if (error.code === ErrorCodeEnum.BAD_CHALLENGE) {
            return showErrorMessage('verify-email-bad-challenge', error.code);
          }

          return showErrorMessage('verify-email', error.code);
        });

        // This is intercepted in EmailOTPController and prevents from going to the next page
        throw err;
      }
    },
    [onSuccess, t]
  );

  return {
    onStart: handleStart,
    onVerify: handleVerify,
  };
};
