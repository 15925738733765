import { useState } from 'react';
import type { PublicKeyCredentialRequestOptionsJSON } from '@simplewebauthn/typescript-types';

import { ErrorCodeEnum, HttpStatusEnum } from '@app/config/error-config';
import {
  showDefaultErrorMessage,
  showErrorMessage,
} from '@app/core/error/show-error-message';
import { catchApiError } from '@app/core/error/catch-api-error';
import { confirmIdentityService } from '@app/core/service/confirm-identity.service';
import { VerifyIdentityWithWebauthnController } from '@app/feature/confirm-identity/verify-identity-with-webauthn.controller';
import { VerifyIdentityWithEmailController } from '@app/feature/confirm-identity/verify-identity-with-email.controller';

interface ConfirmIdentityControllerProps {
  emailAddress: string;
  onSuccess: (token: string) => void;
  children: (data: {
    pending: boolean;
    onStart: () => Promise<void>;
    onCancel: () => void;
  }) => JSX.Element;
}

export const ConfirmIdentityController = (
  props: ConfirmIdentityControllerProps
): JSX.Element => {
  const { emailAddress, onSuccess, children } = props;

  const [pending, setPending] = useState(false);
  const [identityVerificationKey, setIdentityVerificationKey] = useState<
    string | null
  >();
  const [webauthnOptions, setWebauthnOptions] =
    useState<PublicKeyCredentialRequestOptionsJSON | null>();

  const handleStart = async () => {
    setPending(true);

    try {
      const response = await confirmIdentityService.startIdentityVerification();
      setIdentityVerificationKey(response.identityVerificationKey);
      setWebauthnOptions(response.options);
    } catch (err) {
      catchApiError(err, (error) => {
        if (error.status === HttpStatusEnum.FORBIDDEN) {
          showErrorMessage(
            'confirm-identity-must-use-webauthn',
            error.code ??
              ErrorCodeEnum.MUST_LOGIN_WITH_WEBAUTHN_TO_CONFIRM_IDENTITY
          );
          return;
        }

        showDefaultErrorMessage();
      });
      setPending(false);
    }
  };

  const handleCancel = () => {
    setIdentityVerificationKey(null);
    setWebauthnOptions(null);
    setPending(false);
  };

  return (
    <>
      {children({ pending, onStart: handleStart, onCancel: handleCancel })}

      {identityVerificationKey && webauthnOptions && (
        <VerifyIdentityWithWebauthnController
          startPending={pending}
          identityVerificationKey={identityVerificationKey}
          options={webauthnOptions}
          onSuccess={onSuccess}
          onRetry={handleStart}
          onCancel={handleCancel}
        />
      )}

      {identityVerificationKey && !webauthnOptions && (
        <VerifyIdentityWithEmailController
          emailAddress={emailAddress}
          identityVerificationKey={identityVerificationKey}
          onSuccess={onSuccess}
          onRetry={handleStart}
        />
      )}
    </>
  );
};
