import { ChangeEvent, forwardRef, HTMLProps, useState } from 'react';
import classNames from 'classnames';

import { Text } from '../typography/text/text';
import css from './text-input.module.scss';

export interface TextInputProps extends HTMLProps<HTMLInputElement> {
  inputClassName?: string;
  label: string;
  errorMessage?: string;
  isTouched?: boolean;
  adornment?: React.ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean;
  enterKeyHint?:
    | 'enter'
    | 'done'
    | 'go'
    | 'next'
    | 'previous'
    | 'search'
    | 'send';
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (props: TextInputProps, ref): JSX.Element => {
    const {
      required = false,
      autoFocus,
      inputClassName,
      label,
      errorMessage,
      isTouched,
      adornment,
      className,
      disabled = false,
      value,
      ...inputProps
    } = props;

    const [isFocused, setIsFocused] = useState(!!autoFocus);

    return (
      <label className={classNames(css.label, className)} htmlFor={label}>
        <span>
          {label}
          {required && <span className={css.required}>*</span>}
        </span>

        <div
          className={classNames(
            css.container,
            isFocused && css.containerFocus,
            disabled && css.containerDisabled,
            isTouched && errorMessage && css.containerError
          )}>
          <input
            {...inputProps}
            ref={ref}
            type="text"
            id={label}
            className={classNames(inputClassName, css.input)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            disabled={disabled}
            autoFocus={autoFocus}
          />
          {adornment}
        </div>

        <Text className={css.errorMessage}>{isTouched && errorMessage}</Text>
      </label>
    );
  }
);
