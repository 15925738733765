import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { H1 } from '@app/shared/typography/heading/heading';
import { ItemMenu } from '@app/shared/item-menu/item-menu';
import CreditCard from '@app/asset/credit-card.svg';
import { Text } from '@app/shared/typography/text/text';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';
import { RedirectUrlResponseModel } from '@app/core/model/redirect-url-response.model';
import { applePayService } from '@app/core/service/apple-pay.service';

import { GooglePayController } from './view/google-pay/google-pay.controller';
import { ApplePayController } from './view/apple-pay/apple-pay.controller';
import { ListUserPaymentMethodController } from './view/list-user-payment-method/list-user-payment-method.controller';

interface PaymentViewProps {
  currentPaymentMethodId?: string;
  onSelect: (preCaptureReponse: RedirectUrlResponseModel) => void;
  onCreateCheckoutPaymentMethod: () => void;
  onBack: () => void;
}

export function PaymentView({
  currentPaymentMethodId,
  onSelect,
  onCreateCheckoutPaymentMethod,
  onBack,
}: PaymentViewProps): JSX.Element {
  const { t } = useTranslation();

  const [showApplePayPayment, setShowApplePayPayment] =
    useState<boolean>(false);

  useLayoutEffect(() => {
    applePayService
      .canMakeApplePayPayments()
      .then((response: boolean) => {
        setShowApplePayPayment(response);
      })
      .catch(() => setShowApplePayPayment(false));
  }, []);

  return (
    <DrawerLayout>
      <DrawerLayout.Header>
        <H1>{t('payment.title')}</H1>
        <Text>{t('payment.caption')}</Text>
      </DrawerLayout.Header>

      <DrawerLayout.Body>
        <ListUserPaymentMethodController
          onSubmit={onSelect}
          onCancel={onBack}
          currentPaymentMethodId={currentPaymentMethodId}
        />

        <ItemMenu
          left={<CreditCard />}
          onClick={onCreateCheckoutPaymentMethod}
          label={t('payment.list.create-checkout-payment-method')}
        />

        <GooglePayController onSubmit={onSelect} />

        {showApplePayPayment && <ApplePayController onSubmit={onSelect} />}
      </DrawerLayout.Body>
    </DrawerLayout>
  );
}
