import { generatePath } from 'react-router-dom';

import { AbortablePromise } from '@app/core/helper/abort-promise';
import { httpClientService } from '@app/core/client/http/http-client.service';
import {
  PAYMENT_METHOD_API,
  PREFERRED_PAYMENT_METHOD_API,
  RENAME_PAYMENT_METHOD_API,
} from '@app/config/api-routes.const';
import {
  PatchPaymentMethodModel,
  PaymentMethodModel,
} from '@app/core/model/payment-method.model';
import { PreferredMethodResponseModel } from '@app/core/model/preferred-method-response.model';
import { TransactionFromTokenCreationRequestModel } from '@app/core/model/payment-transaction.model';
import { RedirectUrlResponseModel } from '@app/core/model/redirect-url-response.model';

class PaymentMethodService {
  mergePaymentMethodAndPreferred(
    list: PaymentMethodModel[],
    preferred: PreferredMethodResponseModel
  ): PaymentMethodModel[] {
    return list.map((item) => ({
      ...item,
      preferred: item.iPaymentSourceId === preferred.iPaymentSourceId,
    }));
  }

  list(): AbortablePromise<PaymentMethodModel[]> {
    const [listPromise, abortList] =
      httpClientService.list<PaymentMethodModel[]>(PAYMENT_METHOD_API);

    const [preferredPromise] =
      httpClientService.run<PreferredMethodResponseModel>(
        PREFERRED_PAYMENT_METHOD_API,
        { method: 'GET' }
      );

    const allPromises: Promise<PaymentMethodModel[]> = Promise.all([
      listPromise,
      preferredPromise,
    ]).then((result) => {
      return this.mergePaymentMethodAndPreferred(result[0], result[1]);
    });

    return [allPromises, abortList];
  }

  createPaymentMethod(
    data: TransactionFromTokenCreationRequestModel
  ): AbortablePromise<RedirectUrlResponseModel> {
    return httpClientService.post<RedirectUrlResponseModel>(
      PAYMENT_METHOD_API,
      data
    );
  }

  deletePaymentMethod(
    iPaymentSourceId: string
  ): AbortablePromise<PaymentMethodModel> {
    return httpClientService.delete<PaymentMethodModel>(
      PAYMENT_METHOD_API,
      iPaymentSourceId
    );
  }

  renamePaymentMethod(
    iPaymentSourceId: string,
    data: PatchPaymentMethodModel
  ): AbortablePromise<void> {
    return httpClientService.run<void>(
      generatePath(RENAME_PAYMENT_METHOD_API, { iPaymentSourceId }),
      {
        method: 'PATCH',
        data,
      }
    );
  }

  setPreferred(iPaymentSourceId: string): AbortablePromise<void> {
    return httpClientService.run<void>(PREFERRED_PAYMENT_METHOD_API, {
      method: 'POST',
      data: {
        iPaymentSourceId,
      },
    });
  }

  removePreferred(): AbortablePromise<void> {
    return httpClientService.run<void>(PREFERRED_PAYMENT_METHOD_API, {
      method: 'DELETE',
    });
  }
}

export const paymentMethodService = new PaymentMethodService();
