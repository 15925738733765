import { VALIDATE_APPLE_SESSION_API } from '@app/config/api-routes.const';
import { AbortablePromise } from '@app/core/helper/abort-promise';
import { httpClientService } from '@app/core/client/http/http-client.service';
import { paymentTransactionService } from '@app/core/service/payment-transaction.service';
import { AppleSessionValidationRequestModel } from '@app/core/model/apple-session-validation.model';
import { Logger } from '@app/core/logger/logger';

class ApplePayService {
  validateSession(
    data: AppleSessionValidationRequestModel
  ): AbortablePromise<unknown> {
    return httpClientService.post<unknown>(VALIDATE_APPLE_SESSION_API, data);
  }

  isApplePayAvailable(): boolean {
    return Boolean(ApplePaySession);
  }

  async canMakeApplePayPayments(): Promise<boolean> {
    Logger.debug(
      'isApplePayAvailable (=Boolean(ApplePaySession))',
      this.isApplePayAvailable()
    );

    if (!this.isApplePayAvailable()) {
      return false;
    }

    const [configurationPromise] = paymentTransactionService.getConfiguration();
    try {
      const configuration = await configurationPromise;

      const canMakePayments =
        await ApplePaySession.canMakePaymentsWithActiveCard(
          configuration.apple.merchantId
        );

      Logger.debug(
        `can make payments with active card using merchantId ${configuration.apple.merchantId} is `,
        canMakePayments
      );

      return canMakePayments;
    } catch (error) {
      Logger.debug('An error occurred while loading Apple Pay', error);
      return false;
    }
  }
}

export const applePayService = new ApplePayService();
