import { ChangeEvent, ForwardedRef, forwardRef, ReactNode } from 'react';
import { Text } from '../typography/text/text';
import css from './checkbox.module.scss';

export interface CheckboxProps {
  id: string;
  label: string | ReactNode;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { id, label, ...props }: CheckboxProps,
    ref: ForwardedRef<HTMLInputElement>
  ): JSX.Element => (
    <label className={css.label} htmlFor={id}>
      <input
        {...props}
        ref={ref}
        id={id}
        type="checkbox"
        className={css.checkbox}
      />
      <Text className={css.labelText}>{label}</Text>
    </label>
  )
);
