import { PropsWithChildren, useState } from 'react';
import { Icon, IconName } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';
import { Collapsible } from '@app/shared/collapsible/collapsible';

import css from './charge-chart-layout.module.scss';

type Props = {
  icon: IconName;
  title: string;
  subTitle: string;
};

export function ChargeChartLayout(
  props: PropsWithChildren<Props>
): JSX.Element {
  const { icon, title, subTitle, children } = props;

  const [isOpen, setIsOpen] = useState(true);

  return (
    <div>
      <button
        className={css.container}
        onClick={() => setIsOpen((value) => !value)}>
        <div className={css.titleContainer}>
          <Icon name={icon} />
          <Text bold>{title}</Text>
          <Text fontSize="small" color="secondary">
            {subTitle}
          </Text>
        </div>

        <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />
      </button>
      <Collapsible isOpen={isOpen}>{children}</Collapsible>
    </div>
  );
}
