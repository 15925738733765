import { useTranslation } from 'react-i18next';

import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { WebauthnAuthenticatorModel } from '@app/core/model/authenticator.model';
import { SECURITY_PATH } from '@app/config/app-paths.const';

import { ManageWebauthnAuthenticationController } from './manage-webauthn-authentication/manage-webauthn-authentication.controller';
import { ManageEmailAuthenticationView } from './manage-email-authentication/manage-email-authentication.view';

import css from './manage-authentication-method.module.scss';

interface ManageAuthenticationMethodViewProps {
  webauthnAuthenticators?: WebauthnAuthenticatorModel[];
  isEmailVerified: boolean;
  emailAddress?: string;
  onRefresh: () => void;
}

export const ManageAuthenticationMethodView = (
  props: ManageAuthenticationMethodViewProps
): JSX.Element => {
  const {
    webauthnAuthenticators = [],
    emailAddress = '',
    isEmailVerified,
    onRefresh,
  } = props;
  const { t } = useTranslation();

  return (
    <PageLayout withHomeButton>
      <PageLayout.Title
        title={t('manage-authentication-method.title')}
        icon="lock-keyhole-circle"
        backTo={SECURITY_PATH}
      />

      <PageLayout.Body className={css.body}>
        <ManageWebauthnAuthenticationController
          authenticators={webauthnAuthenticators}
        />
        <ManageEmailAuthenticationView
          emailAddress={emailAddress}
          isEmailVerified={isEmailVerified}
          onRefresh={onRefresh}
        />
      </PageLayout.Body>

      <PageLayout.AppbarFooter />
    </PageLayout>
  );
};
