import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { H1 } from '@app/shared/typography/heading/heading';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { Text } from '@app/shared/typography/text/text';
import { ChargePointName } from '@app/shared/charge-point/charge-point-name/charge-point-name';
import { Icon } from '@app/shared/icon/icon';
import { InfosWithLegend } from '@app/shared/infos-with-legend/infos-with-legend';

import css from './charge-overview.module.scss';

interface ChargeOverviewProps {
  hasInvoice: boolean;
  chargePointName?: string;
  physicalReference?: string;
  duration?: number;
  estimatedPrice?: number;
  energyDelivered?: number;
  isLoadingInvoice?: boolean;
  onGoToDashboard: () => void;
  onShowInvoice: () => void;
}

export const ChargeOverview = ({
  chargePointName,
  physicalReference,
  hasInvoice,
  duration = 0,
  estimatedPrice = 0,
  energyDelivered = 0,
  onGoToDashboard,
  onShowInvoice,
  isLoadingInvoice,
}: ChargeOverviewProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PageLayout colored className={css.page} withHomeButton>
      <ChargePointName
        light
        name={chargePointName}
        physicalReference={physicalReference}
      />

      <PageLayout.Header>
        <H1 color="light">{t('charge-overview.title')}</H1>
      </PageLayout.Header>

      <PageLayout.Body className={css.body}>
        <div className={css.car} />

        <div className={css.chargeCard}>
          <InfosWithLegend
            icon="lightning-02"
            value={
              energyDelivered
                ? t('charge-overview.energy.value', {
                    amount: energyDelivered,
                  })
                : '-'
            }
            label={t('charge-overview.energy.label')}
          />

          <InfosWithLegend
            icon="clock-refresh"
            value={
              duration
                ? t('charge-overview.time.value', {
                    duration,
                  })
                : '-'
            }
            label={t('charge-overview.time.label')}
          />

          <InfosWithLegend
            icon="currency-euro"
            value={
              estimatedPrice
                ? t('charge-overview.cost.value', {
                    amount: estimatedPrice,
                  })
                : '-'
            }
            label={t('charge-overview.cost.label')}
          />
        </div>

        <Text className={css.hint} color="light" fontSize="xs">
          {t('charge-overview.provisional-information')}
        </Text>
      </PageLayout.Body>

      {hasInvoice && (
        <Button onClick={onShowInvoice} isLoading={isLoadingInvoice}>
          {t('charge-overview.go-to-invoice-button')}
        </Button>
      )}
      {!hasInvoice && (
        <p className={css.noInvoice}>{t('charge-overview.no-invoice')}</p>
      )}
      <Button
        onClick={onGoToDashboard}
        variant="outlined"
        className={css.dashboardButton}>
        <Icon name="home-02" />
        {t('charge-overview.go-to-dashboard-button')}
      </Button>
    </PageLayout>
  );
};
