import { forwardRef } from 'react';
import classNames from 'classnames';

import { Text } from '../typography/text/text';
import css from './code-input.module.scss';

function keepOnlyDigit(value: string): string {
  return value.replace(/\D/g, '');
}

export interface CodeInputProps {
  autoFocus?: boolean;
  value?: string;
  length?: number;
  errorMessage?: string;
  onChange?: (code: string) => void;
  className?: string;
}

export const CodeInput = forwardRef<HTMLInputElement, CodeInputProps>(
  (
    {
      className,
      autoFocus = false,
      value = '',
      length = 4,
      errorMessage = '',
      onChange = () => {},
    }: CodeInputProps,
    ref
  ): JSX.Element => {
    const getOTPValue = () => (value ? value.toString().split('') : []);
    const getOTPValueAtIndex = (index: number): string => {
      return getOTPValue()[index] ?? '';
    };

    const handleChangeCode = (newValue: string, index: number): void => {
      const otp = getOTPValue();
      otp[index] = newValue;
      onChange(otp.join(''));
    };

    const focusToNextInput = (target: HTMLElement) => {
      const nextElement = target.nextElementSibling as HTMLInputElement | null;

      if (nextElement) {
        nextElement.focus();
      }
    };
    const focusToPrevInput = (target: HTMLElement) => {
      const previousElement =
        target.previousElementSibling as HTMLInputElement | null;

      if (previousElement) {
        previousElement.focus();
      }
    };

    const handleInput = (
      event: React.FormEvent<HTMLInputElement>,
      index: number
    ) => {
      const target = event.target as HTMLInputElement;

      if (!target?.value) {
        return;
      }

      const targetValue = keepOnlyDigit(target.value).slice(0, length - index);

      if (targetValue.length === 0) {
        return;
      }

      handleChangeCode(targetValue, index);

      if (targetValue.length === 1) {
        focusToNextInput(target);
        return;
      }

      target.blur();
    };

    const handleKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement>,
      index: number
    ): void => {
      const { key } = event;
      const target = event.target as HTMLInputElement;

      if (key === 'ArrowRight' || key === 'ArrowDown') {
        event.preventDefault();
        focusToNextInput(target);
      }

      if (key === 'ArrowLeft' || key === 'ArrowUp') {
        event.preventDefault();
        focusToPrevInput(target);
      }

      if (key === 'Backspace' || key === 'Delete') {
        handleChangeCode('', index);
        focusToPrevInput(target);
      }
    };

    const handleSelectOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      const { target } = event;
      target.setSelectionRange(0, target.value.length);
    };

    return (
      <div className={classNames(css.container, className)}>
        <div className={css.items}>
          <input
            ref={ref}
            value={value}
            className={css.hidden}
            onInput={(event) => handleInput(event, 0)}
          />
          {[...Array(length).keys()].map((index) => (
            <input
              key={index}
              autoFocus={autoFocus && index === 0}
              type="text"
              inputMode="numeric"
              autoComplete="one-time-code"
              className={classNames(
                css.case,
                getOTPValueAtIndex(index) !== '' && css.number,
                errorMessage !== '' && css.error
              )}
              value={getOTPValueAtIndex(index)}
              onInput={(event) => handleInput(event, index)}
              onKeyDown={(event) => handleKeyDown(event, index)}
              onFocus={handleSelectOnFocus}
            />
          ))}
        </div>
        {errorMessage !== '' && (
          <Text className={css.errorMessage}>{errorMessage}</Text>
        )}
      </div>
    );
  }
);
