import { LegalText } from '@app/core/model/legal-text';
import { Dialog, DialogProps } from '@app/shared/dialog/dialog';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';

import css from './legal-text-dialog.module.scss';

interface LegalTextDialogProps extends DialogProps {
  legalText?: LegalText;
}

export const LegalTextDialog = (
  props: LegalTextDialogProps
): JSX.Element | null => {
  const { legalText, ...dialogProps } = props;

  return legalText ? (
    <Dialog {...dialogProps}>
      <DialogLayout.Body
        className={css.body}
        scrollable
        dangerouslySetInnerHTML={{
          __html: legalText.content,
        }}
      />
    </Dialog>
  ) : null;
};
