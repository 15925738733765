import { Avatar } from '@app/shared/avatar/avatar';
import { useTranslation } from 'react-i18next';
import { Text } from '@app/shared/typography/text/text';
import { Icon } from '@app/shared/icon/icon';
import { H1 } from '@app/shared/typography/heading/heading';

import css from './header.module.scss';

type Props = { firstName?: string; lastName?: string; pseudo?: string };

export function Header(props: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    firstName = t('dashboard.header.fallback-name'),
    lastName = t('dashboard.header.fallback-name'),
    pseudo,
  } = props;

  return (
    <div className={css.container}>
      <Avatar firstName={firstName} lastName={lastName} />

      <div className={css.body}>
        <Text fontSize="large">{t('dashboard.header.welcome-label')}</Text>

        <div className={css.name}>
          <H1>{firstName}</H1>

          <div className={css.id}>
            <Icon name="user-01" />
            <Text bold>{pseudo}</Text>
          </div>
        </div>
      </div>
    </div>
  );
}
