import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { ApiError } from '@app/core/error/api-error';
import { catchApiError } from '@app/core/error/catch-api-error';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { authenticationMethodService } from '@app/core/service/authentication-method.service';
import {
  EmailOtpProcessStartRequestModel,
  EmailOtpProcessVerificationRequestModel,
} from '@app/core/model/email-otp-process.model';
import { EMAIL_LSK } from '@app/config/localstorage-keys.const';
import { setInLocalStorage } from '@app/core/storage/local-storage';
import { ErrorCodeEnum } from '@app/config/error-config';

interface UpdateEmailHookReturn {
  onStart: (payload: EmailOtpProcessStartRequestModel) => Promise<void>;
  onVerify: (payload: EmailOtpProcessVerificationRequestModel) => Promise<void>;
}

interface UpdateEmailHookProps {
  onSuccess: () => void;
}

export const useUpdateEmail = (
  props: UpdateEmailHookProps
): UpdateEmailHookReturn => {
  const { onSuccess } = props;

  const { t } = useTranslation();
  const [emailAddress, setEmailAddress] = useState('');

  const handleStart = useCallback(
    async (payload: EmailOtpProcessStartRequestModel) => {
      try {
        const [startPromise] = authenticationMethodService.startEmailUpdate({
          emailAddress: payload.emailAddress,
        });
        await startPromise;
        setEmailAddress(payload.emailAddress);
      } catch (err) {
        catchApiError(err, (error: ApiError) => {
          if (
            error.code === ErrorCodeEnum.EMAIL_ALREADY_REGISTERED ||
            error.code === ErrorCodeEnum.EMAIL_CONFLICT
          ) {
            return showErrorMessage('update-email-already-taken', error.code);
          }

          return showErrorMessage('update-email', error.code);
        });

        // This is intercepted in EmailOTPController and prevents from going to the next page
        throw err;
      }
    },
    []
  );

  const handleVerify = useCallback(
    async (payload: EmailOtpProcessVerificationRequestModel) => {
      const [verifyPromise] = authenticationMethodService.verifyEmailUpdate({
        challenge: payload.challenge,
        emailAddress,
      });

      try {
        await verifyPromise;
        toast.success(t('manage-data.update-email.success-update'));
        setInLocalStorage(EMAIL_LSK, emailAddress);

        onSuccess();
      } catch (err) {
        catchApiError(err, (error: ApiError) => {
          if (error.code === ErrorCodeEnum.BAD_CHALLENGE) {
            return showErrorMessage('update-email-bad-challenge', error.code);
          }

          return showErrorMessage('update-email', error.code);
        });

        // This is intercepted in EmailOTPController and prevents from going to the next page
        throw err;
      }
    },
    [emailAddress, onSuccess, t]
  );

  return {
    onStart: handleStart,
    onVerify: handleVerify,
  };
};
