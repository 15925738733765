import { ACTIVE_CHARGE_SESSION_API } from '@app/config/api-routes.const';
import { AbortablePromise } from '@app/core/helper/abort-promise';
import { httpClientService } from '@app/core/client/http/http-client.service';
import { ChargeSessionResponseModel } from '@app/core/model/charge-session-response.model';

class ChargeSessionService {
  getActive(): AbortablePromise<ChargeSessionResponseModel> {
    return httpClientService.run<ChargeSessionResponseModel>(
      ACTIVE_CHARGE_SESSION_API,
      {
        method: 'GET',
      }
    );
  }
}

export const chargeSessionService = new ChargeSessionService();
