import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

export type TimeRestrictionProps = { startTime?: Date; endTime?: Date };

function getTimeRestrictionText(
  props: TimeRestrictionProps & { t: TFunction }
): string | null {
  const { startTime, endTime, t } = props;

  if (!startTime && !endTime) {
    return null;
  }

  if (startTime && endTime) {
    return t('tariff.time.min-max', {
      startTime,
      endTime,
    });
  }

  if (startTime) {
    return t('tariff.time.from', { value: startTime });
  }

  return t('tariff.time.until', { value: endTime });
}

type KwhRestrictionProps = { minKwh?: number; maxKwh?: number };
function getEnergyRestrictionText(
  props: KwhRestrictionProps & { t: TFunction }
): string | null {
  const { minKwh, maxKwh, t } = props;

  if (!minKwh && !maxKwh) {
    return null;
  }

  if (minKwh && maxKwh) {
    return t('tariff.kwh.min-max', {
      minKwh,
      maxKwh,
    });
  }

  if (minKwh) {
    return t('tariff.kwh.from', { value: minKwh });
  }

  return t('tariff.kwh.until', { value: maxKwh });
}

type Props = TimeRestrictionProps | KwhRestrictionProps;

export function useRestrictionText(): (props: Props) => string | null {
  const { t } = useTranslation();

  return (props: Props): string | null => {
    if ('startTime' in props || 'endTime' in props) {
      return getTimeRestrictionText({ ...props, t });
    }

    if ('minKwh' in props || 'maxKwh' in props) {
      return getEnergyRestrictionText({ ...props, t });
    }

    return null;
  };
}
