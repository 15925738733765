import { LogLevelEnum, LoggerHandler } from './logger.types';
import { sendBeacon } from './send-beacon';

function stringifyError(body: any[]): string[] {
  return body.map((parameter) => {
    if (parameter instanceof Error) {
      return JSON.stringify(parameter, Object.getOwnPropertyNames(parameter));
    }
    return parameter;
  });
}

export const RemoteHandler: LoggerHandler = (
  logLevel: LogLevelEnum,
  ...parameters: any[]
): void => {
  const log = {
    level: LogLevelEnum[logLevel],
    message: stringifyError(parameters),
    timestamp: new Date().toUTCString(),
  };

  const body = JSON.stringify(log);

  sendBeacon('log', body).catch(() => {
    // Do nothing, just catch the error
  });
};
