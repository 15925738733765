import { HTMLProps, useEffect, useRef } from 'react';
import QrScanner from 'qr-scanner';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ErrorCodeEnum } from '@app/config/error-config';
import { configurationService } from '@app/core/configuration/configuration.service';

export interface ScannerProps extends HTMLProps<HTMLVideoElement> {
  onScan: (url: string) => void;
  onCameraError: () => void;
}

export const Scanner = (props: ScannerProps): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { t } = useTranslation();

  const { onScan, onCameraError, ...videoProps } = props;

  useEffect(() => {
    if (!videoRef.current) {
      return () => {};
    }

    const qrScanner = new QrScanner(
      videoRef.current,
      (result: QrScanner.ScanResult) => {
        onScan(result.data);
        qrScanner.stop();
      },
      {
        onDecodeError: (error: string | Error) => {
          if (
            /*
             * QR-Scanner tries to decode a QR code from input video $maxScansPerSecond times per seconds.
             * If no QR code is found, it throws an error as string containing the NO_QR_CODE_FOUND message.
             * We need only need to handle the cases where there is a Qr code but it is unreadable.
             */
            typeof error === 'string' &&
            error.includes(QrScanner.NO_QR_CODE_FOUND)
          ) {
            return;
          }

          toast.error(
            `${t('errors.scan-qr-code-unreadable', {
              phoneNumber: configurationService.getSupportPhoneNumber(),
            })} [Code: ${ErrorCodeEnum.QR_CODE_UNREADABLE}]`,
            {
              toastId: ErrorCodeEnum.QR_CODE_UNREADABLE,
            }
          );
        },
        preferredCamera: 'environment',
        maxScansPerSecond: 5,
      }
    );

    qrScanner.start().catch(() => {
      onCameraError();
    });

    return () => {
      qrScanner.destroy();
    };
  }, [onScan, onCameraError, t]);

  return <video muted ref={videoRef} {...videoProps} />;
};
