import { useCallback, useMemo, useState } from 'react';

import { ACCOUNT_PATH, HOME_PATH } from '@app/config/app-paths.const';
import { configurationService } from '@app/core/configuration/configuration.service';
import { useUser } from '@app/core/context-providers/user-context/use-user';
import { useLoadLegalText } from '@app/feature/legal-text/hook/use-load-legal-text';
import { useNavigateWithState } from '@app/router/redirect/use-navigate-with-state';

import { LegalText } from '@app/core/model/legal-text';
import { Drawer, DrawerStateEnum } from '@app/shared/drawer/drawer';
import { LegalTextView } from './legal-text.view';
import { LegalTextContent } from './component/legal-text-content/legal-text-content';

export const LegalTextController = (): JSX.Element => {
  const version = useMemo(() => configurationService.getVersion(), []);
  const [selectedLegalText, setSelectedLegalText] = useState<LegalText | null>(
    null
  );
  const navigate = useNavigateWithState();
  const { user } = useUser();

  const handleBack = useCallback(() => {
    navigate(user ? ACCOUNT_PATH : HOME_PATH);
  }, [navigate, user]);

  const { loading, requiredLegalText } = useLoadLegalText({
    onError: handleBack,
  });

  return (
    <>
      <LegalTextView
        version={version}
        pseudo={user?.pseudo}
        pending={loading}
        legalTextList={requiredLegalText}
        onSelect={setSelectedLegalText}
      />
      <Drawer
        state={selectedLegalText ? DrawerStateEnum.OPEN : DrawerStateEnum.CLOSE}
        onClose={() => setSelectedLegalText(null)}>
        {selectedLegalText && (
          <LegalTextContent legalText={selectedLegalText} />
        )}
      </Drawer>
    </>
  );
};
