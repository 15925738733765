import { ChargePointPriceUnitEnum } from '@app/core/model/enum/charge-point-price-unit.enum';
import { Icon } from '@app/shared/icon/icon';

import { SinglePriceSquare } from './single-price-square';
import css from './price-square.module.scss';

type Props = {
  pricePerKwh: number;
  pricePerMin: number;
};

export const DoublePriceSquare = (props: Props): JSX.Element => {
  const { pricePerKwh, pricePerMin } = props;

  return (
    <>
      <SinglePriceSquare
        price={pricePerKwh}
        unit={ChargePointPriceUnitEnum.PER_KWH}
        className={css.component}
      />
      <Icon name="plus" />
      <SinglePriceSquare
        price={pricePerMin}
        unit={ChargePointPriceUnitEnum.PER_MIN}
        className={css.component}
      />
    </>
  );
};
