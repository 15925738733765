import { useTranslation } from 'react-i18next';

import { configurationService } from '@app/core/configuration/configuration.service';

import css from './call-support-button.module.scss';
import { Text } from '../typography/text/text';
import { Icon } from '../icon/icon';

const DELMONICOS_SUPPORT_PHONE_NUMBER =
  configurationService.getSupportPhoneNumber();

export const CallSupportButton = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <a
      href={`tel:${DELMONICOS_SUPPORT_PHONE_NUMBER}`}
      className={css.container}>
      <Icon name="phone-call-01" />
      <div className={css.text}>
        <Text>{t('shared.call-support.label')}</Text>
        <Text bold>{DELMONICOS_SUPPORT_PHONE_NUMBER}</Text>
      </div>
    </a>
  );
};
