import { Icon, IconName } from '@app/shared/icon/icon';
import { ItemMenu } from '@app/shared/item-menu/item-menu';

import css from './account-item-menu.module.scss';

type Props = {
  label: string;
  icon: IconName;
  caption?: string;
  bold?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export function AccountItemMenu({
  label,
  disabled,
  icon,
  bold,
  onClick,
  caption,
}: Props): JSX.Element {
  return (
    <ItemMenu
      className={css.container}
      label={label}
      bold={bold}
      disabled={disabled}
      left={
        <div className={css.avatar}>
          <Icon name={icon} />
        </div>
      }
      onClick={onClick}
      caption={caption}
      right={<div />}
    />
  );
}
