export const DISCOVER_URL = 'https://delmonicos.tech/conducteurs/';

export const BLOG_URL = 'https://delmonicos.tech/blog/';

export const WEBSITE_URL = 'https://delmonicos.tech/';

export const FACEBOOK_URL = 'https://www.facebook.com/DelmonicosInside';

export const LINKEDIN_URL =
  'https://www.linkedin.com/company/delmonicos-inside/';

export const TWITTER_URL = 'https://twitter.com/delmonicos6';

export const YOUTUBE_URL = 'https://youtube.com/@delmonicosinside2392';
