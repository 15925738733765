import { useUser } from '@app/core/context-providers/user-context/use-user';
import { useGetSubscriptionDetailsByEmspSubscriptionIdsService } from '@app/core/service/use-get-subscription-details-by-emsp-subscription-ids.service';
import { useEffect } from 'react';
import { showDefaultErrorMessage } from '@app/core/error/show-error-message';
import { useUserSyncSubscriptionService } from '@app/core/service/use-user-sync-subscription.service';
import { useDrawer } from '@app/shared/drawer/use-drawer';
import { UserSubscriptionView } from './user-subscription.view';
import { ValidateOrUpdateEmailDrawer } from '../user-security/view/manage-authentication-method/manage-email-authentication/component/validate-or-update-email.drawer';

export function UserSubscriptionController(): JSX.Element {
  const { user, refresh } = useUser();
  const {
    data: subscriptionDetailsList,
    isFetching,
    isError,
  } = useGetSubscriptionDetailsByEmspSubscriptionIdsService(
    user?.emspSubscriptionIds
  );

  const { openDrawer, closeDrawer, drawerState } = useDrawer();

  const handleSuccess = () => {
    refresh();
    closeDrawer();
  };

  const { mutate, isLoading: isSyncing } = useUserSyncSubscriptionService();

  useEffect(() => {
    if (isError) {
      showDefaultErrorMessage();
    }
  }, [isError]);

  useEffect(() => {
    if (user?.isEmailAddressVerified) {
      mutate();
      return;
    }

    openDrawer();
  }, [mutate, user?.isEmailAddressVerified, openDrawer]);

  return (
    <>
      <UserSubscriptionView
        onVerifyEmailAddress={openDrawer}
        isEmailAddressVerified={user?.isEmailAddressVerified}
        subscriptionList={subscriptionDetailsList}
        onSync={() => mutate()}
        isLoading={isFetching}
        isSyncing={isSyncing}
      />

      <ValidateOrUpdateEmailDrawer
        state={drawerState}
        translationKey="subscription.verify-email"
        emailAddress={user?.emailAddress}
        onClose={closeDrawer}
        onSuccess={handleSuccess}
      />
    </>
  );
}
