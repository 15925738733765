import { ChangeEvent } from 'react';
import classNames from 'classnames';

import css from './toggle.module.scss';

export interface ToggleProps {
  state?: boolean;
  isDisabled?: boolean;
  id: string;
  onChange: (value: boolean) => void;
}
export const Toggle = ({
  state = false,
  isDisabled = false,
  id,
  onChange,
}: ToggleProps): JSX.Element => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isDisabled) {
      return;
    }
    onChange(event.target.checked);
  };

  return (
    <label className={css.label} htmlFor={id}>
      <input
        checked={state}
        className={css.input}
        type="checkbox"
        onChange={handleChange}
        id={id}
      />
      <div className={classNames(css.toggle, isDisabled && css.disabled)} />
    </label>
  );
};
