import css from './physical-reference.module.scss';

type Props = {
  physicalReference: string;
};

export function PhysicalReference(props: Props): JSX.Element {
  const { physicalReference } = props;

  return <div className={css.physicalReference}>{physicalReference}</div>;
}
