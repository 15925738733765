import classNames from 'classnames';
import { HTMLProps } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@delmonicos/shared-graphical-assets/assets/icomoon/delmonicos-icons/style.css';
import css from './icon.module.scss';
import { Text, TextProps } from '../typography/text/text';

export const iconNamesArray = [
  'alert-triangle',
  'arrow-narrow-left',
  'arrow-narrow-right',
  'battery-empty',
  'battery-full',
  'battery-low',
  'battery-mid',
  'bar-chart-08',
  'bell-04',
  'bell-ringing-04',
  'calendar-check-01',
  'calendar-check-02',
  'calendar-date',
  'camera-01',
  'camera-off',
  'car-front',
  'car-profile',
  'check',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'clock',
  'clock-fast-forward',
  'clock-refresh',
  'clock-stopwatch',
  'copy-03',
  'credit-card-01',
  'credit-card-02',
  'credit-card-check',
  'credit-card-refresh',
  'credit-card-shield',
  'credit-card-x',
  'currency-euro',
  'currency-euro-circle',
  'delmonicos',
  'dots-vertical',
  'download-01',
  'edit-05',
  'electric-car',
  'electric-car-2',
  'electric-terminal',
  'eye',
  'eye-off',
  'file-05',
  'file-lock-02',
  'fingerprint-03',
  'flag-04',
  'help-circle',
  'home-02',
  'home-03',
  'info-circle',
  'lightning-02',
  'line-chart-up-01',
  'link-external-02',
  'loading-02',
  'lock-01',
  'lock-keyhole-circle',
  'log-in-02',
  'log-out-02',
  'log-out-04',
  'mail-01',
  'mail-02',
  'mail-03',
  'mail-04',
  'marker-pin-01',
  'marker-pin-04',
  'moon-01',
  'no-car',
  'notification-text',
  'paperclip',
  'parking',
  'pencil-01',
  'phone-01',
  'phone-call-01',
  'phone-call-02',
  'power-ac',
  'power-chademo',
  'power-dc',
  'power-domestic',
  'power-type-1',
  'power-type-1-combo-ccs',
  'power-type-2',
  'power-type-2-combo-ccs',
  'power-type-e',
  'plus',
  'price',
  'qr-code-02',
  'qr-empty',
  'refresh-cw-05',
  'reverse-left',
  'route',
  'rs-facebook',
  'rs-linkedin',
  'rs-twitter',
  'rs-youtube',
  'scales-02',
  'settings-02',
  'share-07',
  'shield-01',
  'shield-tick',
  'star-01',
  'sun',
  'tool-02',
  'trash-01',
  'user-01',
  'user-circle',
  'user-plus-01',
  'wifi-off',
  'wire',
  'x-close',
] as const;

export type IconName = (typeof iconNamesArray)[number];

export type IconProps = Pick<TextProps, 'color'> &
  HTMLProps<HTMLSpanElement> & {
    name: IconName;
    badge?: boolean;
  };

export const Icon = ({
  name,
  badge = false,
  color,
  className,
  ...props
}: IconProps): JSX.Element => {
  return (
    <Text
      className={classNames(
        `icon-${name}`,
        css.icon,
        badge && css.badge,
        className
      )}
      color={color}
      {...props}
    />
  );
};
