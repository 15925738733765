import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { useAuthentication } from '@app/core/context-providers/authentication-context/use-authentication';
import { useDialog } from '@app/shared/dialog/use-dialog';
import { ConfirmIdentityController } from '@app/feature/confirm-identity/confirm-identity.controller';
import { deleteAccountService } from '@app/core/service/delete-account.service';
import { catchApiError } from '@app/core/error/catch-api-error';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { Dialog } from '@app/shared/dialog/dialog';
import { ConfirmDeleteAccountDialog } from '@app/feature/account/view/user-security/view/delete-account/confirm-delete-account-dialog/confirm-delete-account.dialog';

interface DeleteAccountControllerProps {
  emailAddress: string;
  children: (payload: { onStart: () => void }) => JSX.Element;
}

export const DeleteAccountController = (
  props: DeleteAccountControllerProps
): JSX.Element => {
  const { emailAddress, children } = props;
  const { t } = useTranslation();
  const { disconnect } = useAuthentication();

  const {
    openDialog: openConfirmDialog,
    closeDialog: closeConfirmDialog,
    isDialogOpen: isConfirmDialogOpen,
  } = useDialog();

  const handleDeleteAccount = (token: string) => {
    deleteAccountService
      .deleteAccount(token)
      .then(() => {
        localStorage.clear();
        sessionStorage.clear();
        toast.success(t('manage-data.delete-account.success-delete'));
        disconnect();
      })
      .catch((err) => {
        catchApiError(err, (error) => {
          showErrorMessage('delete-account-server-error', error.code);
        });

        closeConfirmDialog();
      });
  };

  return (
    <>
      {children({ onStart: openConfirmDialog })}

      <ConfirmIdentityController
        emailAddress={emailAddress}
        onSuccess={handleDeleteAccount}>
        {({ pending, onStart, onCancel }) => (
          <Dialog onClose={closeConfirmDialog} open={isConfirmDialogOpen}>
            <ConfirmDeleteAccountDialog
              pending={pending}
              onContinue={onStart}
              onCancel={() => {
                closeConfirmDialog();
                onCancel();
              }}
            />
          </Dialog>
        )}
      </ConfirmIdentityController>
    </>
  );
};
