import { Logger } from '@app/core/logger/logger';

export const getFromLocalStorage = (key: string): string | null => {
  try {
    return window.localStorage.getItem(key);
  } catch (e) {
    Logger.error(`Cannot retrieve ${key} from localstorage`, e);
    return null;
  }
};

export const setInLocalStorage = (key: string, token: string): string => {
  try {
    window.localStorage.setItem(key, token);
  } catch (e) {
    Logger.error(`Cannot set ${key} from localstorage`, e);
  }

  return token;
};

export const removeInLocalStorage = (key: string): void => {
  try {
    window.localStorage.removeItem(key);
  } catch (e) {
    Logger.error(`Cannot remove ${key} from localstorage`, e);
  }
};
