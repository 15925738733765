export function SvgGradient(): JSX.Element {
  return (
    <svg
      style={{ position: 'absolute' }}
      xmlns="http://www.w3.org/2000/svg"
      height="0"
      width="0"
      fill="none"
      aria-hidden="true"
      focusable="false">
      <defs>
        <linearGradient id="brand-primary" x2="1" y2="1">
          <stop offset="0%" stopColor=" var(--brand-primary)" />
          <stop offset="100%" stopColor="var(--brand-primary)" />
        </linearGradient>

        <linearGradient
          id="brand-secondary"
          x2="1"
          y2="1"
          gradientTransform="rotate(55)">
          <stop offset="1.06%" stopColor=" var(--secondary-start-color)" />
          <stop offset="81.21%" stopColor="var(--secondary-stop-color)" />
        </linearGradient>

        <linearGradient
          id="charging-car"
          x2="1"
          y2="1"
          gradientTransform="rotate(-52)">
          <stop offset="0%" stopColor="var(--charging-car-start-color)" />
          <stop offset="100%" stopColor="var(--charging-car-stop-color)" />
        </linearGradient>
      </defs>
    </svg>
  );
}
