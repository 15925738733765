import { Logger } from '@app/core/logger/logger';
import { ChargeSessionModel } from '@app/core/model/charge-session.model';
import { ChargeSessionEnum } from '@app/core/model/enum/charge-session.enum';
import { ChargingEventModel } from '@app/core/model/charging-event.model';

export const defaultChargeSession: ChargeSessionModel = {
  status: null,
  transactionId: null,
  history: [],
  startedAt: null,
  endedAt: null,
};

export function isSameTransactionIdOrNull(
  currentTransactionId: string | null,
  eventTransactionId?: string | null
): boolean {
  const isMatchingTransactionId =
    currentTransactionId === null ||
    currentTransactionId === eventTransactionId;

  if (!isMatchingTransactionId) {
    Logger.warn(
      `Received charging event for transactionId ${eventTransactionId} while the current transactionId context is ${currentTransactionId}.`
    );
  }

  return isMatchingTransactionId;
}

export function isStatusBacktracking(
  currentStatus: ChargeSessionEnum | null,
  nextStatus: ChargeSessionEnum
): boolean {
  const isNextStatusInRollback =
    currentStatus !== null && nextStatus < currentStatus;
  if (isNextStatusInRollback) {
    Logger.debug(
      `Event ${nextStatus} ignored. Current status is ${currentStatus}`
    );
  }

  return isNextStatusInRollback;
}

export function getTransactionId(
  currentTransactionId: string | null,
  eventTransactionId?: string | null
): string | null {
  return (
    currentTransactionId ??
    eventTransactionId ??
    defaultChargeSession.transactionId
  );
}

export function concatHistory(
  history: ChargingEventModel[],
  item: ChargingEventModel
): ChargingEventModel[] {
  const isChargeSessionAlreadyReceived = history.find(
    (chargeSessionItem) => chargeSessionItem.duration === item.duration
  );

  if (isChargeSessionAlreadyReceived) {
    return history;
  }

  return history
    .concat(item)
    .sort(
      (chargeSession1, chargeSession2): number =>
        chargeSession1.duration - chargeSession2.duration
    );
}
