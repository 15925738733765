import { useCallback, useState } from 'react';

import {
  EmailOtpProcessStartRequestModel,
  EmailOtpProcessVerificationRequestModel,
} from '@app/core/model/email-otp-process.model';

import { useValidateEmail } from './use-validate-email';
import { useUpdateEmail } from './use-update-email';

export interface ValidateOrUpdateEmailHookReturn {
  onStart: (payload: EmailOtpProcessStartRequestModel) => Promise<void>;
  onVerify: (payload: EmailOtpProcessVerificationRequestModel) => Promise<void>;
}

interface ValidateOrUpdateEmailHookProps {
  emailAddress?: string;
  onSuccess: () => void;
}

export const useValidateOrUpdateEmail = (
  props: ValidateOrUpdateEmailHookProps
): ValidateOrUpdateEmailHookReturn => {
  const { emailAddress, onSuccess } = props;

  const [isValidationProcess, setIsValidationProcess] = useState(false);

  const { onStart: onStartEmailValidation, onVerify: onVerifyEmailValidation } =
    useValidateEmail({ onSuccess });

  const { onStart: onStartEmailUpdate, onVerify: onVerifyEmailUpdate } =
    useUpdateEmail({ onSuccess });

  const handleStart = useCallback(
    async (payload: EmailOtpProcessStartRequestModel) => {
      setIsValidationProcess(emailAddress === payload.emailAddress);

      if (emailAddress === payload.emailAddress) {
        return onStartEmailValidation(payload);
      }
      return onStartEmailUpdate(payload);
    },
    [emailAddress, onStartEmailValidation, onStartEmailUpdate]
  );

  const handleVerify = useCallback(
    async (payload: EmailOtpProcessVerificationRequestModel) => {
      if (isValidationProcess) {
        return onVerifyEmailValidation(payload);
      }

      return onVerifyEmailUpdate(payload);
    },
    [isValidationProcess, onVerifyEmailValidation, onVerifyEmailUpdate]
  );

  return {
    onStart: handleStart,
    onVerify: handleVerify,
  };
};
