import { ConsoleHandler } from './console.logger';
import { LogLevelEnum, LoggerHandler } from './logger.types';
import { RemoteHandler } from './remote.logger';

export class Logger {
  private static handler: LoggerHandler = Logger.getDefaultHandler();

  private static logLevel: LogLevelEnum = Logger.getLogLevel();

  static getDefaultHandler(): LoggerHandler {
    return process.env.DELMONICOS_DISABLE_REMOTE_LOGGING?.toUpperCase() ===
      'TRUE'
      ? ConsoleHandler
      : RemoteHandler;
  }

  static setHandler(handler: LoggerHandler): void {
    Logger.handler = handler;
  }

  static getLogLevel(): LogLevelEnum {
    const processLogLevel =
      process.env.DELMONICOS_LOG_LEVEL?.toUpperCase() ?? 'INFO';

    return (
      LogLevelEnum[processLogLevel as keyof typeof LogLevelEnum] ??
      LogLevelEnum.INFO
    );
  }

  static log(logLevel: LogLevelEnum, ...parameters: any[]): void {
    if (logLevel >= Logger.logLevel) {
      Logger.handler(logLevel, ...parameters);
    }
  }

  static debug(...parameters: any[]): void {
    Logger.log(LogLevelEnum.DEBUG, ...parameters);
  }

  static info(...parameters: any[]): void {
    Logger.log(LogLevelEnum.INFO, ...parameters);
  }

  static warn(...parameters: any[]): void {
    Logger.log(LogLevelEnum.WARN, ...parameters);
  }

  static error(...parameters: any[]): void {
    Logger.log(LogLevelEnum.ERROR, ...parameters);
  }
}
