import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { ErrorCodeEnum } from '@app/config/error-config';

import { chargePointService } from './charge-point.service';
import {
  ChargePointModel,
  ChargePointResponseModel,
} from '../model/charge-point.model';
import { useUser } from '../context-providers/user-context/use-user';
import {
  extractOcpiTariffsOnChargePointResponse,
  mapOcpiTariffModelToTariffModel,
} from '../helper/ocpi-utils';
import { ApiError } from '../error/api-error';

type Return = UseQueryResult<ChargePointModel[]> & {
  isBeingInstalled: boolean;
};

export const useChargePointListService = (
  tinyChargePointId: string | null
): Return => {
  const { user } = useUser();

  const result = useQuery({
    queryKey: ['tiny-chargePoint', tinyChargePointId],

    queryFn: async ({ queryKey }) =>
      chargePointService
        .getChargePointList(queryKey[1])
        .then((response: ChargePointResponseModel[]): ChargePointModel[] => {
          if (!response) {
            return [];
          }

          return response.map((payload) => {
            const ocpiTariff = extractOcpiTariffsOnChargePointResponse(
              payload,
              user?.emspSubscriptionIds
            );

            return {
              ...payload,
              ...(ocpiTariff && {
                tariff: mapOcpiTariffModelToTariffModel(ocpiTariff),
              }),
            };
          });
        }),

    enabled: !!tinyChargePointId,
    staleTime: 5 * 60 * 1000,
  });

  const isBeingInstalled = useMemo(
    () =>
      (result.error as ApiError)?.code === ErrorCodeEnum.OCPI_EVSE_NOT_FOUND ||
      (result.error as ApiError)?.code ===
        ErrorCodeEnum.OCPI_LOCATION_NOT_FOUND,
    [result.error]
  );

  return { ...result, isBeingInstalled };
};
