import i18n from '@app/core/locale/i18n';

import { DEFAULT_NAMESPACE, LOCALE_KEYS } from '@app/core/locale/locale';
import { FaqModel } from '@app/core/model/faq.model';
import { IconName } from '@app/shared/icon/icon';

export interface SectionInfos {
  key: string;
  title: string;
  icon: IconName;
}

// Utilisation d'une Map pour sectionsIcons
const sectionsIcons = new Map<string, IconName>([
  ['charging', 'electric-car-2'],
  ['start-stop-charging', 'electric-car'],
  ['charge-point', 'electric-terminal'],
  ['app', 'phone-01'],
  ['invoice', 'file-05'],
  ['payment', 'credit-card-02'],
  ['account', 'user-circle'],
  ['support', 'help-circle'],
  ['registration', 'user-plus-01'],
  ['authentication', 'log-in-02'],
  ['tarrification', 'currency-euro-circle'],
]);

export const localeFaqQuestionsRoot = `faq-questions`;
const localFaqFallback = `faq.fallback`;
const localFaqSections = `faq.section`;

function getResource<T>(key: string): T {
  return i18n.getResource(
    i18n.resolvedLanguage ?? LOCALE_KEYS.fr,
    DEFAULT_NAMESPACE,
    key
  ) as T;
}

export function getFaqItem(): FaqModel[] {
  return getResource<FaqModel[]>(localeFaqQuestionsRoot);
}

export function getFallbackFaqItem(): FaqModel {
  return getResource<FaqModel>(localFaqFallback);
}

export function getFaqSections(): SectionInfos[] {
  const sections = getResource<{ [key: string]: string }>(localFaqSections);

  return Object.keys(sections).map((key) => ({
    title: sections[key],
    key,
    icon: sectionsIcons.get(key) ?? 'help-circle',
  }));
}

export function getFaqSection(sectionKey: string): SectionInfos & {
  items: FaqModel[];
} {
  const faqItems = getFaqItem();
  const faqSections = getFaqSections();

  const items = faqItems.filter((item) => item.section === sectionKey);
  const { title, icon } = faqSections.find(
    (item) => item.key === sectionKey
  ) ?? {
    title: sectionKey,
    icon: 'help-circle',
  };

  return {
    title,
    items,
    icon,
    key: sectionKey,
  };
}
