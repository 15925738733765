import { useEffect, useState } from 'react';

import { useLoadLegalText } from '@app/feature/legal-text/hook/use-load-legal-text';
import { LegalText } from '@app/core/model/legal-text';
import { useUser } from '@app/core/context-providers/user-context/use-user';
import { useDrawer } from '@app/shared/drawer/use-drawer';
import { ConsentModel } from '@app/core/model/consent.model';

import { CheckUserConsentDrawer } from './check-user-consent.drawer';

export const CheckUserConsentController = (): JSX.Element => {
  const { user } = useUser();
  const { requiredLegalText, loading: loadingLegalText } = useLoadLegalText();
  const [outdatedLegalText, setOutdatedLegalText] = useState<LegalText[]>([]);
  const { openDrawer, closeDrawer, drawerState } = useDrawer();

  useEffect(() => {
    const localOutdatedLegalText: LegalText[] = requiredLegalText.filter(
      (legalText: LegalText) => {
        return !user?.consents.find(
          (userConsent: ConsentModel) =>
            userConsent.type === legalText.type &&
            userConsent.version >= legalText.version
        );
      }
    );

    setOutdatedLegalText(localOutdatedLegalText);
  }, [requiredLegalText, user?.consents, loadingLegalText]);

  useEffect(() => {
    if (outdatedLegalText.length > 0) {
      openDrawer();
      return;
    }

    closeDrawer();
  }, [outdatedLegalText.length, openDrawer, closeDrawer]);

  return (
    <CheckUserConsentDrawer
      legalText={outdatedLegalText[0]}
      state={drawerState}
    />
  );
};
