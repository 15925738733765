import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { ListItemMenu } from '@app/shared/list-item-menu/list-item-menu';
import { DeleteAccountController } from '@app/feature/account/view/user-security/view/delete-account/delete-account.controller';
import { useTranslation } from 'react-i18next';
import { Button } from '@app/shared/button/button';
import {
  ACCOUNT_PATH,
  LANGUAGE_PATH,
  MANAGE_AUTHENTICATION_PATH,
  MANAGE_PERSONAL_INFORMATION_PATH,
} from '@app/config/app-paths.const';
import { useNavigate } from 'react-router-dom';
import { NotificationItem } from '../../component/notification-item/notification-item';
import { AccountItemMenu } from '../../component/account-item-menu/account-item-menu';

interface UserSecurityViewProps {
  emailAddress: string;
  isChargeStarted: boolean;
  onUpdateEmail: () => void;
}

export function UserSecurityView({
  emailAddress,
  isChargeStarted = false,
  onUpdateEmail,
}: UserSecurityViewProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageLayout withHomeButton>
      <PageLayout.Title
        title={t('account.security-label')}
        icon="user-circle"
        backTo={ACCOUNT_PATH}
      />

      <PageLayout.Body>
        {isChargeStarted && (
          <NotificationItem>
            {t('account.notification-item.charge-is-started-toast')}
          </NotificationItem>
        )}

        <ListItemMenu title={t('user-security.profile-section')}>
          <AccountItemMenu
            icon="user-circle"
            bold
            label={t('user-security.personal-information')}
            disabled={isChargeStarted}
            onClick={() => navigate(MANAGE_PERSONAL_INFORMATION_PATH)}
          />

          <AccountItemMenu
            icon="mail-02"
            bold
            label={t('user-security.update-email')}
            disabled={isChargeStarted}
            onClick={onUpdateEmail}
          />
        </ListItemMenu>

        <ListItemMenu title={t('user-security.security-section')}>
          <AccountItemMenu
            icon="lock-keyhole-circle"
            bold
            label={t('user-security.authentication')}
            disabled={isChargeStarted}
            onClick={() => navigate(MANAGE_AUTHENTICATION_PATH)}
          />
        </ListItemMenu>

        <ListItemMenu title={t('user-security.customization-section')}>
          <AccountItemMenu
            icon="flag-04"
            bold
            label={t('user-security.language')}
            onClick={() => navigate(LANGUAGE_PATH)}
          />
        </ListItemMenu>
      </PageLayout.Body>

      <PageLayout.Footer>
        <DeleteAccountController emailAddress={emailAddress}>
          {({ onStart }) => (
            <Button
              variant="outlined"
              disabled={isChargeStarted}
              onClick={onStart}>
              {t('user-security.delete-account')}
            </Button>
          )}
        </DeleteAccountController>
      </PageLayout.Footer>
    </PageLayout>
  );
}
