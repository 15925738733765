import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PowerTypeEnum } from '@app/core/model/enum/power-type.enum';

export function useChargePointPowerType(powerType?: PowerTypeEnum): string {
  const { t } = useTranslation();

  const powerTypeText = useMemo(() => {
    if (!powerType) {
      return '-';
    }

    const alternativeCurrent = 'shared.charge-point.power-type.alternative';
    const directCurrent = 'shared.charge-point.power-type.direct';

    return powerType !== PowerTypeEnum.DC
      ? t(alternativeCurrent)
      : t(directCurrent);
  }, [powerType, t]);

  return powerTypeText;
}
