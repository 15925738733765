import { useMemo } from 'react';
import { browserSupportsWebAuthn } from '@simplewebauthn/browser';

import { useUser } from '@app/core/context-providers/user-context/use-user';
import { WebauthnAuthenticatorModel } from '@app/core/model/authenticator.model';

import { ManageWebauthnAuthenticationView } from './manage-webauthn-authentication.view';

interface ManageWebauthnAuthenticationControllerProps {
  authenticators?: WebauthnAuthenticatorModel[];
}

export const ManageWebauthnAuthenticationController = (
  props: ManageWebauthnAuthenticationControllerProps
): JSX.Element => {
  const { authenticators = [] } = props;
  const {
    onChangeWebauthnAuthenticator,
    onCreateWebauthnAuthenticator,
    isWebauthnEnabled,
  } = useUser();

  const isWebauthnSupported = useMemo(() => browserSupportsWebAuthn(), []);

  return (
    <ManageWebauthnAuthenticationView
      isWebauthnEnabled={isWebauthnEnabled}
      isWebauthnSupported={isWebauthnSupported}
      authenticators={authenticators}
      onRenameAuthenticator={onChangeWebauthnAuthenticator}
      onCreateAuthenticator={onCreateWebauthnAuthenticator}
    />
  );
};
