import { useCallback, useEffect } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';

import {
  CHECKOUT_STATUS_QSTR,
  CHECKOUT_STATUS_SUCCESS_QSTR,
} from '@app/config/app-paths.const';
import { PaymentController } from '@app/feature/payment/payment.controller';
import { Logger } from '@app/core/logger/logger';
import { OrderModel } from '@app/core/model/order.model';
import { orderService } from '@app/core/service/order.service';

interface PaymentDrawerControllerProps {
  isValidatedWithoutPayment: boolean;
  currentPaymentMethodId?: string;
  onClose: () => void;
  onOrder: (order: OrderModel) => void;
}

export const PaymentDrawerController = (
  props: PaymentDrawerControllerProps
): JSX.Element => {
  const {
    currentPaymentMethodId,
    onClose,
    isValidatedWithoutPayment,
    onOrder,
  } = props;

  const params = useSearchParams();
  const setSearchParams = params[1];

  // is executed only if checkout does not send a redirectURL -- meaning we must open the precapture dialog manually
  const handleSelectPayment = useCallback(() => {
    onClose();

    setSearchParams(
      createSearchParams({
        [CHECKOUT_STATUS_QSTR]: CHECKOUT_STATUS_SUCCESS_QSTR,
      })
    );
  }, [setSearchParams, onClose]);

  useEffect(() => {
    if (isValidatedWithoutPayment) {
      return;
    }

    const [validateQuoteWithoutPaymentPromise] =
      orderService.validateWithoutPayment();

    validateQuoteWithoutPaymentPromise.then(onOrder).catch((error) => {
      Logger.error(error);
    });
  }, [onOrder, isValidatedWithoutPayment]);

  return (
    <PaymentController
      currentPaymentMethodId={currentPaymentMethodId}
      onSelect={handleSelectPayment}
      onBack={onClose}
    />
  );
};
