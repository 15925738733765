import { AbortablePromise } from '@app/core/helper/abort-promise';
import { httpClientService } from '@app/core/client/http/http-client.service';
import { PrivateAccessAuthenticate } from '../model/private-access-authenticate';
import { PrivateAccessLoginForm } from '../model/private-access-login-form';

export const PRIVATE_ACCESS_CHECK_JWT_API = '/private/check-jwt';
export const PRIVATE_ACCESS_AUTHENTICATE_API = '/private/authenticate';

class PrivateAccessService {
  checkJwt(jwt: string): AbortablePromise<void> {
    return httpClientService.post<void>(PRIVATE_ACCESS_CHECK_JWT_API, { jwt });
  }

  authenticate(
    data: PrivateAccessLoginForm
  ): AbortablePromise<PrivateAccessAuthenticate> {
    return httpClientService.post<PrivateAccessAuthenticate>(
      PRIVATE_ACCESS_AUTHENTICATE_API,
      data
    );
  }
}

export const privateAccessService = new PrivateAccessService();
