import { Logger } from '../logger/logger';
import { ApiError } from './api-error';
import { showDefaultErrorMessage } from './show-error-message';

type CatchCallbackFunction = (error: ApiError) => void;

export function catchApiError(
  error: unknown,
  callback: CatchCallbackFunction
): void {
  if (!(error instanceof ApiError)) {
    Logger.warn('catchApiError', 'error is not an instance of ApiError', error);
    showDefaultErrorMessage();
    return;
  }

  if (error.isIntercepted) {
    return;
  }

  Logger.debug('catchApiError', error);
  callback(error);
}
