import { PricingModel } from '@app/core/model/charge-point.model';
import { CostModel } from '@app/core/model/cost.model';
import { CustomerInformationsModel } from '@app/core/model/customer-information.model';
import { InvoiceResponseModel } from '@app/core/model/invoice-response.model';
import { TariffModel } from './tariff.model';
import { mapOcpiTariffModelToTariffModel } from '../helper/ocpi-utils';
import { TransactionTypeEnum } from './enum/transaction-type.enum';

export class InvoiceModel {
  invoiceId: string;

  orderId: string;

  pricing: PricingModel & {
    tariff?: TariffModel;
  };

  customer: CustomerInformationsModel;

  chargePointName: string;

  cost: CostModel;

  createdAt: Date;

  startedAt: Date;

  duration: number;

  energyDelivered: number;

  proforma?: boolean;

  paymentTicket?: {
    paymentTicketId: string;
    provider: TransactionTypeEnum;
  };

  constructor(payload: InvoiceResponseModel) {
    const { tariff: tariffResponse, ...pricing } = payload.header.pricing;

    this.cost = {
      totalTTC: payload.details.session.vatArray.totalTTC,
      totalHT: payload.details.session.vatArray.totalHT,
      totalVAT:
        payload.details.session.vatArray.totalTTC -
        payload.details.session.vatArray.totalHT,
      vatRate: payload.details.vat.percent,
    };
    this.createdAt = new Date(payload.createdAt);
    this.startedAt = new Date(payload.details.session.start);
    this.duration = payload.details.session.chargingTime;
    this.energyDelivered = payload.details.session.totalKWh;
    this.pricing = {
      ...pricing,
      ...(tariffResponse && {
        tariff: mapOcpiTariffModelToTariffModel(tariffResponse),
      }),
    };
    this.chargePointName = payload.header.location.name;
    this.customer = payload.header.customer;
    this.invoiceId = payload.invoiceId;
    this.orderId = payload.orderId;
    this.proforma = false;
    this.paymentTicket = {
      paymentTicketId: payload.paymentTicket.paymentTicketId,
      provider: payload.paymentTicket.provider,
    };
  }
}
