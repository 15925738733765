import { Icon, IconName } from '@app/shared/icon/icon';
import { Spinner } from '@app/shared/spinner/spinner';
import { Toggle } from '@app/shared/toggle/toggle';
import { Text } from '@app/shared/typography/text/text';
import css from './user-preference.module.scss';

interface UserPreferenceProps {
  requireEmailVerified: boolean;
  label: string;
  isActive: boolean;
  name: string;
  onChange: (state: boolean) => void;
  pending?: boolean;
  icon?: IconName;
}

export const UserPreference = ({
  requireEmailVerified,
  label,
  isActive,
  pending,
  name,
  onChange,
  icon,
}: UserPreferenceProps): JSX.Element => {
  const handleChange = (value: boolean) => {
    if (!requireEmailVerified) {
      onChange(value);
    }
  };

  return (
    <div className={css.action}>
      {icon && <Icon name={icon} />}
      <Text className={css.label}>{label}</Text>
      {!pending && (
        <Toggle
          isDisabled={requireEmailVerified}
          state={isActive && !requireEmailVerified}
          id={name}
          onChange={handleChange}
        />
      )}
      {pending && <Spinner />}
    </div>
  );
};
