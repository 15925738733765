import { useCallback, useEffect, useMemo, useReducer } from 'react';

import { AbortablePromise } from '@app/core/helper/abort-promise';
import { ApiError } from '@app/core/error/api-error';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { HttpStatusEnum } from '@app/config/error-config';
import { catchApiError } from '@app/core/error/catch-api-error';
import { ChargeSessionEnum } from '@app/core/model/enum/charge-session.enum';
import { ChargeStateEnum } from '@app/core/model/enum/charge-state.enum';
import { chargeSessionService } from '@app/core/service/charge-session.service';
import { useAuthentication } from '@app/core/context-providers/authentication-context/use-authentication';
import { ChargeSessionConsumptionDataModel } from '@app/core/model/charge-session-response.model';
import { ChargingEventModel } from '@app/core/model/charging-event.model';

import { setInLocalStorage } from '@app/core/storage/local-storage';
import { DELMO_CHARGE_POINT_ID_LSK } from '@app/config/localstorage-keys.const';
import { mapChargeSessionEnumToChargeStateEnum } from './helper/map-charge-session-enum-to-charge-state-enum';
import { IChargeContext, ChargeContext } from './charge-context';
import { AsyncProviderProps } from '../providers.props';
import { chargeSessionReducer } from './charge-session-reducer/charge-session.reducer';
import { defaultChargeSession } from './charge-session-reducer/charge-session-reducer.helper';

export function ChargeContextProvider({
  onReady,
  children,
}: AsyncProviderProps): JSX.Element {
  const { isAuthenticated } = useAuthentication();

  const [session, dispatch] = useReducer(
    chargeSessionReducer,
    defaultChargeSession
  );

  const loadData = useCallback((): AbortablePromise<void> => {
    const [chargeSessionPromise, abortPromise] =
      chargeSessionService.getActive();

    const returnedPromise = chargeSessionPromise
      .then((response) => {
        if (response.status.type < ChargeSessionEnum.SESSION_PENDING) {
          dispatch({
            type: response.status.type,
            payload: new ChargingEventModel({
              transactionId: response.transactionId,
            }),
          });
          return;
        }

        const baseEvent = {
          transactionId: response.transactionId,
          startedAt: response.sessionStartedAt,
          endedAt: response.sessionEndedAt,
        };

        dispatch({
          type: ChargeSessionEnum.SESSION_STARTED,
          payload: new ChargingEventModel({
            ...baseEvent,
            transactionId: response.transactionId,
          }),
        });

        setInLocalStorage(DELMO_CHARGE_POINT_ID_LSK, response.chargePointId);

        response?.consumptionsData?.forEach(
          (consumptionItem: ChargeSessionConsumptionDataModel) => {
            dispatch({
              type: ChargeSessionEnum.SESSION_PENDING,
              payload: new ChargingEventModel({
                ...baseEvent,
                updatedAt: consumptionItem.sampleTime,
                totalHT: consumptionItem.totalHT,
                totalTTC: consumptionItem.totalTTC,
                totalKwh: consumptionItem.totalKwh,
              }),
            });
          }
        );
      })
      .catch((error) => {
        catchApiError(error, (apiError: ApiError) => {
          if (apiError.status === HttpStatusEnum.NOT_FOUND) {
            dispatch({ type: ChargeSessionEnum.RESET });
            return;
          }

          showErrorMessage('get-charge-session', apiError.code);
        });
      });

    return [returnedPromise, abortPromise];
  }, []);

  // Retrieve current charge-session
  useEffect(() => {
    if (!isAuthenticated) {
      onReady();
      return () => {};
    }

    const [chargeSessionPromise, abortPromise] = loadData();

    chargeSessionPromise.finally(onReady);

    return () => {
      abortPromise.abort();
    };
  }, [isAuthenticated, onReady, loadData]);

  const refresh = useCallback((): Promise<void> => {
    const [loadDataPromise] = loadData();

    return loadDataPromise;
  }, [loadData]);

  const value: IChargeContext = useMemo(() => {
    const chargeState: ChargeStateEnum = mapChargeSessionEnumToChargeStateEnum(
      session.status,
      session.endedAt !== null
    );

    const isChargeStarted =
      chargeState >= ChargeStateEnum.START_CHARGE_REQUESTED;
    const isCharging =
      isChargeStarted && chargeState < ChargeStateEnum.SESSION_ENDED;
    const isChargeEnded =
      chargeState === ChargeStateEnum.SESSION_ENDED ||
      chargeState === ChargeStateEnum.SESSION_ERROR;
    const isError = chargeState === ChargeStateEnum.SESSION_ERROR;

    const lastHistory: ChargingEventModel | null =
      session.history.length > 0
        ? session.history[session.history.length - 1]
        : null;

    return {
      chargeState,
      history: session.history,
      transactionId: session.transactionId,

      ...(session.startedAt && { startedAt: new Date(session.startedAt) }),

      ...(lastHistory?.updatedAt && {
        updatedAt: new Date(lastHistory?.updatedAt),
      }),

      duration: lastHistory?.duration,
      totalKwh: lastHistory?.totalKwh,
      totalTTC: lastHistory?.totalTTC,

      onEvent: dispatch,
      isChargeStarted,
      isCharging,
      isChargeEnded,
      isError,
      refresh,
    };
  }, [session, refresh]);

  return (
    <ChargeContext.Provider value={value}>{children}</ChargeContext.Provider>
  );
}
