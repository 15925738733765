import { useTranslation } from 'react-i18next';

import { Text } from '@app/shared/typography/text/text';
import { ChargingCarAnim } from '@app/shared/charging-car-anim/charging-car-anim';
import { Button } from '@app/shared/button/button';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { BackLink } from '@app/shared/back-link/back-link';
import { H1 } from '@app/shared/typography/heading/heading';

interface ApiErrorViewProps {
  onRefresh: () => void;
  onBack: () => void;
}

export const ApiErrorView = (props: ApiErrorViewProps): JSX.Element => {
  const { onRefresh, onBack } = props;
  const { t } = useTranslation();

  return (
    <PageLayout>
      <BackLink onClick={onBack} />
      <PageLayout.Header>
        <H1>{t('api-error.title')}</H1>
      </PageLayout.Header>

      <PageLayout.Body>
        <ChargingCarAnim />
        <Text>{t('api-error.message')}</Text>
      </PageLayout.Body>

      <PageLayout.Footer>
        <Button onClick={onRefresh}>{t('api-error.refresh-button')}</Button>
      </PageLayout.Footer>
    </PageLayout>
  );
};
