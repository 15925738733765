import { useEffect, useState } from 'react';

import { showErrorMessage } from '@app/core/error/show-error-message';
import { catchApiError } from '@app/core/error/catch-api-error';
import { ApiError } from '@app/core/error/api-error';
import { PaymentWidgetsConfigurationResponseModel } from '@app/core/model/payment-widgets-configuration-response.model';
import { paymentTransactionService } from '@app/core/service/payment-transaction.service';

interface PaymentWidgetConfigurationHook {
  configuration?: PaymentWidgetsConfigurationResponseModel;
  isError: boolean;
}

export const usePaymentWidgetConfiguration =
  (): PaymentWidgetConfigurationHook => {
    const [configuration, setConfiguration] =
      useState<PaymentWidgetsConfigurationResponseModel>();
    const [isError, setIsError] = useState(false);

    useEffect(() => {
      const [configPromise, configAbort] =
        paymentTransactionService.getConfiguration();

      configPromise.then(setConfiguration).catch((err) => {
        setIsError(true);

        catchApiError(err, (error: ApiError) =>
          showErrorMessage('list-payment-configuration', error.code)
        );
      });

      return () => {
        configAbort.abort();
      };
    }, []);

    return { configuration, isError };
  };
