import { TariffElementModel } from '@app/core/model/tariff.model';
import { Divider } from '@app/shared/divider/divider';

import { TariffElement } from './tariff-element';

type Props = {
  tariffElements: TariffElementModel[];
};

export function TariffDetailDrawerBody(props: Props): JSX.Element {
  const { tariffElements } = props;

  return (
    <>
      {tariffElements.map((tariffElement, index) => (
        <>
          {index > 0 && <Divider />}

          <TariffElement
            key={`${
              (tariffElement.priceComponents.flatTTC ?? 0) +
              tariffElement.priceComponents.perKwhTTC +
              (tariffElement.priceComponents.perTimeChargingHourTTC ?? 0)
            }`}
            {...tariffElement}
          />
        </>
      ))}
    </>
  );
}
