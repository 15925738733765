import { useCallback } from 'react';

import { Dialog, DialogProps } from '@app/shared/dialog/dialog';

import { EditPaymentView } from './edit-payment.view';

interface EditPaymentDialogProps extends DialogProps {
  onContinue: () => void;
}

export const EditPaymentDialog = (
  props: EditPaymentDialogProps
): JSX.Element => {
  const { onContinue, onClose = () => {}, ...dialogProps } = props;

  const handleContinue = useCallback(() => {
    onContinue();
    onClose();
  }, [onContinue, onClose]);

  return (
    <Dialog {...dialogProps} onClose={onClose}>
      <EditPaymentView onContinue={handleContinue} onCancel={onClose} />
    </Dialog>
  );
};
