import { useMemo } from 'react';
import { useCharge } from '@app/core/context-providers/charge-context/use-charge';
import {
  mapHistoryToTotalKWPoint,
  mapTotalEnergyDeliveredPointToSpeedPoint,
} from '@app/feature/charging/component/charge-chart/charge-chart-utils';

import { MAX_AWAIT_CHART } from '@app/config/charge-charts.const';
import { Point } from './model/point';
import { ChargeChartView } from './charge-chart.view';
import {
  FallbackChargeChart,
  FallbackReason,
} from './fallback-charge-chart.view';

export const ChargeChartController = (): JSX.Element | null => {
  const { history, duration } = useCharge();

  const totalKWDeliveredPoint: Point[] = useMemo(
    () => mapHistoryToTotalKWPoint(history),
    [history]
  );

  const speedChargePoint: Point[] = useMemo(
    () => mapTotalEnergyDeliveredPointToSpeedPoint(totalKWDeliveredPoint),
    [totalKWDeliveredPoint]
  );

  // Wait to have at least 2 points to display the chart
  if (history.length <= 2) {
    if (history.length === 1 && duration && duration > MAX_AWAIT_CHART * 60) {
      return <FallbackChargeChart reason={FallbackReason.NO_DATA_IN_TIME} />;
    }

    return <FallbackChargeChart reason={FallbackReason.AWAIT_DATA} />;
  }

  return (
    <ChargeChartView
      speedCharge={speedChargePoint}
      totalKWDelivered={totalKWDeliveredPoint}
    />
  );
};
