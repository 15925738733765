import classNames from 'classnames';
import { ReactNode, useLayoutEffect, useRef, useState } from 'react';

import { Spinner } from '../spinner/spinner';
import css from './collapsible.module.scss';

type CollapsibleProps = {
  isOpen: boolean;
  children: ReactNode;
};

export const Collapsible = (props: CollapsibleProps): JSX.Element => {
  const { isOpen, children } = props;
  const collapseRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  const getWrapperSize = () =>
    wrapperRef.current ? wrapperRef.current.clientHeight : 0;

  const collapsedSize = 0;

  useLayoutEffect(() => {
    if (!collapseRef.current || !wrapperRef.current) return () => {};

    const observer = new ResizeObserver(() => {
      const wrapperSize = getWrapperSize();
      setIsLoading(wrapperSize === 0);
      collapseRef.current!.style.maxHeight = `${
        isOpen ? wrapperSize : collapsedSize
      }px`;
    });

    observer.observe(wrapperRef.current);

    return () => observer.disconnect();
  }, [isOpen, children]);

  return (
    <div className={css.container}>
      <div ref={collapseRef} className={classNames(css.collapsible)}>
        <div ref={wrapperRef}>{children}</div>
      </div>
      {isLoading && <Spinner className={css.spinner} />}
    </div>
  );
};
