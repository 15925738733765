import {
  PSEUDO_MAX_LENGTH,
  PSEUDO_MIN_LENGTH,
} from '@app/config/form-validation-rules.const';

export const recoverAccountWithWebauthnFormValidationRules = {
  pseudo: {
    required: true,
    minLength: PSEUDO_MIN_LENGTH,
    maxLength: PSEUDO_MAX_LENGTH,
  },
};
