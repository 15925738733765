import { TranslationPaths } from '@app/core/helper/use-typed-translation';

export interface VerifyEmailOTPTranslations {
  title: string;
  caption: string;
  codeNotReceived: string;
  warningRequestingAnotherCode: string;
  resendCode: string;
  invalidCode: string;
  submitButton: string;
}

export function buildVerifyEmailOTPTranslations(
  translationKey: TranslationPaths
): VerifyEmailOTPTranslations {
  return {
    title: `${translationKey}.verify-code.title`,
    caption: `${translationKey}.verify-code.caption`,
    codeNotReceived: `${translationKey}.verify-code.code-not-received`,
    warningRequestingAnotherCode: `${translationKey}.verify-code.warning-requesting-another-code`,
    resendCode: `${translationKey}.verify-code.resend-code`,
    invalidCode: `${translationKey}.verify-code.code-invalid`,
    submitButton: `${translationKey}.verify-code.submit-button`,
  };
}
