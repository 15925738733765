import { HTMLProps } from 'react';
import classNames from 'classnames';

import CarModel from '@app/asset/car-model.svg';
import Ellipse1 from '@app/asset/ellipse1.svg';
import Ellipse2 from '@app/asset/ellipse2.svg';
import Ellipse3 from '@app/asset/ellipse3.svg';
import Ellipse4 from '@app/asset/ellipse4.svg';

import css from './charging-car-anim.module.scss';
import { Spinner } from '../spinner/spinner';
import { Text } from '../typography/text/text';

type Props = HTMLProps<HTMLDivElement> & {
  animate?: boolean;
  loading?: boolean;
  message?: string | null;
};

export const ChargingCarAnim = (props: Props): JSX.Element => {
  const { animate = false, loading = false, message, className } = props;

  return (
    <div className={classNames(css.container, className)}>
      <Ellipse1
        className={classNames(
          css.animated,
          css.ellipse1,
          loading && css.disabled,
          !animate && css.pause
        )}
      />
      {animate && (
        <>
          <Ellipse2
            className={classNames(
              css.animated,
              css.ellipse2,
              loading && css.disabled,
              !animate && css.pause
            )}
          />
          <Ellipse3
            className={classNames(
              css.animated,
              css.ellipse3,
              loading && css.disabled,
              !animate && css.pause
            )}
          />
          <Ellipse4
            className={classNames(
              css.animated,
              css.ellipse4,
              loading && css.disabled,
              !animate && css.pause
            )}
          />
        </>
      )}

      <CarModel className={classNames(css.carModel, loading && css.disabled)} />

      {loading && (
        <div className={css.spinnerContainer}>
          <Spinner color="brand-secondary" className={css.spinner} />
        </div>
      )}

      {message && <Text className={css.message}>{message}</Text>}
    </div>
  );
};
