import { useTranslation } from 'react-i18next';

import { LearnMoreLink } from '@app/feature/faq/component/learn-more-link/learn-more-link';
import { Button } from '@app/shared/button/button';
import { Icon } from '@app/shared/icon/icon';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';
import { H2 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';

import { CircleContainer } from '@app/shared/circle-container/circle-container';
import css from './edit-payment.module.scss';

interface EditPaymentViewProps {
  onContinue: () => void;
  onCancel: () => void;
}

export const EditPaymentView = (props: EditPaymentViewProps): JSX.Element => {
  const { onContinue, onCancel } = props;
  const { t } = useTranslation();

  return (
    <DialogLayout>
      <DialogLayout.Body>
        <CircleContainer className={css.iconContainer}>
          <Icon
            color="primary"
            name="alert-triangle"
            className={css.dialogIcon}
          />
        </CircleContainer>
        <H2>{t('quote.edit-payment-dialog.title')}</H2>

        <Text>
          {t('quote.edit-payment-dialog.warning')}{' '}
          <LearnMoreLink questionId="precapture" />
        </Text>
        <Text>{t('quote.edit-payment-dialog.are-you-sure')}</Text>
      </DialogLayout.Body>

      <DialogLayout.Footer className={css.footer}>
        <Button variant="outlined" onClick={onCancel}>
          {t('quote.edit-payment-dialog.cancel')}
        </Button>
        <Button onClick={onContinue}>
          {t('quote.edit-payment-dialog.continue')}
        </Button>
      </DialogLayout.Footer>
    </DialogLayout>
  );
};
