import { TariffRestrictionModel } from '@app/core/model/tariff.model';
import { useMemo } from 'react';
import { useRestrictionText } from '../restriction/use-restriction-text';

type Props = {
  restrictions: TariffRestrictionModel;
};

export function InlineTariffRestriction(props: Props): JSX.Element {
  const { restrictions } = props;
  const getRestrictionText = useRestrictionText();

  const restrictionTextArray: string[] = useMemo(() => {
    const result: string[] = [];

    const timeRestriction = getRestrictionText({
      startTime: restrictions.startTime,
      endTime: restrictions.endTime,
    });

    if (timeRestriction) {
      result.push(timeRestriction);
    }

    const energyRestriction = getRestrictionText({
      minKwh: restrictions.minKwh,
      maxKwh: restrictions.maxKwh,
    });

    if (energyRestriction) {
      result.push(energyRestriction);
    }
    return result;
  }, [restrictions, getRestrictionText]);

  return <>{restrictionTextArray.join(' - ')}</>;
}
