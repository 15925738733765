import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import classnames from 'classnames';

import { Icon, IconName } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';

import css from './appbar-link.module.scss';

export interface AppbarLinkProps extends RouterLinkProps {
  on?: boolean;
  badge?: boolean;
  label?: string;
  icon: IconName;
}

export const AppbarLink = ({
  on = false,
  badge = false,
  label = '',
  icon,
  className,
  children,
  ...props
}: AppbarLinkProps): JSX.Element => {
  return (
    <RouterLink className={classnames(css.link, className)} {...props}>
      {children}

      <Icon name={icon} color={on ? 'primary' : 'inherit'} badge={badge} />
      <Text fontSize="small" bold={on}>
        {label}
      </Text>
    </RouterLink>
  );
};
