import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  Fragment,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@app/shared/checkbox/checkbox';
import { Link } from '@app/shared/typography/link/link';
import { LegalText } from '@app/core/model/legal-text';
import { Text } from '@app/shared/typography/text/text';

import css from './mandatory-consent-checkbox.module.scss';

interface MandatoryConsentCheckboxProps {
  legalTexts: LegalText[];
  onClick: (legalText: LegalText) => void;
  id: string;
  className?: string;
  errorMessage?: string;
  isTouched?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const MandatoryConsentCheckbox = forwardRef<
  HTMLInputElement,
  MandatoryConsentCheckboxProps
>(
  (
    props: MandatoryConsentCheckboxProps,
    ref: ForwardedRef<HTMLInputElement>
  ): JSX.Element => {
    const { legalTexts, onClick, errorMessage, isTouched, ...checkboxProps } =
      props;
    const { t } = useTranslation();

    const legalTextLinks: JSX.Element = useMemo(() => {
      const logicalConnector = t(
        'registration.registration-form.legal-text.logical-connector'
      );

      const legalTextLabelsAsLink = legalTexts.map((legalText: LegalText) => (
        <Link
          key={legalText.id}
          color="inherit"
          onClick={() => onClick(legalText)}
          className={css.label}>
          {legalText.title}
        </Link>
      ));

      const lastIndex = legalTextLabelsAsLink.length - 1;

      return (
        <>
          {legalTextLabelsAsLink.map((item, index) => (
            <Fragment key={item.key}>
              {index > 0 && index < lastIndex && ', '}
              {index === lastIndex && ` ${logicalConnector} `}
              {item}
            </Fragment>
          ))}
        </>
      );
    }, [t, legalTexts, onClick]);

    return (
      <div className={css.label}>
        <Checkbox
          {...checkboxProps}
          label={t('registration.registration-form.legal-text.label')}
          ref={ref}
        />{' '}
        {legalTextLinks}
        <div>
          <Text color="error">{isTouched && errorMessage}</Text>
        </div>
      </div>
    );
  }
);
