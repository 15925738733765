import { useTranslation } from 'react-i18next';
import { InfosWithLegend } from '@app/shared/infos-with-legend/infos-with-legend';

type Props = {
  duration: number;
  disabled?: boolean;
  className?: string;
};

export const LiveDurationView = (props: Props): JSX.Element => {
  const { duration, disabled, className } = props;
  const { t } = useTranslation();

  return (
    <InfosWithLegend
      className={className}
      disabled={disabled}
      icon="clock-refresh"
      value={t('charging.live-duration.value', {
        duration,
      })}
      label={t('charging.live-duration.label')}
    />
  );
};
