import { useTranslation } from 'react-i18next';

type GenerateInvoiceFilenameHookReturn = (
  createdAt: Date,
  pseudo: string
) => string;

export function useGenerateInvoiceFilename(): GenerateInvoiceFilenameHookReturn {
  const { t } = useTranslation();

  return (createdAt: Date, pseudo: string) => {
    const createdAtKebabCase = `${createdAt.getDate()}-${createdAt.getMonth()}-${createdAt.getFullYear()}-${createdAt.getHours()}-${createdAt.getMinutes()}`;

    return `${t('shared.invoice.download-button.filename', {
      pseudo,
      createdAtKebabCase,
    })}.pdf`;
  };
}
