/*
 * Transaction API
 */
const TRANSACTION_ENDPOINT = `transaction-service`;
export const GET_CHARGE_POINT_API = `/${TRANSACTION_ENDPOINT}/charge-point`;
export const ACTIVE_CHARGE_SESSION_API = `/${TRANSACTION_ENDPOINT}/charge-session/current`;
export const START_CHARGE_API = `/${TRANSACTION_ENDPOINT}/start-charge`;
export const STOP_CHARGE_API = `/${TRANSACTION_ENDPOINT}/stop-charge`;

/*
 * Authentication API
 */
const AUTHENTICATION_ENDPOINT = `authentication`;
export const START_WEBAUTHN_AUTHENTICATION_API = `/${AUTHENTICATION_ENDPOINT}/webauthn`;
export const VERIFY_WEBAUTHN_AUTHENTICATION_API = `/${AUTHENTICATION_ENDPOINT}/webauthn/verify`;
export const START_EMAIL_AUTHENTICATION_API = `/${AUTHENTICATION_ENDPOINT}/email`;
export const VERIFY_EMAIL_AUTHENTICATION_API = `/${AUTHENTICATION_ENDPOINT}/email/verify`;

/*
 * Identity API
 */
const IDENTITY_ENDPOINT = `identity`;
export const START_IDENTITY_VERIFICATION_API = `${IDENTITY_ENDPOINT}/start-verification`;
export const VERIFY_IDENTITY_VERIFICATION_API = `${IDENTITY_ENDPOINT}/confirm-verification`;

/*
 * Registration API
 */
const REGISTRATION_ENDPOINT = `registration`;
export const START_WEBAUTHN_REGISTRATION_API = `/${REGISTRATION_ENDPOINT}/webauthn`;
export const VERIFY_WEBAUTHN_REGISTRATION_API = `/${REGISTRATION_ENDPOINT}/webauthn/verify`;
export const START_EMAIL_REGISTRATION_API = `/${REGISTRATION_ENDPOINT}/email`;
export const VERIFY_EMAIL_REGISTRATION_API = `/${REGISTRATION_ENDPOINT}/email/verify`;

/*
 * Invoice API
 */
const INVOICE_ENDPOINT = 'invoice-service';

const ORDER_RESOURCE = 'order';
export const GET_LATEST_ORDER_API = `/${INVOICE_ENDPOINT}/${ORDER_RESOURCE}/latest`;
export const GET_LATEST_ORDER_WITH_COMPLETED_API = `/${INVOICE_ENDPOINT}/${ORDER_RESOURCE}/latest-with-completed`;
export const CREATE_ORDER_API = `/${INVOICE_ENDPOINT}/${ORDER_RESOURCE}`;
export const INVOICE_PROFORMA_API = `${INVOICE_ENDPOINT}/${ORDER_RESOURCE}/awaiting-capture`;

const QUOTE_RESOURCE = 'quote';
export const VALIDATE_ORDER_QUOTE_WITHOUT_PAYMENT_API = `/${INVOICE_ENDPOINT}/${QUOTE_RESOURCE}/validate-without-payment`;
export const VALIDATE_ORDER_QUOTE_API = `/${INVOICE_ENDPOINT}/${QUOTE_RESOURCE}/validate`;

const INVOICE_RESOURCE = 'invoices';
export const INVOICE_API = `/${INVOICE_ENDPOINT}/${INVOICE_RESOURCE}`;

export const DOWNLOAD_INVOICE_API = `/${INVOICE_ENDPOINT}/${INVOICE_RESOURCE}/:invoiceId/pdf`;

/*
 * Payment routes
 */

const PAYMENT_ENDPOINT = 'payment-service';

const PAYMENT_METHOD_RESOURCE = 'payment-methods';
export const PAYMENT_METHOD_API = `/${PAYMENT_ENDPOINT}/${PAYMENT_METHOD_RESOURCE}`;
export const PREFERRED_PAYMENT_METHOD_API = `/${PAYMENT_ENDPOINT}/${PAYMENT_METHOD_RESOURCE}/preferred`;
export const RENAME_PAYMENT_METHOD_API = `/${PAYMENT_ENDPOINT}/${PAYMENT_METHOD_RESOURCE}/:iPaymentSourceId`;

const TRANSACTION_RESOURCE = 'transaction';
export const PRE_CAPTURE_SYNC_API = `/${PAYMENT_ENDPOINT}/${TRANSACTION_RESOURCE}/pre-capture-sync`;
export const CREATE_TRANSACTION_FROM_TOKEN_API = `/${PAYMENT_ENDPOINT}/${TRANSACTION_RESOURCE}/create-from-checkout-token`;
export const CREATE_TRANSACTION_FROM_PROVIDER_API = `/${PAYMENT_ENDPOINT}/${TRANSACTION_RESOURCE}/create-from-provider-token`;
export const CREATE_TRANSACTION_FROM_I_PAYMENT_SOURCE_ID_API = `/${PAYMENT_ENDPOINT}/${TRANSACTION_RESOURCE}/create-from-i-payment-source-id`;

export const PROVIDERS_CONFIG_API = `/${PAYMENT_ENDPOINT}/providers-config`;
export const VALIDATE_APPLE_SESSION_API = `/${PAYMENT_ENDPOINT}/${TRANSACTION_RESOURCE}/validate-apple-session`;

/*
 * User API
 */
const USER_ENDPOINT = 'users';
export const USER_API = `/${USER_ENDPOINT}/me`;
export const GPDR_API = `/${USER_ENDPOINT}/me/rgpd`;
export const USER_SYNC_SUBSCRIPTION = `${USER_API}/sync-subscriptions`;

export const SUBSCRIPTION_DETAILS_API = '/subscription-details';

/*
 * Legal-text API
 */
export const LEGAL_TEXT_API = `/legal-text`;

/*
 * Authentication method API
 */
const AUTHENTICATION_METHOD_ENDPOINT = 'authentication-methods';

export const AUTHENTICATION_WEBAUTHN_API = `${AUTHENTICATION_METHOD_ENDPOINT}/webauthn`;
export const AUTHENTICATION_ADD_WEBAUTHN_API = `/${AUTHENTICATION_METHOD_ENDPOINT}/webauthn/add`;
export const AUTHENTICATION_VERIFY_WEBAUTHN_API = `/${AUTHENTICATION_METHOD_ENDPOINT}/webauthn/add/confirm`;

export const START_EMAIL_VALIDATION_API = `/${AUTHENTICATION_METHOD_ENDPOINT}/email/validate`;
export const VERIFY_EMAIL_VALIDATION_API = `/${AUTHENTICATION_METHOD_ENDPOINT}/email/validate/confirm`;

export const START_EMAIL_UPDATE_API = `/${AUTHENTICATION_METHOD_ENDPOINT}/email`;
export const VERIFY_EMAIL_UPDATE_API = `/${AUTHENTICATION_METHOD_ENDPOINT}/email/confirm`;

/*
 * Websocket topics
 */
export const WS_TOKEN_WST = `ws-token`;
export const JWT_TOKEN_WST = `jwt-token`;
export const AUTHENTICATE_WST = `authenticate`;
export const JWT_EXPIRED_WST = `jwt-expired-event`;
export const ORDER_WST = `order-event`;

export const SESSION_START_CP_TIMEOUT_WST = `start_charge_cp_timeout`;
export const SESSION_START_CP_ACCEPTED_WST = `start_charge_cp_accepted`;
export const SESSION_START_CP_REFUSED_WST = `start_charge_cp_refused`;
export const SESSION_START_TIMEOUT_WST = `session_start_timeout`;
export const SESSION_STARTED_WST = `session_started`;
export const SESSION_PENDING_WST = `session_pending`;
export const SESSION_STOP_CP_TIMEOUT_WST = `stop_charge_cp_timeout`;
export const SESSION_STOP_CP_ACCEPTED_WST = `stop_charge_cp_accepted`;
export const SESSION_STOP_CP_REFUSED_WST = `stop_charge_cp_refused`;
export const SESSION_STOP_TIMEOUT_WST = `session_stop_timeout`;
export const SESSION_ENDED_WST = `session_ended`;
export const SESSION_INVALID_WST = `session_invalid`;
