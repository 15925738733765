import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LOCALE_KEYS } from '@app/core/locale/locale';
import i18n from '@app/core/locale/i18n';

import { Button } from '@app/shared/button/button';
import { H1 } from '@app/shared/typography/heading/heading';
import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';
import { TextInput } from '@app/shared/text-input/text-input';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';

import { PrivateAccessLoginForm } from './model/private-access-login-form';
import { privateAccessFormValidationRules } from './private-access-form-validation-rules.const';

import css from './private-access.module.scss';

import en from './locale/en.json';
import fr from './locale/fr.json';

i18n
  .addResourceBundle(LOCALE_KEYS.en, 'translation', en, true)
  .addResourceBundle(LOCALE_KEYS.fr, 'translation', fr, true);

interface PrivateAccessViewProps {
  pending: boolean;
  onSubmit: (payload: PrivateAccessLoginForm) => Promise<void>;
}

export const PrivateAccessView = (
  props: PrivateAccessViewProps
): JSX.Element => {
  const { t } = useTranslation();
  const { pending, onSubmit } = props;

  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { touchedFields, errors, isValid },
  } = useForm<PrivateAccessLoginForm>({
    mode: 'onChange',
    defaultValues: {},
  });

  const handleTrimThenSubmit = (data: PrivateAccessLoginForm) => {
    onSubmit({
      email: data.email.trim(),
      password: data.password.trim(),
    });
  };

  return (
    <form
      onSubmit={handleSubmit(handleTrimThenSubmit)}
      className={css.formContainer}>
      <PageLayout>
        <PageLayout.Header>
          <H1>{t('private-access.title')}</H1>
          <Text className={css.introduction}>
            {t('private-access.introduction')}
          </Text>
        </PageLayout.Header>

        <PageLayout.Body>
          <TextInput
            autoFocus
            label={t('private-access.email.label')}
            {...register('email', privateAccessFormValidationRules.email)}
            isTouched={touchedFields?.email}
            errorMessage={
              errors?.email?.type === 'pattern'
                ? t('private-access.email.errors.pattern')
                : errors?.email?.type === 'required'
                ? t('private-access.email.errors.required')
                : ''
            }
          />
          <TextInput
            label={t('private-access.password.label')}
            adornment={
              <button
                onClick={() => setShowPassword((value) => !value)}
                type="button">
                <Icon
                  name="delmonicos"
                  color={showPassword ? 'tertiary' : 'secondary'}
                />
              </button>
            }
            type={showPassword ? 'text' : 'password'}
            {...register('password', privateAccessFormValidationRules.password)}
            isTouched={touchedFields?.password}
            errorMessage={
              errors?.password?.type === 'maxLength'
                ? t('private-access.password.errors.max-length')
                : errors?.password?.type === 'minLength'
                ? t('private-access.password.errors.min-length')
                : errors?.password?.type === 'required'
                ? t('private-access.password.errors.required')
                : ''
            }
          />
        </PageLayout.Body>

        <PageLayout.Footer>
          <Button type="submit" disabled={!isValid} isLoading={pending}>
            {t('private-access.submit')}
          </Button>
        </PageLayout.Footer>
      </PageLayout>
    </form>
  );
};
