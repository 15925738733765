import { useState } from 'react';

import { INVOICE_PER_PAGE } from '@app/config/pagination';

export interface OffsetLimitPaginationHookReturn {
  offset: number;
  limit: number;
  incrementPage: () => void;
}

export const useOffsetLimitPagination = (): OffsetLimitPaginationHookReturn => {
  const [page, setPage] = useState(0);

  const incrementPage = () => {
    setPage((prev) => prev + 1);
  };

  return {
    offset: INVOICE_PER_PAGE * page,
    limit: INVOICE_PER_PAGE,
    incrementPage,
  };
};
