import { Text } from '@app/shared/typography/text/text';
import { useTranslation } from 'react-i18next';
import { configurationService } from '@app/core/configuration/configuration.service';
import css from './hosting.module.scss';

export const Hosting = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={css.container}>
      <Text bold>{t('legal-text.legal-notices.hosting.title')}</Text>
      <Text>{configurationService.getLegalNoticesHostingName()}</Text>
      <Text>{configurationService.getLegalNoticesHostingHeadquarters()}</Text>
    </div>
  );
};
