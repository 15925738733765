import classNames from 'classnames';

import css from './stepper.module.scss';

type StepProps = {
  active: boolean;
  left?: number;
};

function Step(props: StepProps): JSX.Element {
  const { active, left = 0 } = props;

  return (
    <div
      className={classNames(css.step, active && css.stepActive)}
      style={{ left: `calc(${left}% - var(--stepper-height))` }}
    />
  );
}

type Props = {
  total: number;
  current: number;
};

export function Stepper(props: Props): JSX.Element {
  const { total, current } = props;

  return (
    <div className={css.stepperContainer}>
      <div
        className={css.backgroundActive}
        style={{
          width: `calc(${
            (current / total) * 100
          }% - (var(--stepper-height) / 2))`,
        }}
      />

      {[...Array(total).keys()].map((value) => (
        <Step
          key={value}
          active={value + 1 === current}
          left={((value + 1) / total) * 100}
        />
      ))}
    </div>
  );
}
