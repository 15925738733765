import { useEffect } from 'react';

export const useInterval = (callback: () => void, duration: number): void => {
  useEffect(() => {
    const timeoutId = setInterval(callback, duration);

    return () => {
      clearInterval(timeoutId);
    };
  }, [callback, duration]);
};
