import { Navigate } from 'react-router-dom';

import { CHARGING_PATH } from '@app/config/app-paths.const';
import { useCharge } from '@app/core/context-providers/charge-context/use-charge';
import { GuardProps } from '@app/router/model/guard.props';

export const ChargeIsEndedGuard = ({ children }: GuardProps): JSX.Element => {
  const { isChargeEnded } = useCharge();

  if (!isChargeEnded) {
    return <Navigate to={CHARGING_PATH} replace />;
  }

  return children;
};
