import { useParams } from 'react-router-dom';
import { useChargePointService } from '@app/core/service/use-charge-point.service';
import { sift } from 'radash';
import { ChargePointController } from './charge-point.controller';

export const DelmoChargePointIdController = (): JSX.Element => {
  const { delmoChargePointId } = useParams() as {
    delmoChargePointId: string;
  };

  const {
    data: chargePoint,
    isBeingInstalled,
    isError,
  } = useChargePointService(delmoChargePointId);

  return (
    <ChargePointController
      connectorList={sift([chargePoint])}
      isError={isError}
      isBeingInstalled={isBeingInstalled}
    />
  );
};
