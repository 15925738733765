import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { TextInput } from '@app/shared/text-input/text-input';
import { Icon } from '@app/shared/icon/icon';
import { Spinner } from '@app/shared/spinner/spinner';
import {
  PAYMENT_METHOD_MAX_LENGTH,
  PAYMENT_METHOD_MIN_LENGTH,
} from '@app/config/form-validation-rules.const';

import {
  RENAME_PAYMENT_METHOD_FORM_VALIDATION_RULES,
  RenamePaymentMethodForm,
} from './rename-payment-method.form';

interface RenamePaymentMethodViewProps {
  pending: boolean;
  defaultValue?: string;
  onSubmit: (payload: RenamePaymentMethodForm) => void;
  onCancel: () => void;
}

export const RenamePaymentMethodView = (
  props: RenamePaymentMethodViewProps
): JSX.Element => {
  const { pending, defaultValue, onSubmit, onCancel } = props;
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { dirtyFields, errors, isValid },
  } = useForm<RenamePaymentMethodForm>({
    mode: 'onChange',
    defaultValues: { name: defaultValue },
    delayError: 400,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        autoFocus
        label={t('user-payment-method.rename.label')}
        {...register('name', RENAME_PAYMENT_METHOD_FORM_VALIDATION_RULES)}
        onBlur={onCancel}
        adornment={
          pending ? (
            <Spinner />
          ) : (
            isValid && (
              <button onClick={handleSubmit(onSubmit)}>
                {' '}
                <Icon name="check" />
              </button>
            )
          )
        }
        isTouched={dirtyFields?.name}
        errorMessage={
          errors?.name?.type === 'pattern'
            ? t('user-payment-method.rename.errors.name.pattern')
            : errors?.name?.type === 'required'
            ? t('user-payment-method.rename.errors.name.required')
            : errors?.name?.type === 'minLength'
            ? t('user-payment-method.rename.errors.name.minLength', {
                minLength: PAYMENT_METHOD_MIN_LENGTH,
              })
            : errors?.name?.type === 'maxLength'
            ? t('user-payment-method.rename.errors.name.maxLength', {
                maxLength: PAYMENT_METHOD_MAX_LENGTH,
              })
            : ''
        }
      />
    </form>
  );
};
