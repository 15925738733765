import jwtDecode from 'jwt-decode';
import { Logger } from '../logger/logger';

interface DecodedTokenModel {
  exp?: number;
}

export class JwtModel {
  public token;

  private expirationDate?: Date;

  constructor(token: string) {
    try {
      const decoded: DecodedTokenModel = jwtDecode(token);
      if (decoded?.exp) {
        this.expirationDate = new Date(decoded.exp * 1000);
      }

      this.token = token;
    } catch (error) {
      Logger.warn(`JWT ${token} is not parsable.`);
    }
  }

  isExpired(): boolean {
    if (!this.expirationDate) {
      return false;
    }

    return this.expirationDate <= new Date();
  }

  getAuthorizationHeader(): string {
    return `Bearer ${this.token}`;
  }
}
