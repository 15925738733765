import { HTMLProps } from 'react';
import classNames from 'classnames';

import css from './text.module.scss';
import cssTypography from '../typography.module.scss';

export interface TextProps extends HTMLProps<HTMLSpanElement> {
  bold?: boolean;
  fontSize?: 'inherit' | 'large' | 'medium' | 'small' | 'xs';
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'light'
    | 'error'
    | 'success'
    | 'brand-primary'
    | 'brand-secondary'
    | 'brand-tertiary';
  truncate?: boolean;
}

export const Text = ({
  bold = false,
  fontSize = 'inherit',
  color = 'inherit',
  truncate = false,
  className,
  ...props
}: TextProps): JSX.Element => (
  <span
    {...props}
    className={classNames(
      css.text,
      truncate && cssTypography.truncate,
      color === 'inherit' && cssTypography.inherit,
      color === 'primary' && cssTypography.primary,
      color === 'secondary' && cssTypography.secondary,
      color === 'tertiary' && cssTypography.tertiary,
      color === 'light' && cssTypography.light,
      color === 'error' && cssTypography.error,
      color === 'success' && cssTypography.success,
      color === 'brand-primary' && cssTypography.brandPrimary,
      color === 'brand-secondary' && cssTypography.brandSecondary,
      color === 'brand-tertiary' && cssTypography.brandTertiary,
      bold && css.bold,
      fontSize === 'large' && css.large,
      fontSize === 'medium' && css.medium,
      fontSize === 'small' && css.small,
      fontSize === 'xs' && css.xs,
      className
    )}
  />
);
