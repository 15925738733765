import { useCallback, useEffect, useState } from 'react';

import { showErrorMessage } from '@app/core/error/show-error-message';
import { useDialog } from '@app/shared/dialog/use-dialog';
import { LegalText } from '@app/core/model/legal-text';
import { legalTextService } from '@app/core/service/legal-text.service';
import { LegalTextDialog } from '@app/shared/legal-text-dialog/legal-text-dialog';
import { ApiError } from '@app/core/error/api-error';
import { catchApiError } from '@app/core/error/catch-api-error';
import { RegistrationRequestModel } from '@app/core/model/registration.model';

import { RegistrationFormView } from './registration-form.view';

interface RegistrationFormControllerProps {
  payload?: RegistrationRequestModel;
  onSubmit: (data: RegistrationRequestModel) => void;
  onGoToAuthentication: () => void;
}

export const RegistrationFormController = (
  props: RegistrationFormControllerProps
): JSX.Element => {
  const { payload, onSubmit, onGoToAuthentication } = props;

  const [legalTextLoading, setLegalTextLoading] = useState(true);

  const [mandatoryLegalTexts, setMandatoryLegalTexts] = useState<LegalText[]>(
    []
  );
  const [optionalLegalTexts, setOptionalLegalTexts] = useState<LegalText[]>([]);

  const [selectedLegalText, setSelectedLegalText] = useState<LegalText>();
  const { openDialog, isDialogOpen, closeDialog } = useDialog();

  useEffect(() => {
    setLegalTextLoading(true);
    const [listLegalTextPromise, listLegalTextAbort] = legalTextService.list();

    listLegalTextPromise
      .then((response: LegalText[]) => {
        const mandatory = response.filter(
          (legalText) => legalText.isConsentRequired
        );
        const optional = response.filter(
          (legalText) => !legalText.isConsentRequired
        );

        setMandatoryLegalTexts(mandatory);
        setOptionalLegalTexts(optional);
      })
      .catch((error) => {
        catchApiError(error, (apiError: ApiError) => {
          showErrorMessage('list-legal-text', apiError.code);
        });
      })
      .finally(() => {
        setLegalTextLoading(false);
      });

    return () => {
      listLegalTextAbort.abort();
    };
  }, []);

  const handleViewLegalText = useCallback(
    (legalText: LegalText) => {
      setSelectedLegalText(legalText);
      openDialog();
    },
    [openDialog]
  );

  return (
    <>
      <RegistrationFormView
        onGoToAuthentication={onGoToAuthentication}
        loading={legalTextLoading}
        mandatoryLegalTexts={mandatoryLegalTexts}
        optionalLegalTexts={optionalLegalTexts}
        defaultValues={payload}
        onViewLegalText={handleViewLegalText}
        onSubmit={onSubmit}
      />
      <LegalTextDialog
        open={isDialogOpen}
        onClose={closeDialog}
        legalText={selectedLegalText}
      />
    </>
  );
};
