import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFaqSection } from '../../get-faq-item';
import { FaqSectionView } from './faq-section.view';

export const FaqSectionController = (): JSX.Element => {
  const { section } = useParams();
  const { t } = useTranslation();

  const sectionInfos = useMemo(
    () => section && getFaqSection(section),
    [section]
  );

  if (!sectionInfos) {
    return <h1>{t('faq.not-found')}</h1>;
  }

  return <FaqSectionView {...sectionInfos} />;
};
