import { useCallback } from 'react';

import { Spinner } from '@app/shared/spinner/spinner';
import {
  showDefaultErrorMessage,
  showErrorMessage,
} from '@app/core/error/show-error-message';
import i18n from '@app/core/locale/i18n';
import { ErrorCodeEnum } from '@app/config/error-config';

import GooglePayButton from './component/google-pay-button/google-pay-button';
import { usePaymentWidgetConfiguration } from '../../use-payment-widget-configuration';
import css from './google-pay.module.scss';

interface GooglePayViewProps {
  onToken: (token: string) => Promise<void>;
}

export const GooglePayView = ({ onToken }: GooglePayViewProps): JSX.Element => {
  const { configuration } = usePaymentWidgetConfiguration();

  const handleLoadPaymentData = useCallback(
    async (paymentRequest: google.payments.api.PaymentData) => {
      await onToken(paymentRequest.paymentMethodData.tokenizationData.token);
    },
    [onToken]
  );

  const handleCancel = useCallback(() => {
    showErrorMessage('google-user-cancel', ErrorCodeEnum.GOOGLE_CANCEL);
  }, []);

  if (!configuration) {
    return <div />;
  }

  return configuration ? (
    <GooglePayButton
      paymentRequest={configuration.google.paymentRequest}
      environment={configuration.google.environment}
      cdn={configuration.google.cdn}
      buttonType="buy"
      buttonColor="black"
      buttonSizeMode="fill"
      buttonLocale={i18n.resolvedLanguage}
      onLoadPaymentData={handleLoadPaymentData}
      onError={showDefaultErrorMessage}
      onCancel={handleCancel}
      className={css.buttonContainer}
    />
  ) : (
    <Spinner />
  );
};
