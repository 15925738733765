import { useCallback, useEffect, useMemo, useState } from 'react';

import { userService } from '@app/core/service/user.service';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { AbortablePromise } from '@app/core/helper/abort-promise';
import { catchApiError } from '@app/core/error/catch-api-error';
import { ApiError } from '@app/core/error/api-error';
import { setInLocalStorage } from '@app/core/storage/local-storage';
import { EMAIL_LSK, PSEUDO_LSK } from '@app/config/localstorage-keys.const';
import { UserModel } from '@app/core/model/user.model';
import { DefaultUserPreference } from '@app/core/model/user-preference.model';
import { WebauthnAuthenticatorModel } from '@app/core/model/authenticator.model';

import { UserContext } from './user-context';
import { AsyncProviderProps } from '../providers.props';
import { useAuthentication } from '../authentication-context/use-authentication';

export function UserContextProvider(props: AsyncProviderProps): JSX.Element {
  const { children, onReady } = props;

  const { isAuthenticated } = useAuthentication();

  const [user, setUser] = useState<UserModel>();

  const loadData = useCallback((): AbortablePromise<void> => {
    const [loadPromise, abortLoading] = userService.get();

    const returnedPromise = loadPromise
      .then((response) => {
        setUser({
          ...response,
          preferences: {
            ...DefaultUserPreference,
            ...response.preferences,
          },
        });

        setInLocalStorage(EMAIL_LSK, response.emailAddress);
        setInLocalStorage(PSEUDO_LSK, response.pseudo);
      })
      .catch((err) => {
        catchApiError(err, (error: ApiError) =>
          showErrorMessage('get-current-user', error.code)
        );
      });

    return [returnedPromise, abortLoading];
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      onReady();
      return () => {};
    }

    const [promise, abort] = loadData();

    promise.finally(onReady);

    return () => {
      abort.abort();
    };
  }, [isAuthenticated, loadData, onReady]);

  const refresh = useCallback(() => {
    const [promise] = loadData();
    return promise;
  }, [loadData]);

  const handleUserUpdate = useCallback((newUserData: UserModel) => {
    setUser((previousUserData) => ({
      ...previousUserData,
      ...newUserData,
      preferences: {
        ...DefaultUserPreference,
        ...previousUserData?.preferences,
        ...newUserData.preferences,
      },
    }));
  }, []);

  const handleChangeWebauthnAuthenticator = useCallback(
    (updatedAuthenticator: WebauthnAuthenticatorModel): void => {
      setUser((previousUser) => {
        if (!previousUser) {
          return previousUser;
        }

        const userAuthenticators = previousUser?.authenticators?.webauthn?.map(
          (authenticator) =>
            authenticator.id === updatedAuthenticator.id
              ? updatedAuthenticator
              : authenticator
        );

        return {
          ...previousUser,
          authenticators: {
            email: previousUser?.authenticators.email,
            webauthn: userAuthenticators,
          },
        };
      });
    },
    []
  );

  const handleCreateWebauthnAuthenticator = useCallback(
    (newWebauthnAuthenticator: WebauthnAuthenticatorModel) => {
      setUser((previousUser) => {
        if (!previousUser) {
          return previousUser;
        }

        const userAuthenticators = previousUser?.authenticators?.webauthn ?? [];

        return {
          ...previousUser,
          authenticators: {
            email: previousUser?.authenticators.email,
            webauthn: [...userAuthenticators, newWebauthnAuthenticator],
          },
        };
      });
    },
    []
  );

  const value = useMemo(
    () => ({
      user,
      isWebauthnEnabled: (user?.authenticators?.webauthn.length ?? 0) > 0,
      refresh,
      onUserUpdate: handleUserUpdate,
      onChangeWebauthnAuthenticator: handleChangeWebauthnAuthenticator,
      onCreateWebauthnAuthenticator: handleCreateWebauthnAuthenticator,
    }),
    [
      user,
      refresh,
      handleUserUpdate,
      handleChangeWebauthnAuthenticator,
      handleCreateWebauthnAuthenticator,
    ]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
