import { HELP_PATH, HOME_PATH } from '@app/config/app-paths.const';
import { useUser } from '@app/core/context-providers/user-context/use-user';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { useTranslation } from 'react-i18next';
import { AccountItemMenu } from '../account/component/account-item-menu/account-item-menu';
import css from './faq.module.scss';
import { SectionInfos } from './get-faq-item';

interface FaqViewProps {
  faqSections: SectionInfos[];
  onSelect: (section: string) => void;
}

export const FaqView = ({
  faqSections,
  onSelect,
}: FaqViewProps): JSX.Element => {
  const { user } = useUser();
  const { t } = useTranslation();

  return (
    <PageLayout>
      <PageLayout.Title
        title={t('faq.title')}
        icon="help-circle"
        backTo={user ? HELP_PATH : HOME_PATH}
      />
      <PageLayout.Body className={css.faq}>
        {faqSections.map((section) => (
          <AccountItemMenu
            key={section.title}
            label={section.title}
            icon={section.icon}
            onClick={() => onSelect(section.key)}
          />
        ))}
      </PageLayout.Body>
      {user && <PageLayout.AppbarFooter />}
    </PageLayout>
  );
};
