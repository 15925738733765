import { usePreferredPaymentMethod } from '@app/feature/account/view/user-payment-method/preferred-payment-method/use-preferred-payment-method';
import { PreferredPaymentMethodLink } from '@app/feature/account/view/user-payment-method/preferred-payment-method/preferred-payment-method.link';

interface PreferredPaymentMethodControllerProps {
  iPaymentSourceId: string;
  isPreferred: boolean;
  onChange: (isPreferred: boolean) => void;
}

export const PreferredPaymentMethodController = (
  props: PreferredPaymentMethodControllerProps
): JSX.Element => {
  const { iPaymentSourceId, isPreferred, onChange } = props;

  const { pending, onPrefer, onDeletePreferred } = usePreferredPaymentMethod({
    iPaymentSourceId,
    isPreferred,
    onChange,
  });

  return (
    <PreferredPaymentMethodLink
      isPreferred={isPreferred}
      pending={pending}
      onPrefer={onPrefer}
      onDeletePreferred={onDeletePreferred}
    />
  );
};
