import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { RedirectionState } from '../model/redirection-state';

export const useGetRedirectionState = (): RedirectionState => {
  const location = useLocation();

  const redirectionState = useMemo(() => {
    const currentLocationState = location.state as RedirectionState;

    if (currentLocationState) {
      const { from, ...restOfTheState } = currentLocationState;

      return {
        state: restOfTheState,
        from: from || location.pathname,
      } as RedirectionState;
    }

    return {
      from: location.pathname,
    };
  }, [location.state, location.pathname]);

  return redirectionState;
};
