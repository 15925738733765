import { HTMLProps } from 'react';
import classnames from 'classnames';
import css from './circle-container.module.scss';

export const CircleContainer = ({
  className,
  ...divProps
}: HTMLProps<HTMLDivElement>): JSX.Element => (
  <div className={classnames('avatar', css.avatar, className)} {...divProps} />
);
