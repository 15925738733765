export enum LogLevelEnum {
  DEBUG,
  INFO,
  WARN,
  ERROR,
}

export type LoggerHandler = (
  loglevel: LogLevelEnum,
  ...parameters: any[]
) => void;
