import { useEffect } from 'react';

export const useTimeout = (callback: () => void, duration: number): void => {
  useEffect(() => {
    const timeoutId = setTimeout(callback, duration);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [callback, duration]);
};
