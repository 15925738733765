import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { SCAN_PATH } from '@app/config/app-paths.const';
import { Logger } from '@app/core/logger/logger';
import { CreateQuoteRouterStateModel } from '../../../core/model/create-quote-router-state.model';

export interface WithCreateQuotePayloadProps {
  payload: CreateQuoteRouterStateModel;
}

type ComponentProps<T> = Omit<T, keyof WithCreateQuotePayloadProps>;

export function withCreateQuotePayload<
  T extends WithCreateQuotePayloadProps = WithCreateQuotePayloadProps,
>(
  WrappedComponent: React.ComponentType<T>
): (props: ComponentProps<T>) => JSX.Element {
  const ComponentWithCreateQuotePayload = (
    props: ComponentProps<T>
  ): JSX.Element => {
    const location = useLocation();
    const createQuotePayload: CreateQuoteRouterStateModel | null =
      useMemo(() => {
        const locationState = location.state as CreateQuoteRouterStateModel;
        if (
          !locationState?.delmoChargePointOperatorId ||
          !locationState?.delmoChargePointId ||
          !locationState?.scannedAt
        ) {
          Logger.debug(
            'localationState.delmoChargePointOperatorId or locationState.delmoChargePointId or locationState.scannedAt is null',
            'locationState',
            locationState
          );
          return null;
        }

        const scannedAt = new Date(locationState.scannedAt);

        if (scannedAt.toString() === 'Invalid Date') {
          Logger.debug(
            'locationState.scannedAt is an invalid date',
            'locationState.scannedAt',
            locationState.scannedAt
          );
          return null;
        }

        return {
          delmoChargePointOperatorId: locationState.delmoChargePointOperatorId,
          delmoChargePointId: locationState.delmoChargePointId,
          scannedAt,
        };
      }, [location.state]);

    if (!createQuotePayload) {
      return <Navigate to={SCAN_PATH} replace />;
    }

    return <WrappedComponent {...(props as T)} payload={createQuotePayload} />;
  };

  return ComponentWithCreateQuotePayload;
}
