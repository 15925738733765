import classNames from 'classnames';
import { Icon, IconName } from '../icon/icon';
import { Text } from '../typography/text/text';

import css from './infos-with-legend.module.scss';

type Props = {
  className?: string;
  icon: IconName;
  value: string;
  label: string;
  disabled?: boolean;
};

export function InfosWithLegend(props: Props): JSX.Element {
  const { className, icon, value, label, disabled = false } = props;

  return (
    <div
      className={classNames(
        css.container,
        disabled && css.disabled,
        className
      )}>
      <div className={css.line}>
        <Icon name={icon} />
        <Text className={css.value} bold>
          {value}
        </Text>
      </div>

      <Text
        color="secondary"
        fontSize="medium"
        className={classNames(disabled && css.disabled)}>
        {label}
      </Text>
    </div>
  );
}
