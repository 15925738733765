import { useCallback, useState } from 'react';

import { TranslationPaths } from '@app/core/helper/use-typed-translation';
import {
  EmailOtpProcessStartRequestModel,
  EmailOtpProcessVerificationRequestModel,
} from '@app/core/model/email-otp-process.model';
import { VerifyEmailOTPProcessView } from '@app/feature/email-otp-process/verify-email-otp-process/verify-email-otp-process.view';

interface VerifyEmailOtpProcessControllerProps {
  remainingLockTimeInSec?: number;
  translationKey?: TranslationPaths;
  payload: EmailOtpProcessStartRequestModel;
  onValidateOTP: (
    payload: EmailOtpProcessVerificationRequestModel
  ) => Promise<void>;
  onRequestAgainOTP: (
    payload: EmailOtpProcessStartRequestModel
  ) => Promise<void>;
}

export const VerifyEmailOtpProcessController = (
  props: VerifyEmailOtpProcessControllerProps
): JSX.Element => {
  const {
    remainingLockTimeInSec,
    translationKey,
    payload,
    onValidateOTP,
    onRequestAgainOTP,
  } = props;
  const [pending, setPending] = useState(false);
  const [isCodeInvalid, setIsCodeInvalid] = useState(false);

  const handleRequestAgainOTP = useCallback(async () => {
    setPending(true);
    setIsCodeInvalid(false);

    try {
      await onRequestAgainOTP(payload);
      setPending(false);
    } catch (err) {
      setPending(false);
    }
  }, [onRequestAgainOTP, payload]);

  const handleValidateOTP = useCallback(
    async (body: EmailOtpProcessVerificationRequestModel) => {
      setPending(true);
      setIsCodeInvalid(false);

      try {
        await onValidateOTP(body);
        setPending(false);
      } catch (err) {
        setPending(false);
        setIsCodeInvalid(true);
      }
    },
    [onValidateOTP]
  );

  return (
    <VerifyEmailOTPProcessView
      remainingLockTimeInSec={remainingLockTimeInSec}
      isCodeInvalid={isCodeInvalid}
      pending={pending}
      onSubmit={handleValidateOTP}
      onRequestAgainOTP={handleRequestAgainOTP}
      translationKey={translationKey}
    />
  );
};
