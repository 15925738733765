import { useTranslation } from 'react-i18next';

import { Icon } from '@app/shared/icon/icon';
import FollowChargeOnSmartphoneSVG from '@app/asset/follow-charge-on-smartphone-step.svg';

import { StepContainer } from '../../component/step-container/step-container';
import { StepInstructionIcon } from '../../component/step-instruction-icon/step-instruction-icon';
import { StepTitle } from '../../component/step-title/step-title';

import css from './view-the-charge-on-your-smartphone-step.module.scss';
import type { StepComponent, StepProps } from '../step.props';

export const ViewTheChargeOnYourSmartphoneStep: StepComponent = (
  props: StepProps
): JSX.Element => {
  const { step } = props;
  const { t } = useTranslation();

  return (
    <StepContainer>
      <StepTitle step={step}>
        {t('charging.follow-charge-on-smartphone-step.title')}
      </StepTitle>

      <StepInstructionIcon>
        <Icon name="phone-01" />
      </StepInstructionIcon>

      <div className={css.svgContainer}>
        <FollowChargeOnSmartphoneSVG />
      </div>
    </StepContainer>
  );
};
