import { useCallback, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { PageFallback } from '@app/shared/lazy-loading/page-fallback/page-fallback';
import { Fade } from '@app/shared/fade/fade';

import { ChargeContextProvider } from './charge-context/charge-context.provider';
import { OrderContextProvider } from './order-context/order-context.provider';
import { UserContextProvider } from './user-context/user-context.provider';

export const ContextProviders = (): JSX.Element => {
  const [isChargeContextReady, setIsChargeContextReady] = useState(false);
  const [isOrderContextReady, setIsOrderContextReady] = useState(false);

  const [isUserContextReady, setIsUserContextReady] = useState(false);

  const handleChargeContextReady = useCallback(() => {
    setIsChargeContextReady(true);
  }, []);

  const handleUserContextReady = useCallback(() => {
    setIsUserContextReady(true);
  }, []);

  const handleOrderContextReady = useCallback(() => {
    setIsOrderContextReady(true);
  }, []);

  const canShowOutlet = useMemo(
    () => isOrderContextReady && isChargeContextReady && isUserContextReady,
    [isOrderContextReady, isChargeContextReady, isUserContextReady]
  );

  return (
    <UserContextProvider onReady={handleUserContextReady}>
      <ChargeContextProvider onReady={handleChargeContextReady}>
        <Fade show={isChargeContextReady}>
          <OrderContextProvider onReady={handleOrderContextReady}>
            {canShowOutlet ? <Outlet /> : <PageFallback />}
          </OrderContextProvider>
        </Fade>
      </ChargeContextProvider>
    </UserContextProvider>
  );
};
