import classNames from 'classnames';

import './spinner.scss';
import { Icon, IconProps } from '../icon/icon';

export type SpinnerProps = Omit<IconProps, 'name' | 'badge'>;

export const Spinner = (props: SpinnerProps): JSX.Element => {
  const { className, ...iconProps } = props;

  return (
    <Icon
      {...iconProps}
      name="loading-02"
      className={classNames('spinner', className)}
    />
  );
};
