/* eslint-disable react/no-danger */
import { LegalText } from '@app/core/model/legal-text';

interface LegalTextContentProps {
  legalText: LegalText;
}

export const LegalTextContent = ({
  legalText,
}: LegalTextContentProps): JSX.Element => {
  return <div dangerouslySetInnerHTML={{ __html: legalText.content }} />;
};
