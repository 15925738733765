import { useTranslation } from 'react-i18next';

import { Icon } from '@app/shared/icon/icon';
import CableToVehicleStepSVG from '@app/asset/cable-to-vehicle-step.svg';

import { StepContainer } from '../../component/step-container/step-container';
import { StepInstructionIcon } from '../../component/step-instruction-icon/step-instruction-icon';
import { StepTitle } from '../../component/step-title/step-title';
import { StepFootnote } from '../../component/step-footnote/step-footnote';
import { AnimatedChevron } from '../../../animated-chevron/animated-chevron';

import css from './cable-to-vehicle-step.module.scss';
import type { StepComponent, StepProps } from '../step.props';

export const CableToVehicleStep: StepComponent = (
  props: StepProps
): JSX.Element => {
  const { step } = props;
  const { t } = useTranslation();

  return (
    <StepContainer>
      <StepTitle step={step}>
        {t('charging.cable-to-vehicle-step.title')}
      </StepTitle>

      <StepInstructionIcon>
        <Icon name="wire" />
        <Icon name="chevron-right" />
        <Icon name="car-profile" />
      </StepInstructionIcon>

      <div className={css.svgContainer}>
        <CableToVehicleStepSVG />
        <AnimatedChevron className={css.chevron} />
      </div>

      <StepFootnote>
        {t('charging.cable-to-vehicle-step.footnote')}
      </StepFootnote>
    </StepContainer>
  );
};
