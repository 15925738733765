import { useCallback, useState } from 'react';
import { browserSupportsWebAuthn } from '@simplewebauthn/browser';

import {
  EMAIL_LSK,
  LAST_AUTHENTICATION_METHOD_LSK,
  PSEUDO_LSK,
} from '@app/config/localstorage-keys.const';
import {
  getFromLocalStorage,
  setInLocalStorage,
} from '@app/core/storage/local-storage';
import { useAuthentication } from '@app/core/context-providers/authentication-context/use-authentication';
import { useRedirectToInitialDestination } from '@app/router/redirect/use-redirect-to-initial-destination';
import { AuthenticationMethodEnum } from '@app/core/model/enum/authentication-method.enum';

import { EmailAuthenticationController } from './view/email-authentication/email-authentication.controller';
import { WebauthnAuthenticationController } from './view/webauthn-authentication/webauthn-authentication.controller';

function getAuthenticationMethod(): AuthenticationMethodEnum {
  const lastAuthenticationMethod = getFromLocalStorage(
    LAST_AUTHENTICATION_METHOD_LSK
  ) as AuthenticationMethodEnum | null;

  if (!lastAuthenticationMethod) {
    return browserSupportsWebAuthn()
      ? AuthenticationMethodEnum.WEBAUTHN
      : AuthenticationMethodEnum.EMAIL;
  }

  return Number(lastAuthenticationMethod);
}

interface AuthenticationControllerProps {
  emailAddress: string;
  pseudo: string;
}

export const AuthenticationController = ({
  emailAddress,
  pseudo,
}: AuthenticationControllerProps): JSX.Element => {
  const [method, setMethod] = useState<AuthenticationMethodEnum>(
    getAuthenticationMethod()
  );
  const { authenticate } = useAuthentication();
  const redirectToInitialDestination = useRedirectToInitialDestination();

  const handleSwitchToEmail = useCallback(() => {
    setMethod(AuthenticationMethodEnum.EMAIL);
  }, []);

  const handleSwitchToWebauthn = useCallback(() => {
    setMethod(AuthenticationMethodEnum.WEBAUTHN);
  }, []);

  const handleAuthentication = (jwt: string) => {
    authenticate(jwt);
    redirectToInitialDestination();
  };

  const handleEmailAuthentication = (jwt: string, identifier: string): void => {
    setInLocalStorage(EMAIL_LSK, identifier);
    setInLocalStorage(
      LAST_AUTHENTICATION_METHOD_LSK,
      AuthenticationMethodEnum.EMAIL.toString()
    );

    handleAuthentication(jwt);
  };

  const handleWebauthnAuthentication = (
    jwt: string,
    identifier: string
  ): void => {
    setInLocalStorage(PSEUDO_LSK, identifier);
    setInLocalStorage(
      LAST_AUTHENTICATION_METHOD_LSK,
      AuthenticationMethodEnum.WEBAUTHN.toString()
    );

    handleAuthentication(jwt);
  };

  return method === AuthenticationMethodEnum.WEBAUTHN ? (
    <WebauthnAuthenticationController
      pseudo={pseudo}
      onSwitchToEmailAuthentication={handleSwitchToEmail}
      onAuthentication={handleWebauthnAuthentication}
    />
  ) : (
    <EmailAuthenticationController
      emailAddress={emailAddress}
      onAuthentication={handleEmailAuthentication}
      onSwitchToWebauthnAuthentication={handleSwitchToWebauthn}
    />
  );
};
