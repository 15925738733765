import { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import FrenchFlag from '@app/asset/french-flag.svg';
import EnglishFlag from '@app/asset/english-flag.svg';
import BrowserFlag from '@app/asset/browser-flag.svg';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { Button } from '@app/shared/button/button';
import { Radio } from '@app/shared/radio/radio';
import { SECURITY_PATH } from '@app/config/app-paths.const';

import css from './manage-language.module.scss';

export enum AvailableLanguageEnum {
  BROWSER = 'browser',
  ENGLISH = 'en',
  FRENCH = 'fr',
}

interface ManageLanguageViewProps {
  locale: AvailableLanguageEnum;
  pending: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
}

export const ManageLanguageView = (
  props: ManageLanguageViewProps
): JSX.Element => {
  const { t } = useTranslation();
  const { locale, pending, onSubmit, onChange } = props;

  const renderBrowserFlag = useCallback(
    () => <BrowserFlag className={css.flag} />,
    []
  );
  const renderFrenchFlag = useCallback(
    () => <FrenchFlag className={css.flag} />,
    []
  );
  const renderEnglishFlag = useCallback(
    () => <EnglishFlag className={css.flag} />,
    []
  );

  // TODO: use a loop to iterate over available languages
  return (
    <PageLayout withHomeButton>
      <PageLayout.Title
        title={t('manage-language.title')}
        icon="flag-04"
        backTo={SECURITY_PATH}
      />

      <PageLayout.Body>
        <Radio
          label={t('manage-language.browser')}
          caption={t('manage-language.browser-caption')}
          value={AvailableLanguageEnum.BROWSER}
          checked={locale === AvailableLanguageEnum.BROWSER}
          name="locale"
          defaultChecked={locale === AvailableLanguageEnum.BROWSER}
          onChange={onChange}
          adornment={renderBrowserFlag}
        />
        <Radio
          label={t('manage-language.french')}
          value={AvailableLanguageEnum.FRENCH}
          checked={locale === AvailableLanguageEnum.FRENCH}
          name="locale"
          defaultChecked={locale === AvailableLanguageEnum.FRENCH}
          onChange={onChange}
          adornment={renderFrenchFlag}
        />
        <Radio
          label={t('manage-language.english')}
          value={AvailableLanguageEnum.ENGLISH}
          checked={locale === AvailableLanguageEnum.ENGLISH}
          name="locale"
          defaultChecked={locale === AvailableLanguageEnum.ENGLISH}
          onChange={onChange}
          adornment={renderEnglishFlag}
        />
      </PageLayout.Body>

      <PageLayout.Footer>
        <Button type="submit" isLoading={pending} onClick={onSubmit}>
          {t('manage-language.submit')}
        </Button>
      </PageLayout.Footer>
    </PageLayout>
  );
};
