import { ConnectorTypeEnum } from '@app/core/model/enum/connector-type.enum';
import { IconName } from '@app/shared/icon/icon';

const connectorTypeToIconName: Partial<Record<ConnectorTypeEnum, IconName>> = {
  [ConnectorTypeEnum.IEC_62196_T2_COMBO]: 'power-type-2-combo-ccs',
  [ConnectorTypeEnum.IEC_62196_T2]: 'power-type-2',
  [ConnectorTypeEnum.CHADEMO]: 'power-chademo',
  [ConnectorTypeEnum.IEC_62196_T1_COMBO]: 'power-type-1-combo-ccs',
  [ConnectorTypeEnum.IEC_62196_T1]: 'power-type-1',
  [ConnectorTypeEnum.DOMESTIC_A]: 'power-type-e',
  [ConnectorTypeEnum.DOMESTIC_B]: 'power-type-e',
  [ConnectorTypeEnum.DOMESTIC_C]: 'power-type-e',
  [ConnectorTypeEnum.DOMESTIC_D]: 'power-type-e',
  [ConnectorTypeEnum.DOMESTIC_E]: 'power-type-e',
  [ConnectorTypeEnum.DOMESTIC_F]: 'power-type-e',
  [ConnectorTypeEnum.DOMESTIC_G]: 'power-type-e',
  [ConnectorTypeEnum.DOMESTIC_H]: 'power-type-e',
  [ConnectorTypeEnum.DOMESTIC_I]: 'power-type-e',
  [ConnectorTypeEnum.DOMESTIC_J]: 'power-type-e',
  [ConnectorTypeEnum.DOMESTIC_K]: 'power-type-e',
  [ConnectorTypeEnum.DOMESTIC_L]: 'power-type-e',
  [ConnectorTypeEnum.DOMESTIC_M]: 'power-type-e',
  [ConnectorTypeEnum.DOMESTIC_N]: 'power-type-e',
  [ConnectorTypeEnum.DOMESTIC_O]: 'power-type-e',
};

export function useConnectorTypeIconName(
  connectorType?: ConnectorTypeEnum
): IconName {
  if (connectorType && connectorType in connectorTypeToIconName) {
    return connectorTypeToIconName[connectorType] as IconName;
  }

  return 'power-type-e';
}
