import { useCopyToClipBoard } from '@app/core/helper/use-copy-to-clipboard';
import { Avatar } from '@app/shared/avatar/avatar';
import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';
import css from './account-header.module.scss';

type Props = {
  firstName: string;
  lastName: string;
  pseudo: string;
};

export function AccountHeader(props: Props): JSX.Element {
  const { firstName, lastName, pseudo } = props;
  const { t } = useTranslation();

  const { onCopyToClipboard } = useCopyToClipBoard({
    onSuccess: () => {},
    onError: () => {},
  });

  const handleCopyToClipboard = (toCopy: string) => {
    onCopyToClipboard(toCopy);
    toast.success(t('account.pseudo-copied'));
  };

  return (
    <div className={css.container}>
      <div className={css.stack}>
        <Avatar
          firstName={firstName}
          lastName={lastName}
          className={css.avatar}
        />
        <Text className={css.name}>
          {firstName} {lastName}
        </Text>
      </div>

      <div className={classNames(css.stack, css.bottom)}>
        <div className={css.stack}>
          <Icon name="user-01" />

          <Text>{t('account.pseudo')}</Text>
        </div>

        <button
          className={classNames(css.pseudo, css.stack)}
          onClick={() => handleCopyToClipboard(pseudo)}>
          <Text>{pseudo}</Text>

          <Icon name="copy-03" />
        </button>
      </div>
    </div>
  );
}
