import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentMethodModel } from '@app/core/model/payment-method.model';
import { ListItemMenu } from '@app/shared/list-item-menu/list-item-menu';

import { Text } from '../typography/text/text';
import { CreditCardItem } from '../credit-card-item/credit-card-item';
import { Divider } from '../divider/divider';

interface CreditCardListProps {
  paymentMethods: PaymentMethodModel[];
  onSelect: (paymentMethod: PaymentMethodModel) => void;
  isLoading?: (paymentMethod: PaymentMethodModel) => boolean;
}

export const CreditCardList = (props: CreditCardListProps): JSX.Element => {
  const { paymentMethods, onSelect, isLoading = () => false } = props;
  const { t } = useTranslation();

  const preferredPaymentMethod = useMemo(
    () => paymentMethods.find((item) => item.preferred),
    [paymentMethods]
  );

  const restOfPaymentMethod = useMemo(
    () => paymentMethods.filter((item) => !item.preferred),
    [paymentMethods]
  );

  return (
    <ListItemMenu>
      {preferredPaymentMethod && (
        <>
          <Text fontSize="small">{t('shared.credit-card-list.preferred')}</Text>
          <CreditCardItem
            {...preferredPaymentMethod}
            onClick={() => onSelect(preferredPaymentMethod)}
            loading={isLoading(preferredPaymentMethod)}
          />
          {restOfPaymentMethod.length > 0 && <Divider />}
        </>
      )}

      {restOfPaymentMethod.map((paymentMethod: PaymentMethodModel) => (
        <CreditCardItem
          {...paymentMethod}
          key={paymentMethod.iPaymentSourceId}
          onClick={() => onSelect(paymentMethod)}
          loading={isLoading(paymentMethod)}
        />
      ))}
    </ListItemMenu>
  );
};
