import { httpClientService } from '@app/core/client/http/http-client.service';
import {
  START_CHARGE_API,
  STOP_CHARGE_API,
} from '@app/config/api-routes.const';
import { AbortablePromise } from '@app/core/helper/abort-promise';

interface BooleanResponse {
  success: boolean;
}

function unwrapSuccessOrThrow(
  abortablePromise: AbortablePromise<BooleanResponse>
): AbortablePromise<void> {
  const [promise, abort] = abortablePromise;

  const returnedPromise = promise.then((response) => {
    if (!response?.success) {
      throw new Error();
    }
  });

  return [returnedPromise, abort];
}

class ChargeService {
  start(): AbortablePromise<void> {
    return unwrapSuccessOrThrow(
      httpClientService.run<BooleanResponse>(START_CHARGE_API)
    );
  }

  stop(): AbortablePromise<void> {
    return unwrapSuccessOrThrow(
      httpClientService.run<BooleanResponse>(STOP_CHARGE_API)
    );
  }
}

export const chargeService = new ChargeService();
