import { useCallback, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import {
  CHARGE_POINT_PATH,
  TINY_CHARGE_POINT_PATH,
} from '@app/config/app-paths.const';
import { configurationService } from '@app/core/configuration/configuration.service';
import { ErrorCodeEnum } from '@app/config/error-config';
import { TINY_CHARGE_POINT_ID_MAX_LENGTH } from '@app/config/tiny-charge-point-id.const';

import { ScanView } from './scan.view';

export const ScanController = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isCameraError, setIsCameraError] = useState(false);
  const [retryKey, setRetryKey] = useState(0);

  const handleCameraError = useCallback(() => {
    setIsCameraError(true);
  }, []);

  const handleRetry = useCallback(() => {
    setIsCameraError(false);
    setRetryKey((prevKey) => prevKey + 1);
  }, []);

  const handleScan = useCallback(
    (url: string) => {
      // do not navigate to url directly, but decode chargePointId and navigate through the app.
      // that prevents scan QR code that are not from delmonicos
      const chargePointId = url.split('/').filter(Boolean).pop();

      if (!chargePointId) {
        toast.error(
          `${t('scan-qr-code-has-no-cpid', {
            phoneNumber: configurationService.getSupportPhoneNumber(),
          })} [Code: ${ErrorCodeEnum.QR_CODE_MALFORMED}]`,
          {
            toastId: ErrorCodeEnum.QR_CODE_MALFORMED,
          }
        );

        return;
      }

      if (chargePointId.length > TINY_CHARGE_POINT_ID_MAX_LENGTH) {
        navigate(
          generatePath(CHARGE_POINT_PATH, {
            delmoChargePointId: chargePointId,
          })
        );
        return;
      }

      navigate(
        generatePath(TINY_CHARGE_POINT_PATH, {
          tinyChargePointId: chargePointId,
        })
      );
    },
    [navigate, t]
  );

  return (
    <ScanView
      key={retryKey}
      onScan={handleScan}
      isCameraError={isCameraError}
      onCameraError={handleCameraError}
      onRetry={handleRetry}
    />
  );
};
