import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Card, CardProps } from '@app/shared/card/card';
import { Text } from '@app/shared/typography/text/text';

import { ChargeGraph } from '../charge-graph/charge-graph';
import css from './last-charges-card.module.scss';

interface LastChargesGraphCardProps extends CardProps {
  consumptions?: number[];
}
export const LastChargesCard = ({
  consumptions,
  className,
  ...props
}: LastChargesGraphCardProps): JSX.Element => {
  const { t } = useTranslation();

  const maxConsumptions = useMemo(
    () => (consumptions ? Math.max(...consumptions) : 1),
    [consumptions]
  );

  const getConsumptionRatio = useCallback(
    (consumption: number): number => (consumption / maxConsumptions) * 100,
    [maxConsumptions]
  );

  return (
    <Card className={classNames(css.card, className)} {...props}>
      {consumptions && consumptions.length > 0 ? (
        <>
          <Text fontSize="large" color="secondary" className={css.title}>
            {t('dashboard.last-charges-card.label')}
          </Text>
          <div className={css.graph}>
            {consumptions.map((consumption: number) => (
              <ChargeGraph
                key={consumption}
                height={getConsumptionRatio(consumption)}
                consumption={consumption}
              />
            ))}
          </div>
        </>
      ) : (
        <Text className={css.title}>
          {t('dashboard.last-charges-card.fallback')}
        </Text>
      )}
    </Card>
  );
};
