import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useOrder } from '@app/core/context-providers/order-context/use-order';
import {
  CHARGE_POINT_PATH,
  CHARGING_PATH,
  QUOTE_PATH,
  SCAN_PATH,
} from '@app/config/app-paths.const';
import { LAST_SCAN_VALIDITY_IN_MINUTES } from '@app/config/last-scan-validity-in-minutes';
import { Spinner } from '@app/shared/spinner/spinner';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { BackLink } from '@app/shared/back-link/back-link';
import { H1 } from '@app/shared/typography/heading/heading';
import { setInLocalStorage } from '@app/core/storage/local-storage';
import {
  DELMO_CHARGE_POINT_OPERATOR_ID_LSK,
  DELMO_CHARGE_POINT_ID_LSK,
} from '@app/config/localstorage-keys.const';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { ApiError } from '@app/core/error/api-error';
import { ErrorCodeEnum } from '@app/config/error-config';
import { catchApiError } from '@app/core/error/catch-api-error';
import { orderService } from '@app/core/service/order.service';

import { CreateQuoteRouterStateModel } from '../../../core/model/create-quote-router-state.model';
import { withCreateQuotePayload } from './with-create-quote-payload';

interface CreateQuoteControllerProps {
  payload: CreateQuoteRouterStateModel;
}

const CreateQuoteController = (
  props: CreateQuoteControllerProps
): JSX.Element => {
  const { onOrder, refresh: reloadExistingOrder } = useOrder();
  const { payload: createQuotePayload } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const isScanStillAvailable = useMemo((): boolean => {
    return (
      createQuotePayload.scannedAt.getTime() +
        LAST_SCAN_VALIDITY_IN_MINUTES * 60 * 1000 >
      new Date().getTime()
    );
  }, [createQuotePayload]);

  const handleBack = useCallback(() => {
    return navigate(
      generatePath(CHARGE_POINT_PATH, {
        delmoChargePointId: createQuotePayload.delmoChargePointId,
      }),
      { replace: true }
    );
  }, [navigate, createQuotePayload.delmoChargePointId]);

  useEffect(() => {
    if (!isScanStillAvailable) {
      showErrorMessage('scan-expired', ErrorCodeEnum.SCAN_EXPIRED);
      navigate(SCAN_PATH, { replace: true });
      return () => {};
    }

    const { delmoChargePointId } = createQuotePayload;
    const [createQuotePromise, abortCreateQuote] =
      orderService.create(delmoChargePointId);

    createQuotePromise
      .then((response) => {
        onOrder(response);
        setInLocalStorage(
          DELMO_CHARGE_POINT_OPERATOR_ID_LSK,
          createQuotePayload.delmoChargePointOperatorId
        );
        setInLocalStorage(
          DELMO_CHARGE_POINT_ID_LSK,
          createQuotePayload.delmoChargePointId
        );
        navigate(QUOTE_PATH, { replace: true });
      })
      .catch((error) => {
        catchApiError(error, (apiError: ApiError) => {
          switch (apiError.code) {
            case ErrorCodeEnum.CANNOT_CREATE_ORDER_ALREADY_EXISTS:
              reloadExistingOrder().then(() => {
                setInLocalStorage(
                  DELMO_CHARGE_POINT_OPERATOR_ID_LSK,
                  createQuotePayload.delmoChargePointOperatorId
                );
                setInLocalStorage(
                  DELMO_CHARGE_POINT_ID_LSK,
                  createQuotePayload.delmoChargePointId
                );
                navigate(QUOTE_PATH, { replace: true });
              });
              break;

            case ErrorCodeEnum.CANNOT_CREATE_ORDER_CHARGE_SESSION_PENDING:
              toast.warn(t('api-error.quote-creation-charge-started'), {
                toastId: apiError.code,
              });
              navigate(CHARGING_PATH, { replace: true });
              break;

            default:
              showErrorMessage('quote-creation', apiError.code);
              handleBack();
          }
        });
      });

    return () => {
      abortCreateQuote.abort();
    };
  }, [
    createQuotePayload,
    navigate,
    onOrder,
    handleBack,
    isScanStillAvailable,
    t,
    reloadExistingOrder,
  ]);

  return (
    <PageLayout withHomeButton>
      <BackLink onClick={handleBack} />

      <PageLayout.Header>
        <H1>{t('quote.title')}</H1>
      </PageLayout.Header>

      <Spinner />
    </PageLayout>
  );
};

export const CreateQuoteControllerWithPayload = withCreateQuotePayload(
  CreateQuoteController
);
