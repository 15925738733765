import { useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Drawer } from '@app/shared/drawer/drawer';
import { useDrawer } from '@app/shared/drawer/use-drawer';
import { CHARGE_POINT_PATH, CHARGING_PATH } from '@app/config/app-paths.const';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { WithOrderProps } from '@app/router/guard/with-order.guard';
import { WithChargePointIdProps } from '@app/router/guard/with-charge-point-id.guard';
import { useDialog } from '@app/shared/dialog/use-dialog';
import { ApiError } from '@app/core/error/api-error';
import { catchApiError } from '@app/core/error/catch-api-error';
import { OrderStatusEnum } from '@app/core/model/enum/order-status-enum';
import { orderService } from '@app/core/service/order.service';
import { useChargePointService } from '@app/core/service/use-charge-point.service';
import { TariffModel } from '@app/core/model/tariff.model';
import { isTariffElementComplex } from '@app/core/helper/ocpi-utils';

import { QuoteView } from './quote.view';
import { CheckoutCallbackDialogController } from './view/checkout-callback-dialog/checkout-callback-dialog.controller';
import { PaymentDrawerController } from './payment-drawer/payment-drawer.controller';
import { EditPaymentDialog } from './component/edit-payment-dialog/edit-payment-dialog';

interface QuoteControllerProps extends WithOrderProps, WithChargePointIdProps {}

export function QuoteController(props: QuoteControllerProps): JSX.Element {
  const { order, onOrder, delmoChargePointId } = props;

  const navigate = useNavigate();

  const { data: chargePoint } = useChargePointService(delmoChargePointId);

  const [pending, setPending] = useState(false);

  const {
    drawerState: paymentDrawerState,
    openDrawer: openPayment,
    closeDrawer: closePayment,
  } = useDrawer();

  const {
    openDialog: onOpenEditPaymentDialog,
    isDialogOpen: isEditPaymentDialogOpen,
    closeDialog: onCloseEditPaymentDialog,
  } = useDialog();

  const isPrecaptureAccepted = useMemo(
    () => order.status >= OrderStatusEnum.PRE_CAPTURE_WEBHOOK_ACCEPTED,
    [order.status]
  );

  const isValidatedWithoutPayment = useMemo(
    () => order.status >= OrderStatusEnum.QUOTE_VALIDATED_WITHOUT_PAYMENT,
    [order.status]
  );

  const hasAlreadyAPaymentMethod = useMemo(
    () => Boolean(order.paymentInfos),
    [order.paymentInfos]
  );

  const handleBack = useCallback(() => {
    return navigate(
      generatePath(CHARGE_POINT_PATH, {
        delmoChargePointId,
      })
    );
  }, [navigate, delmoChargePointId]);

  const handleValidateQuote = useCallback(async () => {
    if (order.status >= OrderStatusEnum.QUOTE_VALIDATED) {
      navigate(CHARGING_PATH);
      return;
    }

    setPending(true);

    try {
      const [validateQuotePromise] = orderService.validate();
      const data = await validateQuotePromise;
      onOrder(data);

      setPending(false);
      navigate(CHARGING_PATH);
    } catch (error) {
      setPending(false);
      catchApiError(error, (apiError: ApiError) => {
        showErrorMessage('quote-validation', apiError.code);
      });
    }
  }, [navigate, order.status, onOrder]);

  const handleOpenPayment = useCallback(() => {
    if (order?.paymentInfos) {
      onOpenEditPaymentDialog();
      return;
    }

    openPayment();
  }, [openPayment, onOpenEditPaymentDialog, order?.paymentInfos]);

  const numberOfTariffElements = order?.pricing?.tariff?.elements?.length ?? 0;

  const tariff: TariffModel =
    order?.pricing?.tariff && numberOfTariffElements > 0
      ? order?.pricing?.tariff
      : {
          // backwards compatibility with pricing
          elements: [{ priceComponents: order?.pricing }],
        };

  const isTariffComplex = isTariffElementComplex(tariff.elements);

  return (
    <>
      <QuoteView
        tariff={tariff}
        isTariffComplex={isTariffComplex}
        chargePoint={chargePoint}
        isPending={pending}
        order={order}
        isPrecaptureAccepted={isPrecaptureAccepted}
        onValidate={handleValidateQuote}
        onOpenPaymentSelection={handleOpenPayment}
        onBack={handleBack}
      />
      <Drawer
        state={paymentDrawerState}
        icon="credit-card-shield"
        onClose={closePayment}>
        <PaymentDrawerController
          isValidatedWithoutPayment={isValidatedWithoutPayment}
          onOrder={onOrder}
          currentPaymentMethodId={order.paymentInfos?.iPaymentSourceId}
          onClose={closePayment}
        />
      </Drawer>
      <EditPaymentDialog
        onContinue={openPayment}
        open={isEditPaymentDialogOpen}
        onClose={onCloseEditPaymentDialog}
      />
      <CheckoutCallbackDialogController
        hasAlreadyAPaymentMethod={hasAlreadyAPaymentMethod}
        onPrecaptureFailed={openPayment}
      />
    </>
  );
}
