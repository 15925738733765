import { Logger } from '@app/core/logger/logger';
import { ChargeSessionEnum } from '@app/core/model/enum/charge-session.enum';
import { ChargeStateEnum } from '@app/core/model/enum/charge-state.enum';

const ChargeSessionEnumMapperToChargeState: Record<
  ChargeSessionEnum,
  ChargeStateEnum
> = {
  [ChargeSessionEnum.RESET]: ChargeStateEnum.INIT,
  [ChargeSessionEnum.SESSION_INVALID]: ChargeStateEnum.SESSION_ERROR,
  [ChargeSessionEnum.START_CHARGE_CPO_ACCEPTED]:
    ChargeStateEnum.START_CHARGE_REQUESTED,
  [ChargeSessionEnum.START_CHARGE_CPO_REFUSED]:
    ChargeStateEnum.RETRY_START_CHARGE,
  [ChargeSessionEnum.START_CHARGE_CP_TIMEOUT]:
    ChargeStateEnum.RETRY_START_CHARGE,
  [ChargeSessionEnum.START_CHARGE_CP_ACCEPTED]:
    ChargeStateEnum.START_CHARGE_REQUESTED,
  [ChargeSessionEnum.START_CHARGE_CP_REFUSED]:
    ChargeStateEnum.RETRY_START_CHARGE,
  [ChargeSessionEnum.SESSION_START_TIMEOUT]: ChargeStateEnum.RETRY_START_CHARGE,
  [ChargeSessionEnum.SESSION_STARTED]: ChargeStateEnum.SESSION_STARTED,
  [ChargeSessionEnum.SESSION_PENDING]: ChargeStateEnum.CHARGING,
  [ChargeSessionEnum.STOP_CHARGE_CPO_ACCEPTED]:
    ChargeStateEnum.STOP_CHARGE_REQUESTED,
  [ChargeSessionEnum.STOP_CHARGE_CPO_REFUSED]:
    ChargeStateEnum.RETRY_STOP_CHARGE,
  [ChargeSessionEnum.STOP_CHARGE_CP_TIMEOUT]: ChargeStateEnum.RETRY_STOP_CHARGE,
  [ChargeSessionEnum.STOP_CHARGE_CP_ACCEPTED]:
    ChargeStateEnum.STOP_CHARGE_REQUESTED,
  [ChargeSessionEnum.STOP_CHARGE_CP_REFUSED]: ChargeStateEnum.RETRY_STOP_CHARGE,
  [ChargeSessionEnum.SESSION_STOP_TIMEOUT]: ChargeStateEnum.RETRY_STOP_CHARGE,
  [ChargeSessionEnum.SESSION_ENDED]: ChargeStateEnum.SESSION_ENDED,
  [ChargeSessionEnum.CDR_EDITED]: ChargeStateEnum.SESSION_ENDED,
};

export function mapChargeSessionEnumToChargeStateEnum(
  session: ChargeSessionEnum | null,
  isEnded: boolean = false
): ChargeStateEnum {
  if (session === null) {
    return ChargeStateEnum.INIT;
  }

  if (session === ChargeSessionEnum.SESSION_INVALID) {
    return isEnded
      ? ChargeStateEnum.SESSION_ERROR
      : ChargeStateEnum.RETRY_START_CHARGE;
  }

  const result = Object.entries(ChargeSessionEnumMapperToChargeState).find(
    ([key]) => key === session.toString()
  );

  if (result) {
    return result[1];
  }

  Logger.warn(
    `Try to convert ChargeSessionEnum to ChargeStateEnum. But value ${session} is not found. Return SESSION_ERROR.`
  );
  return ChargeStateEnum.SESSION_ERROR;
}
