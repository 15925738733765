import { Text } from '@app/shared/typography/text/text';
import { useTranslation } from 'react-i18next';
import { configurationService } from '@app/core/configuration/configuration.service';
import css from './compagny-infos.module.scss';

export const CompanyInfos = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={css.companyInfos}>
      <Text bold>{t('legal-text.legal-notices.company.name')}</Text>

      <div>
        <Text>{t('legal-text.legal-notices.company.share-capital')} : </Text>
        <Text>{configurationService.getLegalNoticesCompanyShareCapital()}</Text>
      </div>
      <div>
        <Text>{t('legal-text.legal-notices.company.rcs')} : </Text>
        <Text>{configurationService.getLegalNoticesCompanyRcs()}</Text>
      </div>
      <div>
        <Text>{t('legal-text.legal-notices.company.tva')} : </Text>
        <Text>{configurationService.getLegalNoticesCompanyTva()}</Text>
      </div>
      <div>
        <Text>{t('legal-text.legal-notices.company.headquarters')} : </Text>
        <Text>{configurationService.getLegalNoticesCompanyHeadquarters()}</Text>
      </div>
    </div>
  );
};
