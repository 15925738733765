import { ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import './slide-from-bottom.scss';

interface SlideFromBottomProps {
  show?: boolean;

  children: ReactNode;
}

export const SlideFromBottom = ({
  show = false,
  children,
}: SlideFromBottomProps): JSX.Element => {
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={show}
      timeout={300}
      classNames="slide-from-bottom"
      mountOnEnter
      unmountOnExit
      appear>
      <div ref={nodeRef}>{children}</div>
    </CSSTransition>
  );
};
