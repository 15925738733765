import { recoverWsTokenOrCreate } from '@app/core/client/websocket/recover-ws-token-or-create';

export interface AuthSocketOptions {
  webAppToken: string;
}

export function getSocketAuth(): AuthSocketOptions {
  const wsToken = recoverWsTokenOrCreate();

  return {
    webAppToken: wsToken,
  };
}
