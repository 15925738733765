import { FrameCardTokenizedEvent } from 'frames-react';

import { paymentTransactionService } from '@app/core/service/payment-transaction.service';
import { TransactionFromTokenCreationRequestModel } from '@app/core/model/payment-transaction.model';
import { RedirectUrlResponseModel } from '@app/core/model/redirect-url-response.model';
import { paymentMethodService } from '@app/core/service/payment-method.service';

interface CheckoutFrameOnTokenHookProps {
  forceSave: boolean;
  withPrecapture: boolean;
}

type CheckoutFrameOnTokenHookReturn = (
  event: FrameCardTokenizedEvent,
  mustSavePaymentMethod: boolean
) => Promise<RedirectUrlResponseModel>;

export const useCheckoutFrameOnToken = (
  props: CheckoutFrameOnTokenHookProps
): CheckoutFrameOnTokenHookReturn => {
  const { forceSave, withPrecapture } = props;

  const createBody = (
    event: FrameCardTokenizedEvent,
    mustSavePaymentMethod: boolean
  ): TransactionFromTokenCreationRequestModel => {
    return {
      token: event.token,
      mustSavePaymentMethod: mustSavePaymentMethod || forceSave,
      ...(event.preferred_scheme
        ? { preferredScheme: event.preferred_scheme }
        : {}),
    } as TransactionFromTokenCreationRequestModel;
  };

  const handleTokenWithPrecapture = async (
    event: FrameCardTokenizedEvent,
    mustSavePaymentMethod: boolean
  ) => {
    const body = createBody(event, mustSavePaymentMethod);

    const [createTransactionPromise] =
      paymentTransactionService.createTransactionFromToken(body);

    return createTransactionPromise;
  };

  const handleTokenWithoutPrecapture = async (
    event: FrameCardTokenizedEvent,
    mustSavePaymentMethod: boolean
  ) => {
    const body = createBody(event, mustSavePaymentMethod);

    const [createTransactionPromise] =
      paymentMethodService.createPaymentMethod(body);

    return createTransactionPromise;
  };

  return withPrecapture
    ? handleTokenWithPrecapture
    : handleTokenWithoutPrecapture;
};
