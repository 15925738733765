import { Link, LinkProps } from '../typography/link/link';
import {
  OpenTabDialog,
  OpenTabDialogProps,
} from '../open-tab-dialog/open-tab.dialog';
import { useDialog } from '../dialog/use-dialog';
import { Icon } from '../icon/icon';

interface OuterLinkProps
  extends Omit<LinkProps, 'children' | 'onClick' | 'title'>,
    Omit<OpenTabDialogProps, 'isOpen' | 'onClose'> {
  withIcon?: boolean;
  children: string | JSX.Element;
}

export const OuterLink = (props: OuterLinkProps): JSX.Element => {
  const { url, title, children, withIcon = true, ...linkProps } = props;
  const openTabDialogProps = { url, title };

  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  return (
    <>
      <Link
        onClick={openDialog}
        {...linkProps}
        endIcon={withIcon ? <Icon name="link-external-02" /> : null}>
        {children}
      </Link>
      <OpenTabDialog
        {...openTabDialogProps}
        isOpen={isDialogOpen}
        onClose={closeDialog}
      />
    </>
  );
};
