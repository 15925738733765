import {
  EMAIL_PATTERN,
  FIRST_NAME_MAX_LENGTH,
  FIRST_NAME_MIN_LENGTH,
  LAST_NAME_MAX_LENGTH,
  LAST_NAME_MIN_LENGTH,
  NAME_PATTERN,
} from '@app/config/form-validation-rules.const';

export const registrationFormValidationRules = {
  firstName: {
    required: true,
    minLength: FIRST_NAME_MIN_LENGTH,
    maxLength: FIRST_NAME_MAX_LENGTH,
    pattern: NAME_PATTERN,
  },
  lastName: {
    required: true,
    minLength: LAST_NAME_MIN_LENGTH,
    maxLength: LAST_NAME_MAX_LENGTH,
    pattern: NAME_PATTERN,
  },
  emailAddress: {
    required: true,
    pattern: EMAIL_PATTERN,
  },
  legalText: {
    required: true,
  },
};
