import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { SCAN_PATH } from '@app/config/app-paths.const';
import { DELMO_CHARGE_POINT_ID_LSK } from '@app/config/localstorage-keys.const';
import { getFromLocalStorage } from '@app/core/storage/local-storage';
import { Logger } from '@app/core/logger/logger';

export interface WithChargePointIdProps {
  delmoChargePointId: string;
}

type ComponentProps<T> = Omit<T, keyof WithChargePointIdProps>;

export function WithChargePointIdGuard<
  T extends WithChargePointIdProps = WithChargePointIdProps,
>(
  WrappedComponent: React.ComponentType<T>
): (props: ComponentProps<T>) => JSX.Element {
  const ComponentWithChargePointId = (
    props: ComponentProps<T>
  ): JSX.Element => {
    const delmoChargePointId = useMemo(
      () => getFromLocalStorage(DELMO_CHARGE_POINT_ID_LSK),
      []
    );

    if (!delmoChargePointId) {
      Logger.debug(
        'withChargePointIdGuard',
        'no charge-point-id found in localstorage'
      );
      return <Navigate to={SCAN_PATH} />;
    }

    return (
      <WrappedComponent
        {...(props as T)}
        delmoChargePointId={delmoChargePointId}
      />
    );
  };

  return ComponentWithChargePointId;
}
