import classNames from 'classnames';
import { HTMLProps } from 'react';

import css from './dialog-layout.module.scss';

const DialogLayout = ({
  className,
  ...divProps
}: HTMLProps<HTMLDivElement>): JSX.Element => (
  <div className={classNames(css.layout, className)} {...divProps} />
);

interface DialogBodyProps extends HTMLProps<HTMLDivElement> {
  scrollable?: boolean;
}

const DialogBody = ({
  scrollable,
  className,
  ...divProps
}: DialogBodyProps): JSX.Element => (
  <div
    className={classNames(css.body, scrollable && css.scrollable, className)}
    {...divProps}
  />
);

const DialogFooter = ({
  className,
  ...divProps
}: HTMLProps<HTMLDivElement>): JSX.Element => (
  <div className={classNames(css.footer, className)} {...divProps} />
);

DialogLayout.Body = DialogBody;
DialogLayout.Footer = DialogFooter;

export { DialogLayout };
