import classNames from 'classnames';

import { Icon } from '@app/shared/icon/icon';
import css from './apple-pay-button.module.scss';

interface ApplePayButtonProps {
  onClick: () => void;
}
export const ApplePayButton = ({
  onClick,
}: ApplePayButtonProps): JSX.Element => (
  <button onClick={onClick}>
    <div className={classNames(css.applePayButton, css.applePayButtonWhite)}>
      <div className={css.rightElement}>
        <Icon color="primary" name="chevron-right" />
      </div>
    </div>
  </button>
);
