export enum HttpStatusEnum {
  INTERNAL_SERVER_ERROR = '500',
  UNAUTHORIZED = '401',
  FORBIDDEN = '403',
  NOT_FOUND = '404',
  TIMEOUT = '408',
  TOO_MANY_REQUEST = '429',
}

export const ROOT_ERROR_TRANSLATION = 'errors';
export const DEFAULT_STATUS_ERR = HttpStatusEnum.INTERNAL_SERVER_ERROR;

export enum ErrorCodeEnum {
  // User service errors
  EMAIL_CONFLICT = 'USRV_001',
  ACCOUNT_LOCKED = 'USRV_017',
  BAD_CHALLENGE = 'USRV_019',
  EMAIL_ALREADY_REGISTERED = 'USRV_020',
  AUTHENTICATOR_NAME_ALREADY_EXISTS = 'USRV_021',

  // Invoice service errors
  CANNOT_CREATE_ORDER_CHARGE_SESSION_PENDING = 'ISRV_017',
  CANNOT_CREATE_ORDER_ALREADY_EXISTS = 'ISRV_018',

  // Transaction service errors
  LATEST_VERSION_OF_TERMS_OF_USE_NOT_VALIDATED = 'TSRV_022',
  OCPI_LOCATION_NOT_FOUND = 'TSRV_007',
  OCPI_EVSE_NOT_FOUND = 'TSRV_020',

  // Webapp errors
  DEFAULT = 'DEL_001',
  NO_ERROR_CODE = 'DEL_002',
  QR_CODE_UNREADABLE = 'DEL_003',
  QR_CODE_MALFORMED = 'DEL_004',
  SCAN_EXPIRED = 'DEL_005',
  GOOGLE_CANCEL = 'DEL_006',
  APPLE_CANCEL = 'DEL_007',
  SWITCH_LANGUAGE = 'DEL_009',
  CHECKOUT_SUBMIT_FAILED = 'DEL_010',
  CHECKOUT_TOKENIZATION_FAILED = 'DEL_011',
  APPLE_SESSION_VALIDATION_FAILED = 'DEL_012',
  WITH_ORDER_GUARD_HAS_NO_ORDER = 'DEL_013',
  WITH_USER_GUARD_HAS_NO_USER = 'DEL_014',
  ACCOUNT_NOT_FOUND = 'DEL_016',
  MUST_LOGIN_WITH_WEBAUTHN_TO_CONFIRM_IDENTITY = 'DEL_017',
  INVOICE_PAYLOAD_EMPTY = 'DEL_018',
  USER_SYNC_SUBSCRIPTION = 'DEL_019',
}

export const DEFAULT_CHECKOUT_MESSAGE = 'payment-failed';
export const checkoutErrorsToMessage = {
  '20001': 'payment-refused',
  '20002': 'payment-refused',
  '20003': 'payment-failed',
  '20005': 'payment-refused',
  '20014': 'payment-refused-card-error',
  '20012': 'payment-refused',
  '20013': 'payment-refused-retry-with-another-card',
  '20030': 'payment-failed-check-card-information',
  '20046': 'payment-refused',
  '20051': 'payment-refused',
  '20057': 'payment-refused',
  '20059': 'payment-refused',
  '20061': 'card-error-type-payment',
  '20062': 'payment-refused',
  '20064': 'payment-refused',
  '20065': 'payment-refused',
  '20068': 'payment-failed-check-card-information',
  '20075': 'payment-refused',
  '20078': 'payment-refused',
  '20087': 'payment-failed-check-card-information',
  '20091': 'payment-failed-check-card-information',
  '20093': 'payment-refused',
  '20096': 'payment-failed-check-card-information',
  '20099': 'payment-failed-technic',
  '2006P': 'payment-failed-try-another-card',
  '200R1': 'payment-refused',
  '200R3': 'payment-refused',
  '20100': 'payment-refused-expiration',
  '20102': 'payment-failed-technic',
  '20103': 'payment-refused',
  '20104': 'payment-failed-check-card-information',
  '20105': 'payment-failed-check-card-information',
  '20111': 'cancel-payment-already-done',
  '20112': 'payment-failed-contact-us',
  '20117': 'payment-failed-technic',
  '20118': 'payment-failed-technic',
  '20119': 'payment-failed-technic',
  '20120': 'payment-failed-technic',
  '20123': 'payment-failed-technic',
  '20152': 'payment-expired',
  '20153': 'payment-failed-check-card-information',
  '20151': 'payment-failed-technic',
  '20154': 'payment-refused-identity',
  '20179': 'payment-refused-missing-fund',
  '20182': 'payment-refused-missing-fund',
  '20183': 'payment-refused-missing-fund',
  '30004': 'payment-refused',
  '30007': 'payment-refused',
  '30015': 'payment-failed-check-card-information',
  '30016': 'payment-refused',
  '30017': 'payment-refused',
  '30018': 'payment-refused',
  '30019': 'payment-refused',
  '30020': 'payment-failed-technic',
  '30021': 'payment-refused',
  '30022': 'payment-refused',
  '30033': 'payment-refused',
  '30034': 'payment-refused-contact-bank',
  '30035': 'payment-refused',
  '30036': 'payment-refused',
  '30037': 'payment-refused',
  '30038': 'payment-refused',
  '30041': 'payment-refused',
  '30043': 'payment-refused-contact-bank',
  '30044': 'payment-refused-contact-bank',
  '30045': 'payment-refused-contact-bank',
  '30046': 'payment-refused-contact-bank',
};
