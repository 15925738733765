import { useTranslation } from 'react-i18next';

import { LegalText } from '@app/core/model/legal-text';
import { Button } from '@app/shared/button/button';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';
import { H1 } from '@app/shared/typography/heading/heading';

import css from './validate-legal-text.module.scss';

interface ValidateLegalTextViewProps {
  legalText: LegalText;
  pending: boolean;
  onValidate: () => void;
}

export const ValidateLegalTextView = (
  props: ValidateLegalTextViewProps
): JSX.Element => {
  const { pending, legalText, onValidate } = props;
  const { t } = useTranslation();

  return (
    <DrawerLayout>
      <DrawerLayout.Header>
        <H1>{t('check-user-consent.title')}</H1>
      </DrawerLayout.Header>

      <DrawerLayout.Body
        className={css.body}
        dangerouslySetInnerHTML={{ __html: legalText.content }}
      />

      <DrawerLayout.Footer>
        <Button onClick={onValidate} isLoading={pending}>
          {t('check-user-consent.accept-button')}
        </Button>
      </DrawerLayout.Footer>
    </DrawerLayout>
  );
};
