import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TariffElementModel } from '@app/core/model/tariff.model';
import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';

import css from './tariff-list-item.module.scss';
import { InlineTariffRestriction } from './inline-tariff-restriction';

type Props = {
  tariffElement: TariffElementModel;
  withIcon?: boolean;
};

export function TariffListItem(props: Props): JSX.Element {
  const { tariffElement, withIcon = true } = props;
  const { restrictions, priceComponents } = tariffElement;
  const {
    perKwhTTC = 0,
    flatTTC = 0,
    perTimeChargingHourTTC = 0,
  } = priceComponents;
  const { t } = useTranslation();

  const label = useMemo(() => {
    if (perKwhTTC > 0) {
      return t('quote.price-section.price-per-kwh');
    }

    if (perTimeChargingHourTTC > 0) {
      return t('quote.price-section.price-per-min');
    }

    return t('quote.price-section.flat-fee');
  }, [t, perKwhTTC, perTimeChargingHourTTC]);

  return (
    <div className={css.container}>
      {withIcon && <Icon className={css.icon} name="price" />}

      <div className={css.body}>
        <Text>{label}</Text>

        {restrictions && (
          <Text className={css.caption}>
            <InlineTariffRestriction restrictions={restrictions} />
          </Text>
        )}
      </div>

      <Text bold className={css.price}>
        {t('quote.price-section.amount', {
          amount: perKwhTTC || perTimeChargingHourTTC / 60 || flatTTC,
        })}
      </Text>
    </div>
  );
}
