import { AbortablePromise } from '@app/core/helper/abort-promise';
import { httpClientService } from '@app/core/client/http/http-client.service';
import {
  START_EMAIL_AUTHENTICATION_API,
  START_WEBAUTHN_AUTHENTICATION_API,
  VERIFY_EMAIL_AUTHENTICATION_API,
  VERIFY_WEBAUTHN_AUTHENTICATION_API,
} from '@app/config/api-routes.const';
import { JwtResponseModel } from '@app/core/model/jwt-response.model';
import {
  EmailAuthenticationVerificationRequestModel,
  WebauthnAuthenticationStartRequestModel,
  WebauthnAuthenticationStartResponseModel,
  WebauthnAuthenticationVerificationRequestModel,
} from '@app/core/model/authentication.model';
import { EmailOtpProcessStartRequestModel } from '@app/core/model/email-otp-process.model';

class AuthenticationService {
  startEmail(data: EmailOtpProcessStartRequestModel): AbortablePromise<void> {
    return httpClientService.post<void>(START_EMAIL_AUTHENTICATION_API, data);
  }

  verifyEmail(
    data: EmailAuthenticationVerificationRequestModel
  ): AbortablePromise<JwtResponseModel> {
    return httpClientService.post<JwtResponseModel>(
      VERIFY_EMAIL_AUTHENTICATION_API,
      data
    );
  }

  startWebauthn(
    data: WebauthnAuthenticationStartRequestModel
  ): AbortablePromise<WebauthnAuthenticationStartResponseModel> {
    return httpClientService.post<WebauthnAuthenticationStartResponseModel>(
      START_WEBAUTHN_AUTHENTICATION_API,
      data
    );
  }

  verifyWebauthn(
    data: WebauthnAuthenticationVerificationRequestModel
  ): AbortablePromise<JwtResponseModel> {
    return httpClientService.post<JwtResponseModel>(
      VERIFY_WEBAUTHN_AUTHENTICATION_API,
      data
    );
  }
}

export const authenticationService = new AuthenticationService();
