import { Navigate, NavigateOptions } from 'react-router-dom';

import { HOME_PATH } from '@app/config/app-paths.const';

import { useGetRedirectionState } from './use-get-redirection-state';

export const RedirectToHomeWithState = (
  options?: NavigateOptions
): JSX.Element => {
  const state = useGetRedirectionState();

  return <Navigate to={HOME_PATH} {...options} state={state} replace />;
};
