import { useUser } from '@app/core/context-providers/user-context/use-user';
import { InvoiceModel } from '@app/core/model/invoice.model';
import { Drawer, DrawerStateEnum } from '@app/shared/drawer/drawer';
import { Icon } from '@app/shared/icon/icon';
import { InvoiceFooter } from '@app/shared/invoice/invoice-footer/invoice.footer';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';
import { Spinner } from '@app/shared/spinner/spinner';
import { H2, H3 } from '@app/shared/typography/heading/heading';
import { useTranslation } from 'react-i18next';

import css from './invoice-detail.drawer.module.scss';
import { InvoiceCard } from './invoice.card';

type Props = {
  invoice?: InvoiceModel | null;
  displayProformaFooter?: boolean;
  onClose: () => void;
};

export function InvoiceDetailDrawer({
  onClose,
  invoice,
  displayProformaFooter = true,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { user } = useUser();

  return (
    <Drawer
      icon="file-05"
      state={invoice ? DrawerStateEnum.OPEN : DrawerStateEnum.CLOSE}
      onClose={onClose}>
      <DrawerLayout>
        {invoice ? (
          <>
            <DrawerLayout.Header className={css.header}>
              <H2 className={css.title}>
                {invoice.proforma ? (
                  <>
                    <Icon name="credit-card-refresh" className={css.warning} />
                    {t('invoicing.detail.title-proforma')}
                  </>
                ) : (
                  <>
                    <Icon name="credit-card-check" className={css.success} />
                    {t('invoicing.detail.title')}
                  </>
                )}
              </H2>
              <H3>
                {t('invoicing.detail.sub-title', { id: invoice.invoiceId })}
              </H3>
            </DrawerLayout.Header>

            <DrawerLayout.Body>
              <InvoiceCard invoice={invoice} pseudo={user?.pseudo} />
            </DrawerLayout.Body>

            <DrawerLayout.Footer>
              {displayProformaFooter && (
                <InvoiceFooter
                  proforma={invoice.proforma}
                  invoiceId={invoice.invoiceId}
                  createdAt={invoice.createdAt}
                  autoSendInvoiceByMail={
                    !!user?.preferences?.autoSendInvoiceByMail
                  }
                  isEmailAddressVerified={!!user?.isEmailAddressVerified}
                  pseudo={invoice.customer.pseudo}
                />
              )}
            </DrawerLayout.Footer>
          </>
        ) : (
          <Spinner />
        )}
      </DrawerLayout>
    </Drawer>
  );
}
