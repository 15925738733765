import {
  NAME_PATTERN,
  PAYMENT_METHOD_MAX_LENGTH,
  PAYMENT_METHOD_MIN_LENGTH,
} from '@app/config/form-validation-rules.const';

export interface RenamePaymentMethodForm {
  name: string;
}

export const RENAME_PAYMENT_METHOD_FORM_VALIDATION_RULES = {
  required: true,
  minLength: PAYMENT_METHOD_MIN_LENGTH,
  maxLength: PAYMENT_METHOD_MAX_LENGTH,
  pattern: NAME_PATTERN,
};
