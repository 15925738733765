import { USER_API } from '@app/config/api-routes.const';
import { httpClientService } from '@app/core/client/http/http-client.service';
import { AbortablePromise } from '@app/core/helper/abort-promise';
import { PatchUserPreferenceModel } from '@app/core/model/user-preference.model';
import { UserModel } from '@app/core/model/user.model';

class UserPreferenceService {
  patch(preferences: PatchUserPreferenceModel): AbortablePromise<UserModel> {
    return httpClientService.run<UserModel>(USER_API, {
      data: {
        preferences,
      },
      method: 'PATCH',
    });
  }
}

export const userPreferenceService = new UserPreferenceService();
