import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useDialog } from '@app/shared/dialog/use-dialog';
import { Link, LinkProps } from '@app/shared/typography/link/link';
import { DeletePaymentMethodController } from '@app/feature/account/view/user-payment-method/delete-payment-method/delete-payment-method.controller';
import { Dialog } from '@app/shared/dialog/dialog';
import { Icon } from '@app/shared/icon/icon';

import css from './delete-payment-method.module.scss';

interface DeletePaymentMethodLinkProps extends Omit<LinkProps, 'onClick'> {
  iPaymentSourceId: string;
  onDelete: () => void;
}

export const DeletePaymentMethodLink = (
  props: DeletePaymentMethodLinkProps
): JSX.Element => {
  const { iPaymentSourceId, onDelete, className, children, ...linkProps } =
    props;
  const { t } = useTranslation();

  const {
    openDialog: openDeleteDialog,
    isDialogOpen: isDeleteDialogOpen,
    closeDialog: closeDeleteDialog,
  } = useDialog();

  return (
    <>
      <Link
        onClick={openDeleteDialog}
        className={classNames(css.link, className)}
        startIcon={<Icon name="trash-01" />}
        {...linkProps}>
        {children ?? t('user-payment-method.delete.link')}
      </Link>

      <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
        <DeletePaymentMethodController
          iPaymentSourceId={iPaymentSourceId}
          onSuccess={onDelete}
          onError={closeDeleteDialog}
          onClose={closeDeleteDialog}
        />
      </Dialog>
    </>
  );
};
