import { i18n, TOptions } from 'i18next';
import { $Dictionary } from 'i18next/typescript/helpers';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Leaves, Paths } from '@app/core/model/path.model';
import fr from '../locale/fr.json';

export type TranslationLeaves = Leaves<typeof fr>;
export type TranslationPaths = Paths<typeof fr>;

/**
 * Override of default hook useTranslation() of react-i18next
 * Add typing to translation keys to control and prevent typing error
 */
export const useTypedTranslation = (): {
  t: (
    key: TranslationLeaves,
    options?: TOptions<object> & $Dictionary
  ) => string;
  i18n: i18n;
} => {
  const { t, i18n: defaultI18n } = useTranslation();
  const typedT: (
    key: TranslationLeaves,
    options?: Parameters<typeof t>[2]
  ) => string = useCallback(
    (key: TranslationLeaves, options?: Parameters<typeof t>[2]) =>
      t(key, options),
    [t]
  );
  return { t: typedT, i18n: defaultI18n };
};
