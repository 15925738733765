export async function sendBeacon(url: string, payload: string): Promise<void> {
  // TODO: replace with real API when ready
  const baseUrl = process.env.DELMONICOS_MOCK_API;

  const endpoint = new URL(url, baseUrl ?? '');

  if (navigator.sendBeacon) {
    navigator.sendBeacon(endpoint, payload);
    return;
  }

  fetch(endpoint.toString(), {
    body: payload,
    method: 'POST',
    keepalive: true,
  });
}
