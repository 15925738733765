import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  ABOUT_PATH,
  LEGAL_TEXT_PATH,
  MANAGE_AUTHENTICATION_PATH,
  SECURITY_PATH,
  USER_PAYMENT_METHOD_PATH,
  USER_PREFERENCE_PATH,
  USER_SUBSCRIPTION_PATH,
} from '@app/config/app-paths.const';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { ListItemMenu } from '@app/shared/list-item-menu/list-item-menu';

import { Button } from '@app/shared/button/button';
import { Divider } from '@app/shared/divider/divider';
import { Icon } from '@app/shared/icon/icon';
import css from './account.module.scss';
import { AccountHeader } from './component/account-header/account-header';
import { AccountItemMenu } from './component/account-item-menu/account-item-menu';
import { NotificationItem } from './component/notification-item/notification-item';

interface AccountViewProps {
  firstName: string;
  lastName: string;
  pseudo: string;
  isWebauthnEnabled?: boolean;
  isEmailVerified?: boolean;
  isChargeStarted?: boolean;
  onLogout: () => void;
}

export function AccountView({
  firstName,
  lastName,
  pseudo,
  isWebauthnEnabled = false,
  isEmailVerified = false,
  isChargeStarted = false,
  onLogout,
}: AccountViewProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageLayout>
      <PageLayout.Header>
        <AccountHeader
          pseudo={pseudo}
          firstName={firstName}
          lastName={lastName}
        />
      </PageLayout.Header>

      <PageLayout.Body>
        {isChargeStarted && (
          <NotificationItem>
            {t('account.notification-item.charge-is-started-toast')}
          </NotificationItem>
        )}

        {!isEmailVerified && (
          <NotificationItem
            isDisabled={isChargeStarted}
            onClick={() => navigate(MANAGE_AUTHENTICATION_PATH)}>
            {t('account.notification-item.verify-email')}
          </NotificationItem>
        )}

        {!isWebauthnEnabled && (
          <NotificationItem
            isDisabled={isChargeStarted}
            onClick={() => navigate(MANAGE_AUTHENTICATION_PATH)}>
            {t('account.notification-item.enable-webauthn')}
          </NotificationItem>
        )}

        <ListItemMenu
          title={t('account.section.account')}
          className={css.section}>
          <AccountItemMenu
            icon="user-circle"
            bold
            label={t('account.security-label')}
            onClick={() => navigate(SECURITY_PATH)}
          />

          <AccountItemMenu
            icon="bell-04"
            bold
            label={t('account.user-preference-label')}
            onClick={() => navigate(USER_PREFERENCE_PATH)}
          />

          <AccountItemMenu
            icon="credit-card-02"
            bold
            label={t('account.payment-method-label')}
            disabled={isChargeStarted}
            onClick={() => navigate(USER_PAYMENT_METHOD_PATH)}
          />

          <AccountItemMenu
            icon="calendar-check-01"
            bold
            label={t('account.subscription-label')}
            disabled={isChargeStarted}
            onClick={() => navigate(USER_SUBSCRIPTION_PATH)}
          />
        </ListItemMenu>

        <ListItemMenu title={t('account.section.delmonicos')}>
          <AccountItemMenu
            icon="shield-tick"
            label={t('account.legal-text-label')}
            onClick={() => navigate(LEGAL_TEXT_PATH)}
          />
          <AccountItemMenu
            icon="delmonicos"
            label={t('account.about-us.label')}
            onClick={() => navigate(ABOUT_PATH)}
          />
        </ListItemMenu>

        <Divider type="solid" />

        <Button onClick={onLogout} variant="outlined">
          <Icon name="log-out-02" />
          {t('account.logout-button')}
        </Button>
      </PageLayout.Body>
      <PageLayout.AppbarFooter />
    </PageLayout>
  );
}
