import { Navigate } from 'react-router-dom';

import { CHARGING_PATH } from '@app/config/app-paths.const';
import { useCharge } from '@app/core/context-providers/charge-context/use-charge';
import { GuardProps } from '@app/router/model/guard.props';

interface ChargeNotStartedGuardProps extends GuardProps {
  redirectTo?: string;
}

export const ChargeNotStartedGuard = (
  props: ChargeNotStartedGuardProps
): JSX.Element => {
  const { children, redirectTo = CHARGING_PATH } = props;
  const { isChargeStarted } = useCharge();

  if (isChargeStarted) {
    return <Navigate to={redirectTo} replace />;
  }

  return children;
};
