import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { Dialog } from '@app/shared/dialog/dialog';
import { H1 } from '@app/shared/typography/heading/heading';
import { Icon } from '@app/shared/icon/icon';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { Text } from '@app/shared/typography/text/text';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';
import { PseudoTag } from '@app/shared/pseudo-tag/pseudo-tag';
import { AuthenticationMethodEnum } from '@app/core/model/enum/authentication-method.enum';

import css from './first-login.module.scss';

interface FirstLoginViewProps {
  authenticationMethod: AuthenticationMethodEnum | null;
  onContinue: () => void;
}

export const FirstLoginView = (props: FirstLoginViewProps): JSX.Element => {
  const { authenticationMethod, onContinue } = props;
  const { t } = useTranslation();

  return (
    <PageLayout colored>
      <Dialog open onClose={onContinue}>
        <DialogLayout.Body className={css.header}>
          <div className={css.icon}>
            <Icon name="check" />
          </div>

          <H1>{t('first-login.title')}</H1>
        </DialogLayout.Body>

        <DialogLayout.Body className={css.body}>
          <PseudoTag />

          <div className={css.text}>
            <Text>
              {/* The space after email-info is crucial. */}
              {authenticationMethod === AuthenticationMethodEnum.EMAIL
                ? t('first-login.email-text')
                : t('first-login.webauthn-text')}
            </Text>

            <Text color="secondary">{t('first-login.keep-it')}</Text>
          </div>
        </DialogLayout.Body>

        <DialogLayout.Footer className={css.footer}>
          <Button onClick={onContinue} className={css.button}>
            {t('first-login.button-continue')}
          </Button>
        </DialogLayout.Footer>
      </Dialog>
    </PageLayout>
  );
};
