import { useTranslation } from 'react-i18next';

import { H2 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';
import { Spinner } from '@app/shared/spinner/spinner';

import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';

export const CheckoutPendingView = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <DialogLayout>
      <DialogLayout.Body>
        <Spinner />

        <H2>{t(`quote.callback-dialog.pending.title`)}</H2>

        <Text>{t(`quote.callback-dialog.pending.caption`)}</Text>
      </DialogLayout.Body>
    </DialogLayout>
  );
};
