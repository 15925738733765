import {
  ACTIVE_CHARGE_SESSION_API,
  CREATE_ORDER_API,
  CREATE_TRANSACTION_FROM_I_PAYMENT_SOURCE_ID_API,
  CREATE_TRANSACTION_FROM_PROVIDER_API,
  CREATE_TRANSACTION_FROM_TOKEN_API,
  GET_CHARGE_POINT_API,
  PROVIDERS_CONFIG_API,
  GET_LATEST_ORDER_API,
  ORDER_WST,
  PRE_CAPTURE_SYNC_API,
  SESSION_ENDED_WST,
  SESSION_PENDING_WST,
  SESSION_STARTED_WST,
  SESSION_START_CP_ACCEPTED_WST,
  SESSION_START_CP_REFUSED_WST,
  SESSION_START_CP_TIMEOUT_WST,
  SESSION_START_TIMEOUT_WST,
  SESSION_STOP_CP_ACCEPTED_WST,
  SESSION_STOP_CP_REFUSED_WST,
  SESSION_STOP_CP_TIMEOUT_WST,
  SESSION_STOP_TIMEOUT_WST,
  START_CHARGE_API,
  STOP_CHARGE_API,
  VALIDATE_ORDER_QUOTE_API,
  VALIDATE_ORDER_QUOTE_WITHOUT_PAYMENT_API,
} from '@app/config/api-routes.const';
import { Logger } from '@app/core/logger/logger';
import { httpClientService } from '@app/core/client/http/http-client.service';
import {
  getFromSessionStorage,
  removeInSessionStorage,
  setInSessionStorage,
} from '@app/core/storage/session-storage';
import { matchPath } from 'react-router-dom';
import { addHttpMock, isUrlMocked, removeHttpMock } from './mock-axios';
import { addWsMock, removeWsMock } from './mock-socket';

const ENABLE_CHARGE_MOCK_SSK = 'enable-charge-mock';

export function enableMockChargeWorkflow(): void {
  addHttpMock(GET_LATEST_ORDER_API);
  addHttpMock(ACTIVE_CHARGE_SESSION_API);
  addHttpMock(CREATE_ORDER_API);
  addHttpMock(VALIDATE_ORDER_QUOTE_WITHOUT_PAYMENT_API);
  addHttpMock(PRE_CAPTURE_SYNC_API);
  addHttpMock(VALIDATE_ORDER_QUOTE_API);
  addHttpMock(START_CHARGE_API);
  addHttpMock(STOP_CHARGE_API);
  addHttpMock(CREATE_TRANSACTION_FROM_TOKEN_API);
  addHttpMock(CREATE_TRANSACTION_FROM_PROVIDER_API);
  addHttpMock(CREATE_TRANSACTION_FROM_I_PAYMENT_SOURCE_ID_API);
  addHttpMock(PROVIDERS_CONFIG_API);
  addWsMock(ORDER_WST);
  addWsMock(SESSION_START_CP_TIMEOUT_WST);
  addWsMock(SESSION_START_CP_ACCEPTED_WST);
  addWsMock(SESSION_START_CP_REFUSED_WST);
  addWsMock(SESSION_START_TIMEOUT_WST);
  addWsMock(SESSION_STARTED_WST);
  addWsMock(SESSION_PENDING_WST);
  addWsMock(SESSION_STOP_CP_TIMEOUT_WST);
  addWsMock(SESSION_STOP_CP_ACCEPTED_WST);
  addWsMock(SESSION_STOP_CP_REFUSED_WST);
  addWsMock(SESSION_STOP_TIMEOUT_WST);
  addWsMock(SESSION_ENDED_WST);
}

export function disableMockChargeWorkflow(): void {
  removeHttpMock(GET_LATEST_ORDER_API);
  removeHttpMock(ACTIVE_CHARGE_SESSION_API);
  removeHttpMock(CREATE_ORDER_API);
  removeHttpMock(VALIDATE_ORDER_QUOTE_WITHOUT_PAYMENT_API);
  removeHttpMock(PRE_CAPTURE_SYNC_API);
  removeHttpMock(VALIDATE_ORDER_QUOTE_API);
  removeHttpMock(START_CHARGE_API);
  removeHttpMock(STOP_CHARGE_API);
  removeHttpMock(CREATE_TRANSACTION_FROM_TOKEN_API);
  removeHttpMock(CREATE_TRANSACTION_FROM_PROVIDER_API);
  removeHttpMock(CREATE_TRANSACTION_FROM_I_PAYMENT_SOURCE_ID_API);
  removeHttpMock(PROVIDERS_CONFIG_API);
  removeWsMock(ORDER_WST);
  removeWsMock(SESSION_START_CP_TIMEOUT_WST);
  removeWsMock(SESSION_START_CP_ACCEPTED_WST);
  removeWsMock(SESSION_START_CP_REFUSED_WST);
  removeWsMock(SESSION_START_TIMEOUT_WST);
  removeWsMock(SESSION_STARTED_WST);
  removeWsMock(SESSION_PENDING_WST);
  removeWsMock(SESSION_STOP_CP_TIMEOUT_WST);
  removeWsMock(SESSION_STOP_CP_ACCEPTED_WST);
  removeWsMock(SESSION_STOP_CP_REFUSED_WST);
  removeWsMock(SESSION_STOP_TIMEOUT_WST);
  removeWsMock(SESSION_ENDED_WST);
}

export function registerChargeMock(): void {
  const enableChargeMock =
    getFromSessionStorage(ENABLE_CHARGE_MOCK_SSK) === 'true';

  if (enableChargeMock) {
    enableMockChargeWorkflow();
  }

  httpClientService.axios.interceptors.request.use(
    (config) => {
      if (config.url && matchPath(`${GET_CHARGE_POINT_API}/*`, config.url)) {
        if (isUrlMocked(config.url)) {
          Logger.debug('enable charge mock');
          setInSessionStorage(ENABLE_CHARGE_MOCK_SSK, true.toString());
          enableMockChargeWorkflow();
        } else {
          Logger.debug('disable charge mock');
          removeInSessionStorage(ENABLE_CHARGE_MOCK_SSK);
          disableMockChargeWorkflow();
        }
      }

      return config;
    },
    (error) => Promise.reject(error)
  );
}
