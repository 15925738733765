import { Outlet } from 'react-router-dom';

import { useCharge } from '@app/core/context-providers/charge-context/use-charge';
import {
  SESSION_ENDED_WST,
  SESSION_INVALID_WST,
  SESSION_PENDING_WST,
  SESSION_STARTED_WST,
  SESSION_START_CP_ACCEPTED_WST,
  SESSION_START_CP_REFUSED_WST,
  SESSION_START_CP_TIMEOUT_WST,
  SESSION_START_TIMEOUT_WST,
  SESSION_STOP_CP_ACCEPTED_WST,
  SESSION_STOP_CP_REFUSED_WST,
  SESSION_STOP_CP_TIMEOUT_WST,
  SESSION_STOP_TIMEOUT_WST,
} from '@app/config/api-routes.const';
import { useWebsocket } from '@app/core/client/websocket/use-websocket';
import { ChargeSessionEnum } from '@app/core/model/enum/charge-session.enum';
import {
  ChargingEventModel,
  ChargingEventResponseModel,
} from '@app/core/model/charging-event.model';

export const ListenForChargingEventController = (): JSX.Element => {
  const { onEvent } = useCharge();

  useWebsocket(
    SESSION_START_CP_TIMEOUT_WST,
    (payload: ChargingEventResponseModel) =>
      onEvent({
        type: ChargeSessionEnum.START_CHARGE_CP_TIMEOUT,
        payload: new ChargingEventModel(payload),
      })
  );
  useWebsocket(
    SESSION_START_CP_ACCEPTED_WST,
    (payload: ChargingEventResponseModel) =>
      onEvent({
        type: ChargeSessionEnum.START_CHARGE_CP_ACCEPTED,
        payload: new ChargingEventModel(payload),
      })
  );

  useWebsocket(
    SESSION_START_CP_REFUSED_WST,
    (payload: ChargingEventResponseModel) =>
      onEvent({
        type: ChargeSessionEnum.START_CHARGE_CP_REFUSED,
        payload: new ChargingEventModel(payload),
      })
  );
  useWebsocket(
    SESSION_START_TIMEOUT_WST,
    (payload: ChargingEventResponseModel) =>
      onEvent({
        type: ChargeSessionEnum.SESSION_START_TIMEOUT,
        payload: new ChargingEventModel(payload),
      })
  );
  useWebsocket(SESSION_STARTED_WST, (payload: ChargingEventResponseModel) =>
    onEvent({
      type: ChargeSessionEnum.SESSION_STARTED,
      payload: new ChargingEventModel(payload),
    })
  );
  useWebsocket(SESSION_PENDING_WST, (payload: ChargingEventResponseModel) =>
    onEvent({
      type: ChargeSessionEnum.SESSION_PENDING,
      payload: new ChargingEventModel(payload),
    })
  );
  useWebsocket(
    SESSION_STOP_CP_TIMEOUT_WST,
    (payload: ChargingEventResponseModel) =>
      onEvent({
        type: ChargeSessionEnum.SESSION_STOP_TIMEOUT,
        payload: new ChargingEventModel(payload),
      })
  );
  useWebsocket(
    SESSION_STOP_CP_ACCEPTED_WST,
    (payload: ChargingEventResponseModel) =>
      onEvent({
        type: ChargeSessionEnum.STOP_CHARGE_CP_ACCEPTED,
        payload: new ChargingEventModel(payload),
      })
  );
  useWebsocket(
    SESSION_STOP_CP_REFUSED_WST,
    (payload: ChargingEventResponseModel) =>
      onEvent({
        type: ChargeSessionEnum.STOP_CHARGE_CP_REFUSED,
        payload: new ChargingEventModel(payload),
      })
  );
  useWebsocket(
    SESSION_STOP_TIMEOUT_WST,
    (payload: ChargingEventResponseModel) =>
      onEvent({
        type: ChargeSessionEnum.SESSION_STOP_TIMEOUT,
        payload: new ChargingEventModel(payload),
      })
  );
  useWebsocket(SESSION_ENDED_WST, (payload: ChargingEventResponseModel) =>
    onEvent({
      type: ChargeSessionEnum.SESSION_ENDED,
      payload: new ChargingEventModel(payload),
    })
  );

  useWebsocket(SESSION_INVALID_WST, (payload: ChargingEventResponseModel) =>
    onEvent({
      type: ChargeSessionEnum.SESSION_INVALID,
      payload: new ChargingEventModel(payload),
    })
  );

  return <Outlet />;
};
