import { useTranslation } from 'react-i18next';

export const useDeviceName = (): string => {
  const { t } = useTranslation();

  // TODO: 27/09/2023 https://delmonicos.atlassian.net/browse/BFP-280 - Example of using UA-parser-js to determine the device name
  // const parser = new UAParser();
  // const os = parser.getOS().name ?? t('shared.device-name.fallback');
  // const browser = parser.getBrowser().name;
  // return `${os} ${browser}`;

  return t('shared.device-name.fallback');
};
