import classNames from 'classnames';
import Applepay from '@app/asset/applepay.svg';
import Googlepay from '@app/asset/googlepay.svg';
import MasterCard from '@app/asset/mastercard.svg';
import Visa from '@app/asset/visa-colored.svg';
import VisaWhite from '@app/asset/visa-white.svg';
import CarteBancaire from '@app/asset/carte-bancaire.svg';
import { PaymentSchemeEnum } from '@app/core/model/enum/payment-scheme.enum';

import css from './payment-scheme-logo.module.scss';

interface PaymentSchemeLogoProps {
  paymentScheme: PaymentSchemeEnum;
  variant?: 'light' | 'colored';
  className?: string;
}

export const PaymentSchemeLogo = ({
  paymentScheme,
  variant = 'colored',
  className,
  ...props
}: PaymentSchemeLogoProps): JSX.Element => {
  const logoProps = { ...props, className: classNames(css.logo, className) };

  if (!paymentScheme) {
    return <div />;
  }

  switch (paymentScheme.toLowerCase()) {
    case PaymentSchemeEnum.APPLE_PAY:
      return <Applepay {...logoProps} />;

    case PaymentSchemeEnum.GOOGLE_PAY:
      return <Googlepay {...logoProps} />;

    case PaymentSchemeEnum.MASTER_CARD:
      return <MasterCard {...logoProps} />;

    case PaymentSchemeEnum.VISA:
      return variant === 'light' ? (
        <VisaWhite {...logoProps} />
      ) : (
        <Visa {...logoProps} />
      );
    case PaymentSchemeEnum.CARTE_BANCAIRE:
      return <CarteBancaire {...logoProps} />;

    default:
      return <div />;
  }
};
