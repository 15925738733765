import { toInt } from 'radash';
import { Logger } from '../logger/logger';

const PRODUCTION_ENV = 'production';

interface ThemeIdModel {
  theme1: string;
  theme2: string;
}

class ConfigurationService {
  private static instance: ConfigurationService;

  private constructor() {
    const configuration = {
      isProduction: this.isProduction(),
      api: this.getApi(),
      ws: this.getWebsocket(),
      defaultCpoId: this.getDefaultCpoId(),
      wsDefaultTimeout: this.getWsDefaultTimeout(),
      isPrivateAccessEnabled: this.isPrivateAccessEnabled(),
      version: this.getVersion(),
      logLevel: Logger.getLogLevel(),
      supportPhoneNumber: this.getSupportPhoneNumber(),
      themeId: this.getThemeId(),
      legalNoticesPublicationManagerName:
        this.getLegalNoticesPublicationManagerName(),
      legalNoticesPublicationManagerPhoneNumber:
        this.getLegalNoticesPublicationManagerPhoneNumber(),
      legalNoticesPublicationManagerMail:
        this.getLegalNoticesPublicationManagerMail(),
      legalNoticesCompanyShareCapital:
        this.getLegalNoticesCompanyShareCapital(),
      legalNoticesCompanyRcs: this.getLegalNoticesCompanyRcs(),
      legalNoticesCompanyTva: this.getLegalNoticesCompanyTva(),
      legalNoticesCompanyHeadquarters:
        this.getLegalNoticesCompanyHeadquarters(),
      legalNoticesHostingName: this.getLegalNoticesHostingName(),
      legalNoticesHostingHeadquarters:
        this.getLegalNoticesHostingHeadquarters(),
    };
    Logger.debug('configuration loaded', configuration);
  }

  static getInstance(): ConfigurationService {
    if (!ConfigurationService.instance) {
      ConfigurationService.instance = new ConfigurationService();
    }

    return ConfigurationService.instance;
  }

  getApi(): string {
    return process.env.DELMONICOS_API ?? '';
  }

  getWebsocket(): string {
    return process.env.DELMONICOS_WS ?? '';
  }

  getDefaultCpoId(): string {
    return process.env.DELMONICOS_DEFAULT_CPO_ID ?? '';
  }

  getWsDefaultTimeout(): number {
    return toInt(process.env.DELMONICOS_WS_DEFAULT_TIMEOUT, 20);
  }

  isPrivateAccessEnabled(): boolean {
    return process.env.DELMONICOS_ENABLE_PRIVATE_ACCESS === 'true';
  }

  isProduction(): boolean {
    return process.env.NODE_ENV === PRODUCTION_ENV;
  }

  getVersion(): string {
    return process.env.VERSION ?? '';
  }

  getSupportPhoneNumber(): string {
    if (!process.env.DELMONICOS_SUPPORT_PHONE_NUMBER) {
      return '+33 6 00 00 00 00';
    }
    return process.env.DELMONICOS_SUPPORT_PHONE_NUMBER;
  }

  getThemeId(): ThemeIdModel {
    return {
      theme1: process.env.DELMONICOS_THEME_ID_UEM ?? '',
      theme2: process.env.DELMONICOS_THEME_ID_FORD ?? '',
    };
  }

  getLegalNoticesPublicationManagerName(): string {
    // TODO: Make environment variable management cross-platform
    if (!process.env.DELMONICOS_LEGAL_NOTICES_PUBLICATION_MANAGER_NAME) {
      return 'LegalNoticesPublicationManagerName';
    }
    // TODO: Make environment variable management cross-platform
    return process.env.DELMONICOS_LEGAL_NOTICES_PUBLICATION_MANAGER_NAME;
  }

  getLegalNoticesPublicationManagerPhoneNumber(): string {
    // TODO: Make environment variable management cross-platform
    if (
      !process.env.DELMONICOS_LEGAL_NOTICES_PUBLICATION_MANAGER_PHONE_NUMBER
    ) {
      return 'LegalNoticesPublicationManagerPhoneNumber';
    }
    // TODO: Make environment variable management cross-platform
    return process.env
      .DELMONICOS_LEGAL_NOTICES_PUBLICATION_MANAGER_PHONE_NUMBER;
  }

  getLegalNoticesPublicationManagerMail(): string {
    // TODO: Make environment variable management cross-platform
    if (!process.env.DELMONICOS_LEGAL_NOTICES_PUBLICATION_MANAGER_MAIL) {
      return 'LegalNoticesPublicationManagerManagerMail';
    }
    // TODO: Make environment variable management cross-platform
    return process.env.DELMONICOS_LEGAL_NOTICES_PUBLICATION_MANAGER_MAIL;
  }

  getLegalNoticesCompanyShareCapital(): string {
    // TODO: Make environment variable management cross-platform
    if (!process.env.DELMONICOS_LEGAL_NOTICES_COMPANY_SHARE_CAPITAL) {
      return 'LegalNoticesCompanyShareCapital';
    }
    // TODO: Make environment variable management cross-platform
    return process.env.DELMONICOS_LEGAL_NOTICES_COMPANY_SHARE_CAPITAL;
  }

  getLegalNoticesCompanyRcs(): string {
    // TODO: Make environment variable management cross-platform
    if (!process.env.DELMONICOS_LEGAL_NOTICES_COMPANY_RCS) {
      return 'LegalNoticesCompanyRcs';
    }
    // TODO: Make environment variable management cross-platform
    return process.env.DELMONICOS_LEGAL_NOTICES_COMPANY_RCS;
  }

  getLegalNoticesCompanyTva(): string {
    // TODO: Make environment variable management cross-platform
    if (!process.env.DELMONICOS_LEGAL_NOTICES_COMPANY_TVA) {
      return 'LegalNoticesCompanyTva';
    }
    // TODO: Make environment variable management cross-platform
    return process.env.DELMONICOS_LEGAL_NOTICES_COMPANY_TVA;
  }

  getLegalNoticesCompanyHeadquarters(): string {
    // TODO: Make environment variable management cross-platform
    if (!process.env.DELMONICOS_LEGAL_NOTICES_COMPANY_HEADQUARTERS) {
      return 'LegalNoticesCompanyHeadquarters';
    }
    // TODO: Make environment variable management cross-platform
    return process.env.DELMONICOS_LEGAL_NOTICES_COMPANY_HEADQUARTERS;
  }

  getLegalNoticesHostingName(): string {
    // TODO: Make environment variable management cross-platform
    if (!process.env.DELMONICOS_LEGAL_NOTICES_HOSTING_NAME) {
      return 'LegalNoticesHostingName';
    }
    // TODO: Make environment variable management cross-platform
    return process.env.DELMONICOS_LEGAL_NOTICES_HOSTING_NAME;
  }

  getLegalNoticesHostingHeadquarters(): string {
    // TODO: Make environment variable management cross-platform
    if (!process.env.DELMONICOS_LEGAL_NOTICES_HOSTING_HEADQUARTERS) {
      return 'LegalNoticesHostingHeadquarters';
    }
    // TODO: Make environment variable management cross-platform
    return process.env.DELMONICOS_LEGAL_NOTICES_HOSTING_HEADQUARTERS;
  }
}

export const configurationService = ConfigurationService.getInstance();
