import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { ACCOUNT_PATH } from '@app/config/app-paths.const';
import { SubscriptionDetailsModel } from '@app/core/model/subscription.model';
import { useTranslation } from 'react-i18next';
import { Button } from '@app/shared/button/button';
import { Text } from '@app/shared/typography/text/text';
import { Link } from '@app/shared/typography/link/link';
import { Icon } from '@app/shared/icon/icon';
import { UserSubscriptionList } from './component/user-subscription-list/user-subscription-list';
import { ManageUserSubscriptionButton } from './component/manage-user-subscription/manage-user-subscription.button';

import css from './user-subscription.module.scss';

interface UserSubscriptionViewProps {
  isEmailAddressVerified?: boolean;
  onVerifyEmailAddress: () => void;
  subscriptionList?: SubscriptionDetailsModel[];
  isLoading?: boolean;
  isSyncing?: boolean;
  onSync: () => void;
}

export function UserSubscriptionView(
  props: UserSubscriptionViewProps
): JSX.Element {
  const {
    isEmailAddressVerified = false,
    subscriptionList = [],
    isLoading = false,
    isSyncing = false,
    onVerifyEmailAddress,
    onSync,
  } = props;
  const { t } = useTranslation();

  return (
    <PageLayout withHomeButton>
      <PageLayout.Title
        title={t('account.subscription-label')}
        icon="calendar-check-01"
        backTo={ACCOUNT_PATH}
      />

      <PageLayout.Body>
        <UserSubscriptionList
          subscriptionList={subscriptionList}
          isLoading={isLoading}
        />

        <ManageUserSubscriptionButton
          numberOfSubscriptions={subscriptionList.length}
        />
      </PageLayout.Body>

      <PageLayout.Footer>
        {isEmailAddressVerified ? (
          <Button variant="outlined" onClick={onSync} isLoading={isSyncing}>
            <Icon name="refresh-cw-05" />
            {t('subscription.refresh-button')}
          </Button>
        ) : (
          <Text className={css.emailNotValidated}>
            {t('subscription.email-not-validated.label')}
            <Link
              onClick={onVerifyEmailAddress}
              className={css.emailNotValidatedLink}>
              {t('subscription.email-not-validated.validate-link')}
            </Link>
          </Text>
        )}
      </PageLayout.Footer>
    </PageLayout>
  );
}
