import { useCallback, useState } from 'react';

import { ApiError } from '@app/core/error/api-error';
import { catchApiError } from '@app/core/error/catch-api-error';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { downloadFile } from '@app/core/helper/download-file';
import { fromJsonToBlob } from '@app/core/helper/from-json-to-blob';
import { userService } from '@app/core/service/user.service';
import { useTranslation } from 'react-i18next';
import { Link } from '@app/shared/typography/link/link';
import { Icon } from '@app/shared/icon/icon';
import { Spinner } from '@app/shared/spinner/spinner';

interface DownloadPersonalInformationControllerProps {
  pseudo: string;
}

export const DownloadPersonalInformationController = (
  props: DownloadPersonalInformationControllerProps
): JSX.Element => {
  const { pseudo } = props;
  const { t } = useTranslation();

  const [pending, setPending] = useState(false);

  const handleDownload = useCallback(async () => {
    if (pending) {
      return;
    }

    setPending(true);
    try {
      const [promise] = userService.getGPDR();
      const data = await promise;
      const today = new Date();

      downloadFile(
        fromJsonToBlob(data),
        `${pseudo}-${today.toISOString()}.json`
      );
    } catch (error) {
      catchApiError(error, (apiError: ApiError) => {
        showErrorMessage('download-personal-data', apiError.code);
      });
    } finally {
      setPending(false);
    }
  }, [pseudo, pending]);

  return (
    <div>
      <Link
        color="brand-primary"
        onClick={handleDownload}
        startIcon={<Icon name="download-01" />}>
        {t('legal-text.download-personal-data-link')}
      </Link>
      {pending && <Spinner />}
    </div>
  );
};
