import { AbortablePromise } from '@app/core/helper/abort-promise';
import { httpClientService } from '@app/core/client/http/http-client.service';
import {
  PROVIDERS_CONFIG_API,
  CREATE_TRANSACTION_FROM_I_PAYMENT_SOURCE_ID_API,
  CREATE_TRANSACTION_FROM_PROVIDER_API,
  CREATE_TRANSACTION_FROM_TOKEN_API,
} from '@app/config/api-routes.const';
import {
  TransactionFromIPaymentSourceIdRequestModel,
  TransactionFromProviderCreationRequestModel,
  TransactionFromTokenCreationRequestModel,
} from '@app/core/model/payment-transaction.model';
import { PaymentWidgetsConfigurationResponseModel } from '@app/core/model/payment-widgets-configuration-response.model';
import { RedirectUrlResponseModel } from '@app/core/model/redirect-url-response.model';

class PaymentTransactionService {
  getConfiguration(): AbortablePromise<PaymentWidgetsConfigurationResponseModel> {
    return httpClientService.run<PaymentWidgetsConfigurationResponseModel>(
      PROVIDERS_CONFIG_API,
      { method: 'GET' }
    );
  }

  createTransactionFromIPaymentSourceId(
    data: TransactionFromIPaymentSourceIdRequestModel
  ): AbortablePromise<RedirectUrlResponseModel> {
    return httpClientService.post<RedirectUrlResponseModel>(
      CREATE_TRANSACTION_FROM_I_PAYMENT_SOURCE_ID_API,
      data
    );
  }

  createTransactionFromProvider(
    data: TransactionFromProviderCreationRequestModel
  ): AbortablePromise<RedirectUrlResponseModel> {
    return httpClientService.post<RedirectUrlResponseModel>(
      CREATE_TRANSACTION_FROM_PROVIDER_API,
      data
    );
  }

  createTransactionFromToken(
    data: TransactionFromTokenCreationRequestModel
  ): AbortablePromise<RedirectUrlResponseModel> {
    return httpClientService.post<RedirectUrlResponseModel>(
      CREATE_TRANSACTION_FROM_TOKEN_API,
      data
    );
  }
}

export const paymentTransactionService = new PaymentTransactionService();
