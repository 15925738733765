import { useCallback, useEffect, useState } from 'react';

import { catchApiError } from '@app/core/error/catch-api-error';
import { paymentMethodService } from '@app/core/service/payment-method.service';
import { PaymentMethodModel } from '@app/core/model/payment-method.model';

interface LoadUserPaymentMethodHookReturn {
  isError: boolean;
  loading: boolean;
  listUserPaymentMethod: PaymentMethodModel[];
  onRefresh: () => void;
}

export const useLoadUserPaymentMethod = (): LoadUserPaymentMethodHookReturn => {
  const [listUserPaymentMethod, setListUserPaymentMethod] = useState<
    PaymentMethodModel[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleLoadData = useCallback(() => {
    setLoading(true);
    setIsError(false);
    const [listPromise, abortPromise] = paymentMethodService.list();

    listPromise
      .then(setListUserPaymentMethod)
      .catch((error) => {
        catchApiError(error, () => {
          setIsError(true);
        });
      })
      .finally(() => setLoading(false));

    return () => {
      abortPromise.abort();
    };
  }, []);

  useEffect(handleLoadData, [handleLoadData]);

  return {
    isError,
    loading,
    listUserPaymentMethod,
    onRefresh: handleLoadData,
  };
};
