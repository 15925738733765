import { useEffect } from 'react';
import { WebsocketClientService } from './websocket-client.service';

export const useWebsocket = <T>(
  topic: string,
  callback: (payload: T) => void
): void => {
  useEffect(() => {
    WebsocketClientService.getInstance().on<T>(topic, callback);

    return () => {
      WebsocketClientService.getInstance().off(topic);
    };
  }, [topic, callback]);
};
