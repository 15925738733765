import { LearnMoreLink } from '@app/feature/faq/component/learn-more-link/learn-more-link';
import { ListItemMenu } from '@app/shared/list-item-menu/list-item-menu';
import { WebauthnAuthenticatorModel } from '@app/core/model/authenticator.model';

import { AuthenticatorItem } from './component/authenticator-item/authenticator.item';
import { CreateAuthenticator } from './component/create-authenticator/create-authenticator';

interface ManageWebauthnAuthenticationViewProps {
  isWebauthnEnabled: boolean;
  authenticators: WebauthnAuthenticatorModel[];
  isWebauthnSupported: boolean;
  onRenameAuthenticator: (authenticator: WebauthnAuthenticatorModel) => void;
  onCreateAuthenticator: (authenticator: WebauthnAuthenticatorModel) => void;
}

export const ManageWebauthnAuthenticationView = (
  props: ManageWebauthnAuthenticationViewProps
): JSX.Element => {
  const {
    isWebauthnEnabled,
    authenticators,
    isWebauthnSupported,
    onRenameAuthenticator,
    onCreateAuthenticator,
  } = props;

  return (
    <ListItemMenu>
      {authenticators.map((authenticator, index) => (
        <AuthenticatorItem
          index={index + 1}
          key={authenticator.credentialId}
          authenticator={authenticator}
          onRename={onRenameAuthenticator}
        />
      ))}

      {!isWebauthnEnabled &&
        (isWebauthnSupported ? (
          <CreateAuthenticator onCreate={onCreateAuthenticator} />
        ) : (
          <LearnMoreLink questionId="support-for-webauthn" />
        ))}
    </ListItemMenu>
  );
};
