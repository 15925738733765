export interface ChargingEventResponseModel {
  updatedAt?: string;
  startedAt?: string;
  endedAt?: string;
  totalHT?: number;
  totalTTC?: number;
  totalTime?: number;
  totalKwh?: number;
  transactionId: string | null;
}

export class ChargingEventModel {
  updatedAt: string;

  startedAt?: string;

  endedAt?: string;

  totalHT: number;

  totalTTC: number;

  totalTime: number;

  totalKwh: number;

  duration: number;

  transactionId: string | null;

  constructor(data: ChargingEventResponseModel) {
    this.totalHT = data.totalHT ?? 0;
    this.totalTTC = data.totalTTC ?? 0;
    this.totalTime = data.totalTime ?? 0;
    this.totalKwh = data.totalKwh ?? 0;
    this.transactionId = data.transactionId ?? null;
    this.updatedAt = data.updatedAt ?? new Date().toISOString();
    this.startedAt = data.startedAt;
    this.endedAt = data.endedAt;

    this.duration =
      this.startedAt && this.updatedAt
        ? (new Date(this.updatedAt).getTime() -
            new Date(this.startedAt).getTime()) /
          1000
        : 0;
  }
}
