import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';
import { useTranslation } from 'react-i18next';

import css from './current-tariff-simple.module.scss';
import { PriceSquare } from '../../../tariff/component/price-square/price-square';

type Props = {
  pricePerKwh?: number;
  pricePerMin?: number;
  flat?: number;
};

export function CurrentTariffSimple(props: Props): JSX.Element | null {
  const { pricePerKwh = 0, pricePerMin = 0, flat = 0 } = props;

  const { t } = useTranslation();

  if (pricePerKwh <= 0 && pricePerMin <= 0) {
    return null;
  }

  return (
    <>
      <PriceSquare pricePerKwh={pricePerKwh} pricePerMin={pricePerMin} />

      {flat > 0 && (
        <div className={css.flatContainer}>
          <div className={css.stack}>
            <Icon name="home-02" />
            <Text className={css.flex1}>
              {t('shared.invoice.tariff.flat.label')}
            </Text>
            <Text>{t('shared.invoice.tariff.flat.value', { flat })}</Text>
          </div>
        </div>
      )}
    </>
  );
}
