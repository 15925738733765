import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { H1, H3 } from '@app/shared/typography/heading/heading';
import { Link } from '@app/shared/typography/link/link';
import { CreditCardInformation } from '@app/shared/credit-card-information/credit-card-information';
import { OrderModel } from '@app/core/model/order.model';
import { LearnMoreLink } from '@app/feature/faq/component/learn-more-link/learn-more-link';
import { Text } from '@app/shared/typography/text/text';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { ChargePointModel } from '@app/core/model/charge-point.model';
import { Icon } from '@app/shared/icon/icon';
import { TariffModel } from '@app/core/model/tariff.model';
import { LEGAL_TEXT_PATH, QUOTE_PATH } from '@app/config/app-paths.const';
import { useNavigate } from 'react-router-dom';
import { ChargePointName } from '@app/shared/charge-point/charge-point-name/charge-point-name';

import css from './quote.module.scss';
import { Details } from './component/details/details';

export interface QuoteViewProps {
  tariff?: TariffModel;
  isTariffComplex?: boolean;
  chargePoint?: ChargePointModel | null;
  isPending: boolean;
  isPrecaptureAccepted: boolean;
  order: OrderModel;
  onValidate: () => void;
  onOpenPaymentSelection: () => void;
  onBack: () => void;
}

export function QuoteView({
  tariff,
  isTariffComplex = false,
  chargePoint,
  isPending,
  order,
  isPrecaptureAccepted,
  onValidate,
  onOpenPaymentSelection,
  onBack,
}: QuoteViewProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageLayout withHomeButton>
      <PageLayout.Header>
        <Icon name="chevron-left" onClick={onBack} />
        <H1>{t('quote.title')}</H1>
      </PageLayout.Header>

      <PageLayout.Body>
        <ChargePointName
          name={chargePoint?.name}
          physicalReference={chargePoint?.specifications.physicalReference}
        />
        <Details
          isPrecaptureAccepted={isPrecaptureAccepted}
          power={chargePoint?.specifications?.power}
          powerType={chargePoint?.specifications?.powerType}
          connectorType={chargePoint?.specifications?.connectorType}
          tariff={tariff}
          isTariffComplex={isTariffComplex}
          vatRate={order.cost.vatRate}
        />

        {order?.paymentInfos && (
          <div>
            <div className={css.paymentTitle}>
              <H3>{t('quote.credit-card-section.title')}</H3>
              <Link
                onClick={onOpenPaymentSelection}
                startIcon={<Icon name="edit-05" />}>
                {t('quote.credit-card-section.edit-payment-method')}
              </Link>
            </div>

            <CreditCardInformation {...order.paymentInfos} />
          </div>
        )}

        <Link
          startIcon={<Icon name="shield-tick" />}
          onClick={() =>
            navigate(LEGAL_TEXT_PATH, { state: { from: QUOTE_PATH } })
          }
          className={css.legalText}>
          {t('quote.legal-text')}
        </Link>
      </PageLayout.Body>

      {chargePoint?.specifications && order && (
        <PageLayout.Footer>
          {!chargePoint?.isAvailable && (
            <Text color="error">
              {t('quote.charge-point-unavailable')}{' '}
              <LearnMoreLink questionId="charge-point-unavailable" />
            </Text>
          )}
          <Button
            disabled={!chargePoint?.isAvailable}
            isLoading={isPending}
            onClick={
              isPrecaptureAccepted ? onValidate : onOpenPaymentSelection
            }>
            {isPrecaptureAccepted
              ? t('quote.validate-button')
              : t('quote.select-payment-method')}
          </Button>
        </PageLayout.Footer>
      )}
    </PageLayout>
  );
}
