import { useCallback } from 'react';

import { showErrorMessage } from '@app/core/error/show-error-message';
import { ApiError } from '@app/core/error/api-error';
import { catchApiError } from '@app/core/error/catch-api-error';
import { PaymentTypeTokenEnum } from '@app/core/model/enum/payment-type-token.enum';
import { RedirectUrlResponseModel } from '@app/core/model/redirect-url-response.model';
import { mapCheckoutErrorToTranslationKey } from '@app/core/helper/map-checkout-error-to-translation-key';
import { paymentTransactionService } from '@app/core/service/payment-transaction.service';

import { SelectPaymentProps } from '../../select-payment.props';
import { GooglePayView } from './google-pay.view';

export const GooglePayController = ({
  onSubmit,
}: SelectPaymentProps): JSX.Element => {
  const handleToken = useCallback(
    async (token: string) => {
      const [createTransactionPromise] =
        paymentTransactionService.createTransactionFromProvider({
          data: token,
          dataType: PaymentTypeTokenEnum.GOOGLE,
        });

      try {
        const response: RedirectUrlResponseModel =
          await createTransactionPromise;
        onSubmit(response);
      } catch (error) {
        catchApiError(error, (apiError: ApiError) => {
          const translationKey = mapCheckoutErrorToTranslationKey(
            apiError.code
          );
          showErrorMessage(translationKey, apiError.code);
        });
      }
    },
    [onSubmit]
  );

  return <GooglePayView onToken={handleToken} />;
};
