import { useTranslation } from 'react-i18next';

import { Text } from '@app/shared/typography/text/text';
import { PricingModel } from '@app/core/model/charge-point.model';

import css from './invoice-pricing.module.scss';

export function InvoicePricing(props: PricingModel): JSX.Element {
  const { t } = useTranslation();

  const { flatTTC = 0, perKwhTTC = 0, perTimeChargingHourTTC = 0 } = props;

  return (
    <div className={css.container}>
      {flatTTC > 0 && (
        <div className={css.item}>
          <Text fontSize="large">{t('shared.invoice.tariff.flat.label')}</Text>
          <Text fontSize="large" bold>
            {t('shared.invoice.tariff.flat.value', {
              flat: flatTTC,
            })}
          </Text>
        </div>
      )}

      {perKwhTTC > 0 && (
        <div className={css.item}>
          <Text fontSize="large">
            {t('shared.invoice.tariff.per-kwh.label')}
          </Text>
          <Text fontSize="large" bold>
            {t('shared.invoice.tariff.per-kwh.value', {
              perKwh: perKwhTTC,
            })}
          </Text>
        </div>
      )}

      {perTimeChargingHourTTC > 0 && (
        <div className={css.item}>
          <Text fontSize="large">
            {t('shared.invoice.tariff.per-min.label')}
          </Text>
          <Text fontSize="large" bold>
            {t('shared.invoice.tariff.per-min.value', {
              perMin: perTimeChargingHourTTC / 60,
            })}
          </Text>
        </div>
      )}
    </div>
  );
}
