import { CableToChargePointStep } from '../step/cable-to-charge-point-step/cable-to-charge-point-step';
import { CableToVehicleStep } from '../step/cable-to-vehicle-step/cable-to-vehicle-step';
import { ViewTheChargeOnYourSmartphoneStep } from '../step/follow-charge-on-smartphone-step/view-the-charge-on-your-smartphone-step';
import { StepComponent } from '../step/step.props';

export enum StartChargingCarouselTypeEnum {
  ac = 'ac',
  dc = 'dc',
}

export const StartChargingCarouselConfig: Record<
  StartChargingCarouselTypeEnum,
  StepComponent[]
> = {
  [StartChargingCarouselTypeEnum.ac]: [
    CableToVehicleStep,
    ViewTheChargeOnYourSmartphoneStep,
  ],
  [StartChargingCarouselTypeEnum.dc]: [
    CableToVehicleStep,
    CableToChargePointStep,
    ViewTheChargeOnYourSmartphoneStep,
  ],
};
