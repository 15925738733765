import { io, Socket } from 'socket.io-client';

import { WS_TOKEN_SSK } from '@app/config/sessionstorage-keys.const';
import { Logger } from '@app/core/logger/logger';
import { configurationService } from '@app/core/configuration/configuration.service';
import { removeInSessionStorage } from '@app/core/storage/session-storage';

import { getSocketAuth } from './get-socket-auth';

interface GetOneConfig {
  timeout: number;
}

type SocketHookCallback = (topic: string, realSocket: Socket) => Socket;

export class WebsocketClientService {
  private static instance: WebsocketClientService;

  socket: Socket;

  socketHook: SocketHookCallback;

  private constructor() {
    const options = getSocketAuth();
    this.socket = io(configurationService.getWebsocket(), {
      transports: ['websocket'],
      auth: options,
    });

    this.socketHook = () => this.socket;

    this.socket.on('connect', () => {
      Logger.debug('Websocket is connected');
    });

    this.socket.on('disconnect', (reason) => {
      Logger.debug('Websocket is disconnected: ', reason);
    });
  }

  static getInstance(): WebsocketClientService {
    if (!WebsocketClientService.instance) {
      WebsocketClientService.instance = new WebsocketClientService();
    }

    return WebsocketClientService.instance;
  }

  getSocket(topic: string): Socket {
    return this.socketHook(topic, this.socket);
  }

  onConnect(callback: () => void): void {
    if (this.socket.connected) {
      callback();
    }

    this.socket.on('connect', callback);
  }

  reset(): void {
    removeInSessionStorage(WS_TOKEN_SSK);
    this.socket.disconnect();
    this.socket.auth = getSocketAuth();
    this.socket.connect();
  }

  overrideSocketHook(callback: SocketHookCallback): void {
    this.socketHook = callback;
  }

  getOne<T>(topic: string, config: GetOneConfig): Promise<T> {
    const socket = this.getSocket(topic);

    return new Promise((resolve, reject) => {
      const rejectAfterXSeconds = setTimeout(() => {
        socket.off(topic);
        reject(new Error('Websocket timeout'));
      }, config.timeout * 1000);

      socket.on(topic, (payload: T) => {
        clearTimeout(rejectAfterXSeconds);
        socket.off(topic);

        resolve(payload);
      });
    });
  }

  emit(topic: string, payload: unknown, callback?: (data: any) => void): void {
    this.getSocket(topic).emit(topic, payload, callback);
  }

  on<T>(topic: string, callback: (data: T) => void): void {
    this.getSocket(topic).on(topic, (data: T): void => {
      Logger.debug('WS event received on', topic, data);

      callback(data);
    });
  }

  off(topic: string): void {
    this.getSocket(topic).off(topic);
  }
}
