import classNames from 'classnames';
import css from './logo.module.scss';

export interface LogoProps {
  light?: boolean;
}

export const Logo = ({ light = false }: LogoProps): JSX.Element => (
  <div className={classNames(css.logo, light && css.logoLight)} />
);
