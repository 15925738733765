import {
  AUTHENTICATOR_MAX_LENGTH,
  AUTHENTICATOR_MIN_LENGTH,
} from '@app/config/form-validation-rules.const';
import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface RenameAuthenticatorForm {
  name: string;
}

export function useRenameAuthenticatorFormValidation(): Record<
  keyof RenameAuthenticatorForm,
  RegisterOptions
> {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      name: {
        required: {
          value: true,
          message: t(
            'manage-authentication-method.webauthn.rename-authenticator.name.required'
          ),
        },
        minLength: {
          value: AUTHENTICATOR_MIN_LENGTH,
          message: t(
            'manage-authentication-method.webauthn.rename-authenticator.name.min-length',
            { minLength: AUTHENTICATOR_MIN_LENGTH }
          ),
        },
        maxLength: {
          value: AUTHENTICATOR_MAX_LENGTH,
          message: t(
            'manage-authentication-method.webauthn.rename-authenticator.name.max-length',
            { maxLength: AUTHENTICATOR_MAX_LENGTH }
          ),
        },
      },
    }),
    [t]
  );
}
