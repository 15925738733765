import { useCallback } from 'react';

import { ApiErrorView } from './api-error.view';

export const ApiErrorController = (): JSX.Element => {
  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  const backNavigate = useCallback(() => {
    // refresh and go back
    window.history.back();
  }, []);

  return <ApiErrorView onRefresh={refreshPage} onBack={backNavigate} />;
};
