import { configurationService } from '@app/core/configuration/configuration.service';

export const TIMEOUT = {
  default: configurationService.getWsDefaultTimeout(),
  registration: 45,
  preCaptureSyncInterval: 3,
  preCaptureSyncFail: 20,
  SPINNER: {
    customMessage: 4,
    default: 14,
    charge: 40,
    webauthn: 40,
  },
};

export type SpinnerTimeout = keyof typeof TIMEOUT.SPINNER;
