import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { Dialog, DialogProps } from '@app/shared/dialog/dialog';
import { Icon } from '@app/shared/icon/icon';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';
import { H1 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';

import css from './clear-storage-dialog.module.scss';

interface ClearStorageDialogProps extends DialogProps {
  isAuthenticated: boolean;
  pseudo?: string;
  emailAddress?: string;
  onCopyPseudo: () => void;
  onClear: () => void;
}

export const ClearStorageDialog = (
  props: ClearStorageDialogProps
): JSX.Element => {
  const {
    isAuthenticated,
    pseudo,
    emailAddress,
    onCopyPseudo,
    onClear,
    onClose,
    ...dialogProps
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog {...dialogProps} onClose={onClose}>
      <DialogLayout>
        <DialogLayout.Body className={css.body}>
          <Icon name="trash-01" />

          <H1>{t('legal-text.clear-storage.title')}</H1>

          <Text>{t('legal-text.clear-storage.clear-auth-credentials')}</Text>
        </DialogLayout.Body>

        <DialogLayout.Footer>
          <Button variant="outlined" onClick={onClose}>
            {t('legal-text.clear-storage.cancel')}
          </Button>
          <Button onClick={onClear}>
            {t('legal-text.clear-storage.confirm')}
          </Button>
        </DialogLayout.Footer>
      </DialogLayout>
    </Dialog>
  );
};
