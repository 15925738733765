import { Text } from '@app/shared/typography/text/text';
import { PowerTypeEnum } from '@app/core/model/enum/power-type.enum';
import classNames from 'classnames';

import css from './power-type.module.scss';
import { useChargePointPowerType } from './use-charge-point-power-type';
import { PowerTypeIcon } from './power-type.icon';

type Props = {
  powerType?: PowerTypeEnum;
  className?: string;
};

export function PowerType(props: Props): JSX.Element {
  const { powerType, className } = props;
  const powerTypeText = useChargePointPowerType(powerType);

  return (
    <div className={classNames(css.container, className)}>
      <PowerTypeIcon powerType={powerType} />
      <Text>{powerTypeText}</Text>
    </div>
  );
}
