import { useTimeout } from '@app/core/helper/use-timeout';
import { useEffect, useState } from 'react';

interface RemainingLockTimeHookReturn {
  cooldownLockTimeInSec: number;
  setRemainingLockTimeInSec: (value: number) => void;
  setCooldownLockTimeInSec: (value: number) => void;
}

export function useRemainingLockTime(): RemainingLockTimeHookReturn {
  const [remainingLockTimeInSec, setRemainingLockTimeInSec] = useState(0);
  const [cooldownLockTimeInSec, setCooldownLockTimeInSec] = useState(0);
  useTimeout(() => setRemainingLockTimeInSec(0), remainingLockTimeInSec * 1000);

  useEffect(() => {
    const startTime = Date.now();

    const intervalId = setInterval(() => {
      const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
      const timeLeft = remainingLockTimeInSec - elapsedTime;

      if (timeLeft <= 0) {
        setCooldownLockTimeInSec(0);
        clearInterval(intervalId);
      } else {
        setCooldownLockTimeInSec(timeLeft);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [remainingLockTimeInSec]);

  return {
    setRemainingLockTimeInSec,
    setCooldownLockTimeInSec,
    cooldownLockTimeInSec,
  };
}
