import { DASHBOARD_PATH, HOME_PATH } from '@app/config/app-paths.const';
import { useAuthentication } from '@app/core/context-providers/authentication-context/use-authentication';
import { Logger } from '@app/core/logger/logger';
import { PageNotFoundView } from '@app/feature/404-page-not-found/page-not-found.view';
import { useLocation, useNavigate } from 'react-router-dom';

export const PageNotFoundController = (): JSX.Element => {
  const location = useLocation();
  Logger.error(`Router error : Page "${location.pathname} not found`);

  const navigate = useNavigate();
  const { isAuthenticated } = useAuthentication();

  const handleGoToHome = () => {
    if (isAuthenticated) {
      return navigate(DASHBOARD_PATH);
    }

    return navigate(HOME_PATH);
  };

  return <PageNotFoundView onHome={handleGoToHome} />;
};
