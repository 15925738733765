export const loadScript = (src: string): Promise<() => void> => {
  return new Promise<() => void>((resolve, reject) => {
    const script = document.createElement('script');

    script.src = src;
    script.async = true;

    const onScriptLoad = (): void => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resolve(cleanUp);
    };

    const onScriptError = (): void => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      cleanUp();
      reject(new Error(`Unable to load : ${src}`));
    };

    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);

    document.body.appendChild(script);

    const cleanUp = (): void => {
      script.removeEventListener('load', onScriptLoad);
      script.removeEventListener('error', onScriptError);
      script.remove();
    };
  });
};
