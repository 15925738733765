import { SubscriptionDetailsModel } from '@app/core/model/subscription.model';
import { Text } from '@app/shared/typography/text/text';
import { useTranslation } from 'react-i18next';
import { ListItemMenu } from '@app/shared/list-item-menu/list-item-menu';
import { Spinner } from '@app/shared/spinner/spinner';
import { UserSubscriptionItem } from './user-subscription-item';

interface UserSubscriptionListProps {
  subscriptionList?: SubscriptionDetailsModel[];
  isLoading?: boolean;
}

export function UserSubscriptionList(
  props: UserSubscriptionListProps
): JSX.Element {
  const { subscriptionList = [], isLoading = false } = props;
  const { t } = useTranslation();

  if (isLoading) {
    return <Spinner />;
  }

  if (subscriptionList.length === 0) {
    return <Text>{t('subscription.empty-list-label')}</Text>;
  }

  return (
    <ListItemMenu>
      <Text fontSize="small" color="secondary">
        {t('subscription.list-label', { count: subscriptionList.length })}
      </Text>

      {subscriptionList.map((subscriptionItem: SubscriptionDetailsModel) => (
        <UserSubscriptionItem
          key={subscriptionItem.id}
          subscription={subscriptionItem}
        />
      ))}
    </ListItemMenu>
  );
}
