import { Text } from '@app/shared/typography/text/text';
import css from './invoice-section-title.module.scss';

interface InvoiceSectionTitleProps {
  children: string;
  numberOfItems: number;
}

export const InvoiceSectionTitle = (
  props: InvoiceSectionTitleProps
): JSX.Element => {
  const { children, numberOfItems } = props;

  return (
    <Text
      bold
      fontSize="large"
      color={numberOfItems === 0 ? 'tertiary' : 'inherit'}>
      {children} <span className={css.numberOfItems}>{numberOfItems}</span>
    </Text>
  );
};
