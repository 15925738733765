import { useTranslation } from 'react-i18next';

import { configurationService } from '@app/core/configuration/configuration.service';

import { Link, LinkProps } from '../typography/link/link';

const DELMONICOS_SUPPORT_PHONE_NUMBER =
  configurationService.getSupportPhoneNumber();

interface CallSupportLinkProps extends Omit<LinkProps, 'href' | 'onClick'> {}

export const CallSupportLink = ({
  children,
  ...linkProps
}: CallSupportLinkProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <a href={`tel:${DELMONICOS_SUPPORT_PHONE_NUMBER}`}>
      <Link {...linkProps}>
        {children ??
          t('shared.call-support.label', {
            phoneNumber: DELMONICOS_SUPPORT_PHONE_NUMBER,
          })}
      </Link>
    </a>
  );
};
