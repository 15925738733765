import {
  CREATE_ORDER_API,
  GET_LATEST_ORDER_API,
  GET_LATEST_ORDER_WITH_COMPLETED_API,
  PRE_CAPTURE_SYNC_API,
  VALIDATE_ORDER_QUOTE_API,
  VALIDATE_ORDER_QUOTE_WITHOUT_PAYMENT_API,
} from '@app/config/api-routes.const';
import { AbortablePromise } from '@app/core/helper/abort-promise';
import { httpClientService } from '@app/core/client/http/http-client.service';
import { OrderResponseModel } from '@app/core/model/order-response.model';
import { OrderModel } from '@app/core/model/order.model';

class OrderService {
  getLatest(): AbortablePromise<OrderModel> {
    const [promise, abort] = httpClientService.run<OrderResponseModel>(
      GET_LATEST_ORDER_API,
      {
        method: 'GET',
      }
    );

    return [promise.then((response) => new OrderModel(response)), abort];
  }

  getLatestWithCompleted(): AbortablePromise<OrderModel> {
    const [promise, abort] = httpClientService.run<OrderResponseModel>(
      GET_LATEST_ORDER_WITH_COMPLETED_API,
      {
        method: 'GET',
        params: {
          withCompleted: true,
        },
      }
    );

    return [promise.then((response) => new OrderModel(response)), abort];
  }

  create(chargePointId: string): AbortablePromise<OrderModel> {
    const [promise, abort] = httpClientService.post<OrderResponseModel>(
      CREATE_ORDER_API,
      {
        chargePointId,
      }
    );

    const returnedPromise = promise.then((response): OrderModel => {
      return new OrderModel(response);
    });

    return [returnedPromise, abort];
  }

  validateWithoutPayment(): AbortablePromise<OrderModel> {
    const [promise, abort] = httpClientService.run<OrderResponseModel>(
      VALIDATE_ORDER_QUOTE_WITHOUT_PAYMENT_API
    );

    return [promise.then((response) => new OrderModel(response)), abort];
  }

  syncPrecapture(): AbortablePromise<void> {
    return httpClientService.run(PRE_CAPTURE_SYNC_API);
  }

  validate(): AbortablePromise<OrderModel> {
    const [promise, abort] = httpClientService.run<OrderResponseModel>(
      VALIDATE_ORDER_QUOTE_API
    );

    return [promise.then((response) => new OrderModel(response)), abort];
  }
}

export const orderService = new OrderService();
