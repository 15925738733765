import { Collapsible } from '@app/shared/collapsible/collapsible';
import { Icon } from '@app/shared/icon/icon';
import { H3 } from '@app/shared/typography/heading/heading';
import { useState } from 'react';

import classNames from 'classnames';
import css from './faq-question.module.scss';

type FaqQuestionProps = {
  question: string;
  answers: string[];
};

export const FaqQuestion = ({
  question,
  answers,
}: FaqQuestionProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classNames([css.question, isOpen && css.open])}>
      <button
        className={css.title}
        onClick={() => setIsOpen((value) => !value)}>
        <H3>{question}</H3>

        <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />
      </button>
      <Collapsible isOpen={isOpen}>
        <div className={css.answers}>
          {answers.map((answer) => (
            <div key={answer}>{answer}</div>
          ))}
        </div>
      </Collapsible>
    </div>
  );
};
