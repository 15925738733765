import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ChargePointBeingInstalledSvg from '@app/asset/charge-point-being-installed.svg';
import { SCAN_PATH } from '@app/config/app-paths.const';
import { LearnMoreLink } from '@app/feature/faq/component/learn-more-link/learn-more-link';
import { Button } from '@app/shared/button/button';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { H1 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';

import { CallSupportButton } from '@app/shared/call-support/call-support-button';
import { Icon } from '@app/shared/icon/icon';
import css from './charge-point-not-found.module.scss';

export interface ChargePointNotFoundViewProps {
  isBeingInstalled: boolean;
}

export const ChargePointNotFoundView = ({
  isBeingInstalled,
}: ChargePointNotFoundViewProps): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <PageLayout withHomeButton>
      <PageLayout.Header>
        <Icon name="qr-code-02" />
        <H1>{t('scan.title')}</H1>
      </PageLayout.Header>

      <PageLayout.Body className={css.body}>
        <div className={css.main}>
          <ChargePointBeingInstalledSvg />
          <Text>
            {t(
              `charge-point.${
                isBeingInstalled ? 'being-installed' : 'not-found'
              }.p1`
            )}
          </Text>
          <Text>
            {t(
              `charge-point.${
                isBeingInstalled ? 'being-installed' : 'not-found'
              }.p2`
            )}
          </Text>
        </div>

        <Button onClick={() => navigate(SCAN_PATH)}>
          {t('charge-point.not-found.retry')}
        </Button>

        <div className={css.help}>
          <Text color="secondary">{t('charge-point.not-found.read-faq')}</Text>
          <LearnMoreLink questionId="charge-point-not-found">
            {t('charge-point.not-found.question')}
          </LearnMoreLink>
        </div>
      </PageLayout.Body>

      <CallSupportButton />
      <PageLayout.Footer className={css.footer} />
    </PageLayout>
  );
};
