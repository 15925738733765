import { DownloadInvoiceButton } from '../download-invoice-button/download-invoice.button';
import { ProformaFooter } from './proforma.footer';

interface InvoiceFooterProps {
  proforma?: boolean;
  invoiceId?: string;
  createdAt: Date;
  pseudo: string;
  autoSendInvoiceByMail: boolean;
  isEmailAddressVerified: boolean;
}

export const InvoiceFooter = (props: InvoiceFooterProps): JSX.Element => {
  const {
    proforma = false,
    invoiceId,
    createdAt,
    pseudo,
    autoSendInvoiceByMail,
    isEmailAddressVerified,
  } = props;

  return !proforma && invoiceId ? (
    <DownloadInvoiceButton
      invoiceId={invoiceId}
      createdAt={createdAt}
      pseudo={pseudo}
    />
  ) : (
    <ProformaFooter
      autoSendInvoiceByMail={autoSendInvoiceByMail}
      isEmailAddressVerified={isEmailAddressVerified}
    />
  );
};
