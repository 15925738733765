import css from './animated-chevron.module.scss';

interface AnimatedChevronProps {
  className?: string;
}

export const AnimatedChevron = ({
  className,
}: AnimatedChevronProps): JSX.Element => (
  <svg
    width="24"
    height="48"
    viewBox="0 0 24 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}>
    <g opacity="0.2">
      <path
        className={css.chevron}
        d="M6 9L12 15L18 9"
        stroke="#51586B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g opacity="0.6">
      <path
        className={css.chevron}
        d="M6 21L12 27L18 21"
        stroke="#51586B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      className={css.chevron}
      d="M6 33L12 39L18 33"
      stroke="#51586B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
