import { Spinner } from '@app/shared/spinner/spinner';

import {
  PageLayout,
  PageLayoutProps,
} from '@app/shared/layout/page-layout/page-layout';

import css from './page-fallback.module.scss';

export const PageFallback = (pageProps: PageLayoutProps): JSX.Element => {
  return (
    <PageLayout {...pageProps}>
      <PageLayout.Body className={css.container}>
        <Spinner />
      </PageLayout.Body>
    </PageLayout>
  );
};
