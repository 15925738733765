import { useTranslation } from 'react-i18next';
import { UserPreference } from '../user-preference/user-preference';
import css from './all-notifications.module.scss';

interface AllNotificationProps {
  name: string;
  requireEmailVerified: boolean;
  isActive: boolean;
  handleChange: (value: boolean) => void;
  pending?: boolean;
}

export const AllNotification = ({
  name,
  requireEmailVerified,
  isActive,
  handleChange,
  pending,
}: AllNotificationProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={css.card}>
      {t('user-preference.all.label')} :
      <UserPreference
        requireEmailVerified={requireEmailVerified}
        label={t('user-preference.all.caption')}
        isActive={isActive}
        pending={pending}
        name={name}
        onChange={handleChange}
        icon="mail-02"
      />
    </div>
  );
};
