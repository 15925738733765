import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { DELMO_CHARGE_POINT_ID_LSK } from '@app/config/localstorage-keys.const';
import { getFromLocalStorage } from '@app/core/storage/local-storage';
import { AbortablePromise } from '@app/core/helper/abort-promise';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { ApiError } from '@app/core/error/api-error';
import { catchApiError } from '@app/core/error/catch-api-error';
import { OrderModel } from '@app/core/model/order.model';
import { orderService } from '@app/core/service/order.service';
import { OrderStatusEnum } from '@app/core/model/enum/order-status-enum';
import { HttpStatusEnum } from '@app/config/error-config';

import { IOrderContext, OrderContext } from './order-context';
import { useAuthentication } from '../authentication-context/use-authentication';

interface ChargeProviderProps {
  onReady: () => void;
  children: ReactNode;
}

export function OrderContextProvider({
  onReady,
  children,
}: ChargeProviderProps): JSX.Element {
  const [order, setOrder] = useState<OrderModel | null>();
  const { isAuthenticated } = useAuthentication();

  const hasDelmoChargePointId = useMemo(
    () => getFromLocalStorage(DELMO_CHARGE_POINT_ID_LSK) !== null,
    []
  );

  const loadData = useCallback((): AbortablePromise<void> => {
    const [orderLatestPromise, abortPromise] = orderService.getLatest();

    const returnedPromise = orderLatestPromise.then(setOrder).catch((error) => {
      catchApiError(error, (apiError: ApiError) => {
        if (apiError.status === HttpStatusEnum.NOT_FOUND) {
          // normal behaviour when user does not have an order
          setOrder(null);
          return;
        }

        showErrorMessage('get-order', apiError.code);
      });
    });

    return [returnedPromise, abortPromise];
  }, []);

  useEffect(() => {
    if (!hasDelmoChargePointId || !isAuthenticated) {
      onReady();
      return () => {};
    }

    const [promise, abortPromise] = loadData();

    promise.finally(onReady);

    return () => {
      abortPromise.abort();
    };
  }, [hasDelmoChargePointId, isAuthenticated, onReady, loadData]);

  const refresh = useCallback((): Promise<void> => {
    const [loadDataPromise] = loadData();

    return loadDataPromise;
  }, [loadData]);

  const value: IOrderContext = useMemo(() => {
    const isOrderValidated = Boolean(
      order?.status && order?.status >= OrderStatusEnum.QUOTE_VALIDATED
    );
    const isInvoice = Boolean(
      order?.status && order.status >= OrderStatusEnum.INVOICE_PROFORMA_CREATED
    );

    return {
      order,
      isOrderValidated,
      isInvoice,
      onOrder: setOrder,
      refresh,
    };
  }, [order, refresh]);

  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
}
