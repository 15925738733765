import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { Dialog, DialogProps } from '@app/shared/dialog/dialog';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';
import { H1 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';
import { AuthenticationMethodEnum } from '@app/core/model/enum/authentication-method.enum';

interface EnableBackUpAuthenticationDialogProps extends DialogProps {
  authenticationMethodToEnable: AuthenticationMethodEnum;
  onContinue: () => void;
}

export const EnableBackUpAuthenticationDialog = (
  props: EnableBackUpAuthenticationDialogProps
): JSX.Element => {
  const { authenticationMethodToEnable, onContinue, onClose, ...dialogProps } =
    props;
  const { t } = useTranslation();

  return (
    <Dialog onClose={onClose} {...dialogProps}>
      <DialogLayout>
        <DialogLayout.Body>
          <H1>{t('enable-back-up-authentication.title')}</H1>

          <Text>
            {t(
              `enable-back-up-authentication.${
                authenticationMethodToEnable ===
                AuthenticationMethodEnum.WEBAUTHN
                  ? 'webauthn'
                  : 'email'
              }`
            )}
          </Text>
        </DialogLayout.Body>

        <DialogLayout.Footer>
          <Button variant="outlined" onClick={onClose}>
            {t('enable-back-up-authentication.cancel-button')}
          </Button>

          <Button type="submit" onClick={onContinue}>
            {t('enable-back-up-authentication.continue-button')}
          </Button>
        </DialogLayout.Footer>
      </DialogLayout>
    </Dialog>
  );
};
