import { TariffElementModel } from '@app/core/model/tariff.model';

import { TariffListItem } from './tariff-list-item';

type Props = {
  elements: TariffElementModel[];
  withIcon?: boolean;
};

export function TariffList(props: Props): JSX.Element {
  const { elements, withIcon = true } = props;
  return (
    <>
      {elements.map((tariffElement) => (
        <TariffListItem
          key={`${tariffElement.priceComponents.perKwhTTC}-${
            tariffElement.priceComponents.perTimeChargingHourTTC ?? 0
          }-${tariffElement.priceComponents.flatTTC ?? 0}`}
          tariffElement={tariffElement}
          withIcon={withIcon}
        />
      ))}
    </>
  );
}
