import {
  FIRST_NAME_MAX_LENGTH,
  FIRST_NAME_MIN_LENGTH,
  LAST_NAME_MAX_LENGTH,
  LAST_NAME_MIN_LENGTH,
  NAME_PATTERN,
} from '@app/config/form-validation-rules.const';
import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface ManagePersonalInformationForm {
  firstName: string;
  lastName: string;
}

export function useManagePersonalInformationFormValidation(): Record<
  keyof ManagePersonalInformationForm,
  RegisterOptions
> {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      firstName: {
        required: {
          value: true,
          message: t('personal-information.errors.first-name.required'),
        },
        maxLength: {
          value: FIRST_NAME_MAX_LENGTH,
          message: t('personal-information.errors.first-name.max-length', {
            maxLength: FIRST_NAME_MAX_LENGTH,
          }),
        },

        minLength: {
          value: FIRST_NAME_MIN_LENGTH,
          message: t('personal-information.errors.first-name.min-length', {
            minLength: FIRST_NAME_MIN_LENGTH,
          }),
        },

        pattern: {
          value: NAME_PATTERN,
          message: t('personal-information.errors.first-name.pattern'),
        },
      },
      lastName: {
        required: {
          value: true,
          message: t('personal-information.errors.last-name.required'),
        },
        maxLength: {
          value: LAST_NAME_MAX_LENGTH,
          message: t('personal-information.errors.last-name.max-length', {
            maxLength: LAST_NAME_MAX_LENGTH,
          }),
        },
        minLength: {
          value: LAST_NAME_MIN_LENGTH,
          message: t('personal-information.errors.last-name.min-length', {
            minLength: LAST_NAME_MIN_LENGTH,
          }),
        },
        pattern: {
          value: NAME_PATTERN,
          message: t('personal-information.errors.last-name.pattern'),
        },
      },
    }),
    [t]
  );
}
