import { ConnectorTypeEnum } from '@app/core/model/enum/connector-type.enum';
import { Icon, IconName, IconProps } from '@app/shared/icon/icon';
import { useConnectorTypeIconName } from './use-connector-type-icon-name';

interface ConnectorTypeProps extends Omit<IconProps, 'name'> {
  connectorType?: ConnectorTypeEnum;
}

export const ConnectorTypeIcon = (props: ConnectorTypeProps): JSX.Element => {
  const { connectorType, ...iconProps } = props;

  const connectorIconName: IconName = useConnectorTypeIconName(connectorType);

  return <Icon name={connectorIconName} {...iconProps} />;
};
