import { ChargeSessionModel } from '@app/core/model/charge-session.model';
import { ChargeSessionEnum } from '@app/core/model/enum/charge-session.enum';
import {
  defaultChargeSession,
  getTransactionId,
  isSameTransactionIdOrNull,
  isStatusBacktracking,
  concatHistory,
} from './charge-session-reducer.helper';
import { ChargeSessionAction } from './charge-session.action';

export type ChargeSessionReducerFunc = (
  currentState: ChargeSessionModel,
  action: ChargeSessionAction
) => ChargeSessionModel;

export const reduceResetEvent: ChargeSessionReducerFunc = () =>
  defaultChargeSession;

export const reduceSessionInvalidEvent: ChargeSessionReducerFunc = (
  currentState,
  action
) => {
  if (
    !isSameTransactionIdOrNull(
      currentState.transactionId,
      action?.payload?.transactionId
    )
  ) {
    return currentState;
  }

  return {
    ...currentState,
    transactionId: getTransactionId(
      currentState.transactionId,
      action?.payload?.transactionId
    ),
    status: action.type,
  };
};

export const reduceStartChargeEvent: ChargeSessionReducerFunc = (
  currentState,
  action
) => {
  if (
    !isSameTransactionIdOrNull(
      currentState.transactionId,
      action?.payload?.transactionId
    ) ||
    (currentState.status ?? 0) >= ChargeSessionEnum.SESSION_STARTED
  ) {
    return currentState;
  }

  return {
    ...defaultChargeSession,
    transactionId: getTransactionId(
      currentState.transactionId,
      action?.payload?.transactionId
    ),
    status: action.type,
  };
};
export const reduceSessionStartedEvent: ChargeSessionReducerFunc = (
  currentState,
  action
) => {
  if (
    !isSameTransactionIdOrNull(
      currentState.transactionId,
      action?.payload?.transactionId
    ) ||
    isStatusBacktracking(currentState.status, action.type)
  ) {
    return currentState;
  }

  return {
    ...defaultChargeSession,
    transactionId: getTransactionId(
      currentState.transactionId,
      action?.payload?.transactionId
    ),
    status: action.type,
    startedAt: action.payload?.startedAt ?? currentState.startedAt,
  };
};
export const reduceSessionPendingEvent: ChargeSessionReducerFunc = (
  currentState,
  action
) => {
  if (
    !isSameTransactionIdOrNull(
      currentState.transactionId,
      action?.payload?.transactionId
    ) ||
    isStatusBacktracking(currentState.status, action.type)
  ) {
    return currentState;
  }

  return {
    ...currentState,
    status: action.type,
    startedAt: action.payload?.startedAt ?? currentState.startedAt,
    history: action?.payload
      ? concatHistory(currentState.history, action.payload)
      : currentState.history,
    endedAt: defaultChargeSession.endedAt,
  };
};
export const reduceStopChargeEvent: ChargeSessionReducerFunc = (
  currentState,
  action
) => {
  if (
    !isSameTransactionIdOrNull(
      currentState.transactionId,
      action?.payload?.transactionId
    ) ||
    (currentState.status ?? 0) >= ChargeSessionEnum.SESSION_ENDED
  ) {
    return currentState;
  }

  return {
    ...currentState,
    status: action.type,
    endedAt: action.payload?.endedAt ?? currentState.endedAt,
  };
};

export const reduceSessionEndedEvent: ChargeSessionReducerFunc = (
  currentState,
  action
) => {
  if (
    !isSameTransactionIdOrNull(
      currentState.transactionId,
      action?.payload?.transactionId
    )
  ) {
    return currentState;
  }

  return {
    ...currentState,
    status: action.type,
    endedAt: action.payload?.endedAt ?? currentState.endedAt,
  };
};
