import { Logger } from '@app/core/logger/logger';

export const getFromSessionStorage = (key: string): string | null => {
  try {
    return window.sessionStorage.getItem(key);
  } catch (e) {
    Logger.error(`Cannot retrieve ${key} from sessionstorage`, e);
    return null;
  }
};

export const setInSessionStorage = (key: string, token: string): string => {
  try {
    window.sessionStorage.setItem(key, token);
  } catch (e) {
    Logger.error(`Cannot set ${key} from sessionstorage`, e);
  }

  return token;
};

export const removeInSessionStorage = (key: string): void => {
  try {
    window.sessionStorage.removeItem(key);
  } catch (e) {
    Logger.error(`Cannot remove ${key} from sessionstorage`, e);
  }
};
