import { PaymentSchemeEnum } from './enum/payment-scheme.enum';
import { TransactionTypeEnum } from './enum/transaction-type.enum';
import { PaymentMethodResponseModel } from './payment-method-response.model';

export class PaymentMethodModel {
  expiryMonth: string;

  expiryYear: string;

  scheme: PaymentSchemeEnum;

  last4: string;

  iPaymentSourceId: string;

  productType?: string;

  name?: string;

  preferred?: boolean;

  constructor(payload: PaymentMethodResponseModel) {
    this.expiryMonth = payload.expiryMonth;
    this.expiryYear = payload.expiryYear;
    this.last4 = payload.last4;
    this.iPaymentSourceId = payload.iPaymentSourceId;

    // Must explicitly match APPLE_PAY or GOOGLE_PAY
    switch (payload.transactionType) {
      case TransactionTypeEnum.APPLE_PAY:
        this.scheme = PaymentSchemeEnum.APPLE_PAY;
        break;

      case TransactionTypeEnum.GOOGLE_PAY:
        this.scheme = PaymentSchemeEnum.GOOGLE_PAY;
        break;

      default:
        this.scheme = payload.scheme;
        break;
    }
  }
}

export interface PatchPaymentMethodModel {
  name: string;
}
