import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { H1 } from '@app/shared/typography/heading/heading';
import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';

interface DeletePaymentMethodErrorViewProps {
  onError: () => void;
  onRetry: () => void;
}

export const DeletePaymentMethodErrorView = ({
  onError,
  onRetry,
}: DeletePaymentMethodErrorViewProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <DialogLayout>
      <DialogLayout.Body>
        <Icon name="x-close" />
        <H1>{t('user-payment-method.delete.error.title')}</H1>
        <Text>{t('user-payment-method.delete.error.sub-title')}</Text>
      </DialogLayout.Body>

      <DialogLayout.Footer>
        <Button variant="outlined" onClick={onError}>
          {t('user-payment-method.delete.error.cancel-button')}
        </Button>
        <Button onClick={onRetry}>
          {t('user-payment-method.delete.error.retry-button')}
        </Button>
      </DialogLayout.Footer>
    </DialogLayout>
  );
};
