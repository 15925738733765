import classNames from 'classnames';
import { useMemo } from 'react';

import { DoublePriceSquare } from '@app/feature/tariff/component/price-square/double-price-square';
import { ChargePointPriceUnitEnum } from '@app/core/model/enum/charge-point-price-unit.enum';

import css from './price-square.module.scss';
import { SinglePriceSquare } from './single-price-square';

type Props = {
  pricePerKwh?: number;
  pricePerMin?: number;
  flatTTC?: number;
  className?: string;
};

export const PriceSquare = (props: Props): JSX.Element => {
  const { pricePerKwh = 0, pricePerMin = 0, flatTTC = 0, className } = props;

  const unit = useMemo(() => {
    if (pricePerKwh) {
      return ChargePointPriceUnitEnum.PER_KWH;
    }

    if (pricePerMin) {
      return ChargePointPriceUnitEnum.PER_MIN;
    }

    return null;
  }, [pricePerKwh, pricePerMin]);

  const price = useMemo(() => {
    if (pricePerKwh > 0) {
      return pricePerKwh;
    }

    if (pricePerMin > 0) {
      return pricePerMin;
    }

    return flatTTC;
  }, [pricePerKwh, pricePerMin, flatTTC]);

  if (pricePerKwh && pricePerMin) {
    return (
      <div className={classNames(css.container, className)}>
        <DoublePriceSquare
          pricePerKwh={pricePerKwh}
          pricePerMin={pricePerMin}
        />
      </div>
    );
  }

  return (
    <div className={classNames(css.container, className)}>
      <SinglePriceSquare className={css.component} price={price} unit={unit} />
    </div>
  );
};
