import { useEffect, useState } from 'react';

import { getDuration } from '@app/core/helper/get-duration';
import { LiveDurationView } from './live-duration.view';

type Props = {
  startedAt?: Date;
  isStopped?: boolean;
  disabled?: boolean;
  className?: string;
};

export const LiveDurationController = (props: Props): JSX.Element => {
  const { startedAt, isStopped, disabled, className } = props;

  const [duration, setDuration] = useState(
    startedAt ? getDuration(startedAt) : 0
  );

  useEffect(() => {
    if (isStopped || !startedAt) {
      return () => {};
    }

    const interval = setInterval(() => {
      setDuration(getDuration(startedAt));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [isStopped, startedAt]);

  return (
    <LiveDurationView
      className={className}
      disabled={disabled}
      duration={duration}
    />
  );
};
