import { SpeedChart } from '@app/feature/charging/component/charge-chart/speed-chart';
import { EnergyDeliveredChart } from '@app/feature/charging/component/charge-chart/energy-delivered-chart';

import { Point } from './model/point';

import 'chartist/dist/index.css';
import './chartist.style.scss';

type Props = {
  speedCharge: Point[];
  totalKWDelivered: Point[];
};

export const ChargeChartView = (props: Props): JSX.Element => {
  const { speedCharge, totalKWDelivered } = props;
  return (
    <>
      <EnergyDeliveredChart points={totalKWDelivered} />
      <SpeedChart points={speedCharge} />
    </>
  );
};
