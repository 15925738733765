import {
  AUTHENTICATION_ADD_WEBAUTHN_API,
  AUTHENTICATION_VERIFY_WEBAUTHN_API,
  AUTHENTICATION_WEBAUTHN_API,
  START_EMAIL_UPDATE_API,
  START_EMAIL_VALIDATION_API,
  VERIFY_EMAIL_UPDATE_API,
  VERIFY_EMAIL_VALIDATION_API,
} from '@app/config/api-routes.const';
import { AbortablePromise } from '@app/core/helper/abort-promise';
import { httpClientService } from '@app/core/client/http/http-client.service';
import { EmailUpdateRequestModel } from '@app/core/model/email-update.model';
import {
  EmailOtpProcessStartRequestModel,
  EmailOtpProcessVerificationRequestModel,
} from '@app/core/model/email-otp-process.model';
import { WebauthnRegistrationStartResponseModel } from '@app/core/model/registration.model';
import { WebauthnAuthenticatorCreationRequestModel } from '@app/core/model/webauthn-authenticator-creation.model';
import { buildPath } from '@app/core/helper/build-path';
import { WebauthnAuthenticatorModel } from '@app/core/model/authenticator.model';

class AuthenticationMethodService {
  startEmailValidation(): AbortablePromise<void> {
    return httpClientService.run<void>(START_EMAIL_VALIDATION_API);
  }

  verifyEmailValidation(
    data: EmailOtpProcessVerificationRequestModel
  ): AbortablePromise<void> {
    return httpClientService.run<void>(VERIFY_EMAIL_VALIDATION_API, {
      data,
    });
  }

  startEmailUpdate(
    data: EmailOtpProcessStartRequestModel
  ): AbortablePromise<void> {
    return httpClientService.run<void>(START_EMAIL_UPDATE_API, {
      method: 'PATCH',
      data,
    });
  }

  verifyEmailUpdate(
    data: EmailOtpProcessVerificationRequestModel & EmailUpdateRequestModel
  ): AbortablePromise<void> {
    return httpClientService.run<void>(VERIFY_EMAIL_UPDATE_API, {
      method: 'PATCH',
      data,
    });
  }

  startAddWebauthn(): AbortablePromise<WebauthnRegistrationStartResponseModel> {
    return httpClientService.run<WebauthnRegistrationStartResponseModel>(
      AUTHENTICATION_ADD_WEBAUTHN_API
    );
  }

  verifyAddWebauthn(
    data: WebauthnAuthenticatorCreationRequestModel
  ): AbortablePromise<WebauthnAuthenticatorModel> {
    return httpClientService.post<WebauthnAuthenticatorModel>(
      AUTHENTICATION_VERIFY_WEBAUTHN_API,
      data
    );
  }

  deleteWebauthn(authenticator: WebauthnAuthenticatorModel) {
    return httpClientService.run<void>(
      buildPath(AUTHENTICATION_ADD_WEBAUTHN_API, authenticator.credentialId),
      {
        method: 'DELETE',
      }
    );
  }

  renameWebauthn(
    name: string,
    credentialId: string
  ): AbortablePromise<WebauthnAuthenticatorModel> {
    return httpClientService.run<WebauthnAuthenticatorModel>(
      buildPath(AUTHENTICATION_WEBAUTHN_API, credentialId),
      {
        method: 'PATCH',
        data: { name },
      }
    );
  }
}

export const authenticationMethodService = new AuthenticationMethodService();
