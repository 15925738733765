import { getSocketAuth } from '@app/core/client/websocket/get-socket-auth';
import { WebsocketClientService } from '@app/core/client/websocket/websocket-client.service';
import { io, Socket } from 'socket.io-client';

import { Logger } from '@app/core/logger/logger';

const WS_MOCK_ENDPOINT = process.env.DELMONICOS_MOCK_WS || '';

const hasWsMockEndpoint = Boolean(WS_MOCK_ENDPOINT);

const mockTopics: string[] = [];
const options = getSocketAuth();
const mockSocket: Socket = io(WS_MOCK_ENDPOINT, {
  transports: ['websocket'],
  autoConnect: hasWsMockEndpoint,
  auth: options,
});

function isTopicMock(testedTopic: string): boolean {
  return hasWsMockEndpoint && mockTopics.includes(testedTopic);
}

function mockSocketHookCallback(topic: string, realSocket: Socket): Socket {
  if (isTopicMock(topic)) {
    Logger.debug('MOCK WS', topic);
    return mockSocket;
  }

  return realSocket;
}

export function addWsMock(topic: string): void {
  mockTopics.push(topic);
}

export function removeWsMock(topic: string): void {
  const index = mockTopics.indexOf(topic);
  if (index !== -1) {
    mockTopics.splice(index, 1);
  }
}

WebsocketClientService.getInstance().overrideSocketHook(mockSocketHookCallback);
