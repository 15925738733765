import { EMAIL_PATTERN } from '@app/config/form-validation-rules.const';

const PASSWORD_MIN_LENGTH = 4;
const PASSWORD_MAX_LENGTH = 40;

export const privateAccessFormValidationRules = {
  email: {
    required: true,
    pattern: EMAIL_PATTERN,
  },
  password: {
    required: true,
    minLength: PASSWORD_MIN_LENGTH,
    maxLength: PASSWORD_MAX_LENGTH,
  },
};
