import { PropsWithChildren } from 'react';
import {
  QueryClient,
  QueryClientProvider as TSQueryClientProvider,
} from '@tanstack/react-query';

const queryClient = new QueryClient();

export function QueryClientProvider(props: PropsWithChildren<{}>): JSX.Element {
  const { children } = props;

  return (
    <TSQueryClientProvider client={queryClient}>
      {children}
    </TSQueryClientProvider>
  );
}
