import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { ErrorCodeEnum } from '@app/config/error-config';
import { userService } from './user.service';
import { useUser } from '../context-providers/user-context/use-user';
import { showErrorMessage } from '../error/show-error-message';
import { UserModel } from '../model/user.model';

export const useUserSyncSubscriptionService = (): UseMutationResult<
  UserModel,
  unknown,
  void,
  unknown
> => {
  const { user, onUserUpdate } = useUser();

  return useMutation({
    mutationFn: async () => userService.syncSubscriptions(),
    onSuccess: (userUpdated) => {
      if (user) {
        onUserUpdate(userUpdated);
      }
    },
    onError: () => {
      showErrorMessage(
        'user-sync-subscriptions',
        ErrorCodeEnum.USER_SYNC_SUBSCRIPTION
      );
    },
  });
};
