import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorMessage } from '@app/core/error/show-error-message';
import { ApiError } from '@app/core/error/api-error';
import { catchApiError } from '@app/core/error/catch-api-error';
import { PaymentMethodModel } from '@app/core/model/payment-method.model';
import { paymentTransactionService } from '@app/core/service/payment-transaction.service';
import { paymentMethodService } from '@app/core/service/payment-method.service';
import { RedirectUrlResponseModel } from '@app/core/model/redirect-url-response.model';
import { mapCheckoutErrorToTranslationKey } from '@app/core/helper/map-checkout-error-to-translation-key';
import { setInLocalStorage } from '@app/core/storage/local-storage';
import { CREATE_TRANSACTION_FROM_I_PAYMENT_SOURCE_ID_LSK } from '@app/config/localstorage-keys.const';

import { SelectPaymentProps } from '../../select-payment.props';
import { ListUserPaymentMethodView } from './list-user-payment-method.view';

interface ListUserPaymentMethodControllerProps extends SelectPaymentProps {
  currentPaymentMethodId?: string;
  onCancel: () => void;
}

export const ListUserPaymentMethodController = ({
  currentPaymentMethodId,
  onCancel,
  onSubmit,
}: ListUserPaymentMethodControllerProps): JSX.Element => {
  const { t } = useTranslation();

  const [userPaymentMethods, setUserPaymentMethods] = useState<
    PaymentMethodModel[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const [listPromise, abortPromise] = paymentMethodService.list();

    listPromise
      .then(setUserPaymentMethods)
      .catch((err) => {
        catchApiError(err, (error: ApiError) =>
          showErrorMessage('list-payment-method', error.code)
        );
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      abortPromise.abort();
    };
  }, [t]);

  const handleSelectUserPaymentMethod = useCallback(
    async (paymentMethod: PaymentMethodModel) => {
      if (paymentMethod.iPaymentSourceId === currentPaymentMethodId) {
        onCancel();
        return;
      }

      const [createTransactionPromise] =
        paymentTransactionService.createTransactionFromIPaymentSourceId({
          iPaymentSourceId: paymentMethod.iPaymentSourceId,
        });

      try {
        const response: RedirectUrlResponseModel =
          await createTransactionPromise;

        if (response.redirectUrl) {
          setInLocalStorage(
            CREATE_TRANSACTION_FROM_I_PAYMENT_SOURCE_ID_LSK,
            true.toString()
          );
        }

        onSubmit(response);
      } catch (error) {
        catchApiError(error, (apiError: ApiError) => {
          const translationKey = mapCheckoutErrorToTranslationKey(
            apiError.code
          );
          showErrorMessage(translationKey, apiError.code);
        });
      }
    },
    [onSubmit, currentPaymentMethodId, onCancel]
  );

  return (
    <ListUserPaymentMethodView
      loading={loading}
      paymentMethods={userPaymentMethods}
      onSelect={handleSelectUserPaymentMethod}
    />
  );
};
