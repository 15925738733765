import { useTranslation } from 'react-i18next';

import { Text } from '@app/shared/typography/text/text';
import { PricingModel } from '@app/core/model/charge-point.model';

import css from './price-card.module.scss';

type Props = PricingModel;

export function PriceCard(props: Props): JSX.Element {
  const { t } = useTranslation();
  const { perKwhTTC = 0, flatTTC = 0, perTimeChargingHourTTC = 0 } = props;

  return (
    <>
      {perKwhTTC > 0 && (
        <div className={css.priceItem}>
          <Text>{t('quote.price-section.price-per-kwh')}</Text>
          <Text bold>
            {t('quote.price-section.amount', {
              amount: perKwhTTC,
            })}
          </Text>
        </div>
      )}

      {perTimeChargingHourTTC > 0 && (
        <div className={css.priceItem}>
          <Text>{t('quote.price-section.price-per-min')}</Text>
          <Text bold>
            {t('quote.price-section.amount', {
              amount: perTimeChargingHourTTC / 60,
            })}
          </Text>
        </div>
      )}

      {flatTTC > 0 && (
        <div className={css.priceItem}>
          <Text>{t('quote.price-section.flat-fee')}</Text>
          <Text bold>
            {t('quote.price-section.amount', {
              amount: flatTTC,
            })}
          </Text>
        </div>
      )}
    </>
  );
}
