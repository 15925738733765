import { DASHBOARD_PATH } from '@app/config/app-paths.const';
import { useTranslation } from 'react-i18next';
import { AppbarLink } from './appbar-link/appbar.link';

interface HomeLinkProps {
  on?: boolean;
}

export const HomeLink = ({ on = false }: HomeLinkProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <AppbarLink
      label={t('shared.app-bar.home')}
      icon="home-02"
      to={DASHBOARD_PATH}
      on={on}
    />
  );
};
