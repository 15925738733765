import { createContext } from 'react';

import { ChargeStateEnum } from '@app/core/model/enum/charge-state.enum';
import { ChargingEventModel } from '@app/core/model/charging-event.model';
import { ChargeSessionAction } from './charge-session-reducer/charge-session.action';

export interface IChargeContext {
  chargeState: ChargeStateEnum;
  history: ChargingEventModel[];
  startedAt?: Date;
  duration?: number;
  totalKwh?: number;
  totalTTC?: number;
  updatedAt?: Date;
  transactionId: string | null;

  isChargeStarted: boolean;
  isChargeEnded: boolean;
  isCharging: boolean;
  isError: boolean;

  onEvent: (event: ChargeSessionAction) => void;
  refresh: () => Promise<void>;
}

export const ChargeContext = createContext<IChargeContext>({
  chargeState: ChargeStateEnum.INIT,
  history: [],
  transactionId: null,
  onEvent: () => {},
  isChargeStarted: false,
  isChargeEnded: false,
  isCharging: false,
  isError: false,
  refresh: async () => {},
});
