import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRetrieveLocationDestination } from './use-retrieve-location-destination';

export type RedirectToInitialDestinationHook = () => void;

export const useRedirectToInitialDestination =
  (): RedirectToInitialDestinationHook => {
    const destination = useRetrieveLocationDestination();
    const navigate = useNavigate();

    const navigateToRouterState = useCallback(() => {
      navigate(destination.from, { state: destination.state });
    }, [navigate, destination]);

    return navigateToRouterState;
  };
