import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LOCALE_LSK } from '@app/config/localstorage-keys.const';

import en from './en.json';
import fr from './fr.json';
import { formatTime, formatTimeShort } from './formatters';
import { DEFAULT_NAMESPACE, LOCALE_KEYS } from './locale';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNS: DEFAULT_NAMESPACE,
    resources: {
      [LOCALE_KEYS.en]: {
        translation: en,
      },
      [LOCALE_KEYS.fr]: {
        translation: fr,
      },
    },
    fallbackLng: [LOCALE_KEYS.fr, LOCALE_KEYS.en],
    interpolation: {
      escapeValue: false, // react already safes from xss
      defaultVariables: { errorCode: '0003' },
    },
    detection: {
      lookupLocalStorage: LOCALE_LSK,
      caches: [],
    },
  });

i18n.services.formatter?.add('time', formatTime);

i18n.services.formatter?.add('time-short', formatTimeShort);

export default i18n;
