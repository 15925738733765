import { ChangeEvent, forwardRef, HTMLProps, ReactElement } from 'react';
import classname from 'classnames';
import css from './radio.module.scss';
import { Text } from '../typography/text/text';

export interface RadioProps extends HTMLProps<HTMLInputElement> {
  label: string;
  caption?: string;
  adornment?: (checked: boolean) => ReactElement | null;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      label,
      caption,
      adornment = () => null,
      children,
      className,
      checked = false,
      ...props
    }: RadioProps,
    ref
  ): JSX.Element => {
    return (
      <label
        className={classname(css.container, checked && css.checked)}
        htmlFor={label}>
        <input
          ref={ref}
          type="radio"
          className={classname(className, css.radio)}
          id={label}
          {...props}
        />
        <div className={css.children}>
          <div className={css.label}>
            <Text fontSize="large">{label}</Text>
            {caption && <Text color="tertiary">{caption}</Text>}
          </div>
          {adornment(checked)}
        </div>
      </label>
    );
  }
);
