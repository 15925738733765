import { useParams } from 'react-router-dom';
import { useChargePointListService } from '@app/core/service/use-charge-point-list.service';

import { ChargePointController } from './charge-point.controller';

export const TinyChargePointController = (): JSX.Element => {
  const { tinyChargePointId } = useParams() as {
    tinyChargePointId: string;
  };

  const {
    data: connectorList = [],
    isError,
    isBeingInstalled,
  } = useChargePointListService(tinyChargePointId);

  return (
    <ChargePointController
      connectorList={connectorList}
      isError={isError}
      isBeingInstalled={isBeingInstalled}
    />
  );
};
