import { AxiosError } from 'axios';
import { DEFAULT_STATUS_ERR, ErrorCodeEnum } from '@app/config/error-config';
import { Logger } from '../logger/logger';

function isRequestAborted(error: AxiosError): boolean {
  return error.code === 'ERR_CANCELED';
}

export function getErrorCode(error: unknown): string {
  if (error instanceof AxiosError && error?.response?.data?.errorCode) {
    return error.response.data.errorCode;
  }

  if (error !== null && typeof error === 'object' && 'errorCode' in error) {
    const typedError = error as { errorCode: string };
    return typedError.errorCode;
  }

  Logger.warn('Error response from API does not have any errorCode.', error);
  return ErrorCodeEnum.NO_ERROR_CODE;
}

function getErrorStatus(error: unknown): string {
  if (!(error instanceof AxiosError) || !error.response?.status) {
    return DEFAULT_STATUS_ERR;
  }

  return error.response?.status.toString();
}

function getLockedDurationInSec(error: AxiosError): number {
  if (
    error?.response?.data &&
    typeof error.response.data === 'object' &&
    'vars' in error.response.data
  ) {
    const typedData = error.response.data as {
      vars: { remainingLockTimeInSec?: number };
    };

    return typedData.vars?.remainingLockTimeInSec ?? 0;
  }

  return 0;
}

export class ApiError extends Error {
  status: string;

  code: string;

  isAborted = false;

  isIntercepted = false;

  remainingLockTimeInSec: number;

  constructor(axiosError: AxiosError) {
    super(axiosError.message);
    this.status = getErrorStatus(axiosError);
    this.code = getErrorCode(axiosError);
    this.isAborted = isRequestAborted(axiosError);

    this.remainingLockTimeInSec = getLockedDurationInSec(axiosError);
  }

  intercepts(): this {
    this.isIntercepted = true;
    return this;
  }
}
