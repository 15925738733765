export function padTo2(value: number, locales?: string): string {
  return value.toLocaleString(locales, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
}

export function formatTime(value: number, lng?: string): string {
  const hour = Math.floor(value / 3600);
  const minute = Math.floor((value % 3600) / 60);
  const second = Math.floor(value % 60);

  return `${padTo2(hour, lng)}:${padTo2(minute, lng)}:${padTo2(second, lng)}`;
}

export function formatTimeShort(seconds: number): string {
  const hour = Math.floor(seconds / 3600);
  const minute = Math.floor((seconds % 3600) / 60);
  const second = Math.floor((seconds % 3600) % 60);

  const hourString = `${hour}h`;
  const minuteString = minute > 0 ? `${minute}m` : '';
  const secondString = second > 0 ? `${second}s` : '';

  if (hour > 0) {
    return `${hourString} ${minuteString}`;
  }

  return `${minuteString} ${secondString}`;
}
