import { useTranslation } from 'react-i18next';

import Biometric from '@app/asset/biometric.svg';
import { Button } from '@app/shared/button/button';
import { Text } from '@app/shared/typography/text/text';
import { H1 } from '@app/shared/typography/heading/heading';
import { Link } from '@app/shared/typography/link/link';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';

import css from './webauthn-authentication.module.scss';

interface WebauthnAuthenticationViewProps {
  remainingLockTimeInSec?: number;
  pending: boolean;
  onStart: () => Promise<void>;
  onUseEmail: () => void;
  onBack: () => void;
}

export const WebauthnAuthenticationView = ({
  remainingLockTimeInSec = 0,
  pending,
  onStart,
  onUseEmail,
  onBack,
}: WebauthnAuthenticationViewProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <DrawerLayout>
      <DrawerLayout.Header>
        <H1>{t('authentication.webauthn.title')}</H1>
        <Text>{t('authentication.webauthn.information')}</Text>
      </DrawerLayout.Header>

      <DrawerLayout.Body className={css.body}>
        <Biometric onClick={onStart} />
      </DrawerLayout.Body>

      <DrawerLayout.Footer>
        <Link onClick={onUseEmail}>
          {t('authentication.webauthn.use-email')}
        </Link>

        <div className={css.footer}>
          <Button variant="outlined" onClick={onBack}>
            {t('authentication.webauthn.back-button')}
          </Button>
          <Button
            onClick={onStart}
            isLoading={pending}
            disabled={remainingLockTimeInSec > 0}>
            {t('authentication.webauthn.submit-button', {
              count: remainingLockTimeInSec,
            })}
          </Button>
        </div>
      </DrawerLayout.Footer>
    </DrawerLayout>
  );
};
