import { InvoiceModel } from '@app/core/model/invoice.model';
import { Divider } from '@app/shared/divider/divider';
import { Icon } from '@app/shared/icon/icon';
import { InvoiceTariff } from '@app/shared/invoice/invoice-tariff/invoice-tariff';
import { ListItemMenu } from '@app/shared/list-item-menu/list-item-menu';
import { H3 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TransactionTypeEnum } from '@app/core/model/enum/transaction-type.enum';
import css from './invoice-card.module.scss';

type Props = {
  pseudo?: string;
  invoice: InvoiceModel;
};

export function InvoiceCard(props: Props): JSX.Element {
  const { pseudo, invoice } = props;
  const {
    proforma,
    createdAt,
    chargePointName,
    duration,
    energyDelivered,
    pricing,
    cost,
    paymentTicket,
  } = invoice;
  const { totalHT = 0, totalVAT = 0, totalTTC = 0, vatRate } = cost;

  const { t } = useTranslation();

  return (
    <div className={css.card}>
      <div className={css.row}>
        <div className={css.rowGroup}>
          <Icon name="calendar-date" />
          <Text bold fontSize="large" className={css.rowGroup}>
            {t('invoicing.date', { value: createdAt })}
          </Text>

          <Text fontSize="large" className={css.rowGroup}>
            {t('invoicing.time', { value: createdAt })}
          </Text>
        </div>

        <div className={css.id}>
          <Icon name="user-01" />
          {pseudo && <Text bold>{pseudo}</Text>}
        </div>
      </div>

      <div className={css.row}>
        <div className={css.rowGroup}>
          <Icon name="marker-pin-01" />
          <Text bold>{chargePointName}</Text>
        </div>

        <div className={css.rowGroup}>
          <Icon name="clock-fast-forward" />
          <Text bold>{t('invoicing.duration', { value: duration })}</Text>
        </div>
      </div>

      {paymentTicket && (
        <div className={css.row}>
          <div className={css.rowGroup}>
            <Icon name="credit-card-01" />
            {paymentTicket.provider === TransactionTypeEnum.CARD && (
              <Text bold>{t('invoicing.payment.card')}</Text>
            )}
            {paymentTicket.provider === TransactionTypeEnum.APPLE_PAY && (
              <Text bold>{t('invoicing.payment.apple-pay')}</Text>
            )}
            {paymentTicket.provider === TransactionTypeEnum.GOOGLE_PAY && (
              <Text bold>{t('invoicing.payment.google-pay')}</Text>
            )}
          </div>

          <div className={css.rowGroup}>
            <Text>{t('invoicing.payment.ref')}</Text>
            <Text bold>{paymentTicket.paymentTicketId}</Text>
          </div>
        </div>
      )}

      <Divider type="solid" />

      <H3 className={css.cardTitle}>{t('invoicing.card.title')}</H3>

      <ListItemMenu className={css.detail}>
        <InvoiceTariff {...pricing} />

        {energyDelivered > 0 && (
          <div className={css.row}>
            <Text>{t('invoicing.card.energy-delivered.label')}</Text>
            <Text color="primary" bold>
              {t('invoicing.card.energy-delivered.value', {
                value: energyDelivered,
              })}
            </Text>
          </div>
        )}
      </ListItemMenu>

      {totalHT > 0 && totalVAT > 0 && (
        <>
          <Divider type="dashed" />

          <ListItemMenu>
            <div className={css.row}>
              <Text>{t('invoicing.card.total-ht.label')}</Text>
              <Text>
                {t('invoicing.card.total-ht.value', {
                  totalHT,
                })}
              </Text>
            </div>

            <div className={css.row}>
              <Text>
                {t('invoicing.card.total-vat.label', {
                  vatRate,
                })}
              </Text>
              <Text>
                {t('invoicing.card.total-vat.value', {
                  totalVAT,
                })}
              </Text>
            </div>
          </ListItemMenu>
        </>
      )}

      {totalTTC > 0 ? (
        <>
          <Divider type="dashed" />

          <div className={classNames(css.row)}>
            <Text fontSize="large" bold>
              {t('invoicing.card.total-ttc.label')}
            </Text>
            <Text
              className={classNames(
                css.totalTTC,
                proforma && css.totalProforma
              )}
              bold>
              {proforma ? (
                <Icon name="credit-card-refresh" className={css.proformaIcon} />
              ) : (
                <Icon name="credit-card-check" />
              )}
              {t('invoicing.card.total-ttc.value', {
                totalTTC,
              })}
            </Text>
          </div>
        </>
      ) : (
        <Text>{t('invoicing.card.total-ttc.unavailable')}</Text>
      )}
    </div>
  );
}
