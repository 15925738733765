export enum ChargeStateEnum {
  INIT,
  START_CHARGE_REQUESTED,
  SESSION_STARTED,
  RETRY_START_CHARGE,
  CHARGING,
  STOP_CHARGE_REQUESTED,
  RETRY_STOP_CHARGE,
  SESSION_ERROR,
  SESSION_ENDED,
}
