import { ChargePointModel } from '@app/core/model/charge-point.model';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PowerType } from '@app/shared/charge-point/power-type/power-type';
import { ConnectorType } from '@app/shared/charge-point/connector-type/connector-type';
import { Power } from '@app/shared/charge-point/power-type/power';

import css from './connector-card.module.scss';

type Props = ChargePointModel & {
  onClick: () => void;
};

export function ConnectorCard(props: Props): JSX.Element {
  const { onClick, ...chargePoint } = props;
  const { isAvailable, specifications } = chargePoint;
  const { connectorType, powerType, power } = specifications;

  const { t } = useTranslation();

  return (
    <button className={css.container} onClick={onClick}>
      <div
        className={classNames(
          css.header,
          css.stack,
          isAvailable ? css.headerAvailable : css.headerBusy
        )}>
        <div
          className={classNames(
            css.status,
            isAvailable ? css.statusAvailable : css.statusBusy
          )}
        />
        {t(
          `charge-point.connector.status.${isAvailable ? 'available' : 'busy'}`
        )}
      </div>

      <div className={css.body}>
        <ConnectorType connectorType={connectorType} />

        <div className={css.details}>
          <PowerType powerType={powerType} />
          <Power power={power} />
        </div>
      </div>
    </button>
  );
}
