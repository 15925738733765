import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';
import './core/locale/i18n';

const loaderElement = document.querySelector('.loader');

function hideLoader() {
  loaderElement?.remove();
}

ReactDOM.render(
  <React.StrictMode>
    <App onReady={hideLoader} />
  </React.StrictMode>,
  document.getElementById('root')
);
