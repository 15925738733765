import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Link, LinkProps } from '@app/shared/typography/link/link';
import { Spinner } from '@app/shared/spinner/spinner';
import { Icon } from '@app/shared/icon/icon';

interface PreferredPaymentMethodLinkProps extends LinkProps {
  isPreferred: boolean;
  pending: boolean;
  onPrefer: () => void;
  onDeletePreferred: () => void;
}

export const PreferredPaymentMethodLink = (
  props: PreferredPaymentMethodLinkProps
): JSX.Element => {
  const {
    isPreferred,
    pending,
    onPrefer,
    onDeletePreferred,
    className,
    ...linkProps
  } = props;
  const { t } = useTranslation();

  return (
    <div className={classNames(className)}>
      {isPreferred ? (
        <Link
          onClick={onDeletePreferred}
          startIcon={<Icon name="reverse-left" />}
          {...linkProps}>
          {t('user-payment-method.preferred-payment-method.delete-preferred')}
        </Link>
      ) : (
        <Link
          onClick={onPrefer}
          startIcon={<Icon name="star-01" />}
          {...linkProps}>
          {t('user-payment-method.preferred-payment-method.set-as-preferred')}
        </Link>
      )}

      {pending && <Spinner />}
    </div>
  );
};
