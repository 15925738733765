import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import type { AuthenticationResponseJSON } from '@simplewebauthn/typescript-types';
import { startAuthentication as openAuthenticationDialog } from '@simplewebauthn/browser';

import { JwtResponseModel } from '@app/core/model/jwt-response.model';
import { ApiError } from '@app/core/error/api-error';
import { authenticationService } from '@app/core/service/authentication.service';
import { ErrorCodeEnum } from '@app/config/error-config';
import { useRemainingLockTime } from '@app/core/helper/use-remaining-lock-time';
import { WebauthnAuthenticationStartResponseModel } from '@app/core/model/authentication.model';
import { Logger } from '@app/core/logger/logger';
import { configurationService } from '@app/core/configuration/configuration.service';

interface WebauthnAuthenticationHookProps {
  onError: (error: ApiError) => void;
  onSuccess: (jwt: string, pseudo: string) => void;
}

interface WebauthnAuthenticationHookReturn {
  pending: boolean;
  cooldownLockTimeInSec: number;
  onStartWebauthn: (pseudo: string) => Promise<void>;
  onRetryWebauthn: () => Promise<void>;
}

export const useWebauthnAuthentication = (
  props: WebauthnAuthenticationHookProps
): WebauthnAuthenticationHookReturn => {
  const { onError, onSuccess } = props;

  const { t } = useTranslation();

  const [pending, setPending] = useState(false);
  const [pseudo, setPseudo] = useState('');

  const { setRemainingLockTimeInSec, cooldownLockTimeInSec } =
    useRemainingLockTime();

  const handleError = useCallback(
    (error) => {
      //  Do nothing if webauthn timed out or user declines the UI
      if (!(error instanceof ApiError)) {
        Logger.debug(error);
        return;
      }

      if (error.isIntercepted) {
        return;
      }

      if (error.code === ErrorCodeEnum.ACCOUNT_LOCKED) {
        toast.error(
          `${t(
            error.remainingLockTimeInSec > 0
              ? 'errors.authentication-too-many-attempts'
              : 'errors.authentication-locked',
            {
              count: Math.ceil(error.remainingLockTimeInSec),
              phoneNumber: configurationService.getSupportPhoneNumber(),
            }
          )} [Code: ${error.code}]`,
          {
            toastId: 'authentication-too-many-attempts',
          }
        );

        setRemainingLockTimeInSec(error.remainingLockTimeInSec);

        return;
      }

      onError(error);
    },
    [onError, t, setRemainingLockTimeInSec]
  );

  const handleStart = useCallback(
    async (identifier: string): Promise<void> => {
      setPending(true);
      setPseudo(identifier);

      try {
        // Start authentication
        const [startWebauthnPromise] = authenticationService.startWebauthn({
          pseudo: identifier,
        });

        const response: WebauthnAuthenticationStartResponseModel =
          await startWebauthnPromise;

        const authenticationResponse: AuthenticationResponseJSON =
          await openAuthenticationDialog(response.options);

        // Verify challenge
        const [promise] = authenticationService.verifyWebauthn({
          pseudo: identifier,
          authenticationResponse,
        });
        const jwtResponse: JwtResponseModel = await promise;

        // Authenticate the jwt
        onSuccess(jwtResponse.jwt, identifier);

        setPending(false);
      } catch (err) {
        setPending(false);
        handleError(err);
      }
    },
    [onSuccess, handleError]
  );

  const handleRetry = useCallback(() => {
    return handleStart(pseudo);
  }, [handleStart, pseudo]);

  return useMemo(
    () => ({
      pending,
      onStartWebauthn: handleStart,
      onRetryWebauthn: handleRetry,
      cooldownLockTimeInSec,
    }),
    [pending, handleStart, handleRetry, cooldownLockTimeInSec]
  );
};
