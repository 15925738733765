import { ValidateOrUpdateEmailDrawer } from '@app/feature/account/view/user-security/view/manage-authentication-method/manage-email-authentication/component/validate-or-update-email.drawer';
import { DrawerProps } from '@app/shared/drawer/drawer';

interface UpdateEmailDrawerControllerProps extends DrawerProps {
  emailAddress: string;
  onSuccess: () => void;
}

export const UpdateEmailDrawerController = (
  props: UpdateEmailDrawerControllerProps
): JSX.Element => {
  const { emailAddress, onSuccess, onClose = () => {}, ...drawerProps } = props;

  const handleSuccess = () => {
    onClose();
    onSuccess();
  };

  return (
    <ValidateOrUpdateEmailDrawer
      isUpdating
      emailAddress={emailAddress}
      onSuccess={handleSuccess}
      onClose={onClose}
      {...drawerProps}
    />
  );
};
