import { Divider } from '@app/shared/divider/divider';
import { ChargePointSpecificationModel } from '@app/core/model/charge-point.model';
import { ConnectorType } from '@app/shared/charge-point/connector-type/connector-type';
import { PowerType } from '@app/shared/charge-point/power-type/power-type';
import { Power } from '@app/shared/charge-point/power-type/power';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { H2 } from '@app/shared/typography/heading/heading';
import { TariffModel } from '@app/core/model/tariff.model';
import { TariffList } from '@app/feature/tariff/component/tariff-list/tariff-list';
import { Text } from '@app/shared/typography/text/text';

import css from './details.module.scss';
import { PrecaptureWarning } from '../pre-capture-warning/pre-capture-warning';
import { PriceCard } from '../price-card/price-card';

type Props = Partial<ChargePointSpecificationModel> & {
  isPrecaptureAccepted?: boolean;
  tariff?: TariffModel;
  isTariffComplex?: boolean;
  vatRate: number;
};

export function Details(props: Props): JSX.Element {
  const { power, powerType, connectorType, tariff, isTariffComplex, vatRate } =
    props;
  const { t } = useTranslation();
  const pricing = tariff?.elements?.at(0)?.priceComponents;

  return (
    <div className={classNames(css.stack, css.container)}>
      <div className={css.header}>
        <ConnectorType connectorType={connectorType} />
        <PowerType powerType={powerType} />
        <Power power={power} />
      </div>

      <Divider type="dashed" />

      <div className={css.stack}>
        <H2>{t('quote.details.title')}</H2>
        <PrecaptureWarning />
      </div>

      <div className={css.stack}>
        {tariff && isTariffComplex && <TariffList elements={tariff.elements} />}
        {pricing && !isTariffComplex && <PriceCard {...pricing} />}
      </div>

      <Text color="secondary" className={css.vat}>
        {t('quote.details.vat', { vat: vatRate })}
      </Text>
    </div>
  );
}
