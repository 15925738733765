import { useUser } from '@app/core/context-providers/user-context/use-user';
import {
  GUEST_LEGAL_TEXT_PATH,
  LEGAL_TEXT_PATH,
} from '@app/config/app-paths.const';
import { LegalNoticesView } from './legal-notices.view';

export const LegalNoticesController = (): JSX.Element => {
  const { user } = useUser();
  return (
    <LegalNoticesView backTo={user ? LEGAL_TEXT_PATH : GUEST_LEGAL_TEXT_PATH} />
  );
};
