import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';
import { useTranslation } from 'react-i18next';
import { configurationService } from '@app/core/configuration/configuration.service';
import css from './publication-manager.module.scss';

export const PublicationManager = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={css.container}>
      <Text bold>{t('legal-text.legal-notices.publication.title')}</Text>
      <Text className={css.caption}>
        {t('legal-text.legal-notices.publication.caption')}
      </Text>

      <div className={css.informations}>
        <Text>
          {configurationService.getLegalNoticesPublicationManagerName()}
        </Text>
        <Text>
          <Icon name="phone-call-01" />
          {configurationService.getLegalNoticesPublicationManagerPhoneNumber()}
        </Text>
        <Text>
          <Icon name="mail-02" />
          {configurationService.getLegalNoticesPublicationManagerMail()}
        </Text>
      </div>
    </div>
  );
};
