import { ToastOptions, toast } from 'react-toastify';

import {
  ErrorCodeEnum,
  ROOT_ERROR_TRANSLATION,
} from '@app/config/error-config';
import i18next from '@app/core/locale/i18n';

export function showErrorMessage(
  translationKey: string,
  code: string,
  options?: ToastOptions
): void {
  const message = i18next.t(`${ROOT_ERROR_TRANSLATION}.${translationKey}`);

  // toastId prevent duplication : https://fkhadra.github.io/react-toastify/prevent-duplicate
  toast.error(`${message} [Code: ${code}]`, {
    toastId: translationKey,
    ...options,
  });
}

export function showDefaultErrorMessage(): void {
  showErrorMessage('default', ErrorCodeEnum.DEFAULT);
}
