import { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { EmailInput } from '@app/shared/text-input/email-input';
import { Fade } from '@app/shared/fade/fade';
import { Stepper } from '@app/shared/stepper/stepper';
import { TextInput } from '@app/shared/text-input/text-input';
import { Text } from '@app/shared/typography/text/text';
import { H1 } from '@app/shared/typography/heading/heading';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';
import { Spinner } from '@app/shared/spinner/spinner';
import { Overlay } from '@app/shared/overlay/overlay';
import { LegalText } from '@app/core/model/legal-text';
import { ConsentUpdateRequestModel } from '@app/core/model/consent.model';
import { RegistrationRequestModel } from '@app/core/model/registration.model';
import {
  FIRST_NAME_MAX_LENGTH,
  FIRST_NAME_MIN_LENGTH,
  LAST_NAME_MAX_LENGTH,
  LAST_NAME_MIN_LENGTH,
} from '@app/config/form-validation-rules.const';
import { Divider } from '@app/shared/divider/divider';
import { Link } from '@app/shared/typography/link/link';

import { registrationFormValidationRules } from './registration-form-validation-rules.const';
import css from './registration-form.module.scss';
import { MandatoryConsentCheckbox } from './component/mandatory-consent-checkbox/mandatory-consent-checkbox';
import { OptionalConsentCheckbox } from './component/optional-consent-checkbox/optional-consent-checkbox';
import { RegistrationForm } from './registration.form';

interface RegistrationFormViewProps {
  mandatoryLegalTexts: LegalText[];
  optionalLegalTexts?: LegalText[];
  defaultValues?: RegistrationRequestModel;
  loading?: boolean;
  onSubmit: (data: RegistrationRequestModel) => void;
  onViewLegalText: (text: LegalText) => void;
  onGoToAuthentication: () => void;
}

export const RegistrationFormView = ({
  mandatoryLegalTexts,
  optionalLegalTexts = [],
  defaultValues,
  loading = false,
  onSubmit,
  onViewLegalText,
  onGoToAuthentication,
}: RegistrationFormViewProps): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
  } = useForm<RegistrationForm>({
    mode: 'onChange',
    defaultValues: { ...defaultValues, legalText: false },
  });

  const [optionalConsents, setOptionalConsents] = useState<
    ConsentUpdateRequestModel[]
  >([]);
  const { t } = useTranslation();

  useEffect(() => {
    setOptionalConsents(
      optionalLegalTexts.map((legalText: LegalText) => ({
        id: legalText.id,
        isConsentGiven: false,
      }))
    );
  }, [optionalLegalTexts]);

  const handleChangeOnOptionalConsent = useCallback((consentId: string) => {
    setOptionalConsents((currentOptionalConsents) => {
      return currentOptionalConsents.map((consent) => {
        if (consent.id === consentId) {
          return {
            id: consent.id,
            isConsentGiven: !consent.isConsentGiven,
          };
        }
        return consent;
      });
    });
  }, []);

  const handleRegistrationSubmit = (data: RegistrationForm) => {
    const mandatoryConsents: ConsentUpdateRequestModel[] =
      mandatoryLegalTexts.map((text: LegalText) => ({
        id: text.id,
        isConsentGiven: data.legalText,
      }));

    onSubmit({
      consents: mandatoryConsents.concat(optionalConsents),
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      emailAddress: data.emailAddress.trim().toLowerCase(),
    });
  };

  return (
    <form
      className={css.formContainer}
      onSubmit={handleSubmit(handleRegistrationSubmit)}>
      <DrawerLayout>
        <Stepper current={1} total={3} />

        <DrawerLayout.Header className={css.header}>
          <H1>{t('registration.registration-form.title')}</H1>
          <Text>{t('registration.registration-form.subTitle')}</Text>
        </DrawerLayout.Header>

        <DrawerLayout.Body>
          <Controller
            name="firstName"
            control={control}
            rules={registrationFormValidationRules.firstName}
            render={({ field }) => (
              <TextInput
                required
                autoFocus
                autoComplete="given-name"
                label={t('registration.registration-form.first-name')}
                errorMessage={
                  errors?.firstName?.type === 'maxLength'
                    ? t(
                        'registration.registration-form.errors.first-name.max-length',
                        {
                          maxLength: FIRST_NAME_MAX_LENGTH,
                        }
                      )
                    : errors?.firstName?.type === 'minLength'
                    ? t(
                        'registration.registration-form.errors.first-name.min-length',
                        {
                          minLength: FIRST_NAME_MIN_LENGTH,
                        }
                      )
                    : errors?.firstName?.type === 'pattern'
                    ? t(
                        'registration.registration-form.errors.first-name.pattern'
                      )
                    : errors?.firstName?.type === 'required'
                    ? t(
                        'registration.registration-form.errors.first-name.required'
                      )
                    : ''
                }
                {...field}
                isTouched={dirtyFields.firstName}
              />
            )}
          />

          <Controller
            name="lastName"
            control={control}
            rules={registrationFormValidationRules.lastName}
            render={({ field }) => (
              <TextInput
                required
                autoComplete="family-name"
                label={t('registration.registration-form.last-name')}
                errorMessage={
                  errors?.lastName?.type === 'maxLength'
                    ? t(
                        'registration.registration-form.errors.last-name.max-length',
                        {
                          maxLength: LAST_NAME_MAX_LENGTH,
                        }
                      )
                    : errors?.lastName?.type === 'minLength'
                    ? t(
                        'registration.registration-form.errors.last-name.min-length',
                        {
                          minLength: LAST_NAME_MIN_LENGTH,
                        }
                      )
                    : errors?.lastName?.type === 'pattern'
                    ? t(
                        'registration.registration-form.errors.last-name.pattern'
                      )
                    : errors?.lastName?.type === 'required'
                    ? t(
                        'registration.registration-form.errors.last-name.required'
                      )
                    : ''
                }
                {...field}
                isTouched={dirtyFields.lastName}
              />
            )}
          />

          <Controller
            name="emailAddress"
            control={control}
            rules={registrationFormValidationRules.emailAddress}
            render={({ field }) => (
              <EmailInput
                required
                error={errors?.emailAddress}
                {...field}
                isTouched={dirtyFields.emailAddress}
              />
            )}
          />

          <Controller
            name="legalText"
            control={control}
            rules={registrationFormValidationRules.legalText}
            render={({ field }) => (
              <MandatoryConsentCheckbox
                onClick={onViewLegalText}
                legalTexts={mandatoryLegalTexts}
                id="legal-text"
                errorMessage={
                  errors?.legalText?.type === 'required'
                    ? t(
                        'registration.registration-form.errors.legal-text.required'
                      )
                    : ''
                }
                {...field}
                isTouched={!!errors?.legalText}
              />
            )}
          />

          {optionalLegalTexts.map((legalText) => (
            <OptionalConsentCheckbox
              key={legalText.title}
              label={legalText.title}
              onChange={() => handleChangeOnOptionalConsent(legalText.id)}
              onOpenLegalText={() => onViewLegalText(legalText)}
            />
          ))}
        </DrawerLayout.Body>

        <DrawerLayout.Footer className={css.footer}>
          <Button type="submit" disabled={!isValid}>
            {t('registration.registration-form.submit')}
          </Button>

          <Divider type="solid" />

          <div className={css.alreadyHaveAnAccount}>
            <Text color="secondary">
              {t('registration.registration-form.already-have-an-account')}
            </Text>
            <Link onClick={onGoToAuthentication} className={css.link}>
              {t('registration.registration-form.recover-account')}
            </Link>
          </div>
        </DrawerLayout.Footer>
      </DrawerLayout>

      <Fade show={loading}>
        <Overlay blur>
          <Spinner />
        </Overlay>
      </Fade>
    </form>
  );
};
