import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import ChargePointHeaderPlaceholderSvg from '@app/asset/charge-point-header-placeholder.svg';
import ChargePointPlaceholderSvg from '@app/asset/charge-point-placeholder.svg';

import { ChargePointModel } from '@app/core/model/charge-point.model';
import { Icon } from '@app/shared/icon/icon';
import { H1, H2 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';
import { useTranslation } from 'react-i18next';
import { CallSupportButton } from '@app/shared/call-support/call-support-button';
import { PhysicalReference } from '@app/shared/physical-reference/physical-reference';
import classNames from 'classnames';
import { Button } from '@app/shared/button/button';

import { Spinner } from '@app/shared/spinner/spinner';
import css from './charge-point.module.scss';
import { ConnectorCard } from './component/connector-card/connector-card';

type Props = {
  name?: string;
  address?: string;
  physicalReference?: string;
  imageUrl?: string;
  connectors: ChargePointModel[];
  onSelect: (connector: ChargePointModel) => void;
};

export function ChargePointView(props: Props): JSX.Element {
  const { name, address, physicalReference, imageUrl, connectors, onSelect } =
    props;
  const { t } = useTranslation();

  const handleSelectFirst = () => {
    const connector = connectors.at(0);
    if (connector) {
      onSelect(connector);
    }
  };

  return (
    <PageLayout
      className={css.page}
      topClassName={classNames(!!imageUrl && css.lightLogo)}
      withHomeButton>
      <div className={css.hero} style={{ backgroundImage: imageUrl }}>
        {!imageUrl && <ChargePointHeaderPlaceholderSvg />}
      </div>

      <PageLayout.Header className={css.header}>
        <div className={css.title}>
          <Icon name="electric-terminal" />
          <H1>{name}</H1>
        </div>

        <div className={css.infos}>
          {address && <Text>{address}</Text>}
          {physicalReference && (
            <PhysicalReference physicalReference={physicalReference} />
          )}
        </div>
      </PageLayout.Header>

      <PageLayout.Body>
        <div className={css.title}>
          <Icon name="wire" />
          {connectors.length === 1 ? (
            <H2>{t('charge-point.connector.single-connector-title')}</H2>
          ) : (
            <H2>{t('charge-point.connector.multiple-connectors-title')}</H2>
          )}
        </div>

        {connectors.length === 0 && <Spinner />}

        {connectors.map((connector) => (
          <ConnectorCard
            key={connector.chargePointId}
            {...connector}
            onClick={() => onSelect(connector)}
          />
        ))}

        {connectors.length <= 1 && (
          <div className={css.placeholder}>
            <ChargePointPlaceholderSvg />
          </div>
        )}

        <CallSupportButton />
      </PageLayout.Body>

      <PageLayout.Footer>
        {connectors.length === 1 && (
          <Button onClick={handleSelectFirst}>
            {t('charge-point.start-button')}
          </Button>
        )}
      </PageLayout.Footer>
    </PageLayout>
  );
}
