import css from './recharge-card.module.scss';
import { IsChargingBody } from './is-charging-body';
import { LastChargeBody } from './last-charge-body';
import { NoChargeBody } from './no-charge-body';

type Props = {
  chargePointName?: string;
  startedAt?: Date;
  isCharging: boolean;
};

export function RechargeCardBody(props: Props): JSX.Element {
  const { chargePointName, startedAt, isCharging } = props;

  if (isCharging) {
    return (
      <IsChargingBody chargePointName={chargePointName} className={css.body} />
    );
  }

  if (startedAt || chargePointName) {
    return (
      <LastChargeBody
        startedAt={startedAt}
        chargePointName={chargePointName}
        className={css.body}
      />
    );
  }

  return <NoChargeBody className={css.body} />;
}
