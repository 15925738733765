import { useCallback } from 'react';
import {
  NavigateOptions,
  To,
  useLocation,
  useNavigate,
} from 'react-router-dom';

type NavigateFunction = (to: To, options?: NavigateOptions) => void;

export const useNavigateWithState = (): NavigateFunction => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = useCallback(
    (to: To, options?: NavigateOptions): void => {
      navigate(to, {
        ...options,
        state: { ...options?.state, ...location.state },
      });
    },
    [navigate, location.state]
  );

  return handleNavigate;
};
