import { useTranslation } from 'react-i18next';

import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';
import { ItemMenu } from '@app/shared/item-menu/item-menu';

import css from './invoice-list-item.module.scss';

interface InvoiceListItemProps {
  chargePointName: string;
  createdAt: Date;
  proforma?: boolean;
  totalCost?: number;
  onClick?: () => void;
}

export const InvoiceListItem = ({
  chargePointName,
  createdAt,
  proforma = false,
  totalCost = 0,
  onClick = () => {},
}: InvoiceListItemProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={css.container}>
      <div className={css.row}>
        <Icon name="calendar-date" />

        <div className={css.datetime}>
          <Text>{t('invoicing.date', { value: createdAt })}</Text>
          <Text className={css.italic}>
            {t('invoicing.time', { value: createdAt })}
          </Text>
        </div>
      </div>

      <div className={css.row}>
        <div className={css.lineContainer}>
          <div className={css.point} />
          <div className={css.line} />
        </div>

        <ItemMenu
          onClick={onClick}
          left={<Icon name="marker-pin-01" />}
          label={chargePointName}
          right={
            <>
              {proforma ? (
                <Icon name="credit-card-refresh" className={css.proformaIcon} />
              ) : (
                <Icon name="credit-card-check" />
              )}

              {totalCost > 0 && (
                <Text bold>
                  {t('invoicing.dashboard.item.total-cost', {
                    totalCost,
                  })}
                </Text>
              )}

              <Icon name="chevron-right" color="primary" />
            </>
          }
        />
      </div>
    </div>
  );
};
