export enum OrderStatusEnum {
  QUOTE_CREATED,
  QUOTE_VALIDATED_WITHOUT_PAYMENT,
  PRE_CAPTURE_REQUEST_REFUSED,
  PRE_CAPTURE_REQUEST_ACCEPTED,
  PRE_CAPTURE_WEBHOOK_REFUSED,

  PRE_CAPTURE_WEBHOOK_ACCEPTED,
  PRE_CAPTURE_SKIPPED,
  PRE_CAPTURE_REUSED,

  ORDER_OUTDATED,
  QUOTE_VALIDATED,
  INVOICE_PROFORMA_CREATED,
  INVOICE_EDITED,
  CAPTURE_REQUEST_REFUSED,
  CAPTURE_REQUEST_ACCEPTED,
  CAPTURE_WEBHOOK_REFUSED,
  CAPTURE_WEBHOOK_ACCEPTED,
  INVOICE_TERMINATED_ERROR,
  INVOICE_TERMINATED_SUCCESS,
}
