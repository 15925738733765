import i18n from '@app/core/locale/i18n';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { FAQ_PATH, GUEST_FAQ_PATH } from '@app/config/app-paths.const';
import { DEFAULT_NAMESPACE, LOCALE_KEYS } from '@app/core/locale/locale';
import {
  getFaqSections,
  localeFaqQuestionsRoot,
} from '@app/feature/faq/get-faq-item';

import { useUser } from '@app/core/context-providers/user-context/use-user';
import en from './asset/faq-en.json';
import fr from './asset/faq-fr.json';
import { FaqView } from './faq.view';

i18n
  .addResourceBundle(
    LOCALE_KEYS.en,
    DEFAULT_NAMESPACE,
    { [localeFaqQuestionsRoot]: en },
    true
  )
  .addResourceBundle(
    LOCALE_KEYS.fr,
    DEFAULT_NAMESPACE,
    { [localeFaqQuestionsRoot]: fr },
    true
  );

export const FaqController = (): JSX.Element => {
  const faqSections = useMemo(getFaqSections, []);
  const { user } = useUser();

  const navigate = useNavigate();

  const handleNavigation = (section: string): void => {
    const path = user ? FAQ_PATH : GUEST_FAQ_PATH;
    navigate(`${path}/${section}`);
  };

  return <FaqView faqSections={faqSections} onSelect={handleNavigation} />;
};
