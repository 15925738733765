import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { PaymentMethodModel } from '@app/core/model/payment-method.model';
import { PaymentSchemeLogo } from '../payment-scheme-logo/payment-scheme-logo';
import { ItemMenu, ItemMenuProps } from '../item-menu/item-menu';

import css from './credit-card-item.module.scss';

interface CreditCardItemProps
  extends Pick<PaymentMethodModel, 'scheme' | 'last4' | 'name'>,
    Omit<ItemMenuProps, 'image' | 'label'> {
  preferred?: boolean;
}

export const CreditCardItem = (props: CreditCardItemProps): JSX.Element => {
  const { scheme, last4, name, preferred, ...itemMenuProps } = props;

  const { t } = useTranslation();

  return (
    <ItemMenu
      {...itemMenuProps}
      left={<PaymentSchemeLogo paymentScheme={scheme} />}
      label={
        name ??
        t('shared.credit-card-item.label', {
          digit: last4,
        })
      }
      caption={
        name &&
        t('shared.credit-card-item.label', {
          digit: last4,
        })
      }
      className={classNames(preferred && css.preferred)}
    />
  );
};
