import { useTranslation } from 'react-i18next';
import { InfosWithLegend } from '@app/shared/infos-with-legend/infos-with-legend';

type Props = {
  totalTTC?: number;
  disabled?: boolean;
  className?: string;
};

export const LiveTotalTTC = (props: Props): JSX.Element => {
  const { totalTTC = 0, disabled, className } = props;
  const { t } = useTranslation();

  return (
    <InfosWithLegend
      icon="currency-euro"
      className={className}
      disabled={disabled}
      value={t('charging.live-total-ttc.value', {
        value: totalTTC,
      })}
      label={t('charging.live-total-ttc.label')}
    />
  );
};
