import { HTMLProps } from 'react';
import classNames from 'classnames';

import css from './heading.module.scss';
import cssTypography from '../typography.module.scss';

export interface HeadingProps extends HTMLProps<HTMLHeadingElement> {
  truncate?: boolean;
  color?: 'primary' | 'secondary' | 'tertiary' | 'light' | 'p1';
}

export const H1 = ({
  truncate = false,
  color = 'primary',
  children,
  className,
  ...props
}: HeadingProps): JSX.Element => (
  <h1
    className={classNames(
      css.h1,
      color === 'primary' && cssTypography.primary,
      color === 'secondary' && cssTypography.secondary,
      color === 'tertiary' && cssTypography.tertiary,
      color === 'light' && cssTypography.light,
      color === 'p1' && cssTypography.p1,
      truncate && cssTypography.truncate,
      className
    )}
    {...props}>
    {children}
  </h1>
);

export const H2 = ({
  color = 'primary',
  truncate = false,
  children,
  className,
  ...props
}: HeadingProps): JSX.Element => (
  <h2
    className={classNames(
      css.h2,
      color === 'primary' && cssTypography.primary,
      color === 'secondary' && cssTypography.secondary,
      color === 'tertiary' && cssTypography.tertiary,
      color === 'light' && cssTypography.light,
      color === 'p1' && cssTypography.p1,
      truncate && cssTypography.truncate,
      className
    )}
    {...props}>
    {children}
  </h2>
);

export const H3 = ({
  color = 'secondary',
  truncate = false,
  children,
  className,
  ...props
}: HeadingProps): JSX.Element => (
  <h3
    className={classNames(
      css.h3,
      color === 'primary' && cssTypography.primary,
      color === 'secondary' && cssTypography.secondary,
      color === 'tertiary' && cssTypography.tertiary,
      color === 'light' && cssTypography.light,
      color === 'p1' && cssTypography.p1,
      truncate && cssTypography.truncate,
      className
    )}
    {...props}>
    {children}
  </h3>
);
