import { WithUserProps } from '@app/router/guard/with-user.guard';

import { ManageAuthenticationMethodView } from './manage-authentication-method.view';

export const ManageAuthenticationMethodController = (
  props: WithUserProps
): JSX.Element => {
  const { user, refresh: refreshUser } = props;

  return (
    <ManageAuthenticationMethodView
      webauthnAuthenticators={user.authenticators.webauthn}
      isEmailVerified={!!user.isEmailAddressVerified}
      emailAddress={user.emailAddress}
      onRefresh={refreshUser}
    />
  );
};
