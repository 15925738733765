import { useTranslation } from 'react-i18next';
import { Interpolation, LineChartOptions } from 'chartist';

import { LineChart } from '@app/feature/charging/component/charge-chart/line-chart/line-chart';
import { ChartProps } from '@app/feature/charging/component/charge-chart/model/chart-props';

import { ChargeChartLayout } from './charge-chart-layout/charge-chart-layout';

function getChartOptions(numberOfPoints: number): Partial<LineChartOptions> {
  return {
    lineSmooth:
      numberOfPoints > 16 ? Interpolation.simple() : Interpolation.step(),
    showPoint: false,
    showArea: true,
  };
}

export const EnergyDeliveredChart = (props: ChartProps): JSX.Element => {
  const { points } = props;
  const { t } = useTranslation();

  return (
    <ChargeChartLayout
      icon="bar-chart-08"
      title={t('charging.chart.energy-delivered.title')}
      subTitle={t('charging.chart.energy-delivered.sub-title')}>
      <LineChart points={points} options={getChartOptions(points.length)}>
        {({ chartRef }) => (
          <div className="energy-delivered-chart" ref={chartRef} />
        )}
      </LineChart>
    </ChargeChartLayout>
  );
};
