import { useTranslation } from 'react-i18next';
import { Button } from '@app/shared/button/button';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { H1 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';

import PageNotFoundSvg from '@app/asset/page-not-found-error.svg';

import css from './page-not-found.module.scss';

interface PageNotFoundViewProps {
  onHome: () => void;
}

export const PageNotFoundView = (props: PageNotFoundViewProps): JSX.Element => {
  const { onHome } = props;
  const { t } = useTranslation();

  return (
    <PageLayout>
      <PageLayout.Body className={css.body}>
        <PageNotFoundSvg className={css.svg} />

        <H1>{t('page-not-found.title')}</H1>

        <Text>{t('page-not-found.caption')}</Text>
      </PageLayout.Body>

      <PageLayout.Footer>
        <Button onClick={onHome}>{t('page-not-found.home-button')}</Button>
      </PageLayout.Footer>
    </PageLayout>
  );
};
