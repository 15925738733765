import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Text } from '@app/shared/typography/text/text';
import { Icon } from '@app/shared/icon/icon';

import css from './no-charge.module.scss';

type Props = {
  className?: string;
};

export const NoChargeBody = (props: Props): JSX.Element => {
  const { className } = props;
  const { t } = useTranslation();

  return (
    <div className={classNames(css.container, className)}>
      <Text>{t('dashboard.recharge-card.no-charge.title')}</Text>
      <div className={css.scan}>
        <Icon name="qr-code-02" />
        {t('dashboard.recharge-card.no-charge.scan')}
      </div>
    </div>
  );
};
