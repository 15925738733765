import { useMemo } from 'react';

import { PowerTypeEnum } from '@app/core/model/enum/power-type.enum';
import { Icon, IconName, IconProps } from '@app/shared/icon/icon';

interface PowerTypeProps extends Omit<IconProps, 'name'> {
  powerType?: PowerTypeEnum;
}

export const PowerTypeIcon = (props: PowerTypeProps): JSX.Element => {
  const { powerType, ...iconProps } = props;

  const powerTypeIconName: IconName = useMemo(
    () => (powerType === PowerTypeEnum.DC ? 'power-dc' : 'power-ac'),
    [powerType]
  );

  return <Icon name={powerTypeIconName} {...iconProps} />;
};
