import { useNavigate } from 'react-router-dom';

import { FAQ_PATH, TARIFICATION_PATH } from '@app/config/app-paths.const';
import { ItemMenuInterface } from '@app/shared/item-menu/item-menu.interface';

import { HelpView } from './help.view';

export const HelpController = (): JSX.Element => {
  const navigate = useNavigate();

  const viewItems: ItemMenuInterface[] = [
    {
      label: 'help.faq',
      onClick: () => {
        navigate(FAQ_PATH);
      },
    },
    {
      label: 'help.tarification',
      onClick: () => {
        navigate(TARIFICATION_PATH);
      },
      // TODO: 04/07/2023 - disable when BFP-7 is merged
      disabled: true,
    },
  ];
  return <HelpView items={viewItems} />;
};
