import { useCallback, useMemo, useState } from 'react';

interface DialogHook {
  isDialogOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
}

export const useDialog = (): DialogHook => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = useCallback(() => setIsDialogOpen(true), []);

  const closeDialog = useCallback(() => setIsDialogOpen(false), []);

  return useMemo(
    () => ({
      isDialogOpen,
      openDialog,
      closeDialog,
    }),
    [isDialogOpen, openDialog, closeDialog]
  );
};
