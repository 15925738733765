import { forwardRef, HTMLProps } from 'react';
import classNames from 'classnames';
import css from './overlay.module.scss';

interface OverlayProps extends HTMLProps<HTMLDivElement> {
  blur?: boolean;
  variant?: 'light' | 'dark' | 'colored';
  onClick?: () => void;
}

export const Overlay = forwardRef<HTMLDivElement, OverlayProps>(
  (
    {
      blur = false,
      variant = 'light',
      onClick = () => {},
      className,
      children,
      ...props
    }: OverlayProps,
    ref
  ): JSX.Element => (
    <div
      ref={ref}
      onClick={onClick}
      className={classNames(
        className,
        css.overlay,
        blur && css.blur,
        variant === 'light' && css.light,
        variant === 'dark' && css.dark,
        variant === 'colored' && css.colored
      )}
      {...props}>
      <div className={css.body}>{children}</div>
    </div>
  )
);
