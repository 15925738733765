import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PlaceHolderInvoice from '@app/asset/placeholder-invoice.svg';
import { SCAN_PATH } from '@app/config/app-paths.const';
import { InvoiceModel } from '@app/core/model/invoice.model';
import { Divider } from '@app/shared/divider/divider';
import { Icon } from '@app/shared/icon/icon';
import {
  InfiniteScroll,
  InfiniteScrollProps,
} from '@app/shared/infinite-scroll/infinite-scroll';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { ListItemMenu } from '@app/shared/list-item-menu/list-item-menu';
import { Link } from '@app/shared/typography/link/link';
import { Text } from '@app/shared/typography/text/text';

import { InvoiceListItem } from './component/invoice-list-item/invoice-list-item';
import { InvoiceSectionTitle } from './component/invoice-section-title/invoice-section-title';
import css from './invoice-dashboard.module.scss';

interface InvoiceDashboardProps extends Omit<InfiniteScrollProps, 'children'> {
  pending?: boolean;
  proformaList?: InvoiceModel[];
  invoiceList?: InvoiceModel[];
  onSelect: (invoice: InvoiceModel) => void;
}

export const InvoiceDashboardView = (
  props: InvoiceDashboardProps
): JSX.Element => {
  const {
    pending = false,
    proformaList = [],
    invoiceList = [],
    onSelect,
    ...infinitieScrollProps
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const numberOfItems = useMemo(
    () => proformaList.length + invoiceList.length,
    [proformaList.length, invoiceList.length]
  );

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.Title
          title={t('invoicing.dashboard.title')}
          icon="file-05"
        />
      </PageLayout.Header>

      <PageLayout.Body>
        <div className={css.section}>
          {proformaList.length > 0 && (
            <InvoiceSectionTitle numberOfItems={proformaList.length}>
              {t('invoicing.dashboard.proforma-section')}
            </InvoiceSectionTitle>
          )}

          <ListItemMenu>
            {proformaList.map((order) => (
              <InvoiceListItem
                proforma
                key={order.invoiceId}
                chargePointName={order.chargePointName}
                createdAt={order.createdAt}
                totalCost={order.cost.totalTTC}
                onClick={() => onSelect(order)}
              />
            ))}
          </ListItemMenu>
        </div>

        {numberOfItems > 0 && <Divider type="solid" />}

        <div className={css.section}>
          {invoiceList.length > 0 && (
            <InvoiceSectionTitle numberOfItems={invoiceList.length}>
              {t('invoicing.dashboard.history-section')}
            </InvoiceSectionTitle>
          )}

          <ListItemMenu>
            <InfiniteScroll {...infinitieScrollProps}>
              {invoiceList.map((invoice) => (
                <InvoiceListItem
                  key={invoice.invoiceId}
                  chargePointName={invoice.chargePointName}
                  createdAt={invoice.createdAt}
                  totalCost={invoice.cost.totalTTC}
                  onClick={() => onSelect(invoice)}
                />
              ))}
            </InfiniteScroll>
          </ListItemMenu>
        </div>

        {numberOfItems === 0 && (
          <>
            <div className={classNames(css.section, css.placeholder)}>
              <PlaceHolderInvoice />
            </div>

            {!pending && (
              <div className={classNames(css.section, css.sectionNoInvoice)}>
                <Text color="secondary">
                  {t('invoicing.dashboard.empty.start-charging')}
                </Text>
                <Link
                  onClick={() => navigate(SCAN_PATH)}
                  endIcon={<Icon name="arrow-narrow-right" />}>
                  {t('invoicing.dashboard.empty.scan-link')}
                </Link>
              </div>
            )}
          </>
        )}
      </PageLayout.Body>

      <PageLayout.AppbarFooter />
    </PageLayout>
  );
};
