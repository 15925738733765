import { Logger } from '@app/core/logger/logger';

function getRootThemeElement(): HTMLElement | null {
  const rootElement = document.body;

  if (!rootElement) {
    Logger.error('Cannot find body element to apply theme');
    return null;
  }

  return rootElement;
}

export function removeTheme(): void {
  const rootElement = getRootThemeElement();

  if (rootElement) {
    rootElement.removeAttribute('class');
  }
}

export function applyTheme(themeName: string): void {
  removeTheme();

  const rootElement = getRootThemeElement();

  if (rootElement) {
    rootElement.classList.add(themeName);
  }
}
