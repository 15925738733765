import { SubscriptionDetailsModel } from '@app/core/model/subscription.model';
import { Text } from '@app/shared/typography/text/text';

import css from './user-subscription-item.module.scss';

interface UserSubscriptionItemProps {
  subscription: SubscriptionDetailsModel;
}

export function UserSubscriptionItem(
  props: UserSubscriptionItemProps
): JSX.Element {
  const { subscription } = props;
  const { name } = subscription;

  return (
    <div className={css.container}>
      <Text bold fontSize="large">
        {name}
      </Text>
    </div>
  );
}
