import { useLayoutEffect, useState } from 'react';
import { Logger } from '@app/core/logger/logger';
import { loadScript } from './load-script';

export const useLoadScript = (
  url: string,
  successCallback?: () => void,
  errorCallback?: (error: any) => void
): boolean => {
  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    let cleanUp = () => {
      // This is intentional to default return a void clean-up.
    };

    if (!url) {
      Logger.error(`No url provided in useLoadScript. Received : ${url}`);
      return cleanUp();
    }

    loadScript(url)
      .then((cleanUpCallback: () => void) => {
        if (successCallback) {
          successCallback();
        }
        cleanUp = cleanUpCallback;
      })
      .catch((error) => {
        Logger.error('useScriptLoading', error);
        if (errorCallback) {
          errorCallback(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    return cleanUp();
  }, [url, successCallback, errorCallback]);

  return isLoading;
};
