import { useTranslation } from 'react-i18next';
import { H2 } from '@app/shared/typography/heading/heading';
import { InvoiceModel } from '@app/core/model/invoice.model';

import css from './service-section.module.scss';
import { ServiceSectionBody } from './service-section-body';

type Props = {
  chargePointName?: string;
  lastInvoice: InvoiceModel | null;
  consumptions?: number[];
  onNavigateToInvoices: () => void;
  onClickOnInvoice: () => void;
};

export function ServiceSection(props: Props): JSX.Element {
  const {
    lastInvoice,
    chargePointName,
    consumptions,
    onNavigateToInvoices,
    onClickOnInvoice,
  } = props;
  const { t } = useTranslation();

  return (
    <div className={css.section}>
      <H2>{t('dashboard.service-section.title')}</H2>

      <div className={css.cards}>
        <ServiceSectionBody
          chargePointName={chargePointName}
          lastInvoice={lastInvoice}
          consumptions={consumptions}
          onClickOnLastCharges={onNavigateToInvoices}
          onClickOnInvoice={onClickOnInvoice}
        />
      </div>
    </div>
  );
}
