import { useCallback, useMemo, useState } from 'react';

import { mapPaginatedResponseToObject } from '@app/core/pagination/map-paginated-response-to-object';
import { PaginatedResponseModel } from '@app/core/model/paginated-response.model';

export interface PaginatedResponseHookReturn<T> {
  onRequestWithPagination: (...args: any[]) => Promise<T[]>;
  hasMore: boolean;
}

type LoadExecutor<T> = (...args: any[]) => Promise<PaginatedResponseModel<T>>;

interface PaginatedResponseHookProps<T> {
  onRequestData: LoadExecutor<T>;
  incrementPage: () => void;
}

export const usePaginatedResponse = <T>(
  props: PaginatedResponseHookProps<T>
): PaginatedResponseHookReturn<T> => {
  const { onRequestData, incrementPage } = props;
  const [hasMore, setHasMore] = useState(true);

  const handlePaginatedResponse = useCallback(
    (response: PaginatedResponseModel<T>): PaginatedResponseModel<T> => {
      setHasMore(response.hasNextPage);
      return response;
    },
    []
  );

  const handlePaginatedError = useCallback((error: unknown) => {
    setHasMore(false);
    throw error;
  }, []);

  const onRequestWithPagination = useCallback(
    (...args: any[]): Promise<T[]> => {
      return onRequestData(...args)
        .then(handlePaginatedResponse)
        .then(mapPaginatedResponseToObject)
        .then((response) => {
          incrementPage();
          return response;
        })
        .catch(handlePaginatedError);
    },
    [
      onRequestData,
      handlePaginatedResponse,
      handlePaginatedError,
      incrementPage,
    ]
  );

  return useMemo(
    () => ({
      onRequestWithPagination,
      hasMore,
    }),
    [onRequestWithPagination, hasMore]
  );
};
