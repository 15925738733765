import { ACCOUNT_PATH } from '@app/config/app-paths.const';
import {
  FACEBOOK_URL,
  LINKEDIN_URL,
  TWITTER_URL,
  WEBSITE_URL,
  YOUTUBE_URL,
} from '@app/config/external-url';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { ListItemMenu } from '@app/shared/list-item-menu/list-item-menu';
import { OuterLink } from '@app/shared/outer-link/outer-link';
import { useTranslation } from 'react-i18next';
import { AccountItemMenu } from '../../component/account-item-menu/account-item-menu';
import css from './about.module.scss';

export const AboutView = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <PageLayout withHomeButton>
      <PageLayout.Title
        title={t('account.about-us.title')}
        icon="delmonicos"
        backTo={ACCOUNT_PATH}
      />
      <PageLayout.Body className={css.body}>
        <ListItemMenu title={t('account.about-us.website.title')}>
          <OuterLink title="Delmonicos" url={WEBSITE_URL} withIcon={false}>
            <AccountItemMenu
              icon="delmonicos"
              label={t('account.about-us.website.visit-label')}
              caption={WEBSITE_URL}
            />
          </OuterLink>
        </ListItemMenu>
        <ListItemMenu title={t('account.about-us.application.title')}>
          <AccountItemMenu
            icon="star-01"
            label={t('account.about-us.application.visit-label')}
            caption={t('account.about-us.application.caption')}
            disabled
          />
        </ListItemMenu>
        <ListItemMenu title={t('account.about-us.blog.title')}>
          <OuterLink title="Linkedin" url={LINKEDIN_URL} withIcon={false}>
            <AccountItemMenu icon="rs-linkedin" label="LinkedIn" />
          </OuterLink>
          <OuterLink title="Facebook" url={FACEBOOK_URL} withIcon={false}>
            <AccountItemMenu icon="rs-facebook" label="Facebook" />
          </OuterLink>
          <OuterLink title="X" url={TWITTER_URL} withIcon={false}>
            <AccountItemMenu icon="rs-twitter" label="X" />
          </OuterLink>
          <OuterLink title="Youtube" url={YOUTUBE_URL} withIcon={false}>
            <AccountItemMenu icon="rs-youtube" label="YouTube" />
          </OuterLink>
        </ListItemMenu>
      </PageLayout.Body>
    </PageLayout>
  );
};
