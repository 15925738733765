import { useMemo } from 'react';

import { LAST_AUTHENTICATION_METHOD_LSK } from '@app/config/localstorage-keys.const';
import { useUser } from '@app/core/context-providers/user-context/use-user';
import { AuthenticationMethodEnum } from '@app/core/model/enum/authentication-method.enum';
import { getFromLocalStorage } from '@app/core/storage/local-storage';
import { RedirectToInitialDestination } from '@app/router/redirect/redirect-to-initial-destination';
import { useRedirectToInitialDestination } from '@app/router/redirect/use-redirect-to-initial-destination';

import { FirstLoginView } from './first-login.view';

function getAuthenticationMethod(): AuthenticationMethodEnum | null {
  const lastAuthenticationMethod = getFromLocalStorage(
    LAST_AUTHENTICATION_METHOD_LSK
  ) as AuthenticationMethodEnum | null;

  if (!lastAuthenticationMethod) {
    return null;
  }

  return Number(lastAuthenticationMethod);
}

export const FirstLoginController = (): JSX.Element => {
  const { user } = useUser();
  const authenticationMethod = useMemo(getAuthenticationMethod, []);

  const redirectToInitialDestination = useRedirectToInitialDestination();

  if (!user?.pseudo) {
    return <RedirectToInitialDestination />;
  }

  return (
    <FirstLoginView
      authenticationMethod={authenticationMethod}
      onContinue={redirectToInitialDestination}
    />
  );
};
