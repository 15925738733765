import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { H1 } from '@app/shared/typography/heading/heading';
import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';

interface DeletePaymentMethodSuccessViewProps {
  onContinue: () => void;
}

export const DeletePaymentMethodSuccessView = ({
  onContinue,
}: DeletePaymentMethodSuccessViewProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <DialogLayout>
      <DialogLayout.Body>
        <Icon name="check" />
        <H1>{t('user-payment-method.delete.success.title')}</H1>
        <Text>{t('user-payment-method.delete.success.sub-title')}</Text>
      </DialogLayout.Body>

      <DialogLayout.Footer>
        <Button onClick={onContinue}>
          {t('user-payment-method.delete.success.button')}
        </Button>
      </DialogLayout.Footer>
    </DialogLayout>
  );
};
