export const BASE_CHECKOUT_CONFIGURATION = {
  style: {
    base: {
      color: '#3D4047',
      letterSpacing: '1px',
      fontFamily: 'work, System',
    },
    placeholder: {
      base: {
        color: 'transparent',
      },
      focus: {
        color: 'transparent',
      },
    },
  },
  schemeChoice: true,
};
