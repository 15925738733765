import { useTranslation } from 'react-i18next';

import { Text } from '@app/shared/typography/text/text';
import { SendByEmailButton } from './send-by-email.button';

interface ProformaFooterProps {
  autoSendInvoiceByMail: boolean;
  isEmailAddressVerified: boolean;
}

export const ProformaFooter = (props: ProformaFooterProps): JSX.Element => {
  const { autoSendInvoiceByMail, isEmailAddressVerified } = props;

  const { t } = useTranslation();

  return autoSendInvoiceByMail ? (
    <Text>{t('shared.invoice.footer.invoice-sent-automatically')}</Text>
  ) : (
    <SendByEmailButton isEmailVerified={isEmailAddressVerified} />
  );
};
