import { useCallback } from 'react';

import { TIMEOUT } from '@app/config/timeout.const';
import { useWebsocket } from '@app/core/client/websocket/use-websocket';
import { ORDER_WST } from '@app/config/api-routes.const';
import { ApiError } from '@app/core/error/api-error';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { mapCheckoutErrorToTranslationKey } from '@app/core/helper/map-checkout-error-to-translation-key';
import { useOrder } from '@app/core/context-providers/order-context/use-order';
import { OrderModel } from '@app/core/model/order.model';
import { catchApiError } from '@app/core/error/catch-api-error';
import { orderService } from '@app/core/service/order.service';
import { OrderEventModel } from '@app/core/model/order-event.model';
import { OrderStatusEnum } from '@app/core/model/enum/order-status-enum';
import { useTimeout } from '@app/core/helper/use-timeout';
import { useInterval } from '@app/core/helper/use-interval';

import { CheckoutPendingView } from './checkout-pending.view';

interface CheckoutPendingControllerProps {
  onSuccess: () => void;
  onFailure: () => void;
}

export const CheckoutPendingController = (
  props: CheckoutPendingControllerProps
): JSX.Element => {
  const { onSuccess, onFailure } = props;
  const { onOrder } = useOrder();

  const handleSyncPrecapture = useCallback(() => {
    const [syncPrecapturePromise] = orderService.syncPrecapture();

    syncPrecapturePromise.catch((error) => {
      catchApiError(error, (apiError: ApiError) => {
        const translationKey = mapCheckoutErrorToTranslationKey(apiError.code);
        showErrorMessage(translationKey, apiError.code);
      });
    });
  }, []);

  useWebsocket(ORDER_WST, (response: OrderEventModel) => {
    const payload = new OrderModel(response.order);

    if (
      payload.status === OrderStatusEnum.PRE_CAPTURE_WEBHOOK_ACCEPTED ||
      payload.status === OrderStatusEnum.PRE_CAPTURE_REUSED ||
      payload.status === OrderStatusEnum.PRE_CAPTURE_SKIPPED
    ) {
      onSuccess();
      onOrder(payload);
    }

    if (payload.status === OrderStatusEnum.PRE_CAPTURE_WEBHOOK_REFUSED) {
      onFailure();
    }
  });

  // Triggers a POST PRE_CAPTURE_SYNC_API in order to re-emit pre-capture status in websoket
  useInterval(handleSyncPrecapture, TIMEOUT.preCaptureSyncInterval * 1000);

  // Failover if nothing has arrived in websocket.
  useTimeout(onFailure, TIMEOUT.preCaptureSyncFail * 1000);

  return <CheckoutPendingView />;
};
