import { configurationService } from '@app/core/configuration/configuration.service';

const themeIds = configurationService.getThemeId();

export const themeConfig: Record<
  string,
  () => Promise<typeof import('*.module.scss')>
> = {
  [themeIds.theme1]: () =>
    import(
      /* webpackChunkName: "theme1" */ '@app/shared/styles/theme/theme1.module.scss'
    ),
  [themeIds.theme2]: () =>
    import(
      /* webpackChunkName: "theme2" */ '@app/shared/styles/theme/theme2.module.scss'
    ),
};
