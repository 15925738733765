import { useCallback, useState } from 'react';

import { useRedirectUrlResponse } from '@app/core/helper/use-redirect-url-response';

import { PaymentView } from './payment.view';
import { CheckoutFrameController } from './view/checkout-frame/checkout-frame.controller';

export interface PaymentControllerProps {
  currentPaymentMethodId?: string;
  onSelect: () => void;
  onBack: () => void;
}

export const PaymentController = ({
  currentPaymentMethodId,
  onSelect,
  onBack,
}: PaymentControllerProps): JSX.Element => {
  const [showCreateCheckoutPaymentMethod, setShowCreateCheckoutPaymentMethod] =
    useState(false);

  const handleCreatePaymentMethod = useCallback(() => {
    setShowCreateCheckoutPaymentMethod(true);
  }, []);

  const handleHideCreatePaymentMethod = useCallback(() => {
    setShowCreateCheckoutPaymentMethod(false);
  }, []);

  const handleCreatePaymentMethodError = () => {
    setTimeout(handleHideCreatePaymentMethod, 1000); // Prevent drawer from disappearing too quickly
  };

  const handleSelect = useRedirectUrlResponse(onSelect);

  return showCreateCheckoutPaymentMethod ? (
    <CheckoutFrameController
      onSubmit={handleSelect}
      onError={handleCreatePaymentMethodError}
    />
  ) : (
    <PaymentView
      currentPaymentMethodId={currentPaymentMethodId}
      onCreateCheckoutPaymentMethod={handleCreatePaymentMethod}
      onSelect={handleSelect}
      onBack={onBack}
    />
  );
};
