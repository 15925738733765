import { useTranslation } from 'react-i18next';

import QrCodeScannerFrame from '@app/asset/qr-code-scanner-frame.svg';
import { Text } from '@app/shared/typography/text/text';

import { Scanner, ScannerProps } from './scanner';
import css from './qr-code-scanner.module.scss';

export const QrCodeScanner = (props: ScannerProps): JSX.Element => {
  const { onScan, onCameraError } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className={css.scannerContainer}>
        <Scanner
          className={css.scanner}
          onScan={onScan}
          onCameraError={onCameraError}
        />
        <QrCodeScannerFrame className={css.scannerFrame} />
      </div>

      <Text fontSize="large" bold className={css.text}>
        {t('scan.tips')}
      </Text>
    </>
  );
};
