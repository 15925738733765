import classNames from 'classnames';

import { Text, TextProps } from '@app/shared/typography/text/text';
import css from './step-footnote.module.scss';

export const StepFootnote = (props: TextProps): JSX.Element => {
  const { className, ...textProps } = props;

  return (
    <Text className={classNames(className, css.footnote)} {...textProps} />
  );
};
