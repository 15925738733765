import './variables.scss';
import './mixins.scss';
import './style.scss';

import 'react-toastify/dist/ReactToastify.css';
import '../toast/toast.scss';
import { SvgGradient } from './svg-gradient';

export function AppStyle(): JSX.Element {
  return <SvgGradient />;
}
