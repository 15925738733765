import { FAQ_PATH, GUEST_FAQ_PATH } from '@app/config/app-paths.const';
import { FaqModel } from '@app/core/model/faq.model';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { useUser } from '@app/core/context-providers/user-context/use-user';
import { FaqQuestion } from './faq-question';
import css from './faq-section.module.scss';

interface FaqSectionViewProps {
  title: string;
  items: FaqModel[];
}

export const FaqSectionView = ({
  title,
  items,
}: FaqSectionViewProps): JSX.Element => {
  const { user } = useUser();
  return (
    <PageLayout>
      <PageLayout.Title
        title={title}
        icon="delmonicos"
        backTo={user ? FAQ_PATH : GUEST_FAQ_PATH}
      />

      <PageLayout.Body className={css.body}>
        {items.map((item) => (
          <FaqQuestion
            key={item.id}
            question={item.question}
            answers={item.answers}
          />
        ))}
      </PageLayout.Body>
    </PageLayout>
  );
};
