import { H2, HeadingProps } from '@app/shared/typography/heading/heading';
import classNames from 'classnames';

import css from './step-title.module.scss';

interface StepTitleProps extends HeadingProps {
  step: number;
}

export const StepTitle = (props: StepTitleProps): JSX.Element => {
  const { step, className, children, ...headingProps } = props;

  return (
    <H2 className={classNames(className, css.title)} {...headingProps}>
      {children}
    </H2>
  );
};
