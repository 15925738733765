import { useCallback } from 'react';
import { Logger } from '@app/core/logger/logger';

interface CopyToClipboardHookProps {
  onSuccess: () => void;
  onError: () => void;
}

interface CopyToClipboardHookReturn {
  onCopyToClipboard: (textToCopy?: string | null) => void;
}

export const useCopyToClipBoard = (
  props: CopyToClipboardHookProps
): CopyToClipboardHookReturn => {
  const { onSuccess, onError } = props;

  const handleCopy = useCallback(
    (textToCopy?: string | null) => {
      if (!textToCopy) {
        onError();
        return;
      }

      navigator.clipboard
        .writeText(textToCopy)
        .then(onSuccess)
        .catch((error) => {
          onError();
          Logger.error(error);
        });
    },
    [onSuccess, onError]
  );

  return { onCopyToClipboard: handleCopy };
};
