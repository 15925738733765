import { useCallback, useState } from 'react';

import { ApiError } from '@app/core/error/api-error';
import { catchApiError } from '@app/core/error/catch-api-error';
import { WebauthnAuthenticatorModel } from '@app/core/model/authenticator.model';
import { authenticationMethodService } from '@app/core/service/authentication-method.service';

interface RenameAuthenticatorHookReturn {
  pending: boolean;
  onRename: (name: string) => void;
}

interface RenameAuthenticatorHookProps {
  authenticator: WebauthnAuthenticatorModel;
  onSuccess: (authenticator: WebauthnAuthenticatorModel) => void;
  onError: (error: ApiError) => void;
}

export const useRenameAuthenticator = (
  props: RenameAuthenticatorHookProps
): RenameAuthenticatorHookReturn => {
  const { authenticator, onSuccess, onError } = props;

  const [pending, setPending] = useState(false);

  const handleRename = useCallback(
    (name: string) => {
      setPending(true);

      const [promise] = authenticationMethodService.renameWebauthn(
        name,
        authenticator.id
      );

      promise
        .then(onSuccess)
        .catch((err) => {
          catchApiError(err, onError);
        })
        .finally(() => setPending(false));
    },
    [authenticator, onSuccess, onError]
  );

  return { pending, onRename: handleRename };
};
