import { useCallback, useState } from 'react';

import { LegalText } from '@app/core/model/legal-text';
import { Drawer, DrawerProps } from '@app/shared/drawer/drawer';
import { ValidateLegalTextView } from '@app/feature/check-user-consent/validate-legal-text.view';
import { catchApiError } from '@app/core/error/catch-api-error';
import { useUser } from '@app/core/context-providers/user-context/use-user';
import { Spinner } from '@app/shared/spinner/spinner';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { userService } from '@app/core/service/user.service';

interface CheckUserConsentDrawerProps extends DrawerProps {
  legalText?: LegalText;
}

export const CheckUserConsentDrawer = (
  props: CheckUserConsentDrawerProps
): JSX.Element => {
  const { legalText, ...drawerProps } = props;
  const [pending, setPending] = useState(false);
  const { onUserUpdate } = useUser();

  const handlePatch = useCallback(async (): Promise<void> => {
    if (!legalText) {
      return;
    }

    setPending(true);
    const [patchPromise] = userService.patch({
      consents: [
        {
          id: legalText.id,
          isConsentGiven: true,
        },
      ],
    });

    try {
      const updatedUser = await patchPromise;
      onUserUpdate(updatedUser);
    } catch (err) {
      catchApiError(err, (error) => {
        showErrorMessage('update-user-consent', error.code);
      });
    } finally {
      setPending(false);
    }
  }, [legalText, onUserUpdate]);

  return (
    <Drawer wrapperId="portal-consent" {...drawerProps}>
      {legalText ? (
        <ValidateLegalTextView
          pending={pending}
          legalText={legalText}
          onValidate={handlePatch}
        />
      ) : (
        <Spinner />
      )}
    </Drawer>
  );
};
