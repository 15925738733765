import { HTMLProps, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from './text-input';

interface EmailInputProps extends HTMLProps<HTMLInputElement> {
  label?: string;
  error?: FieldError;
  errorMessage?: string | null;
  isTouched?: boolean;
  adornmentStart?: React.ReactNode;
  adornmentEnd?: React.ReactNode;
}

export const EmailInput = forwardRef<HTMLInputElement, EmailInputProps>(
  (props: EmailInputProps, ref): JSX.Element => {
    const { t } = useTranslation();

    const {
      type = 'email',
      autoComplete = 'email',
      isTouched,
      error,
      label,
      errorMessage,
      ...rest
    } = props;

    return (
      <TextInput
        {...rest}
        type={type}
        autoComplete={autoComplete}
        ref={ref}
        label={label ?? t('shared.email-input.label')}
        isTouched={isTouched}
        errorMessage={
          errorMessage ??
          (error?.type === 'pattern'
            ? t('shared.errors.email.pattern')
            : error?.type === 'required'
            ? t('shared.errors.email.required')
            : '')
        }
      />
    );
  }
);
