import { useCallback, useState } from 'react';

import { catchApiError } from '@app/core/error/catch-api-error';

import { DeletePaymentMethodErrorView } from './delete-payment-method-error.view';
import { DeletePaymentMethodConfirmView } from './delete-payment-method-confirm.view';
import { DeletePaymentMethodSuccessView } from './delete-payment-method-success.view';
import { paymentMethodService } from '../../../../../core/service/payment-method.service';

enum DeleteDialogStateEnum {
  INIT,
  PENDING,
  ERROR,
  SUCCESS,
}

interface DeletePaymentMethodControllerProps {
  iPaymentSourceId: string;
  onSuccess: () => void;
  onError: () => void;
  onClose: () => void;
}

export const DeletePaymentMethodController = ({
  iPaymentSourceId,
  onError,
  onSuccess,
  onClose,
}: DeletePaymentMethodControllerProps): JSX.Element => {
  const [dialogState, setDialogState] = useState(DeleteDialogStateEnum.INIT);

  const handleDelete = useCallback(async () => {
    setDialogState(DeleteDialogStateEnum.PENDING);

    const [deletePromise] =
      paymentMethodService.deletePaymentMethod(iPaymentSourceId);

    try {
      await deletePromise;
      onSuccess();
      setDialogState(DeleteDialogStateEnum.SUCCESS);
    } catch (error) {
      catchApiError(error, () => {
        setDialogState(DeleteDialogStateEnum.ERROR);
      });
    }
  }, [iPaymentSourceId, onSuccess]);

  switch (dialogState) {
    case DeleteDialogStateEnum.ERROR:
      return (
        <DeletePaymentMethodErrorView
          onError={onError}
          onRetry={handleDelete}
        />
      );
    case DeleteDialogStateEnum.SUCCESS:
      return <DeletePaymentMethodSuccessView onContinue={onClose} />;
    default:
      return (
        <DeletePaymentMethodConfirmView
          pending={dialogState === DeleteDialogStateEnum.PENDING}
          onCancel={onClose}
          onDelete={handleDelete}
        />
      );
  }
};
