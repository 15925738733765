import { useCallback } from 'react';

import { Dialog } from '@app/shared/dialog/dialog';
import { useDialog } from '@app/shared/dialog/use-dialog';
import { Icon } from '@app/shared/icon/icon';
import { Spinner } from '@app/shared/spinner/spinner';
import { ApiError } from '@app/core/error/api-error';
import {
  showDefaultErrorMessage,
  showErrorMessage,
} from '@app/core/error/show-error-message';
import { ErrorCodeEnum } from '@app/config/error-config';
import { WebauthnAuthenticatorModel } from '@app/core/model/authenticator.model';

import css from './rename-authenticator.module.scss';
import { useRenameAuthenticator } from './use-rename-authenticator';
import { RenameAuthenticatorView } from './rename-authenticator.view';

interface RenameAuthenticatorProps extends WebauthnAuthenticatorModel {
  name: string;
  onSubmit: (authenticator: WebauthnAuthenticatorModel) => void;
}

export const RenameAuthenticator = (
  props: RenameAuthenticatorProps
): JSX.Element => {
  const { onSubmit, ...authenticator } = props;
  const { name } = authenticator;

  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  const handleSuccess = useCallback(
    (updatedAuthenticator: WebauthnAuthenticatorModel) => {
      onSubmit(updatedAuthenticator);
      closeDialog();
    },
    [onSubmit, closeDialog]
  );

  const handleError = useCallback((error: ApiError) => {
    if (error.code === ErrorCodeEnum.AUTHENTICATOR_NAME_ALREADY_EXISTS) {
      return showErrorMessage(
        'webauthn-authenticator-name-already-taken',
        error.code
      );
    }

    return showDefaultErrorMessage();
  }, []);

  const { pending, onRename } = useRenameAuthenticator({
    authenticator,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return (
    <>
      {pending ? (
        <Spinner />
      ) : (
        <Icon
          name="settings-02"
          color="primary"
          onClick={openDialog}
          className={css.icon}
        />
      )}

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <RenameAuthenticatorView defaultName={name} onSubmit={onRename} />
      </Dialog>
    </>
  );
};
