import { ChargePointModel } from '@app/core/model/charge-point.model';
import { useChargePointConnectorType } from '@app/shared/charge-point/connector-type/use-charge-point-connector-type';
import { useConnectorTypeIconName } from '@app/shared/charge-point/connector-type/use-connector-type-icon-name';
import {
  Drawer,
  DrawerProps,
  DrawerStateEnum,
} from '@app/shared/drawer/drawer';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';
import { H1, H2 } from '@app/shared/typography/heading/heading';
import { PowerType } from '@app/shared/charge-point/power-type/power-type';
import { Power } from '@app/shared/charge-point/power-type/power';
import { Divider } from '@app/shared/divider/divider';
import { useTranslation } from 'react-i18next';
import { TariffElementModel, TariffModel } from '@app/core/model/tariff.model';
import {
  filterActiveNowTariffUsingStartAndEndTime,
  isTariffElementComplex,
} from '@app/core/helper/ocpi-utils';
import { useMemo } from 'react';
import { Icon } from '@app/shared/icon/icon';
import { CurrentTariffComplex } from '@app/feature/charge-point/component/current-tariff-complex/current-tariff-complex';
import { CurrentTariffSimple } from '@app/feature/charge-point/component/current-tariff-simple/current-tariff-simple';
import { Button } from '@app/shared/button/button';
import { useRestrictionText } from '@app/feature/tariff/component/restriction/use-restriction-text';
import { Text } from '@app/shared/typography/text/text';
import i18n from '@app/core/locale/i18n';
import { LOCALE_KEYS } from '@app/core/locale/locale';

import css from './connector-drawer.module.scss';

type Props = Pick<DrawerProps, 'onClose'> & {
  connector?: ChargePointModel | null;
  canChooseConnector?: boolean;
  tariff?: TariffModel | null;
  onSelect: () => void;
  onTariffDetail: () => void;
};

export function ConnectorDrawer(props: Props): JSX.Element {
  const {
    connector,
    tariff,
    onSelect,
    onTariffDetail,
    canChooseConnector,
    ...drawerProps
  } = props;
  const { t } = useTranslation();

  const drawerIcon = useConnectorTypeIconName(
    connector?.specifications.connectorType
  );
  const connectorTypeText = useChargePointConnectorType(
    connector?.specifications.connectorType
  );

  const currentTariffElements = filterActiveNowTariffUsingStartAndEndTime(
    tariff?.elements ?? []
  );

  const tariffElementList: TariffElementModel[] = useMemo(() => {
    if (currentTariffElements?.length > 0) {
      return currentTariffElements;
    }

    return [];
  }, [currentTariffElements]);

  const isTariffComplex = isTariffElementComplex(tariff?.elements ?? []);

  const firstPricingElement = tariff?.elements?.at(0)?.priceComponents;

  const getRestrictionText = useRestrictionText();
  const restriction = currentTariffElements?.at(0)?.restrictions;

  const tariffCaption: string | null =
    tariff?.tariffAltText?.find(
      (item) => item.language === i18n.resolvedLanguage
    )?.text ??
    tariff?.tariffAltText?.find((item) => item.language === LOCALE_KEYS.fr)
      ?.text ??
    null;

  return (
    <Drawer
      icon={drawerIcon}
      state={connector ? DrawerStateEnum.OPEN : DrawerStateEnum.CLOSE}
      {...drawerProps}>
      <DrawerLayout>
        <DrawerLayout.Header>
          <H1>{connectorTypeText}</H1>

          <div className={css.headerRow}>
            <PowerType powerType={connector?.specifications.powerType} />
            <Power power={connector?.specifications.power} />
          </div>
        </DrawerLayout.Header>

        <Divider type="dashed" />

        <DrawerLayout.Body>
          <div className={css.priceHeader}>
            <H2 className={css.priceTitle}>
              <Icon name="price" />
              {t('charge-point.connector-drawer.title')}
            </H2>

            {restriction && (
              <div className={css.restriction}>
                <Text fontSize="large" bold>
                  {getRestrictionText(restriction)}
                </Text>
              </div>
            )}
          </div>

          {isTariffComplex ? (
            <CurrentTariffComplex
              tariffElementList={tariffElementList}
              onClick={onTariffDetail}
            />
          ) : (
            <CurrentTariffSimple
              pricePerKwh={firstPricingElement?.perKwhTTC}
              pricePerMin={
                (firstPricingElement?.perTimeChargingHourTTC ?? 0) / 60
              }
              flat={firstPricingElement?.flatTTC}
            />
          )}

          {isTariffComplex && tariffCaption && (
            <Text className={css.tariffCaption}>{tariffCaption}</Text>
          )}
        </DrawerLayout.Body>

        <DrawerLayout.Footer>
          <Button onClick={onSelect} disabled={!canChooseConnector}>
            {t('charge-point.connector-drawer.submit-button')}
          </Button>
        </DrawerLayout.Footer>
      </DrawerLayout>
    </Drawer>
  );
}
