/* eslint-disable react/destructuring-assignment */
// "options" variable can be undefined
import { useMemo } from 'react';
import { Navigate, NavigateOptions } from 'react-router-dom';

import { useRetrieveLocationDestination } from './use-retrieve-location-destination';

export const RedirectToInitialDestination = (
  options?: NavigateOptions
): JSX.Element => {
  const destination = useRetrieveLocationDestination();
  const state = useMemo(
    () => ({
      ...options?.state,
      ...destination?.state,
    }),
    [options?.state, destination?.state]
  );

  return <Navigate to={destination.from} {...options} state={state} />;
};
