import { DASHBOARD_PATH } from '@app/config/app-paths.const';
import { DELMO_CHARGE_POINT_ID_LSK } from '@app/config/localstorage-keys.const';
import { useCharge } from '@app/core/context-providers/charge-context/use-charge';
import { useOrder } from '@app/core/context-providers/order-context/use-order';
import { InvoiceModel } from '@app/core/model/invoice.model';
import { orderService } from '@app/core/service/order.service';
import { useChargePointService } from '@app/core/service/use-charge-point.service';
import { getFromLocalStorage } from '@app/core/storage/local-storage';
import { ChargeOverviewError } from '@app/feature/charge-overview/charge-overview-error.view';
import { ChargeOverview } from '@app/feature/charge-overview/charge-overview.view';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrderStatusEnum } from '@app/core/model/enum/order-status-enum';
import { invoicingService } from '@app/core/service/invoicing.service';
import { InvoiceDetailDrawer } from '../../shared/invoice-detail-drawer/invoice-detail.drawer';

const useLatestInvoice = (orderId: string | undefined) => {
  const [latestInvoice, setLatestInvoice] = useState<InvoiceModel | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchLatestInvoice = () => {
    setIsLoading(true);
    const toAbort: AbortController[] = [];
    const [orderPromise, abortOrderPromise] =
      orderService.getLatestWithCompleted();
    toAbort.push(abortOrderPromise);

    orderPromise
      .then((order) => {
        if (order.status === OrderStatusEnum.INVOICE_TERMINATED_SUCCESS) {
          // If the order is already invoiced, we need to fetch the invoice
          const [invoicePromise, abortInvoicePromise] =
            invoicingService.getLatest();
          toAbort.push(abortInvoicePromise);
          invoicePromise
            .then((invoice) => {
              setLatestInvoice(invoice);
            })
            .catch(() => {
              setLatestInvoice(null);
            });
        } else {
          setLatestInvoice({
            invoiceId: order.orderId,
            proforma: true,
            orderId: order.orderId,
            cost: {
              totalTTC: order.cost.totalTTC,
              totalHT: order.cost.totalHT,
              totalVAT: order.cost.totalVAT,
              vatRate: order.cost.vatRate,
            },
            pricing: {
              tariff: order.pricing.tariff,
              perKwhTTC: order.pricing.perKwhTTC,
              perTimeChargingHourTTC: order.pricing.perTimeChargingHourTTC,
              flatTTC: order.pricing.flatTTC,
              isRestricted: order.pricing.isRestricted,
            },
            customer: order.customer,
            chargePointName: order.chargePointName,
            createdAt: order.createdAt,
            startedAt: order.startedAt!,
            duration: order.duration!,
            energyDelivered: order.energyDelivered!,
          });
        }
      })
      .catch(() => {
        setLatestInvoice(null);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return { toAbort, orderPromise };
  };

  useEffect(() => {
    const { toAbort } = fetchLatestInvoice();

    return () => {
      toAbort.forEach((abort) => abort.abort());
    };
  }, [orderId]);

  return { latestInvoice, refetch: fetchLatestInvoice, isLoading };
};

export const ChargeOverviewController = (): JSX.Element => {
  const {
    duration,
    totalKwh,
    totalTTC,
    isError: isChargeError,
    refresh: refreshCharge,
  } = useCharge();

  const { order, refresh: refreshOrder } = useOrder();
  const cp = useChargePointService(
    getFromLocalStorage(DELMO_CHARGE_POINT_ID_LSK)
  );
  const navigate = useNavigate();
  const {
    latestInvoice,
    refetch,
    isLoading: isLoadingInvoice,
  } = useLatestInvoice(order?.orderId);
  const [isOpenInvoice, setIsOpenInvoice] = useState(false);

  const handleGoDashboard = () => {
    refreshCharge();
    refreshOrder();
    navigate(DASHBOARD_PATH);
  };

  const handleOpenInvoice = () => {
    refetch().orderPromise.then(() => {
      setIsOpenInvoice(true);
    });
  };

  return isChargeError ? (
    <ChargeOverviewError onGoToDashboard={handleGoDashboard} />
  ) : (
    <>
      <ChargeOverview
        chargePointName={order?.chargePointName}
        duration={duration}
        energyDelivered={totalKwh}
        estimatedPrice={totalTTC}
        onGoToDashboard={handleGoDashboard}
        physicalReference={cp.data?.specifications.physicalReference}
        hasInvoice={!!latestInvoice}
        onShowInvoice={handleOpenInvoice}
        isLoadingInvoice={isLoadingInvoice}
      />

      <InvoiceDetailDrawer
        invoice={isOpenInvoice ? latestInvoice : null}
        displayProformaFooter={!latestInvoice?.proforma}
        onClose={() => setIsOpenInvoice(false)}
      />
    </>
  );
};
