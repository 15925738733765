import { toInt } from 'radash';
import {
  ChargePointResponseModel,
  PricingModel,
} from '@app/core/model/charge-point.model';

import {
  PriceComponentResponseModel,
  TariffDimensionTypeEnum,
  TariffResponseModel,
} from '../model/ocpi.model';
import { TariffElementModel, TariffModel } from '../model/tariff.model';
import { Logger } from '../logger/logger';
import { TariffOverloadingConditionEnum } from '../model/enum/tariff-overloading-condition.enum';

// TODO  temporary no-generic solution to be improved ASAP. This function complet missing “implicite” constraints on time and kWh restrictions.
export function fillRestrictions(
  tariffElementList: TariffElementModel[]
): TariffElementModel[] {
  let prevStartTimeRestriction: Date;
  let prevEndTimeRestriction: Date;
  let prevMinKwhRestriction: number;
  let prevMaxKwhRestriction: number;

  return tariffElementList.map((tariffElement) => {
    const result = {
      ...tariffElement,
      restrictions: {
        ...tariffElement.restrictions,

        startTime:
          tariffElement.restrictions?.startTime ?? prevEndTimeRestriction,
        endTime:
          tariffElement.restrictions?.endTime ?? prevStartTimeRestriction,
        minKwh: tariffElement.restrictions?.minKwh ?? prevMaxKwhRestriction,
        maxKwh: tariffElement.restrictions?.maxKwh ?? prevMinKwhRestriction,
      },
    };

    prevStartTimeRestriction =
      tariffElement.restrictions?.startTime ?? prevStartTimeRestriction;
    prevEndTimeRestriction =
      tariffElement.restrictions?.endTime ?? prevEndTimeRestriction;
    prevMinKwhRestriction =
      tariffElement.restrictions?.minKwh ?? prevMinKwhRestriction;
    prevMaxKwhRestriction =
      tariffElement.restrictions?.maxKwh ?? prevMaxKwhRestriction;

    return result;
  });
}

const TARIFF_DIMENSION_TYPE_ENUM_TO_PRICING_PROPERTY: Record<
  TariffDimensionTypeEnum,
  keyof PricingModel
> = {
  [TariffDimensionTypeEnum.ENERGY]: 'perKwhTTC',
  [TariffDimensionTypeEnum.FLAT]: 'flatTTC',
  [TariffDimensionTypeEnum.TIME]: 'perTimeChargingHourTTC',
};

export function priceComponentsToChargePointPricingModel(
  priceComponents: PriceComponentResponseModel[]
): PricingModel {
  return priceComponents.reduce(
    (accumulator: PricingModel, priceComponent): PricingModel => {
      const pricingKey =
        TARIFF_DIMENSION_TYPE_ENUM_TO_PRICING_PROPERTY[priceComponent.type];

      if (accumulator[pricingKey]) {
        Logger.warn(
          `${priceComponent.type} is defined multiple times. The value is overriden.`
        );
      }

      return {
        ...accumulator,
        [pricingKey]: priceComponent.price_ttc ?? priceComponent.price,
      };
    },
    {
      perKwhTTC: 0,
      perTimeChargingHourTTC: 0,
      flatTTC: 0,
      isRestricted: false,
    } as PricingModel
  );
}

function ocpiTimeRestrictionToDate(restriction: string): Date {
  const [hours, minutes] = restriction.split(':');
  const result = new Date();
  result.setUTCHours(toInt(hours, 0));
  result.setUTCMinutes(toInt(minutes, 0));
  result.setUTCSeconds(0);

  return result;
}

export function extractOcpiTariffsOnChargePointResponse(
  chargePoint: ChargePointResponseModel,
  userEmspSubscriptionIds: string[] = []
): TariffResponseModel | null {
  const tariffs = chargePoint?.pricing?.tariffs;
  if (!tariffs || tariffs.length === 0) {
    return null;
  }

  const sortedTariffs = [...tariffs].sort(
    (tariffLeft, tariffRight) =>
      (tariffRight.details?.weight ?? -1) - (tariffLeft.details?.weight ?? -1)
  );

  for (const tariffItem of sortedTariffs) {
    if (tariffItem.details?.type === TariffOverloadingConditionEnum.OVERWRITE) {
      return tariffItem;
    }

    if (
      tariffItem.details?.type ===
        TariffOverloadingConditionEnum.EMSP_SUBSCRIPTION &&
      tariffItem.details?.parameters?.emspSubscriptionId &&
      userEmspSubscriptionIds.includes(
        tariffItem.details?.parameters?.emspSubscriptionId
      )
    ) {
      return tariffItem;
    }
  }

  return sortedTariffs.at(0) ?? null;
}

export function mapOcpiTariffModelToTariffModel(
  ocpiModel: TariffResponseModel
): TariffModel {
  const ocpiElements = ocpiModel.elements ?? [];

  return {
    tariffAltText: ocpiModel.tariff_alt_text,
    elements: fillRestrictions(
      ocpiElements.map((element) => ({
        priceComponents: priceComponentsToChargePointPricingModel(
          element.price_components
        ),
        ...(element.restrictions && {
          restrictions: {
            ...(element.restrictions.start_time && {
              startTime: ocpiTimeRestrictionToDate(
                element.restrictions.start_time
              ),
            }),

            ...(element.restrictions.end_time && {
              endTime: ocpiTimeRestrictionToDate(element.restrictions.end_time),
            }),

            minKwh: element.restrictions.min_kwh,
            maxKwh: element.restrictions.max_kwh,
          },
        }),
      }))
    ),
  };
}

export function filterActiveNowTariffUsingStartAndEndTime(
  tariffElements: TariffElementModel[]
): TariffElementModel[] {
  const tariffList = tariffElements.filter((tariffElement) => {
    const startTime = tariffElement.restrictions?.startTime;
    const endTime = tariffElement.restrictions?.endTime;

    if (!startTime || !endTime) {
      return true;
    }

    const nowDate = new Date();

    if (startTime < endTime) {
      return startTime < nowDate && nowDate < endTime;
    }

    return nowDate < endTime || startTime < nowDate;
  });

  return tariffList;
}

export function isTariffElementComplex(
  tariffElementList: TariffElementModel[]
): boolean {
  return tariffElementList.length >= 2;
}

export function hasTariffElementHourlyRestrictions(
  tariffElementList: TariffElementModel[]
): boolean {
  return tariffElementList.some(
    (tariffElement) =>
      tariffElement.restrictions?.endTime ||
      tariffElement.restrictions?.startTime
  );
}

export function removeTimeRestrictions(
  tariffElementList: TariffElementModel[]
): TariffElementModel[] {
  return tariffElementList.map((tariffElement) => ({
    ...tariffElement,
    restrictions: {
      maxKwh: tariffElement.restrictions?.maxKwh,
      minKwh: tariffElement.restrictions?.minKwh,
    },
  }));
}

export function isTariffEmpty(tariff: TariffModel): boolean {
  return (
    !tariff.elements ||
    tariff.elements.length === 0 ||
    tariff.elements.every(
      (element) =>
        (element.priceComponents.flatTTC ?? 0) +
          (element.priceComponents.perKwhTTC ?? 0) +
          (element.priceComponents.perTimeChargingHourTTC ?? 0) ===
        0
    )
  );
}
