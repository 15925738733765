import { Text, TextProps } from '@app/shared/typography/text/text';
import { useTranslation } from 'react-i18next';

import css from './charge-chart.module.scss';

export enum FallbackReason {
  AWAIT_DATA = 'AWAIT_DATA',
  NO_DATA_IN_TIME = 'NO_DATA_IN_TIME',
}
interface FallbackChargeChartProps extends TextProps {
  reason: FallbackReason;
}

export const FallbackChargeChart = ({
  reason,
  ...props
}: FallbackChargeChartProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Text className={css.fallback} {...props}>
      {reason === FallbackReason.AWAIT_DATA
        ? t('charging.chart.fallback')
        : t('charging.chart.fallback-no-data')}
    </Text>
  );
};
