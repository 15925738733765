import { ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import './fade.scss';
import css from './fade.module.scss';

interface FadeProps {
  show?: boolean;
  duration?: number;

  children: ReactNode;
}

export const Fade = ({
  show = false,
  duration = 600,
  children,
}: FadeProps): JSX.Element => {
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={show}
      timeout={duration}
      classNames="fade"
      mountOnEnter
      unmountOnExit
      appear>
      <div ref={nodeRef} className={css.container}>
        {children}
      </div>
    </CSSTransition>
  );
};
