/*
    User form validation rules
    Validate user forms as : registration, login, update user informations
 */

export const FIRST_NAME_MIN_LENGTH = 2;
export const FIRST_NAME_MAX_LENGTH = 40;
export const LAST_NAME_MIN_LENGTH = 2;
export const LAST_NAME_MAX_LENGTH = 40;

// Rule that allows one or more letters, upper or lower case, including accented letters and hyphens, apostrophes and spaces
export const NAME_PATTERN = /^[-'\sa-zA-ZÀ-ÿ]*$/;

export const PSEUDO_MIN_LENGTH = 2;
export const PSEUDO_MAX_LENGTH = 40;
export const EMAIL_PATTERN =
  // RegExp taken from https://emailregex.com - disable eslint to use exactly the RegExp provided by the RFC.
  // NOSONAR
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_CODE_LENGTH = 4;
export const PAYMENT_METHOD_MIN_LENGTH = 2;
export const PAYMENT_METHOD_MAX_LENGTH = 40;
export const AUTHENTICATOR_MIN_LENGTH = 2;
export const AUTHENTICATOR_MAX_LENGTH = 40;
