import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Text } from '@app/shared/typography/text/text';

import css from './recharge-card.module.scss';

interface LastChargeBodyProps {
  startedAt?: Date;
  chargePointName?: string;
  className?: string;
}

export const LastChargeBody = (props: LastChargeBodyProps): JSX.Element => {
  const { startedAt, chargePointName, className } = props;
  const { t } = useTranslation();

  return startedAt && chargePointName ? (
    <div className={classNames(css.lastChargeBody, className)}>
      <Text bold fontSize="large">
        {t('dashboard.recharge-card.last-charge.title')}
      </Text>
      <Text fontSize="large">
        {t('dashboard.recharge-card.last-charge.started-at', { startedAt })}
      </Text>
      <Text fontSize="large">{chargePointName}</Text>
    </div>
  ) : (
    <div className={classNames(css.lastChargeBody, css.noInvoice, className)}>
      <Text bold fontSize="large">
        {t('dashboard.recharge-card.last-charge.fallback')}
      </Text>
    </div>
  );
};
