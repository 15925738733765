/*
    Local storage keys
*/

export const LOCALE_LSK = 'i18nextLng';

export const PSEUDO_LSK = 'pseudo';

export const EMAIL_LSK = 'email';

export const JWT_LSK = 'jwt';

export const LAST_AUTHENTICATION_METHOD_LSK = 'last-authentication-method';

export const DELMO_CHARGE_POINT_OPERATOR_ID_LSK =
  'delmo-charge-point-operator-id';

export const DELMO_CHARGE_POINT_ID_LSK = 'delmo-charge-point-id';

export const CREATE_TRANSACTION_FROM_I_PAYMENT_SOURCE_ID_LSK =
  'create-transaction-from-id';
