import { RefObject, useEffect, useRef, useState } from 'react';
import {
  LineChart as ChartistLineChart,
  LineChartOptions,
  FixedScaleAxis,
  ResponsiveOptions,
} from 'chartist';

import i18next from '@app/core/locale/i18n';
import { Point } from '@app/feature/charging/component/charge-chart/model/point';

export interface LineChartProps {
  points: Point[];
  options?: Partial<LineChartOptions>;
  children: (payload: { chartRef: RefObject<HTMLDivElement> }) => JSX.Element;
}

const defaultOptions = {
  axisX: {
    type: FixedScaleAxis,
    divisor: 5,
    labelInterpolationFnc: (value: number): string =>
      i18next.t('charging.chart.x-axis-label', { value }),
  },
  axisY: {
    low: 0,
  },
} as LineChartOptions;

const responsiveOptions: ResponsiveOptions<LineChartOptions> = [
  [
    'screen and (max-height : 667px)',
    {
      chartPadding: { top: 0 },
    },
  ],
  [
    'screen and (min-width: 470px)', // page-max-width
    {
      axisX: {
        divisor: 6,
      },
    },
  ],
  [
    'screen and (max-width: 380px)', // page-min-width
    {
      axisX: {
        divisor: 3,
      },
    },
  ],
];

function buildChart(
  element: HTMLDivElement,
  options?: Partial<LineChartOptions>
): ChartistLineChart {
  return new ChartistLineChart(
    element,
    {
      labels: [],
      series: [],
    },
    {
      ...defaultOptions,
      ...options,
      axisX: { ...defaultOptions.axisX, ...options?.axisX },
      axisY: { ...defaultOptions.axisY, ...options?.axisY },
    },
    responsiveOptions
  );
}

export const LineChart = (props: LineChartProps): JSX.Element => {
  const { points, options, children } = props;

  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement>(null);
  const [chart, setChart] = useState<ChartistLineChart>();

  useEffect(() => {
    if (!chartRef.current) {
      return () => {};
    }

    const localChart = buildChart(chartRef.current);

    setChart(localChart);

    return () => {
      localChart.detach();
    };
  }, []);

  useEffect(() => {
    chart?.update(
      {
        series: [points],
      },
      options,
      true
    );
  }, [chart, points, options]);

  return <div ref={chartContainerRef}>{children({ chartRef })}</div>;
};
