import {
  MANAGE_SUBSCRIPTION_URL,
  SUBSCRIBE_URL,
} from '@app/config/subscription.const';
import { Button } from '@app/shared/button/button';
import { useDialog } from '@app/shared/dialog/use-dialog';
import { OpenTabDialog } from '@app/shared/open-tab-dialog/open-tab.dialog';
import { useTranslation } from 'react-i18next';

interface ManageUserSubscriptionButtonProps {
  numberOfSubscriptions?: number;
}

export function ManageUserSubscriptionButton(
  props: ManageUserSubscriptionButtonProps
): JSX.Element {
  const { numberOfSubscriptions = 0 } = props;
  const { t } = useTranslation();
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  if (numberOfSubscriptions > 0) {
    return (
      <>
        <Button variant="outlined" onClick={openDialog}>
          {t('subscription.manage-subscription-button', {
            count: numberOfSubscriptions,
          })}
        </Button>
        <OpenTabDialog
          url={MANAGE_SUBSCRIPTION_URL}
          title={t('subscription.redirect-dialog')}
          isOpen={isDialogOpen}
          onClose={closeDialog}
        />
      </>
    );
  }

  return (
    <>
      <OpenTabDialog
        url={SUBSCRIBE_URL}
        title={t('subscription.redirect-dialog')}
        isOpen={isDialogOpen}
        onClose={closeDialog}
      />
      <Button variant="outlined" onClick={openDialog}>
        {t('subscription.subscribe-button')}
      </Button>
    </>
  );
}
