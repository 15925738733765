import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CHARGING_PATH,
  INVOICE_DASHBOARD_PATH,
  SCAN_PATH,
} from '@app/config/app-paths.const';
import { NUMBER_OF_CHARGE_TO_DISPLAY_ON_DASHBOARD } from '@app/config/dashboard.const';
import { useCharge } from '@app/core/context-providers/charge-context/use-charge';
import { useOrder } from '@app/core/context-providers/order-context/use-order';
import { useUser } from '@app/core/context-providers/user-context/use-user';
import { ApiError } from '@app/core/error/api-error';
import { catchApiError } from '@app/core/error/catch-api-error';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { InvoiceModel } from '@app/core/model/invoice.model';
import { invoicingService } from '@app/core/service/invoicing.service';

import { InvoiceDetailDrawer } from '@app/shared/invoice-detail-drawer/invoice-detail.drawer';
import { DashboardView } from './dashboard.view';
import {
  getEnergyDeliveredFromListWithEnergyDelivered,
  orderAndFilterInvoices,
} from './invoice-utils';

export const DashboardController = (): JSX.Element => {
  const [latestInvoice, setLatestInvoice] = useState<InvoiceModel[]>([]);
  const lastInvoice: InvoiceModel | null = useMemo(
    () => (latestInvoice.length > 0 ? latestInvoice[0] : null),
    [latestInvoice]
  );

  const [energyDeliveredList, setEnergyDeliveredList] = useState<number[]>([]);

  const { order } = useOrder();
  const { startedAt, isCharging } = useCharge();
  const navigate = useNavigate();
  const { user } = useUser();
  const [isLoadingInvoice, setIsLoadingInvoice] = useState<boolean>(true);
  const [isLoadingProforma, setIsLoadingProforma] = useState<boolean>(true);

  const [isOpenInvoice, setIsOpenInvoice] = useState(false);

  useEffect(() => {
    setIsLoadingInvoice(true);

    const [lastInvoiceListPromise, lastInvoiceListAbort] =
      invoicingService.getLastThree();

    lastInvoiceListPromise
      .then((response) => {
        setLatestInvoice((previousLatestInvoices) =>
          orderAndFilterInvoices(
            [...previousLatestInvoices, ...response],
            NUMBER_OF_CHARGE_TO_DISPLAY_ON_DASHBOARD
          )
        );
      })
      .catch((error) => {
        catchApiError(error, (apiError: ApiError) => {
          showErrorMessage('list-invoice', apiError.code);
        });
      })
      .finally(() => {
        setIsLoadingInvoice(false);
      });

    return () => {
      lastInvoiceListAbort.abort();
    };
  }, []);

  useEffect(() => {
    setIsLoadingProforma(true);
    const [proformaPromise, proformaAbort] = invoicingService.getProforma();

    proformaPromise
      .then((response) => {
        setLatestInvoice((previousLatestInvoices) =>
          orderAndFilterInvoices(
            [...previousLatestInvoices, ...response],
            NUMBER_OF_CHARGE_TO_DISPLAY_ON_DASHBOARD
          )
        );
      })
      .catch((error) => {
        catchApiError(error, () => {});
      })
      .finally(() => {
        setIsLoadingProforma(false);
      });

    return () => {
      proformaAbort.abort();
    };
  }, []);

  useEffect(() => {
    setEnergyDeliveredList(
      getEnergyDeliveredFromListWithEnergyDelivered(latestInvoice)
    );
  }, [latestInvoice]);

  const handleNavigateToScan = () => {
    navigate(SCAN_PATH);
  };

  const handleNavigateToCharge = () => {
    navigate(CHARGING_PATH);
  };

  const handleNavigateToInvoices = () => {
    navigate(INVOICE_DASHBOARD_PATH);
  };

  return (
    <>
      <DashboardView
        isCharging={isCharging}
        chargeStartedAt={startedAt}
        chargePointName={
          isCharging ? order?.chargePointName : lastInvoice?.chargePointName
        }
        loading={isLoadingInvoice || isLoadingProforma}
        lastInvoice={lastInvoice}
        consumptions={energyDeliveredList}
        user={user}
        onNavigateToInvoiceSummary={() => setIsOpenInvoice(true)}
        onNavigateToScan={handleNavigateToScan}
        onNavigateToCharge={handleNavigateToCharge}
        onNavigateToInvoices={handleNavigateToInvoices}
      />
      <InvoiceDetailDrawer
        invoice={isOpenInvoice ? lastInvoice : null}
        onClose={() => setIsOpenInvoice(false)}
      />
    </>
  );
};
