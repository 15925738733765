import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useUser } from '@app/core/context-providers/user-context/use-user';
import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';

import css from './pseudo-tag.module.scss';

export const PseudoTag = (): JSX.Element => {
  const { user } = useUser();
  const { t } = useTranslation();

  const pseudo = user?.pseudo;

  const handleCopy = () => {
    if (!pseudo) {
      toast.warning(t('first-login.copy.error'), { toastId: 'copy-error' });
      return;
    }
    navigator.clipboard
      .writeText(pseudo)
      .then(() => {
        toast.success(t('first-login.copy.success'), {
          toastId: 'copy-success',
        });
      })
      .catch(() => {
        toast.warning(t('first-login.copy.error'), { toastId: 'copy-error' });
      });
  };

  return (
    <div className={css.container}>
      <Text color="secondary" className={css.label}>
        {t('first-login.label')}
      </Text>

      <div className={css.card}>
        <Icon name="user-01" />

        <Text bold className={css.pseudo}>
          {pseudo}
        </Text>

        <button onClick={handleCopy}>
          <Icon name="copy-03" color="primary" />
        </button>
      </div>
    </div>
  );
};
