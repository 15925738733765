import { Outlet } from 'react-router-dom';

import { useAuthentication } from '@app/core/context-providers/authentication-context/use-authentication';
import { CheckUserConsentController } from '@app/feature/check-user-consent/check-user-consent.controller';
import { EnableBackUpAuthenticationController } from '@app/feature/enable-back-up-authentication/enable-back-up-authentication.controller';
import { useDisconnectOnWebsocketEventController } from '@app/feature/disconnect-on-websocket-event/use-disconnect-on-websocket-event.controller';

import { RedirectToHomeWithState } from './redirect/redirect-to-home-with-state';

export const AuthenticatedController = (): JSX.Element => {
  const { isAuthenticated } = useAuthentication();

  useDisconnectOnWebsocketEventController();

  if (!isAuthenticated) {
    return <RedirectToHomeWithState />;
  }

  return (
    <>
      <Outlet />
      <CheckUserConsentController />
      <EnableBackUpAuthenticationController />
    </>
  );
};
