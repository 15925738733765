import { Navigate } from 'react-router-dom';

import { CHARGE_OVERVIEW_PATH } from '@app/config/app-paths.const';
import { useCharge } from '@app/core/context-providers/charge-context/use-charge';
import { GuardProps } from '@app/router/model/guard.props';

export const ChargeIsNotEndedGuard = ({
  children,
}: GuardProps): JSX.Element => {
  const { isChargeEnded } = useCharge();

  if (isChargeEnded) {
    return <Navigate to={CHARGE_OVERVIEW_PATH} replace />;
  }

  return children;
};
