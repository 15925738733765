import { Navigate } from 'react-router-dom';

import { DASHBOARD_PATH } from '@app/config/app-paths.const';
import { useOrder } from '@app/core/context-providers/order-context/use-order';
import { GuardProps } from '@app/router/model/guard.props';

export const ChargeNotTerminatedGuard = ({
  children,
}: GuardProps): JSX.Element => {
  const { isInvoice } = useOrder();

  if (isInvoice) {
    return <Navigate to={DASHBOARD_PATH} replace />;
  }

  return children;
};
