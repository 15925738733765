import { HTMLProps } from 'react';
import classNames from 'classnames';

import css from './list-item-menu.module.scss';
import { Text } from '../typography/text/text';

type Props = HTMLProps<HTMLDivElement> & {
  title?: string;
};

export const ListItemMenu = (props: Props): JSX.Element => {
  const { className, title, children, ...divProps } = props;

  return (
    <div className={classNames(className, css.container)} {...divProps}>
      {title && (
        <Text fontSize="small" color="secondary">
          {title}
        </Text>
      )}
      {children}
    </div>
  );
};
