import { useTranslation } from 'react-i18next';

import { Link, LinkProps } from '@app/shared/typography/link/link';
import { FaqDrawerController } from '@app/feature/faq/component/faq-drawer/faq-drawer.controller';
import { useDrawer } from '@app/shared/drawer/use-drawer';

interface LearnMoreLinkProps extends Omit<LinkProps, 'onClick'> {
  questionId: string;
}

export const LearnMoreLink = (props: LearnMoreLinkProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    questionId,
    color = 'p1',
    children = t('faq.learn-more-link'),
    ...linkProps
  } = props;

  const { drawerState, openDrawer, closeDrawer } = useDrawer();

  return (
    <>
      <Link color={color} onClick={openDrawer} {...linkProps}>
        {children}
      </Link>
      <FaqDrawerController
        id={questionId}
        onClose={closeDrawer}
        state={drawerState}
      />
    </>
  );
};
