import { createContext } from 'react';

import { OrderModel } from '@app/core/model/order.model';

export interface IOrderContext {
  order?: OrderModel | null;
  isOrderValidated: boolean;
  isInvoice: boolean;
  onOrder: (payload: OrderModel) => void;
  refresh: () => Promise<void>;
}

export const OrderContext = createContext<IOrderContext>({
  isOrderValidated: false,
  isInvoice: false,
  onOrder: () => {},
  refresh: async () => {},
});
