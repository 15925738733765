import { GET_CHARGE_POINT_API } from '@app/config/api-routes.const';

import { addHttpMock } from './mock-axios';
import { registerChargeMock } from './register-charge-mock';
import { registerSuitcar, SUITCAR_CHARGEPOINT_ID } from './register-suitcar';

function registerMock(): void {
  // HTTP MOCK

  addHttpMock(`${GET_CHARGE_POINT_API}/${SUITCAR_CHARGEPOINT_ID}`);
  addHttpMock(`${GET_CHARGE_POINT_API}/bd002118-af89-4d07-afa4-5d0164bd78bb`);
  addHttpMock(`${GET_CHARGE_POINT_API}/2fe06fa7-1056-4bc3-b803-ba96ca4cf84b`);

  // WS MOCK
  // addWsMock('topic');

  registerChargeMock();
  registerSuitcar();
}

registerMock();
