import { INVOICE_DASHBOARD_PATH } from '@app/config/app-paths.const';
import { useTranslation } from 'react-i18next';
import { AppbarLink } from './appbar-link/appbar.link';

interface ListInvoiceLinkProps {
  on?: boolean;
}

export const ListInvoiceLink = ({
  on = false,
}: ListInvoiceLinkProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <AppbarLink
      label={t('shared.app-bar.receipt')}
      icon="file-05"
      to={INVOICE_DASHBOARD_PATH}
      on={on}
    />
  );
};
