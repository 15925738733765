import { CreditCardInformation } from '@app/shared/credit-card-information/credit-card-information';
import { PreferredPaymentMethodController } from '@app/feature/account/view/user-payment-method/preferred-payment-method/preferred-payment-method.controller';
import { RenameText } from '@app/feature/account/view/user-payment-method/rename-payment-method-form/rename.text';
import { DeletePaymentMethodLink } from '@app/feature/account/view/user-payment-method/delete-payment-method/delete-payment-method.link';
import { PaymentMethodModel } from '@app/core/model/payment-method.model';

import css from './credit-card-manager.module.scss';

interface CreditCardManagerProps extends PaymentMethodModel {
  onChange: (paymentMethod: PaymentMethodModel) => void;
  onDelete: () => void;
}

export const CreditCardManager = (
  props: CreditCardManagerProps
): JSX.Element => {
  const { onChange, onDelete, ...paymentMethod } = props;

  const handleRename = (newName: string) => {
    onChange({ ...paymentMethod, name: newName });
  };

  const handlePrefer = (preferred: boolean) => {
    onChange({ ...paymentMethod, preferred });
  };

  return (
    <div className={css.container}>
      <div>
        <RenameText
          iPaymentSourceId={paymentMethod.iPaymentSourceId}
          last4={paymentMethod.last4}
          name={paymentMethod.name}
          onRename={handleRename}
        />

        <DeletePaymentMethodLink
          iPaymentSourceId={paymentMethod.iPaymentSourceId}
          onDelete={onDelete}
        />
      </div>

      <CreditCardInformation {...paymentMethod} />

      <PreferredPaymentMethodController
        iPaymentSourceId={paymentMethod.iPaymentSourceId}
        isPreferred={!!paymentMethod.preferred}
        onChange={handlePrefer}
      />
    </div>
  );
};
