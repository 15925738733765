import { ApiError } from '@app/core/error/api-error';
import { catchApiError } from '@app/core/error/catch-api-error';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { LegalText } from '@app/core/model/legal-text';
import { legalTextService } from '@app/core/service/legal-text.service';
import { useEffect, useMemo, useState } from 'react';

interface LoadLegalTextHookProps {
  onError?: (error: ApiError) => void;
}

interface LoadLegalTextHookReturn {
  loading: boolean;
  requiredLegalText: LegalText[];
  optionnalLegalText: LegalText[];
}

export const useLoadLegalText = (
  props?: LoadLegalTextHookProps
): LoadLegalTextHookReturn => {
  const onError = useMemo(() => {
    return props?.onError ? props.onError : () => {};
  }, [props]);

  const [loading, setLoading] = useState(false);
  const [requiredLegalText, setRequiredLegalText] = useState<LegalText[]>([]);
  const [optionnalLegalText, setOptionnalLegalText] = useState<LegalText[]>([]);

  useEffect(() => {
    setLoading(true);
    const [listLegalTextPromise, listLegalTextAbort] = legalTextService.list();

    listLegalTextPromise
      .then((response: LegalText[]) => {
        setRequiredLegalText(response.filter((text) => text.isConsentRequired));
        setOptionnalLegalText(
          response.filter((text) => !text.isConsentRequired)
        );
      })
      .catch((error) => {
        catchApiError(error, (apiError: ApiError) => {
          showErrorMessage('list-legal-text', apiError.code);
          onError(error);
        });
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      listLegalTextAbort.abort();
    };
  }, [onError]);

  return {
    loading,
    requiredLegalText,
    optionnalLegalText,
  };
};
