import { useTranslation } from 'react-i18next';

import CameraErrorSvg from '@app/asset/camera-error.svg';
import { Text } from '@app/shared/typography/text/text';

import { Button } from '@app/shared/button/button';
import css from './camera-error.module.scss';

interface CameraErrorProps {
  onRetry: () => void;
}

export const CameraError = ({ onRetry }: CameraErrorProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <CameraErrorSvg className={css.img} />
      <div className={css.title}>
        <Text>{t('scan.error.title')}</Text>
        <Text>{t('scan.error.subtitle')}</Text>
      </div>
      <Text className={css.text}>{t('scan.error.description')}</Text>
      <Text className={css.text}>{t('scan.error.fallback')}</Text>

      <Button onClick={onRetry}>{t('scan.error.retry')}</Button>
    </>
  );
};
