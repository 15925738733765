import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorCodeEnum, HttpStatusEnum } from '@app/config/error-config';
import { ApiError } from '@app/core/error/api-error';
import {
  showDefaultErrorMessage,
  showErrorMessage,
} from '@app/core/error/show-error-message';
import { EmailOTPProcessController } from '@app/feature/email-otp-process/email-otp-process.controller';
import { Link } from '@app/shared/typography/link/link';

import { AuthenticationMethodProps } from '../../authentication-method.props';
import css from './email-authentication.module.scss';
import { useEmailAuthentication } from './use-email-authentication';

interface EmailAuthenticationControllerProps extends AuthenticationMethodProps {
  emailAddress?: string;
  onSwitchToWebauthnAuthentication: () => void;
}

export const EmailAuthenticationController = ({
  emailAddress,
  onAuthentication,
  onSwitchToWebauthnAuthentication,
}: EmailAuthenticationControllerProps): JSX.Element => {
  const { t } = useTranslation();

  const handleError = useCallback((error: ApiError) => {
    if (error.status === HttpStatusEnum.NOT_FOUND) {
      return showErrorMessage(
        'account-not-found',
        ErrorCodeEnum.ACCOUNT_NOT_FOUND
      );
    }

    return showDefaultErrorMessage();
  }, []);

  const { onStart, onVerify, cooldownLockTimeInSec } = useEmailAuthentication({
    emailAddress,
    onStartError: handleError,
    onSuccess: onAuthentication,
  });

  return (
    <div className={css.container}>
      <EmailOTPProcessController
        remainingLockTimeInSec={cooldownLockTimeInSec}
        emailAddress={emailAddress}
        translationKey="authentication.email"
        onRequestOTP={onStart}
        onValidateOTP={onVerify}
        onRequestAgainOTP={onStart}
        appendFooter={
          <Link
            onClick={onSwitchToWebauthnAuthentication}
            className={css.switchToWebauthn}>
            {t('authentication.email.use-webauthn')}
          </Link>
        }
      />
    </div>
  );
};
