import { RedirectUrlResponseModel } from '@app/core/model/redirect-url-response.model';

type RedirectUrlResponseHookReturn = (
  preCaptureReponse: RedirectUrlResponseModel
) => void;

type RedirectUrlResponseHookProps = () => void;

export const useRedirectUrlResponse = (
  onNext: RedirectUrlResponseHookProps
): RedirectUrlResponseHookReturn => {
  return (response: RedirectUrlResponseModel) => {
    if (response.redirectUrl) {
      window.location.replace(response.redirectUrl);
      return;
    }

    onNext();
  };
};
