import {
  MANAGE_AUTHENTICATION_PATH,
  USER_PREFERENCE_PATH,
} from '@app/config/app-paths.const';
import { Button, ButtonProps } from '@app/shared/button/button';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface SendByEmailButtonProps
  extends Omit<ButtonProps, 'onClick' | 'label'> {
  isEmailVerified: boolean;
}

export const SendByEmailButton = (
  props: SendByEmailButtonProps
): JSX.Element => {
  const { isEmailVerified, ...buttonProps } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoToPreferences = useCallback(() => {
    toast.info(t('shared.invoice.footer.send-by-email-button.not-enabled'));
    navigate(USER_PREFERENCE_PATH);
  }, [t, navigate]);

  const handleNavigateToAuthenticationManagement = useCallback(() => {
    toast.info(
      t('shared.invoice.footer.send-by-email-button.email-not-validated')
    );
    navigate(MANAGE_AUTHENTICATION_PATH);
  }, [t, navigate]);

  const handleClick = useCallback(() => {
    if (isEmailVerified) {
      return handleGoToPreferences();
    }

    return handleNavigateToAuthenticationManagement();
  }, [
    isEmailVerified,
    handleGoToPreferences,
    handleNavigateToAuthenticationManagement,
  ]);

  return (
    <Button onClick={handleClick} {...buttonProps}>
      {t('shared.invoice.footer.send-by-email-button.label')}
    </Button>
  );
};
