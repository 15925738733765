import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { DASHBOARD_PATH } from '@app/config/app-paths.const';

import { RedirectionState } from '../model/redirection-state';

export const useRetrieveLocationDestination = (): RedirectionState => {
  const location = useLocation();

  const destination: RedirectionState = useMemo(() => {
    const locationState = location.state as RedirectionState;
    return {
      from: locationState?.from || DASHBOARD_PATH,
      state: locationState?.state,
    };
  }, [location.state]);

  return destination;
};
