import { useCallback, useEffect, useMemo, useState } from 'react';
import { isNumber } from 'radash';
import { useNavigate } from 'react-router-dom';

import { ENABLE_BACK_UP_AUTHENTICATION_SSK } from '@app/config/sessionstorage-keys.const';
import { useDialog } from '@app/shared/dialog/use-dialog';
import { MANAGE_AUTHENTICATION_PATH } from '@app/config/app-paths.const';
import { useUser } from '@app/core/context-providers/user-context/use-user';
import {
  getFromSessionStorage,
  removeInSessionStorage,
} from '@app/core/storage/session-storage';
import { Logger } from '@app/core/logger/logger';
import { AuthenticationMethodEnum } from '@app/core/model/enum/authentication-method.enum';

import { EnableBackUpAuthenticationDialog } from './enable-back-up-authentication.dialog';

export const EnableBackUpAuthenticationController = (): JSX.Element | null => {
  const { user } = useUser();

  const { openDialog, isDialogOpen, closeDialog } = useDialog();
  const navigate = useNavigate();

  const canShowDialog = useMemo(
    () =>
      getFromSessionStorage(ENABLE_BACK_UP_AUTHENTICATION_SSK) ===
      true.toString(),
    []
  );

  const [authenticationMethodToEnable, setAuthenticationMethodToEnable] =
    useState<AuthenticationMethodEnum>();

  useEffect(() => {
    if (!user) {
      Logger.warn('enable-back-up-authentication.controller', 'no user', user);
      return;
    }

    // At this point, user is logged in.
    // Therefore user is logged through email or webauthn.
    // Meaning, if email is not verified, user is logged through webauthn and vice versa.
    if (!user.isEmailAddressVerified) {
      setAuthenticationMethodToEnable(AuthenticationMethodEnum.EMAIL);
      return;
    }

    if (!user?.authenticators?.webauthn) {
      setAuthenticationMethodToEnable(AuthenticationMethodEnum.WEBAUTHN);
    }
  }, [user]);

  useEffect(() => {
    if (isNumber(authenticationMethodToEnable) && canShowDialog) {
      openDialog();
    }
  }, [authenticationMethodToEnable, canShowDialog, openDialog]);

  const handleCloseDialog = useCallback(() => {
    removeInSessionStorage(ENABLE_BACK_UP_AUTHENTICATION_SSK);
    closeDialog();
  }, [closeDialog]);

  const handleGoToManageAuthentication = useCallback(() => {
    handleCloseDialog();
    navigate(MANAGE_AUTHENTICATION_PATH);
  }, [navigate, handleCloseDialog]);

  return isNumber(authenticationMethodToEnable) ? (
    <EnableBackUpAuthenticationDialog
      authenticationMethodToEnable={authenticationMethodToEnable}
      open={isDialogOpen}
      onClose={handleCloseDialog}
      onContinue={handleGoToManageAuthentication}
    />
  ) : null;
};
