import { JWT_EXPIRED_WST } from '@app/config/api-routes.const';
import { useWebsocket } from '@app/core/client/websocket/use-websocket';
import { useAuthentication } from '@app/core/context-providers/authentication-context/use-authentication';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const useDisconnectOnWebsocketEventController = (): void => {
  const { disconnect } = useAuthentication();
  const { t } = useTranslation();

  const handleDisconnect = () => {
    toast.info(t('disconnect-on-websocket-event.toast'));
    disconnect();
  };

  useWebsocket(JWT_EXPIRED_WST, handleDisconnect);
};
