import { createContext } from 'react';

export interface IAuthenticationContext {
  isAuthenticated: boolean;
  authenticate: (newToken: string) => void;
  disconnect: () => void;
}

export const AuthenticationContext = createContext<IAuthenticationContext>({
  isAuthenticated: false,
  authenticate: () => {},
  disconnect: () => {},
});
