import { InvoiceModel } from '@app/core/model/invoice.model';

interface ItemWithEnergyDelivered {
  energyDelivered: number;
}

export function getEnergyDeliveredFromListWithEnergyDelivered(
  invoiceList: ItemWithEnergyDelivered[]
): number[] {
  return invoiceList.map((invoice) => invoice.energyDelivered);
}

export function orderAndFilterInvoices(
  list: InvoiceModel[],
  numberToKeep: number
): InvoiceModel[] {
  return list
    .slice() // sort is a mutating method, so first copy the array
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    .filter((_, index) => index < numberToKeep - 1);
}
