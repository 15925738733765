import { useCallback, useState } from 'react';

import { ApiError } from '@app/core/error/api-error';
import { catchApiError } from '@app/core/error/catch-api-error';
import { paymentMethodService } from '@app/core/service/payment-method.service';
import { showErrorMessage } from '@app/core/error/show-error-message';

interface PreferredPaymentMethodHookReturn {
  pending: boolean;
  onPrefer: () => Promise<void>;
  onDeletePreferred: () => Promise<void>;
}

interface PreferredPaymentMethodHookProps {
  iPaymentSourceId: string;
  isPreferred: boolean;
  onChange: (isPreferred: boolean) => void;
}

export const usePreferredPaymentMethod = (
  props: PreferredPaymentMethodHookProps
): PreferredPaymentMethodHookReturn => {
  const { iPaymentSourceId, onChange } = props;
  const [pending, setPending] = useState(false);

  const handleSetPreferred = useCallback(async () => {
    setPending(true);
    const [preferredPromise] =
      paymentMethodService.setPreferred(iPaymentSourceId);

    try {
      await preferredPromise;
      onChange(true);
    } catch (err) {
      catchApiError(err, (error: ApiError) => {
        showErrorMessage('update-prefer-payment-method', error.code);
      });
    } finally {
      setPending(false);
    }
  }, [iPaymentSourceId, onChange]);

  const handleDeletePreferred = useCallback(async () => {
    setPending(true);
    const [preferredPromise] = paymentMethodService.removePreferred();

    try {
      await preferredPromise;
      onChange(false);
    } catch (err) {
      catchApiError(err, (error: ApiError) => {
        showErrorMessage('update-prefer-payment-method', error.code);
      });
    } finally {
      setPending(false);
    }
  }, [onChange]);

  return {
    pending,
    onPrefer: handleSetPreferred,
    onDeletePreferred: handleDeletePreferred,
  };
};
