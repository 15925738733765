import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { WebsocketClientService } from './core/client/websocket/websocket-client.service';
import { AuthenticationContextProvider } from './core/context-providers/authentication-context/authentication-context.provider';
import { HttpErrorBoundary } from './core/error/http-error-boundary';
import { PrivateAccessController } from './feature/private-access/private-access.controller';
import { Router } from './router/router';
import { AppStyle } from './shared/styles/app-style';
import { QueryClientProvider } from './core/context-providers/query-client-provider/query-client-provider';
import { AppInitializer } from './feature/app-initializer/app-initializer';

interface AppProps {
  onReady: () => void;
}

function App(props: AppProps): JSX.Element {
  const { onReady } = props;

  WebsocketClientService.getInstance();

  return (
    <>
      <AppStyle />
      <QueryClientProvider>
        <BrowserRouter>
          <PrivateAccessController>
            <AuthenticationContextProvider>
              <AppInitializer onReady={onReady}>
                <HttpErrorBoundary>
                  <Router />
                </HttpErrorBoundary>
              </AppInitializer>
            </AuthenticationContextProvider>
          </PrivateAccessController>
        </BrowserRouter>
      </QueryClientProvider>
      <ToastContainer
        position="top-center"
        autoClose={7000}
        hideProgressBar
        closeOnClick
      />
    </>
  );
}

export default App;
