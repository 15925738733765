import { ChangeEvent, ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@app/shared/checkbox/checkbox';
import { Link } from '@app/shared/typography/link/link';

interface OptionalConsentCheckboxProps {
  label: string;
  onOpenLegalText: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const OptionalConsentCheckbox = forwardRef<
  HTMLInputElement,
  OptionalConsentCheckboxProps
>(
  (
    props: OptionalConsentCheckboxProps,
    ref: ForwardedRef<HTMLInputElement>
  ): JSX.Element => {
    const { label, onOpenLegalText, onChange } = props;

    const { t } = useTranslation();

    return (
      <div>
        <Checkbox
          ref={ref}
          label={t('registration.registration-form.legal-text.label')}
          onChange={onChange}
          id={label}
        />{' '}
        <Link color="inherit" onClick={onOpenLegalText}>
          {label}
        </Link>
      </div>
    );
  }
);
