import classNames from 'classnames';
import { CircleContainer } from '../circle-container/circle-container';
import { textToInitials } from './text-to-initials';

import css from './avatar.module.scss';

interface AvatarProps {
  className?: string;
  firstName: string;
  lastName: string;
  onClick?: () => void;
}

export const Avatar = ({
  firstName,
  lastName,
  onClick,
  className,
}: AvatarProps): JSX.Element => (
  <CircleContainer
    className={classNames(css.avatar, className)}
    onClick={onClick}>
    {textToInitials(`${firstName} ${lastName}`)}
  </CircleContainer>
);
