import classnames from 'classnames';
import { HTMLProps } from 'react';
import { useMatch } from 'react-router-dom';

import AppbarCurve from '@app/asset/appbar-curve.svg';
import {
  ACCOUNT_PATH,
  DASHBOARD_PATH,
  HELP_PATH,
  INVOICE_DASHBOARD_PATH,
  SCAN_PATH,
} from '@app/config/app-paths.const';

import css from './appbar.module.scss';
import { AccountLink } from './component/account-link';
import { HelpLink } from './component/help-link';
import { HomeLink } from './component/home-link';
import { ListInvoiceLink } from './component/list-invoice-link';
import { ScanLink } from './component/scan-link';

export const Appbar = ({
  className,
  ...divProps
}: HTMLProps<HTMLDivElement>): JSX.Element => {
  const isOnHome = useMatch(DASHBOARD_PATH) !== null;
  const isOnListInvoice = useMatch(INVOICE_DASHBOARD_PATH) !== null;
  const isOnHelp = useMatch(`${HELP_PATH}/*`) !== null;
  const isOnScan = useMatch(SCAN_PATH) !== null;
  const isOnAccount = useMatch(`${ACCOUNT_PATH}/*`) !== null;

  return (
    <div className={classnames(css.appbar, className)} {...divProps}>
      <div className={classnames(css.left)} />

      <div
        className={classnames(
          css.center,
          isOnScan ? css.centerStraight : css.centerCurve
        )}>
        {!isOnScan && <AppbarCurve />}
        <div className={classnames(!isOnScan && css.centerCurveBottom)} />
      </div>

      <div className={classnames(css.right)} />

      <div className={css.menu}>
        <HomeLink on={isOnHome} />
        <HelpLink on={isOnHelp} />
        <ScanLink on={isOnScan} />
        <ListInvoiceLink on={isOnListInvoice} />
        <AccountLink on={isOnAccount} />
      </div>
    </div>
  );
};
