import { InvoiceModel } from '@app/core/model/invoice.model';
import { TariffModel } from '@app/core/model/tariff.model';
import {
  isTariffElementComplex,
  isTariffEmpty,
} from '@app/core/helper/ocpi-utils';
import { TariffList } from '@app/feature/tariff/component/tariff-list/tariff-list';
import { Logger } from '@app/core/logger/logger';

import { InvoicePricing } from './invoice-pricing';

type Props = InvoiceModel['pricing'];

export function InvoiceTariff(props: Props): JSX.Element | null {
  const { tariff: tariffModel, ...simplePricing } = props;

  const tariff: TariffModel = tariffModel ?? {
    // backwards compatibility with pricing
    elements: [{ priceComponents: simplePricing }],
  };
  const isTariffComplex = isTariffElementComplex(tariff.elements);

  if (isTariffEmpty(tariff)) {
    Logger.warn('[invoice-tariff]: Tariff is empty');
    return null;
  }

  return isTariffComplex ? (
    <TariffList elements={tariff.elements} withIcon={false} />
  ) : (
    <InvoicePricing {...simplePricing} />
  );
}
