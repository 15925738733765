import {
  GPDR_API,
  USER_API,
  USER_SYNC_SUBSCRIPTION,
} from '@app/config/api-routes.const';
import { AbortablePromise } from '@app/core/helper/abort-promise';
import { httpClientService } from '@app/core/client/http/http-client.service';
import {
  UserUpdateRequestModel,
  UserModel,
  UserGPDRModel,
} from '@app/core/model/user.model';

class UserService {
  get(): AbortablePromise<UserModel> {
    return httpClientService.run<UserModel>(USER_API, {
      method: 'GET',
    });
  }

  patch(data: UserUpdateRequestModel): AbortablePromise<UserModel> {
    return httpClientService.run<UserModel>(USER_API, {
      data,
      method: 'PATCH',
    });
  }

  getGPDR(): AbortablePromise<UserGPDRModel> {
    return httpClientService.run<UserGPDRModel>(GPDR_API, {
      method: 'GET',
    });
  }

  syncSubscriptions(): Promise<UserModel> {
    const [promise] = httpClientService.run<UserModel>(USER_SYNC_SUBSCRIPTION, {
      method: 'POST',
    });
    return promise;
  }
}

export const userService = new UserService();
