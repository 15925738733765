import { createContext } from 'react';

import { WebauthnAuthenticatorModel } from '@app/core/model/authenticator.model';
import { UserModel } from '@app/core/model/user.model';

export interface IUserContext {
  user?: UserModel;
  isWebauthnEnabled: boolean;
  refresh: () => Promise<void>;
  onUserUpdate: (data: UserModel) => void;
  onChangeWebauthnAuthenticator: (
    authenticator: WebauthnAuthenticatorModel
  ) => void;
  onCreateWebauthnAuthenticator: (
    authenticator: WebauthnAuthenticatorModel
  ) => void;
}

export const UserContext = createContext<IUserContext>({
  isWebauthnEnabled: false,
  refresh: async () => {},
  onUserUpdate: () => {},
  onChangeWebauthnAuthenticator: () => {},
  onCreateWebauthnAuthenticator: () => {},
});
