import { HELP_PATH } from '@app/config/app-paths.const';
import { useTranslation } from 'react-i18next';
import { AppbarLink } from './appbar-link/appbar.link';

interface HelpLinkProps {
  on?: boolean;
}

export const HelpLink = ({ on = false }: HelpLinkProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <AppbarLink
      label={t('shared.app-bar.help')}
      icon="help-circle"
      to={HELP_PATH}
      on={on}
    />
  );
};
