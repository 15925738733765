import { useLoadScript } from '@app/core/load-script/use-load-script';
import { Spinner } from '@app/shared/spinner/spinner';

import { ApplePayButton } from './component/apple-pay-button/apple-pay-button';

interface ApplePayViewProps {
  cdn: string;
  onBegin: () => void;
}

export const ApplePayView = ({
  cdn,
  onBegin,
}: ApplePayViewProps): JSX.Element => {
  const isScriptLoading = useLoadScript(cdn);

  return isScriptLoading ? <Spinner /> : <ApplePayButton onClick={onBegin} />;
};
