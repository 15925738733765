import { useTranslation } from 'react-i18next';
import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';
import { useDialog } from '@app/shared/dialog/use-dialog';
import { OpenTabDialog } from '@app/shared/open-tab-dialog/open-tab.dialog';
import { BLOG_URL } from '@app/config/external-url';

import css from './news-card.module.scss';

export function NewsCard(): JSX.Element {
  const { t } = useTranslation();
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  return (
    <>
      <button className={css.container} onClick={openDialog}>
        <Icon name="delmonicos" className={css.icon} />
        <Text bold className={css.label}>
          {t('dashboard.news-card.title')}
        </Text>
      </button>

      <OpenTabDialog
        url={BLOG_URL}
        title={t('dashboard.news-card.external-url')}
        isOpen={isDialogOpen}
        onClose={closeDialog}
      />
    </>
  );
}
