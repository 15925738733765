import {
  ACCOUNT_PATH,
  GUEST_LEGAL_NOTICES_PATH,
  HOME_PATH,
  LEGAL_NOTICES_PATH,
} from '@app/config/app-paths.const';
import { useTypedTranslation } from '@app/core/helper/use-typed-translation';
import { ClearStorageController } from '@app/feature/legal-text/component/clear-storage-dialog/clear-storage.controller';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { Spinner } from '@app/shared/spinner/spinner';
import { Text } from '@app/shared/typography/text/text';

import { useLocation, useNavigate } from 'react-router-dom';
import { Divider } from '@app/shared/divider/divider';
import { useMemo } from 'react';
import css from './legal-text.module.scss';

import { LegalText } from '../../core/model/legal-text';
import { AccountItemMenu } from '../account/component/account-item-menu/account-item-menu';
import { DownloadPersonalInformationController } from './component/download-personal-information/download-personal-information.controller';

function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

interface LegalTextViewProps {
  version?: string;
  pending: boolean;
  legalTextList: LegalText[];
  pseudo?: string;
  onSelect: (legalText: LegalText) => void;
}

export const LegalTextView = (props: LegalTextViewProps): JSX.Element => {
  const { version, pending, legalTextList, pseudo, onSelect } = props;
  const navigate = useNavigate();

  const { state } = useLocation();

  const handleGoBack = useMemo(() => {
    if (state?.from) {
      return state.from;
    }
    return pseudo ? ACCOUNT_PATH : HOME_PATH;
  }, [state, pseudo]);

  const { t } = useTypedTranslation();

  return (
    <PageLayout withHomeButton>
      <PageLayout.Title
        title={t('legal-text.title')}
        icon="shield-tick"
        backTo={handleGoBack}
      />

      <PageLayout.Body>
        {pending && <Spinner />}

        <div className={css.linksContainer}>
          <AccountItemMenu
            bold
            icon="shield-tick"
            label={t('legal-text.legal-notices.title')}
            onClick={() =>
              navigate(pseudo ? LEGAL_NOTICES_PATH : GUEST_LEGAL_NOTICES_PATH)
            }
          />

          {legalTextList.map((legalText) => (
            <AccountItemMenu
              bold
              key={legalText.id}
              label={capitalizeFirstLetter(legalText.title)}
              onClick={() => onSelect(legalText)}
              icon="file-05"
            />
          ))}
        </div>
        <Divider type="solid" />
        <div className={css.footerLink}>
          {pseudo && <DownloadPersonalInformationController pseudo={pseudo} />}

          <ClearStorageController />

          {version && (
            <Text fontSize="xs">{t('legal-text.version', { version })}</Text>
          )}
        </div>
      </PageLayout.Body>

      {pseudo && <PageLayout.AppbarFooter />}
    </PageLayout>
  );
};
