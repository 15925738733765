import { TariffElementModel } from '@app/core/model/tariff.model';
import { Text } from '@app/shared/typography/text/text';

import { useRestrictionText } from '../../../tariff/component/restriction/use-restriction-text';
import { PriceSquare } from '../../../tariff/component/price-square/price-square';
import css from './current-tariff-complex.module.scss';

type Props = {
  tariffElement: TariffElementModel;
};

export function FirstTariffDetail(props: Props): JSX.Element {
  const { tariffElement } = props;
  const getRestrictionText = useRestrictionText();

  const pricing = tariffElement.priceComponents;

  const maxKwhRestrictionText = getRestrictionText({
    maxKwh: tariffElement?.restrictions?.maxKwh,
  });

  return (
    <div className={css.tariffElementItem}>
      <PriceSquare
        className={css.item}
        pricePerKwh={pricing.perKwhTTC}
        pricePerMin={pricing.perTimeChargingHourTTC}
        flatTTC={pricing.flatTTC}
      />

      {maxKwhRestrictionText && (
        <Text className={css.restriction}>{maxKwhRestrictionText}</Text>
      )}
    </div>
  );
}
