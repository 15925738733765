import {
  START_IDENTITY_VERIFICATION_API,
  VERIFY_IDENTITY_VERIFICATION_API,
} from '@app/config/api-routes.const';
import { httpClientService } from '@app/core/client/http/http-client.service';
import {
  StartIdentityVerificationResponseModel,
  VerifyIdentityVerificationBodyModel,
  VerifyIdentityVerificationResponseModel,
} from '@app/core/model/identity-verification.model';

class ConfirmIdentityService {
  startIdentityVerification(): Promise<StartIdentityVerificationResponseModel> {
    const [promise] =
      httpClientService.run<StartIdentityVerificationResponseModel>(
        START_IDENTITY_VERIFICATION_API
      );

    return promise;
  }

  verifyIdentityVerification(
    data: VerifyIdentityVerificationBodyModel
  ): Promise<VerifyIdentityVerificationResponseModel> {
    const [promise] =
      httpClientService.run<VerifyIdentityVerificationResponseModel>(
        VERIFY_IDENTITY_VERIFICATION_API,
        {
          data,
        }
      );

    return promise;
  }
}

export const confirmIdentityService = new ConfirmIdentityService();
