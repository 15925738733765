/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { loadScript } from '@app/core/load-script/load-script';
import { Logger } from '@app/core/logger/logger';

export interface ReadyToPayChangeResponse {
  isButtonVisible: boolean;
  isReadyToPay: boolean;
  paymentMethodPresent?: boolean;
}

export interface Config {
  environment: google.payments.api.Environment;
  existingPaymentMethodRequired?: boolean;
  paymentRequest: google.payments.api.PaymentDataRequest;
  onPaymentDataChanged?: google.payments.api.PaymentDataChangedHandler;
  onPaymentAuthorized?: google.payments.api.PaymentAuthorizedHandler;
  onLoadPaymentData?: (paymentData: google.payments.api.PaymentData) => void;
  onCancel?: (reason: google.payments.api.PaymentsError) => void;
  onError?: (error: Error | google.payments.api.PaymentsError) => void;
  onReadyToPayChange?: (result: ReadyToPayChangeResponse) => void;
  onClick?: (event: Event) => void;
  buttonColor?: google.payments.api.ButtonColor;
  buttonType?: google.payments.api.ButtonType;
  buttonSizeMode?: google.payments.api.ButtonSizeMode;
  buttonLocale?: string;
}

interface ButtonManagerOptions {
  cssSelector: string;
  softwareInfoId?: string;
  softwareInfoVersion?: string;
}

/**
 * Manages the lifecycle of the Google Pay button.
 *
 * Includes lifecycle management of the `PaymentsClient` instance,
 * `isReadyToPay`, `onClick`, `loadPaymentData`, and other callback methods.
 */
export class ButtonManager {
  private cdn: string;

  private client?: google.payments.api.PaymentsClient;

  private config?: Config;

  private element?: Element;

  private options: ButtonManagerOptions;

  private oldInvalidationValues?: any[];

  isReadyToPay?: boolean;

  paymentMethodPresent?: boolean;

  constructor(options: ButtonManagerOptions & { cdn: string }) {
    const { cdn, ...restOfOptions } = options;

    this.options = restOfOptions;
    this.cdn = cdn;
  }

  getElement(): Element | undefined {
    return this.element;
  }

  private isGooglePayLoaded(): boolean {
    return (
      'google' in (window || global) && !!google?.payments?.api?.PaymentsClient
    );
  }

  async mount(element: Element): Promise<void> {
    if (!this.isGooglePayLoaded()) {
      if (!this.cdn) {
        throw new Error('A CDN must be provided.');
      }

      await loadScript(this.cdn);
    }

    this.element = element;
    if (element && this.config) {
      if (this.config) {
        this.updateElement();
      }
    }
  }

  unmount(): void {
    this.element = undefined;
  }

  configure(newConfig: Config): Promise<void> {
    let promise: Promise<void> | undefined;
    this.config = newConfig;
    if (!this.oldInvalidationValues || this.isClientInvalidated(newConfig)) {
      promise = this.updateElement();
    }
    this.oldInvalidationValues = this.getInvalidationValues(newConfig);

    return promise ?? Promise.resolve();
  }

  /**
   * Creates client configuration options based on button configuration
   * options.
   *
   * This method would normally be private but has been made public for
   * testing purposes.
   *
   * @private
   */
  createClientOptions(config: Config): google.payments.api.PaymentOptions {
    const clientConfig: google.payments.api.PaymentOptions = {
      environment: config.environment,
      merchantInfo: this.createMerchantInfo(config),
    };

    if (config.onPaymentDataChanged || config.onPaymentAuthorized) {
      clientConfig.paymentDataCallbacks = {};

      if (config.onPaymentDataChanged) {
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        clientConfig.paymentDataCallbacks.onPaymentDataChanged = (
          paymentData
        ) => {
          const result = config.onPaymentDataChanged!(paymentData);
          return result || ({} as google.payments.api.PaymentDataRequestUpdate);
        };
      }

      if (config.onPaymentAuthorized) {
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        clientConfig.paymentDataCallbacks.onPaymentAuthorized = (
          paymentData
        ) => {
          const result = config.onPaymentAuthorized!(paymentData);
          return (
            result || ({} as google.payments.api.PaymentAuthorizationResult)
          );
        };
      }
    }

    return clientConfig;
  }

  private createIsReadyToPayRequest(
    config: Config
  ): google.payments.api.IsReadyToPayRequest {
    const { paymentRequest } = config;
    const request: google.payments.api.IsReadyToPayRequest = {
      apiVersion: paymentRequest.apiVersion,
      apiVersionMinor: paymentRequest.apiVersionMinor,
      allowedPaymentMethods: paymentRequest.allowedPaymentMethods,
      existingPaymentMethodRequired: config.existingPaymentMethodRequired,
    };

    return request;
  }

  /**
   * Constructs `loadPaymentData` request object based on button configuration.
   *
   * It infers request properties like `shippingAddressRequired`,
   * `shippingOptionRequired`, and `billingAddressRequired` if not already set
   * based on the presence of their associated options and parameters. It also
   * infers `callbackIntents` based on the callback methods defined in button
   * configuration.
   *
   * This method would normally be private but has been made public for
   * testing purposes.
   *
   * @private
   */
  createLoadPaymentDataRequest(
    config: Config
  ): google.payments.api.PaymentDataRequest {
    const request = {
      ...config.paymentRequest,
      merchantInfo: this.createMerchantInfo(config),
    };

    // TODO: #13 re-enable inferrence if/when we agree as a team

    return request;
  }

  private createMerchantInfo(config: Config): google.payments.api.MerchantInfo {
    const merchantInfo: google.payments.api.MerchantInfo = {
      ...config.paymentRequest.merchantInfo,
    };

    // apply softwareInfo if not set
    if (!merchantInfo.softwareInfo) {
      merchantInfo.softwareInfo = {
        id: this.options.softwareInfoId,
        version: this.options.softwareInfoVersion,
      };
    }

    return merchantInfo;
  }

  private isConnected(): boolean {
    return this.element != null && this.element.isConnected !== false;
  }

  private removeButton(): void {
    if (this.element instanceof ShadowRoot || this.element instanceof Element) {
      Array.from(this.element.children)
        .filter((child) => child.tagName !== 'STYLE')
        .forEach((child) => child.remove());
    }
  }

  private async updateElement(): Promise<void> {
    if (!this.isConnected()) return;
    const element = this.getElement()!;

    if (!this.config) {
      throw new Error('google-pay-button: Missing configuration');
    }

    // remove existing button
    this.removeButton();

    this.client = new google.payments.api.PaymentsClient(
      this.createClientOptions(this.config)
    );

    const buttonOptions: google.payments.api.ButtonOptions = {
      buttonType: this.config.buttonType,
      buttonColor: this.config.buttonColor,
      buttonSizeMode: this.config.buttonSizeMode,
      buttonLocale: this.config.buttonLocale,
      onClick: this.handleClick,
      allowedPaymentMethods: this.config.paymentRequest.allowedPaymentMethods,
    };

    const rootNode = element.getRootNode();
    if (rootNode instanceof ShadowRoot) {
      buttonOptions.buttonRootNode = rootNode;
    }

    // pre-create button
    const button = this.client.createButton(buttonOptions);

    this.setClassName(element, [element.className, 'not-ready']);
    element.appendChild(button);

    let showButton = false;
    let readyToPay: google.payments.api.IsReadyToPayResponse | undefined;

    try {
      readyToPay = await this.client.isReadyToPay(
        this.createIsReadyToPayRequest(this.config)
      );
      showButton =
        (readyToPay.result && !this.config.existingPaymentMethodRequired) ||
        (readyToPay.result &&
          readyToPay.paymentMethodPresent &&
          this.config.existingPaymentMethodRequired) ||
        false;
    } catch (err) {
      if (this.config.onError) {
        this.config.onError(err as Error);
      } else {
        Logger.error(err);
      }
    }

    if (!this.isConnected()) return;

    if (showButton) {
      try {
        this.client.prefetchPaymentData(
          this.createLoadPaymentDataRequest(this.config)
        );
      } catch (err) {
        Logger.info('Error with prefetch', err);
      }

      // remove hidden className
      this.setClassName(
        element,
        (element.className || '')
          .split(' ')
          .filter((className) => className && className !== 'not-ready')
      );
    }

    if (
      this.isReadyToPay !== readyToPay?.result ||
      this.paymentMethodPresent !== readyToPay?.paymentMethodPresent
    ) {
      this.isReadyToPay = !!readyToPay?.result;
      this.paymentMethodPresent = readyToPay?.paymentMethodPresent;

      if (this.config.onReadyToPayChange) {
        const readyToPayResponse: ReadyToPayChangeResponse = {
          isButtonVisible: showButton,
          isReadyToPay: this.isReadyToPay,
        };

        if (this.paymentMethodPresent) {
          readyToPayResponse.paymentMethodPresent = this.paymentMethodPresent;
        }

        this.config.onReadyToPayChange(readyToPayResponse);
      }
    }
  }

  /**
   * Handles the click event of the Google Pay button.
   *
   * This method would normally be private but has been made public for
   * testing purposes.
   *
   * @private
   */
  handleClick = (event: Event): void => {
    const { config } = this;
    if (!config) {
      throw new Error('google-pay-button: Missing configuration');
    }

    const request = this.createLoadPaymentDataRequest(config);

    if (config.onClick) {
      config.onClick(event);
    }

    if (event.defaultPrevented) {
      return;
    }

    this.client!.loadPaymentData(request)
      .then((result) => {
        if (config.onLoadPaymentData) {
          config.onLoadPaymentData(result);
        }
      })
      .catch((err) => {
        if (
          (err as google.payments.api.PaymentsError).statusCode === 'CANCELED'
        ) {
          if (config.onCancel) {
            config.onCancel(err as google.payments.api.PaymentsError);
          }
        } else if (config.onError) {
          config.onError(err as google.payments.api.PaymentsError);
        } else {
          Logger.error(err);
        }
      });
  };

  private setClassName(element: Element, classNames: string[]): void {
    const className = classNames.filter((name) => name).join(' ');
    if (className) {
      element.setAttribute('class', className);
    } else {
      element.removeAttribute('class');
    }
  }

  private isClientInvalidated(newConfig: Config): boolean {
    if (!this.oldInvalidationValues) return true;

    const newValues = this.getInvalidationValues(newConfig);
    return newValues.some(
      (value, index) => value !== this.oldInvalidationValues![index]
    );
  }

  private getInvalidationValues(config: Config): any[] {
    return [
      config.environment,
      config.existingPaymentMethodRequired,
      !!config.onPaymentDataChanged,
      !!config.onPaymentAuthorized,
      config.buttonColor,
      config.buttonType,
      config.buttonLocale,
      config.buttonSizeMode,
      config.paymentRequest.merchantInfo.merchantId,
      config.paymentRequest.merchantInfo.merchantName,
      config.paymentRequest.merchantInfo.softwareInfo?.id,
      config.paymentRequest.merchantInfo.softwareInfo?.version,
      config.paymentRequest.allowedPaymentMethods,
    ];
  }
}
