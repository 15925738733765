import { Text } from '@app/shared/typography/text/text';
import { ConnectorTypeEnum } from '@app/core/model/enum/connector-type.enum';
import classNames from 'classnames';

import { ConnectorTypeIcon } from './connector-type.icon';
import { useChargePointConnectorType } from './use-charge-point-connector-type';
import css from './connector-type.module.scss';

type Props = {
  connectorType?: ConnectorTypeEnum;
  className?: string;
};

export function ConnectorType(props: Props): JSX.Element {
  const { connectorType, className } = props;
  const connectorTypeText = useChargePointConnectorType(connectorType);

  return (
    <div className={classNames(css.container, className)}>
      <ConnectorTypeIcon connectorType={connectorType} />
      <Text>{connectorTypeText}</Text>
    </div>
  );
}
