import { useTranslation } from 'react-i18next';

import { useAuthentication } from '@app/core/context-providers/authentication-context/use-authentication';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { H1 } from '@app/shared/typography/heading/heading';
import { Icon } from '@app/shared/icon/icon';

import { CameraError } from './component/qr-code-scanner/camera-error';
import { QrCodeScanner } from './component/qr-code-scanner/qr-code-scanner';
import { ListChargePoint } from './component/list-charge-point/list-charge-point';

import css from './scan.module.scss';

interface ScanViewProps {
  isCameraError: boolean;
  onCameraError: () => void;
  onScan: (text: string) => void;
  onRetry: () => void;
}

export const ScanView = ({
  isCameraError,
  onCameraError,
  onScan,
  onRetry,
}: ScanViewProps): JSX.Element => {
  const { t } = useTranslation();

  const { isAuthenticated } = useAuthentication();

  return (
    <PageLayout withHomeButton>
      <PageLayout.Header>
        <Icon name="qr-code-02" />
        <H1>{t('scan.title')}</H1>
      </PageLayout.Header>

      <PageLayout.Body className={css.body}>
        {isCameraError ? (
          <CameraError onRetry={onRetry} />
        ) : (
          <QrCodeScanner onScan={onScan} onCameraError={onCameraError} />
        )}

        <ListChargePoint />
      </PageLayout.Body>
      {isAuthenticated && <PageLayout.AppbarFooter />}
    </PageLayout>
  );
};
