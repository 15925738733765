import { DELMO_CHARGE_POINT_ID_LSK } from '@app/config/localstorage-keys.const';
import { AsyncProviderProps } from '@app/core/context-providers/providers.props';
import { useChargePointService } from '@app/core/service/use-charge-point.service';
import { getFromLocalStorage } from '@app/core/storage/local-storage';
import { Fade } from '@app/shared/fade/fade';
import { useEffect, useMemo, useState } from 'react';

export const AppInitializer = (props: AsyncProviderProps): JSX.Element => {
  const { children, onReady } = props;
  const [isReady, setIsReady] = useState(false);

  const delmoChargePointId = useMemo(
    () => getFromLocalStorage(DELMO_CHARGE_POINT_ID_LSK),
    []
  );

  // Load charge-point to apply theme if required
  const { isLoading: isLoadingChargePoint } =
    useChargePointService(delmoChargePointId);

  useEffect(() => {
    if (!isLoadingChargePoint || !delmoChargePointId) {
      setIsReady(true);
      onReady();
    }
  }, [isLoadingChargePoint, delmoChargePointId, onReady]);

  return <Fade show={isReady}>{children}</Fade>;
};
