import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import i18next from 'i18next';

import { AbortablePromise } from '@app/core/helper/abort-promise';
import { buildPath } from '@app/core/helper/build-path';
import { getFromLocalStorage } from '@app/core/storage/local-storage';
import {
  DELMO_CHARGE_POINT_OPERATOR_ID_LSK,
  DELMO_CHARGE_POINT_ID_LSK,
  JWT_LSK,
} from '@app/config/localstorage-keys.const';
import { configurationService } from '@app/core/configuration/configuration.service';
import { getFromSessionStorage } from '@app/core/storage/session-storage';
import { WS_TOKEN_SSK } from '@app/config/sessionstorage-keys.const';

export enum HeadersEnum {
  CPO_ID = 'x-cpo-id',
  DELMO_CP_ID = 'x-delmo-cp-id',
  ACCEPT_LANGUAGE = 'Accept-Language',
  WS_TOKEN = 'x-ws-token',
  JWT = 'Authorization',
  CONTENT_TYPE = 'Content-Type',
}

class HttpClientService {
  private static instance: HttpClientService;

  public axios: AxiosInstance;

  private constructor() {
    this.axios = axios.create({
      baseURL: configurationService.getApi(),
    });
  }

  getHeaders(): Partial<Record<HeadersEnum, string>> {
    const delmoCpoId =
      getFromLocalStorage(DELMO_CHARGE_POINT_OPERATOR_ID_LSK) ??
      configurationService.getDefaultCpoId();
    const delmoCpId = getFromLocalStorage(DELMO_CHARGE_POINT_ID_LSK);
    const { resolvedLanguage } = i18next;
    const wsToken = getFromSessionStorage(WS_TOKEN_SSK);
    const jwt = getFromLocalStorage(JWT_LSK);

    return {
      [HeadersEnum.CONTENT_TYPE]: 'application/json',
      ...(delmoCpoId && { [HeadersEnum.CPO_ID]: delmoCpoId }),
      ...(delmoCpId && { [HeadersEnum.DELMO_CP_ID]: delmoCpId }),
      ...(wsToken && { [HeadersEnum.WS_TOKEN]: wsToken }),
      ...(resolvedLanguage && {
        [HeadersEnum.ACCEPT_LANGUAGE]: resolvedLanguage,
      }),
      ...(jwt && { [HeadersEnum.JWT]: `Bearer ${jwt}` }),
    };
  }

  static getInstance(): HttpClientService {
    if (!HttpClientService.instance) {
      HttpClientService.instance = new HttpClientService();
    }

    return HttpClientService.instance;
  }

  get<T>(
    pathname: string,
    id: string,
    config: AxiosRequestConfig = {}
  ): AbortablePromise<T> {
    const abortController = new AbortController();
    const promise = this.axios
      .get<T>(buildPath(pathname, id), {
        ...config,
        headers: {
          ...this.getHeaders(),
          ...config?.headers,
        },
        signal: abortController.signal,
      })
      .then((response: AxiosResponse<T>): T => response.data);
    return [promise, abortController];
  }

  list<T>(
    pathname: string,
    config: AxiosRequestConfig = {}
  ): AbortablePromise<T> {
    const abortController = new AbortController();
    const promise = this.axios
      .get<T>(pathname, {
        ...config,
        headers: {
          ...this.getHeaders(),
          ...config?.headers,
        },
        signal: abortController.signal,
      })
      .then((response: AxiosResponse<T>): T => response.data);
    return [promise, abortController];
  }

  post<T>(
    pathname: string,
    body: any,
    config: AxiosRequestConfig = {}
  ): AbortablePromise<T> {
    const abortController = new AbortController();
    const promise = this.axios
      .post<T>(pathname, body, {
        ...config,
        headers: {
          ...this.getHeaders(),
          ...config?.headers,
        },
        signal: abortController.signal,
      })
      .then((response: AxiosResponse<T>): T => response.data);
    return [promise, abortController];
  }

  run<T>(
    pathname: string,
    config: AxiosRequestConfig = {}
  ): AbortablePromise<T> {
    const abortController = new AbortController();

    const requestConfiguration = {
      ...config,
      headers: {
        ...this.getHeaders(),
        ...config?.headers,
      },
      method: config.method ?? 'POST',
      url: pathname,
      signal: abortController.signal,
    };

    const promise = this.axios
      .request<T>(requestConfiguration)
      .then((response: AxiosResponse<T>): T => response.data);
    return [promise, abortController];
  }

  put<T>(
    pathname: string,
    id: string,
    body: any,
    config: AxiosRequestConfig = {}
  ): AbortablePromise<T> {
    const abortController = new AbortController();
    const promise = this.axios
      .put<T>(buildPath(pathname, id), body, {
        ...config,
        headers: {
          ...config?.headers,
          ...this.getHeaders(),
        },
        signal: abortController.signal,
      })
      .then((response: AxiosResponse<T>): T => response.data);
    return [promise, abortController];
  }

  patch<T>(
    pathname: string,
    id: string,
    body: any,
    config: AxiosRequestConfig = {}
  ): AbortablePromise<T> {
    const abortController = new AbortController();
    const promise = this.axios
      .patch<T>(buildPath(pathname, id), body, {
        ...config,
        headers: {
          ...config?.headers,
          ...this.getHeaders(),
        },
        signal: abortController.signal,
      })
      .then((response: AxiosResponse<T>): T => response.data);
    return [promise, abortController];
  }

  delete<T>(
    pathname: string,
    id: string,
    config: AxiosRequestConfig = {}
  ): AbortablePromise<T> {
    const abortController = new AbortController();
    const promise = this.axios
      .delete<T>(buildPath(pathname, id), {
        ...config,
        headers: {
          ...config?.headers,
          ...this.getHeaders(),
        },
        signal: abortController.signal,
      })
      .then((response: AxiosResponse<T>): T => response.data);
    return [promise, abortController];
  }
}

export const httpClientService = HttpClientService.getInstance();
