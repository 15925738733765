import { Divider } from '@app/shared/divider/divider';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { useTranslation } from 'react-i18next';
import { CompanyInfos } from './component/company-infos';
import { Hosting } from './component/hosting';
import { PublicationManager } from './component/publication-manager';

interface LegalNoticesViewProps {
  backTo: string;
}

export const LegalNoticesView = ({
  backTo,
}: LegalNoticesViewProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PageLayout withHomeButton>
      <PageLayout.Title
        backTo={backTo}
        title={t('legal-text.legal-notices.title')}
        icon="shield-tick"
      />
      <PageLayout.Body>
        <CompanyInfos />

        <Divider type="solid" />
        <PublicationManager />

        <Divider type="solid" />
        <Hosting />
      </PageLayout.Body>
    </PageLayout>
  );
};
