import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { H1 } from '@app/shared/typography/heading/heading';
import { TextInput } from '@app/shared/text-input/text-input';
import { Button } from '@app/shared/button/button';
import { Text } from '@app/shared/typography/text/text';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';
import { RecoverWithSupportDialog } from '@app/feature/recover-account/component/recover-with-support-dialog/recover-with-support.dialog';
import { useDialog } from '@app/shared/dialog/use-dialog';
import { WebauthnAuthenticationStartRequestModel } from '@app/core/model/authentication.model';
import {
  PSEUDO_MAX_LENGTH,
  PSEUDO_MIN_LENGTH,
} from '@app/config/form-validation-rules.const';

import { Icon } from '@app/shared/icon/icon';
import { configurationService } from '@app/core/configuration/configuration.service';
import { CallSupportLink } from '@app/shared/call-support/call-support-link';
import { recoverAccountWithWebauthnFormValidationRules } from './recover-account-with-webauthn-form-validation-rules.const';

import css from './recover-account-with-webauthn.module.scss';

interface RecoverAccountWithWebauthnViewProps {
  remainingLockTimeInSec?: number;
  pending: boolean;
  onSubmit: (pseudo: string) => Promise<void>;
  onNavigateToRegistration: () => void;
}

export const RecoverAccountWithWebauthnView = (
  props: RecoverAccountWithWebauthnViewProps
): JSX.Element => {
  const {
    remainingLockTimeInSec = 0,
    pending,
    onSubmit,
    onNavigateToRegistration,
  } = props;

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { touchedFields, errors, isValid },
  } = useForm<WebauthnAuthenticationStartRequestModel>({
    mode: 'onChange',
  });

  const handleTrimThenSubmit = (
    payload: WebauthnAuthenticationStartRequestModel
  ) => {
    return onSubmit(payload.pseudo.trim().toUpperCase());
  };

  const { isDialogOpen, closeDialog } = useDialog();

  return (
    <>
      <form
        className={css.formContainer}
        onSubmit={handleSubmit(handleTrimThenSubmit)}>
        <DrawerLayout className={css.drawer}>
          <DrawerLayout.Header>
            <H1>{t('recover-account.webauthn.title')}</H1>
            <Text color="secondary">
              {t('recover-account.webauthn.caption')}
            </Text>
          </DrawerLayout.Header>

          <DrawerLayout.Body>
            <div>
              <TextInput
                inputClassName={css.pseudoInput}
                autoFocus
                label={t('recover-account.webauthn.pseudo.label')}
                {...register(
                  'pseudo',
                  recoverAccountWithWebauthnFormValidationRules.pseudo
                )}
                isTouched={touchedFields?.pseudo}
                errorMessage={
                  errors?.pseudo?.type === 'required'
                    ? t('recover-account.webauthn.pseudo.required')
                    : errors?.pseudo?.type === 'maxLength'
                    ? t('recover-account.webauthn.pseudo.max-length', {
                        maxLength: PSEUDO_MAX_LENGTH,
                      })
                    : errors?.pseudo?.type === 'minLength'
                    ? t('recover-account.webauthn.pseudo.min-length', {
                        minLength: PSEUDO_MIN_LENGTH,
                      })
                    : ''
                }
              />
              <Text color="secondary" className={css.help}>
                <Icon name="info-circle" />
                {t('recover-account.webauthn.help')}
              </Text>
            </div>
          </DrawerLayout.Body>

          <DrawerLayout.Footer>
            <p>
              <Text color="secondary">
                {t('recover-account.webauthn.lost-customer-id')}
              </Text>
              <CallSupportLink>
                {t('recover-account.webauthn.lost-customer-id-link', {
                  phoneNumber: configurationService.getSupportPhoneNumber(),
                })}
              </CallSupportLink>
            </p>
            <Button
              type="submit"
              disabled={!isValid || remainingLockTimeInSec > 0}
              isLoading={pending}>
              {t('recover-account.webauthn.submit-button', {
                count: remainingLockTimeInSec,
              })}
            </Button>
          </DrawerLayout.Footer>
        </DrawerLayout>
      </form>

      <RecoverWithSupportDialog
        onNavigateToRegistration={onNavigateToRegistration}
        open={isDialogOpen}
        onClose={closeDialog}
      />
    </>
  );
};
