import { Navigate } from 'react-router-dom';

import { UserModel } from '@app/core/model/user.model';
import { useUser } from '@app/core/context-providers/user-context/use-user';
import { DASHBOARD_PATH } from '@app/config/app-paths.const';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { ErrorCodeEnum } from '@app/config/error-config';

export interface WithUserProps {
  user: UserModel;
  isWebauthnEnabled: boolean;
  refresh: () => void;
}

type ComponentProps<T> = Omit<T, keyof WithUserProps>;

export function withUserGuard<T extends WithUserProps = WithUserProps>(
  WrappedComponent: React.ComponentType<T>
): (props: ComponentProps<T>) => JSX.Element {
  const ComponentWithUser = (props: ComponentProps<T>): JSX.Element => {
    const userProps = useUser();

    if (!userProps?.user) {
      showErrorMessage(
        'user-loading',
        ErrorCodeEnum.WITH_USER_GUARD_HAS_NO_USER
      );

      return <Navigate to={DASHBOARD_PATH} />;
    }

    return <WrappedComponent {...(props as T)} {...userProps} />;
  };

  return ComponentWithUser;
}
