import { useTranslation } from 'react-i18next';

import { CallSupportLink } from '@app/shared/call-support/call-support-link';
import { H1 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';
import { Button } from '@app/shared/button/button';

import css from './email-already-taken.module.scss';

interface EmailAlreadyTakenViewProps {
  emailAddress: string;
  onGoToAuthentication: () => void;
  onGoToRegistration: () => void;
}

export const EmailAlreadyTakenView = (
  props: EmailAlreadyTakenViewProps
): JSX.Element => {
  const { emailAddress, onGoToAuthentication, onGoToRegistration } = props;
  const { t } = useTranslation();

  return (
    <DrawerLayout>
      <DrawerLayout.Header>
        <H1>{t('registration.email-already-taken.title')}</H1>
      </DrawerLayout.Header>

      <DrawerLayout.Body>
        <div className={css.email}>
          <Text color="secondary" className={css.justify}>
            {t('registration.email-already-taken.caption')}
          </Text>

          <Text bold>{emailAddress}</Text>
        </div>

        <Button variant="contained" onClick={onGoToAuthentication}>
          {t('registration.email-already-taken.authenticate')}
        </Button>
      </DrawerLayout.Body>

      <DrawerLayout.Footer>
        <div className={css.register}>
          <Text fontSize="large">
            {t('registration.email-already-taken.register.title')}
          </Text>
          <Button variant="outlined" onClick={onGoToRegistration}>
            {t('registration.email-already-taken.register.link')}
          </Button>
        </div>

        <div className={css.help}>
          <Text fontSize="large">
            {t('registration.email-already-taken.support')}
          </Text>
          <CallSupportLink />
        </div>
      </DrawerLayout.Footer>
    </DrawerLayout>
  );
};
