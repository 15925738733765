import { TariffElementModel } from '@app/core/model/tariff.model';
import { Divider } from '@app/shared/divider/divider';
import {
  hasTariffElementHourlyRestrictions,
  removeTimeRestrictions,
} from '@app/core/helper/ocpi-utils';
import { Text } from '@app/shared/typography/text/text';
import { useTranslation } from 'react-i18next';
import { Link } from '@app/shared/typography/link/link';

import { FirstTariffDetail } from './first-tariff-detail';
import { TariffList } from '../../../tariff/component/tariff-list/tariff-list';
import css from './current-tariff-complex.module.scss';

type Props = {
  tariffElementList: TariffElementModel[];
  onClick: () => void;
};

export function CurrentTariffComplex(props: Props): JSX.Element {
  const { tariffElementList, onClick } = props;
  const { t } = useTranslation();

  const firstTariffElement = tariffElementList.at(0);
  const extraTariffElementList = removeTimeRestrictions(
    tariffElementList.slice(1)
  );

  const hasHourlyRestrictions =
    hasTariffElementHourlyRestrictions(tariffElementList);

  return (
    <>
      {firstTariffElement && (
        <FirstTariffDetail tariffElement={firstTariffElement} />
      )}

      <div className={css.extraContainer}>
        <TariffList elements={extraTariffElementList} />
        {extraTariffElementList.length > 0 && (
          <Divider className={css.divider} type="dashed" />
        )}

        {hasHourlyRestrictions && (
          <>
            <Text className={css.center} color="secondary">
              {t('tariff.more-tariff.label')}
            </Text>
            <Link className={css.center} onClick={onClick}>
              {t('tariff.more-tariff.link')}
            </Link>
          </>
        )}
      </div>
    </>
  );
}
