import { useCallback, useMemo, useState } from 'react';
import { EMAIL_LSK, PSEUDO_LSK } from '@app/config/localstorage-keys.const';
import { getFromLocalStorage } from '@app/core/storage/local-storage';

import { BackLink } from '@app/shared/back-link/back-link';
import { AuthenticationController } from '../authentication/authentication.controller';
import { RegistrationController } from '../registration/registration.controller';
import { RecoverAccountController } from '../recover-account/recover-account.controller';

enum RegisterOrRecoverEnum {
  REGISTER_OR_RECOVER,
  REGISTER,
  RECOVER,
}

export const HomeDrawer = (): JSX.Element => {
  const [registerOrRecoverState, setRegisterOrRecoverState] = useState(
    RegisterOrRecoverEnum.REGISTER_OR_RECOVER
  );

  const pseudo = useMemo(() => getFromLocalStorage(PSEUDO_LSK), []);
  const [emailAddress, setEmailAddress] = useState(
    getFromLocalStorage(EMAIL_LSK) ?? ''
  );

  const handleGoToRegister = useCallback(() => {
    setRegisterOrRecoverState(RegisterOrRecoverEnum.REGISTER);
  }, []);

  const handleGoToRecover = useCallback((email?: string) => {
    if (email) {
      setEmailAddress(email);
    }

    setRegisterOrRecoverState(RegisterOrRecoverEnum.RECOVER);
  }, []);

  if (pseudo !== null && emailAddress !== null) {
    return (
      <AuthenticationController emailAddress={emailAddress} pseudo={pseudo} />
    );
  }

  switch (registerOrRecoverState) {
    case RegisterOrRecoverEnum.RECOVER:
      // PAGE recuperation (connexion email)
      return (
        <>
          <BackLink onClick={handleGoToRegister} />
          <RecoverAccountController
            emailAddress={emailAddress}
            onNavigateToRegistration={handleGoToRegister}
          />
        </>
      );

    case RegisterOrRecoverEnum.REGISTER:
    default: // PAGE inscription
      return (
        <RegistrationController onOpenAuthentication={handleGoToRecover} />
      );
  }
};
