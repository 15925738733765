import { useCallback, useState } from 'react';

import { ApiError } from '@app/core/error/api-error';
import { catchApiError } from '@app/core/error/catch-api-error';
import { showErrorMessage } from '@app/core/error/show-error-message';

import { paymentMethodService } from '../../../../../core/service/payment-method.service';
import { RenamePaymentMethodForm } from './rename-payment-method.form';
import { RenamePaymentMethodView } from './rename-payment-method.view';

interface RenamePaymentMethodControllerProps {
  iPaymentSourceId: string;
  name: string;
  onRename: (name: string) => void;
  onCancel: () => void;
}

export const RenamePaymentMethodController = (
  props: RenamePaymentMethodControllerProps
): JSX.Element => {
  const { iPaymentSourceId, name, onRename, onCancel } = props;
  const [pending, setPending] = useState(false);

  const handleRename = useCallback(
    async (payload: RenamePaymentMethodForm) => {
      setPending(true);
      try {
        const [renamePromise] = paymentMethodService.renamePaymentMethod(
          iPaymentSourceId,
          payload
        );
        await renamePromise;
        setPending(false);
        onRename(payload.name);
      } catch (err) {
        catchApiError(err, (error: ApiError) => {
          showErrorMessage('rename-payment-method', error.code);
        });
      }
    },
    [iPaymentSourceId, onRename]
  );

  return (
    <RenamePaymentMethodView
      pending={pending}
      defaultValue={name}
      onSubmit={handleRename}
      onCancel={onCancel}
    />
  );
};
