export type DisplayTextModel = { language: string; text: string };

export enum TariffDimensionTypeEnum {
  'ENERGY' = 'ENERGY',
  'FLAT' = 'FLAT',
  'TIME' = 'TIME',
}

export type PriceComponentResponseModel = {
  type: TariffDimensionTypeEnum;
  price: number;
  step_size: number;
  price_ttc?: number;
};

export type TariffRestrictionResponseModel = {
  start_time?: string; // format "hh:mm"
  end_time?: string; // format "hh:mm"
  max_kwh?: number;
  min_kwh?: number;
};

export type TariffElementResponseModel = {
  price_components: PriceComponentResponseModel[];
  restrictions?: TariffRestrictionResponseModel;
};

export type TariffResponseModel = {
  tariff_alt_text?: DisplayTextModel[];
  elements?: TariffElementResponseModel[];
};
