import { ChargingEventModel } from '@app/core/model/charging-event.model';
import { Point } from '@app/feature/charging/component/charge-chart/model/point';

export function getSpeedFromPosition(previous: Point, current: Point): number {
  const deltaX = current.x - previous.x;
  const deltaY = current.y - previous.y;

  if (deltaX === 0) {
    return 0;
  }
  return deltaY / deltaX;
}

export function mapHistoryToTotalKWPoint(
  history: ChargingEventModel[]
): Point[] {
  return history.map(
    (charge): Point => ({
      x: charge.duration ?? 0,
      y: charge.totalKwh ?? 0,
    })
  );
}

export function mapTotalEnergyDeliveredPointToSpeedPoint(
  totalEnergyDeliveredPoint: Point[]
): Point[] {
  let previousPoint: Point = { x: 0, y: 0 };

  return totalEnergyDeliveredPoint.map((currentPoint) => {
    const speedInKWh: number =
      getSpeedFromPosition(previousPoint, currentPoint) * 3600;

    previousPoint = { ...currentPoint };

    return {
      x: currentPoint.x,
      y: speedInKWh,
    };
  });
}
