import { GPDR_API } from '@app/config/api-routes.const';
import { httpClientService } from '@app/core/client/http/http-client.service';

class DeleteAccountService {
  deleteAccount(token: string): Promise<void> {
    const [promise] = httpClientService.run<void>(GPDR_API, {
      method: 'DELETE',
      data: { token },
    });

    return promise;
  }
}

export const deleteAccountService = new DeleteAccountService();
