import { ChargeSessionEnum } from '@app/core/model/enum/charge-session.enum';
import {
  ChargeSessionReducerFunc,
  reduceResetEvent,
  reduceSessionEndedEvent,
  reduceSessionInvalidEvent,
  reduceSessionPendingEvent,
  reduceSessionStartedEvent,
  reduceStartChargeEvent,
  reduceStopChargeEvent,
} from './reduce-charge-event';

const chargeSessionReducerMap: Record<
  ChargeSessionEnum,
  ChargeSessionReducerFunc
> = {
  [ChargeSessionEnum.RESET]: reduceResetEvent,
  [ChargeSessionEnum.SESSION_INVALID]: reduceSessionInvalidEvent,
  [ChargeSessionEnum.START_CHARGE_CPO_ACCEPTED]: reduceStartChargeEvent,
  [ChargeSessionEnum.START_CHARGE_CPO_REFUSED]: reduceStartChargeEvent,
  [ChargeSessionEnum.START_CHARGE_CP_TIMEOUT]: reduceStartChargeEvent,
  [ChargeSessionEnum.START_CHARGE_CP_ACCEPTED]: reduceStartChargeEvent,
  [ChargeSessionEnum.START_CHARGE_CP_REFUSED]: reduceStartChargeEvent,
  [ChargeSessionEnum.SESSION_START_TIMEOUT]: reduceStartChargeEvent,
  [ChargeSessionEnum.SESSION_STARTED]: reduceSessionStartedEvent,
  [ChargeSessionEnum.SESSION_PENDING]: reduceSessionPendingEvent,
  [ChargeSessionEnum.STOP_CHARGE_CPO_ACCEPTED]: reduceStopChargeEvent,
  [ChargeSessionEnum.STOP_CHARGE_CPO_REFUSED]: reduceStopChargeEvent,
  [ChargeSessionEnum.STOP_CHARGE_CP_TIMEOUT]: reduceStopChargeEvent,
  [ChargeSessionEnum.STOP_CHARGE_CP_ACCEPTED]: reduceStopChargeEvent,
  [ChargeSessionEnum.STOP_CHARGE_CP_REFUSED]: reduceStopChargeEvent,
  [ChargeSessionEnum.SESSION_STOP_TIMEOUT]: reduceStopChargeEvent,
  [ChargeSessionEnum.SESSION_ENDED]: reduceSessionEndedEvent,
  [ChargeSessionEnum.CDR_EDITED]: reduceSessionEndedEvent,
};

export const chargeSessionReducer: ChargeSessionReducerFunc = (
  currentState,
  action
) => {
  const mappedAction = chargeSessionReducerMap[action.type];
  return mappedAction ? mappedAction(currentState, action) : currentState;
};
