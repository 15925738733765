import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { userPreferenceService } from '@app/core/service/user-preference.service';
import { catchApiError } from '@app/core/error/catch-api-error';
import { ApiError } from '@app/core/error/api-error';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { useUser } from '@app/core/context-providers/user-context/use-user';
import {
  PatchUserPreferenceModel,
  UserPreferenceKey,
} from '@app/core/model/user-preference.model';
import { UserModel } from '@app/core/model/user.model';

type PreferencePending = Partial<Record<UserPreferenceKey, boolean>>;

interface UserPreferenceHookReturn {
  pending?: PreferencePending;
  onChangeOne: (key: UserPreferenceKey, value: boolean) => void;
  onChangeAll: (value: boolean) => void;
}

export const useUserPreference = (): UserPreferenceHookReturn => {
  const { t } = useTranslation();
  const [pending, setPending] = useState<PreferencePending>();
  const { onUserUpdate } = useUser();

  const handlePending = (
    payload: PatchUserPreferenceModel,
    value: boolean
  ): void => {
    setPending((previous) => {
      const modifiedKeys: PreferencePending = {};

      Object.keys(payload).forEach((key: string) => {
        modifiedKeys[key as UserPreferenceKey] = value;
      });

      return {
        ...previous,
        ...modifiedKeys,
      };
    });
  };

  function handleChange(payload: PatchUserPreferenceModel): void {
    handlePending(payload, true);

    const [patchPromise] = userPreferenceService.patch(payload);

    patchPromise
      .then((response: UserModel) => {
        toast.success(t('user-preference.success-update'), {
          toastId: 'user-preference.success-update',
        });

        onUserUpdate(response);

        handlePending(payload, false);
      })
      .catch((error: unknown) => {
        handlePending(payload, false);
        catchApiError(error, (apiError: ApiError) => {
          showErrorMessage('patch-user-preference', apiError.code);
        });
      });
  }

  const handleChangeAll = (value: boolean) => {
    handleChange({
      autoSendPaymentTicketByMail: value,
      autoSendInvoiceByMail: value,
      autoSendChargeEndByMail: value,
    });
  };

  const handleChangeOne = (key: UserPreferenceKey, value: boolean) => {
    handleChange({ [key]: value });
  };

  return {
    pending,
    onChangeOne: handleChangeOne,
    onChangeAll: handleChangeAll,
  };
};
