import { useCallback, useMemo } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';

import { Dialog } from '@app/shared/dialog/dialog';
import {
  CHECKOUT_SESSION_ID_QSTR,
  CHECKOUT_STATUS_QSTR,
  CHECKOUT_STATUS_SUCCESS_QSTR,
} from '@app/config/app-paths.const';

import { CheckoutCallbackController } from './checkout-callback.controller';

interface CheckoutCallbackDialogControllerProps {
  hasAlreadyAPaymentMethod: boolean;
  onPrecaptureFailed?: () => void;
}

export const CheckoutCallbackDialogController = (
  props: CheckoutCallbackDialogControllerProps
): JSX.Element => {
  const { hasAlreadyAPaymentMethod, onPrecaptureFailed = () => {} } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const isDialogOpen = useMemo(() => {
    const ckoStatus = searchParams.get(CHECKOUT_STATUS_QSTR);
    return Boolean(ckoStatus);
  }, [searchParams]);

  const isQueryStringFailed = useMemo(() => {
    const ckoStatus = searchParams.get(CHECKOUT_STATUS_QSTR);

    return ckoStatus !== null && ckoStatus !== CHECKOUT_STATUS_SUCCESS_QSTR;
  }, [searchParams]);

  const closeDialog = useCallback(() => {
    const params = Array.from(searchParams.entries()).filter(
      ([key]) =>
        key !== CHECKOUT_SESSION_ID_QSTR && key !== CHECKOUT_STATUS_QSTR
    );

    setSearchParams(createSearchParams(params), { replace: true });
  }, [searchParams, setSearchParams]);

  return (
    <Dialog open={isDialogOpen}>
      <CheckoutCallbackController
        hasAlreadyAPaymentMethod={hasAlreadyAPaymentMethod}
        isQueryStringFailed={isQueryStringFailed}
        onContinue={closeDialog}
        onPrecaptureFailed={onPrecaptureFailed}
      />
    </Dialog>
  );
};
