import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getFromLocalStorage,
  removeInLocalStorage,
} from '@app/core/storage/local-storage';
import { CREATE_TRANSACTION_FROM_I_PAYMENT_SOURCE_ID_LSK } from '@app/config/localstorage-keys.const';

import { CheckoutResultView } from './result/checkout-result.view';
import { CheckoutCallbackStatusEnum } from './checkout-callback-status.enum';
import { CheckoutPendingController } from './pending/checkout-pending.controller';

interface CheckoutCallbackControllerProps {
  hasAlreadyAPaymentMethod: boolean;
  isQueryStringFailed: boolean;
  onContinue: () => void;
  onPrecaptureFailed?: () => void;
}

export const CheckoutCallbackController = (
  props: CheckoutCallbackControllerProps
): JSX.Element => {
  const {
    hasAlreadyAPaymentMethod,
    isQueryStringFailed,
    onContinue,
    onPrecaptureFailed = () => {},
  } = props;

  const isPaymentSolutionReused = useMemo(
    () =>
      getFromLocalStorage(CREATE_TRANSACTION_FROM_I_PAYMENT_SOURCE_ID_LSK) ===
      true.toString(),
    []
  );

  const [status, setStatus] = useState<CheckoutCallbackStatusEnum>(
    CheckoutCallbackStatusEnum.PENDING
  );

  const handleFailure = useCallback(() => {
    setStatus(CheckoutCallbackStatusEnum.ERROR);
    onPrecaptureFailed();
  }, [onPrecaptureFailed]);

  const handleSuccess = useCallback(() => {
    setStatus(CheckoutCallbackStatusEnum.SUCCESS);
  }, []);

  const handleContinue = useCallback(() => {
    removeInLocalStorage(CREATE_TRANSACTION_FROM_I_PAYMENT_SOURCE_ID_LSK);
    onContinue();
  }, [onContinue]);

  useEffect((): void => {
    if (isQueryStringFailed) {
      handleFailure();
    }
  }, [isQueryStringFailed, handleFailure]);

  return status === CheckoutCallbackStatusEnum.PENDING ? (
    <CheckoutPendingController
      onSuccess={handleSuccess}
      onFailure={handleFailure}
    />
  ) : (
    <CheckoutResultView
      isPaymentSolutionReused={isPaymentSolutionReused}
      success={status === CheckoutCallbackStatusEnum.SUCCESS}
      hasAlreadyAPaymentMethod={hasAlreadyAPaymentMethod}
      onContinue={handleContinue}
    />
  );
};
