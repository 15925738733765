import { Text } from '@app/shared/typography/text/text';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon } from '@app/shared/icon/icon';

import css from './power-type.module.scss';

type Props = {
  power?: number;
  className?: string;
};

export function Power(props: Props): JSX.Element {
  const { power, className } = props;
  const { t } = useTranslation();

  return (
    <div className={classNames(css.container, className)}>
      <Icon name="lightning-02" />
      <Text>{t('shared.charge-point.power', { value: power })}</Text>
    </div>
  );
}
