import { TariffElementModel } from '@app/core/model/tariff.model';

import css from './tariff-element.module.scss';
import {
  EnergyRestrictions,
  TimeRestrictions,
} from '../restriction/restrictions';
import { PriceComponent } from './price-component';

export function TariffElement(props: TariffElementModel): JSX.Element {
  const { priceComponents, restrictions } = props;

  return (
    <div>
      <div className={css.restrictions}>
        <TimeRestrictions {...restrictions} />
        <EnergyRestrictions {...restrictions} />
      </div>

      <PriceComponent {...priceComponents} className={css.priceComponent} />
    </div>
  );
}
