import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { HOME_PATH } from '@app/config/app-paths.const';
import { useAuthentication } from '@app/core/context-providers/authentication-context/use-authentication';
import { useUser } from '@app/core/context-providers/user-context/use-user';
import { useCopyToClipBoard } from '@app/core/helper/use-copy-to-clipboard';
import { ClearStorageDialog } from '@app/feature/legal-text/component/clear-storage-dialog/clear-storage.dialog';
import { ClearStorageLink } from '@app/feature/legal-text/component/clear-storage-dialog/clear-storage.link';
import { useDialog } from '@app/shared/dialog/use-dialog';

export const ClearStorageController = (): JSX.Element => {
  const { t } = useTranslation();
  const { isDialogOpen, openDialog, closeDialog } = useDialog();
  const { user } = useUser();
  const { isAuthenticated, disconnect } = useAuthentication();
  const navigate = useNavigate();

  const handleClearStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
    disconnect();
    navigate(HOME_PATH);
  };

  const handleSuccessCopy = () => {
    toast.success(t('legal-text.clear-storage.copy.success'));
  };

  const handleErrorCopy = () => {
    toast.warning(t('legal-text.clear-storage.copy.error'));
  };

  const { onCopyToClipboard } = useCopyToClipBoard({
    onSuccess: handleSuccessCopy,
    onError: handleErrorCopy,
  });

  const handleCopy = () => {
    onCopyToClipboard(user?.pseudo);
  };

  return (
    <>
      <ClearStorageLink onClick={openDialog} />
      <ClearStorageDialog
        isAuthenticated={isAuthenticated}
        pseudo={user?.pseudo}
        emailAddress={user?.emailAddress}
        onClear={handleClearStorage}
        open={isDialogOpen}
        onClose={closeDialog}
        onCopyPseudo={handleCopy}
      />
    </>
  );
};
