/*
    All users paths
*/
export const CHARGE_POINT_PATH = '/charge-point/:delmoChargePointId';
export const TINY_CHARGE_POINT_PATH = '/:tinyChargePointId';
export const SCAN_PATH = '/scan';

/*
    Guest paths
*/
export const HOME_PATH = '/';
export const GUEST_FAQ_PATH = '/faq';
export const GUEST_FAQ_SECTION_PATH = '/faq/:section';
export const GUEST_LEGAL_TEXT_PATH = '/terms-and-conditions';
export const GUEST_LEGAL_NOTICES_PATH = '/legal-notices';

/*
    Authenticated users paths
*/
export const DASHBOARD_PATH = '/dashboard';
export const FIRST_LOGIN_PATH = '/first-login';
export const STATION_PATH = '/station';

// Payment feature paths
export const CREATE_QUOTE_PATH = '/create-quote';
export const QUOTE_PATH = '/quote';

// Invoice feature paths
export const INVOICE_DASHBOARD_PATH = '/list-invoice';
export const INVOICE_SUMMARY_PATH = '/invoice/:invoiceId';

// Charge feature paths
export const CHARGE_CAR_PATH = '/charge-car';
export const CHARGING_PATH = '/charging';
export const CHARGE_OVERVIEW_PATH = '/charge-overview';

// FAQ paths
export const HELP_PATH = '/help';

export const FAQ_PATH = '/help/faq';
export const FAQ_SECTION_PATH = '/help/faq/:section';

// Query strings
export const CHECKOUT_SESSION_ID_QSTR = 'cko-session-id';
export const CHECKOUT_STATUS_QSTR = 'cko-status';
export const CHECKOUT_STATUS_SUCCESS_QSTR = 'success';

// Account paths

export const ACCOUNT_PATH = '/account';
export const SECURITY_PATH = '/account/security';
export const LANGUAGE_PATH = '/account/security/language';
export const MANAGE_PERSONAL_INFORMATION_PATH =
  '/account/security/personal-information';
export const MANAGE_AUTHENTICATION_PATH = '/account/security/authentication';

export const USER_SUBSCRIPTION_PATH = '/account/subscriptions';

export const USER_PREFERENCE_PATH = '/account/user-preference';
export const USER_PAYMENT_METHOD_PATH = '/account/payment-method';

export const TARIFICATION_PATH = '/account/help/tarification';

export const LEGAL_TEXT_PATH = '/account/legal-text';
export const LEGAL_NOTICES_PATH = '/account/legal-text/legal-notices';

export const ABOUT_PATH = '/account/about-us';
