import { SUBSCRIPTION_DETAILS_API } from '@app/config/api-routes.const';
import { httpClientService } from '@app/core/client/http/http-client.service';
import { SubscriptionDetailsModel } from '../model/subscription.model';

class SubscriptionService {
  getSubscriptionDetailsByEmspSubscriptionIds(
    emspSubscriptionIds: string[]
  ): Promise<SubscriptionDetailsModel[]> {
    const [promise] = httpClientService.run<SubscriptionDetailsModel[]>(
      SUBSCRIPTION_DETAILS_API,
      {
        method: 'GET',
        params: { ids: JSON.stringify(emspSubscriptionIds) },
      }
    );
    return promise;
  }
}

export const subscriptionService = new SubscriptionService();
