import { HOME_PATH } from '@app/config/app-paths.const';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetRedirectionState } from './use-get-redirection-state';

type RedirectToHomeWithStateHook = () => void;

export const useRedirectToHomeWithState = (): RedirectToHomeWithStateHook => {
  const navigate = useNavigate();
  const state = useGetRedirectionState();

  const redirectToHomeWithState = useCallback(() => {
    navigate(HOME_PATH, {
      state,
      replace: true,
    });
  }, [navigate, state]);

  return redirectToHomeWithState;
};
