import { httpClientService } from '@app/core/client/http/http-client.service';
import { matchPath } from 'react-router-dom';

import { Logger } from '@app/core/logger/logger';

const baseURL = process.env.DELMONICOS_MOCK_API;
const mockURLs: string[] = [];

export function isUrlMocked(testedURL: string): boolean {
  return (
    mockURLs.filter((mockURL) => matchPath(mockURL, testedURL) !== null)
      .length > 0
  );
}

export function addHttpMock(url: string): void {
  mockURLs.push(url);
}

export function removeHttpMock(url: string): void {
  const index = mockURLs.indexOf(url);
  if (index !== -1) {
    mockURLs.splice(index, 1);
  }
}

httpClientService.axios.interceptors.request.use(
  (config) => {
    if (config.url && isUrlMocked(config.url) && baseURL) {
      Logger.debug('MOCK HTTP', config.url);

      return {
        ...config,
        baseURL,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);
