import { HTMLProps, MouseEvent, useCallback } from 'react';
import classNames from 'classnames';

import { Fade } from '../fade/fade';
import { Overlay } from '../overlay/overlay';
import { Portal } from '../portal/portal';
import { Icon } from '../icon/icon';

import css from './dialog.module.scss';

export interface DialogProps extends HTMLProps<HTMLDivElement> {
  open?: boolean;
  onClose?: () => void;
}

export const Dialog = ({
  open = false,
  onClose,
  children,
  className,
}: DialogProps): JSX.Element => {
  const handleDialogClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }, []);

  return (
    <Portal wrapperId="portal-modal">
      <Fade show={open}>
        <Overlay variant="colored" onClick={onClose}>
          <div
            className={classNames(css.container, className)}
            onClick={handleDialogClick}>
            {onClose && (
              <div className={css.closeButton}>
                <button onClick={onClose}>
                  <Icon name="x-close" />
                </button>
              </div>
            )}

            {children}
          </div>
        </Overlay>
      </Fade>
    </Portal>
  );
};
