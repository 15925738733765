import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Frames,
  CardNumber,
  Cvv,
  ExpiryDate,
  FrameCardTokenizationFailedEvent,
  FrameCardTokenizedEvent,
  FrameValidationChangedEvent,
} from 'frames-react';

import { Button } from '@app/shared/button/button';
import { H1 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';
import { Spinner } from '@app/shared/spinner/spinner';
import { Checkbox } from '@app/shared/checkbox/checkbox';
import { showErrorMessage } from '@app/core/error/show-error-message';
import {
  getFromLocalStorage,
  removeInLocalStorage,
  setInLocalStorage,
} from '@app/core/storage/local-storage';
import { useLoadScript } from '@app/core/load-script/use-load-script';
import { Logger } from '@app/core/logger/logger';
import { ApiError } from '@app/core/error/api-error';
import { ErrorCodeEnum } from '@app/config/error-config';
import { catchApiError } from '@app/core/error/catch-api-error';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';
import { CheckoutConfigurationModel } from '@app/core/model/payment-widgets-configuration-response.model';
import { mapCheckoutErrorToTranslationKey } from '@app/core/helper/map-checkout-error-to-translation-key';
import { BASE_CHECKOUT_CONFIGURATION } from '@app/config/checkout-config.const';

import css from './checkout-frame.module.scss';
import { CopyCardNumber, CopyCVV, CopyExpirationDate } from './copy';

const MUST_SAVE_PAYMENT_METHOD_LSK = 'must-save-paymentMethod';

interface CheckoutFrameViewProps {
  forceSave: boolean;
  configuration: CheckoutConfigurationModel;
  onToken: (
    event: FrameCardTokenizedEvent,
    mustSavePaymentMethod: boolean
  ) => Promise<void>;
}

enum CheckoutErrorEnum {
  invalid = 'invalid',
  empty = 'empty',
}

interface FormError {
  'card-number'?: CheckoutErrorEnum | null;
  cvv?: CheckoutErrorEnum | null;
  'expiry-date'?: CheckoutErrorEnum | null;
}

export const CheckoutFrameView = ({
  forceSave,
  configuration,
  onToken,
}: CheckoutFrameViewProps): JSX.Element => {
  const { t } = useTranslation();

  const [fieldErrors, setFieldErrors] = useState<FormError>({});

  const [tokenPending, setTokenPending] = useState(false);
  const [submitPending, setSubmitPending] = useState(false);

  const isScriptLoading = useLoadScript(configuration.cdn);

  const isFormValid = useMemo(() => {
    return (
      fieldErrors['card-number'] === null &&
      fieldErrors?.cvv === null &&
      fieldErrors['expiry-date'] === null
    );
  }, [fieldErrors]);

  const handleFrameValidationChange = useCallback(
    (event: FrameValidationChangedEvent) => {
      setFieldErrors((currentErrors) => ({
        ...currentErrors,
        [event.element]: event.isEmpty
          ? CheckoutErrorEnum.empty
          : !event.isValid
          ? CheckoutErrorEnum.invalid
          : null,
      }));
    },
    []
  );

  const handleCardTokenizationFailed = useCallback(
    (error: FrameCardTokenizationFailedEvent) => {
      Logger.error('handleCardTokenizationFailed', error);

      showErrorMessage(
        'checkout-tokenization',
        ErrorCodeEnum.CHECKOUT_TOKENIZATION_FAILED
      );

      Frames.enableSubmitForm();
    },
    []
  );

  const handleToken = useCallback(
    async (event: FrameCardTokenizedEvent) => {
      setTokenPending(true);

      const mustSavePaymentMethod = Boolean(
        getFromLocalStorage(MUST_SAVE_PAYMENT_METHOD_LSK)
      );
      removeInLocalStorage(MUST_SAVE_PAYMENT_METHOD_LSK);

      try {
        await onToken(event, mustSavePaymentMethod);
      } catch (error) {
        catchApiError(error, (apiError: ApiError) => {
          const translationKey = mapCheckoutErrorToTranslationKey(
            apiError.code
          );
          showErrorMessage(translationKey, apiError.code);
        });

        Logger.warn(error);
      } finally {
        setTokenPending(false);
        Frames.enableSubmitForm();
      }
    },
    [onToken]
  );

  const handleSubmit = useCallback(async () => {
    setSubmitPending(true);

    try {
      await Frames.submitCard();
    } catch (error) {
      Logger.error('handleSubmit', error);
      showErrorMessage('checkout-submit', ErrorCodeEnum.CHECKOUT_SUBMIT_FAILED);

      Frames.enableSubmitForm();
    } finally {
      setSubmitPending(false);
    }
  }, []);

  return (
    <DrawerLayout>
      <DrawerLayout.Header>
        <H1>{t('payment.title')}</H1>
        <Text>{t('payment.checkout.title')}</Text>
      </DrawerLayout.Header>

      <DrawerLayout.Body>
        {isScriptLoading ? (
          <Spinner />
        ) : (
          <Frames
            config={{
              ...BASE_CHECKOUT_CONFIGURATION,
              ...configuration?.paymentRequest,
            }}
            cardTokenized={handleToken}
            cardTokenizationFailed={handleCardTokenizationFailed}
            frameValidationChanged={handleFrameValidationChange}>
            <div>
              <Text className={css.label}>
                {t('payment.checkout.card-number.label')} <CopyCardNumber />
              </Text>
              <CardNumber className={css.iframeContainer} />
              <Text color="error">
                {fieldErrors['card-number'] === CheckoutErrorEnum.invalid &&
                  t('payment.checkout.card-number.invalid')}
                {fieldErrors['card-number'] === CheckoutErrorEnum.empty &&
                  t('payment.checkout.card-number.empty')}
              </Text>
            </div>

            <div>
              <Text className={css.label}>
                {t('payment.checkout.expiry-date.label')} <CopyExpirationDate />
              </Text>
              <ExpiryDate className={css.iframeContainer} />
              <Text color="error">
                {fieldErrors['expiry-date'] === CheckoutErrorEnum.invalid &&
                  t('payment.checkout.expiry-date.invalid')}
                {fieldErrors['expiry-date'] === CheckoutErrorEnum.empty &&
                  t('payment.checkout.expiry-date.empty')}
              </Text>
            </div>

            <div>
              <Text className={css.label}>
                {t('payment.checkout.cvv.label')} <CopyCVV />
              </Text>
              <Cvv className={css.iframeContainer} />
              <Text color="error">
                {fieldErrors.cvv === CheckoutErrorEnum.invalid &&
                  t('payment.checkout.cvv.invalid')}
                {fieldErrors.cvv === CheckoutErrorEnum.empty &&
                  t('payment.checkout.cvv.empty')}
              </Text>
            </div>

            {!forceSave && (
              <Checkbox
                id="save-card"
                label={t('payment.checkout.save-card')}
                onChange={(event) => {
                  setInLocalStorage(
                    MUST_SAVE_PAYMENT_METHOD_LSK,
                    event.target.checked.toString()
                  );
                }}
              />
            )}
          </Frames>
        )}
      </DrawerLayout.Body>

      <DrawerLayout.Footer>
        <Button
          type="submit"
          onClick={handleSubmit}
          isLoading={tokenPending || submitPending}
          disabled={!isFormValid}>
          {t('payment.checkout.button')}
        </Button>
      </DrawerLayout.Footer>
    </DrawerLayout>
  );
};
