import { Text } from '@app/shared/typography/text/text';
import { Icon } from '@app/shared/icon/icon';
import { PhysicalReference } from '@app/shared/physical-reference/physical-reference';

import classNames from 'classnames';
import css from './charge-point-name.module.scss';

interface ChargePointNameProps {
  name?: string;
  physicalReference?: string;
  light?: boolean;
}

export const ChargePointName = (props: ChargePointNameProps): JSX.Element => {
  const { name, physicalReference, light = false } = props;

  return (
    <div
      className={classNames([
        css.container,
        light ? css.light : css.secondary,
      ])}>
      <Icon name="electric-terminal" />

      <Text color={light ? 'light' : 'secondary'} className={css.name}>
        {name}
      </Text>

      {physicalReference && (
        <PhysicalReference physicalReference={physicalReference} />
      )}
    </div>
  );
};
