import { TranslationPaths } from '@app/core/helper/use-typed-translation';

export interface StartEmailOtpTranslations {
  title: string;
  caption: string;
  inputLabel: string;
  submitButton: string;
}

export function buildStartEmailOtpTranslations(
  translationKey: TranslationPaths
): StartEmailOtpTranslations {
  return {
    title: `${translationKey}.ask-for-code.title`,
    caption: `${translationKey}.ask-for-code.caption`,
    inputLabel: `${translationKey}.ask-for-code.input-label`,
    submitButton: `${translationKey}.ask-for-code.submit-button`,
  };
}
