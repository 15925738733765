import { useTranslation } from 'react-i18next';

import { WebauthnAuthenticatorModel } from '@app/core/model/authenticator.model';
import { ItemMenu } from '@app/shared/item-menu/item-menu';
import { Icon } from '@app/shared/icon/icon';

import { RenameAuthenticator } from '../rename-authenticator/rename-authenticator';
import css from './authenticator-item.module.scss';

interface AuthenticatorItemProps {
  index: number;
  authenticator: WebauthnAuthenticatorModel;
  onRename: (authenticator: WebauthnAuthenticatorModel) => void;
}

export const AuthenticatorItem = (
  props: AuthenticatorItemProps
): JSX.Element => {
  const { index, authenticator, onRename } = props;

  const { t } = useTranslation();
  const { credentialId, name } = authenticator;

  const authenticatorName: string =
    name ??
    t('manage-authentication-method.webauthn.item.default-label', {
      index,
    });

  return (
    <ItemMenu
      left={<Icon className={css.icon} name="check" color="primary" />}
      label={authenticatorName}
      caption={credentialId}
      right={
        <RenameAuthenticator
          {...authenticator}
          name={authenticatorName}
          onSubmit={onRename}
        />
      }
    />
  );
};
