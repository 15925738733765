import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { subscriptionService } from './subscription.service';
import { SubscriptionDetailsModel } from '../model/subscription.model';

export const useGetSubscriptionDetailsByEmspSubscriptionIdsService = (
  emspSubcriptionIds: string[] = []
): UseQueryResult<SubscriptionDetailsModel[]> => {
  return useQuery({
    queryKey: ['user-subscription', ...emspSubcriptionIds],

    queryFn: async () =>
      subscriptionService.getSubscriptionDetailsByEmspSubscriptionIds(
        emspSubcriptionIds
      ),

    enabled: emspSubcriptionIds.length > 0,
    staleTime: 5 * 60 * 1000,
  });
};
