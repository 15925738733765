import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { ChargingCarAnim } from '@app/shared/charging-car-anim/charging-car-anim';
import { Text } from '@app/shared/typography/text/text';
import { LearnMoreLink } from '@app/feature/faq/component/learn-more-link/learn-more-link';
import { ChargeStateEnum } from '@app/core/model/enum/charge-state.enum';
import { useCharge } from '@app/core/context-providers/charge-context/use-charge';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { H1 } from '@app/shared/typography/heading/heading';
import { ChargePointName } from '@app/shared/charge-point/charge-point-name/charge-point-name';
import { Divider } from '@app/shared/divider/divider';
import { CallSupportButton } from '@app/shared/call-support/call-support-button';

import { ChargeChartController } from '../charge-chart/charge-chart.controller';
import { LiveDurationController } from '../live-duration/live-duration.controller';
import css from './charging.module.scss';
import { StopChargeButton } from '../stop-charge-button/stop-charge-button';
import { LiveTotalKwh } from '../live-total-kwh/live-total-kwh';
import { LiveTotalTTC } from '../live-total-ttc/live-total-ttc';

type Props = {
  chargePointName?: string;
  physicalReference?: string;
};

export const Charging = (props: Props): JSX.Element => {
  const { chargePointName, physicalReference } = props;
  const { startedAt, updatedAt, totalKwh, totalTTC, chargeState } = useCharge();
  const { t } = useTranslation();

  const isCharging = useMemo(
    () => !!(totalTTC ?? totalKwh ?? startedAt),
    [totalKwh, totalTTC, startedAt]
  );

  return (
    <PageLayout withHomeButton>
      <ChargePointName
        name={chargePointName}
        physicalReference={physicalReference}
      />

      <PageLayout.Header>
        <H1>{t('charging.title.default')}</H1>
      </PageLayout.Header>

      <div className={css.liveDataSection}>
        <LiveTotalKwh
          disabled={!totalKwh}
          className={css.flex1}
          energyDelivered={totalKwh}
        />
        <div className={css.liveDataLine}>
          <LiveDurationController
            className={css.flex1}
            disabled={!startedAt}
            startedAt={startedAt}
            isStopped={chargeState === ChargeStateEnum.STOP_CHARGE_REQUESTED}
          />
          <LiveTotalTTC
            className={css.flex1}
            totalTTC={totalTTC}
            disabled={!totalTTC}
          />
        </div>
      </div>

      <div>
        <ChargingCarAnim
          animate={isCharging}
          loading={!isCharging}
          className={css.chargingCar}
          message={!isCharging ? t('charging.waiting-for-data') : null}
        />

        <Divider type="solid" />
      </div>

      <ChargeChartController />

      <div className={css.hint}>
        <Text>{t('charging.last-data-received-at', { updatedAt })}</Text>
        <Text>
          {t('charging.provisional-information')}{' '}
          <LearnMoreLink questionId="data-given-as-an-indication-during-charging" />
        </Text>
      </div>

      <CallSupportButton />

      <PageLayout.Footer>
        <StopChargeButton />
      </PageLayout.Footer>
    </PageLayout>
  );
};
