import { startAuthentication as openWebauthnAuthenticationDialog } from '@simplewebauthn/browser';
import type { PublicKeyCredentialRequestOptionsJSON } from '@simplewebauthn/typescript-types';

import { ApiError } from '@app/core/error/api-error';
import { confirmIdentityService } from '@app/core/service/confirm-identity.service';
import { WebauthnErrorDialog } from '@app/shared/webauthn-error-dialog/webauthn-error-dialog';
import { useCallback, useEffect, useState } from 'react';
import { useDialog } from '@app/shared/dialog/use-dialog';

interface VerifyIdentityWithWebauthnControllerProps {
  startPending: boolean;
  identityVerificationKey: string;
  options: PublicKeyCredentialRequestOptionsJSON;
  onSuccess: (token: string) => void;
  onRetry: () => Promise<void>;
  onCancel: () => void;
}

export const VerifyIdentityWithWebauthnController = (
  props: VerifyIdentityWithWebauthnControllerProps
): JSX.Element => {
  const {
    startPending,
    identityVerificationKey,
    options,
    onSuccess,
    onRetry,
    onCancel,
  } = props;
  const [verifyPending, setVerifyPending] = useState(false);
  const {
    openDialog: openErrorDialog,
    isDialogOpen: isErrorDialogOpen,
    closeDialog: closeErrorDialog,
  } = useDialog();

  const handleCancel = useCallback(() => {
    closeErrorDialog();
    onCancel();
  }, [onCancel, closeErrorDialog]);

  const handleVerify = useCallback(async () => {
    setVerifyPending(true);

    try {
      const authenticationResponse =
        await openWebauthnAuthenticationDialog(options);
      const { token } = await confirmIdentityService.verifyIdentityVerification(
        {
          authenticationResponse,
          identityVerificationKey,
        }
      );

      setVerifyPending(false);
      onSuccess(token);
    } catch (error) {
      setVerifyPending(false);

      //  Do nothing if webauthn timed out or user declines the UI
      if (!(error instanceof ApiError)) {
        handleCancel();
        return;
      }

      if (error.isIntercepted) {
        return;
      }

      openErrorDialog();
    }
  }, [
    identityVerificationKey,
    options,
    openErrorDialog,
    onSuccess,
    handleCancel,
  ]);

  useEffect(() => {
    handleVerify();
  }, [handleVerify]);

  return (
    <WebauthnErrorDialog
      pending={startPending || verifyPending}
      open={isErrorDialogOpen}
      onClose={handleCancel}
      onRetry={onRetry}
    />
  );
};
