import { WithUserProps } from '@app/router/guard/with-user.guard';
import { useDrawer } from '@app/shared/drawer/use-drawer';
import { UpdateEmailDrawerController } from '@app/feature/account/view/user-security/view/update-email/update-email-drawer.controller';
import { useCharge } from '@app/core/context-providers/charge-context/use-charge';

import { UserSecurityView } from './user-security.view';

export const UserSecurityController = (props: WithUserProps): JSX.Element => {
  const { user, refresh: refreshUser } = props;
  const { isChargeStarted } = useCharge();

  const {
    openDrawer: openUpdateEmailDrawer,
    drawerState: updateEmailDrawerState,
    closeDrawer: closeUpdateEmailDrawer,
  } = useDrawer();

  return (
    <>
      <UserSecurityView
        emailAddress={user.emailAddress}
        onUpdateEmail={openUpdateEmailDrawer}
        isChargeStarted={isChargeStarted}
      />
      <UpdateEmailDrawerController
        emailAddress={user.emailAddress}
        onClose={closeUpdateEmailDrawer}
        state={updateEmailDrawerState}
        onSuccess={refreshUser}
      />
    </>
  );
};
