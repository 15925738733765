import { Text } from '@app/shared/typography/text/text';
import { useTranslation } from 'react-i18next';

import css from './charge-graph.module.scss';

interface ChargeGraphProps {
  consumption: number;
  height: number;
}
export const ChargeGraph = ({
  consumption,
  height,
}: ChargeGraphProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={css.graphContainer}>
      <div className={css.barContainer}>
        <div className={css.bar} style={{ height: `${height}%` }} />
      </div>
      <Text fontSize="large" bold>
        {t('dashboard.charge-graph.consumption', {
          value: consumption,
        })}
      </Text>
    </div>
  );
};
